export default {
//  'home.banner.title': 'Set sail on an alternative investment opportunity',
//  'home.banner.subTitle': 'in the lucrative ship owning industry.',
    'home.banner.title': "Set sail on an alternative investment opportunity in the lucrative ship owning industry.",
    "home.banner.subTitle":" ",
 'home.banner.btn': 'Browse',
 
 'home.newslist.more': 'Learn more',

//  'home.introduce.title': 'What can you get from MDR?',
'home.introduce.title': 'What is MDR?',
//  'home.introduce.desc': 'Is an alternative investing platform that removes barrier-of-entry costs and allows everyone to become a shipowner',
'home.introduce.desc': 'MDR is an informational platform that increases liquidity and transparency while lessening traditional ship owning entry barriers.',

 'home.introduce.more': 'Learn More >',

 'home.introduce.invest.type.title': 'Project Types',

 'home.introduce.invest.desc': 'Apply to be a shipowner \nand  start experience tokenised vessel online instantly',
 'home.introduce.invest.apply.btn': 'Apply STO Now',

 'home.introduce.partner.title': 'Our Partners',

 'home.ship.type': JSON.stringify(['New Building Vessel', 'Trading Vessel', 'Demolition Vessel']) ,

 'home.ship.invest.btn': 'Invest Now',
}