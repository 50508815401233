import { observable, action, values } from "mobx";
import api from "../../../../../../../api";
import { mock } from "./mock";
import { IDtem } from "../../../../../../../components/DynamicFormItem";
import stepRootStore from '../../store';
import { message } from "antd";

class VesselStore {
    
    @observable formItemsList = {
        1: [],
        2: [],
        3: [],
        4: [],
        5: [],
        6: [],
        7: [],
    } as any;

    @observable showConfirm = false;

    @observable loading = false;

    @observable showSubmitConfirm = false;

    @observable projectType = '';

    @observable vesselType = '';

    @observable curStep = 1;

    @observable vesselTypeList = [];

    @action.bound
    setCurStep(cur: number) {
        this.curStep = cur;
    }

    @action.bound
    next() {
        this.curStep += 1;
    }


    // @action.bound
    // async fetchAllFormItems() {
    //     const params = {
    //         projectType: this.projectType,
    //         vesselType: '',
    //     }
    //     // const data = await api.findOneSto(params);
    //     const data = await api.findAllSto(params);

    //     this.formItems = data || [];
    // }

    async fetchVesselTypeList() {
        const data = await api.fetchOptions();
        this.vesselTypeList = data || [];
    }

    async updateStoInfoMain() {
        const data = await api.updateInfoMain({
            projectType: this.projectType,
            vesselType: this.vesselType,
            id: stepRootStore.stoId,
        });
    }

    async findStoInfoAllById(tabNum: number) {
        const data = await api.findStoInfoAllById(
            stepRootStore.stoId,
            tabNum,
        );
        this.formItemsList[tabNum] = data;
    }

    // async handleConfirm() {
    //     await this.updateStoInfoMain();
    //     await this.addStoInfoAll();
    //     await this.findStoInfoAllById(1);
    // }

    @action.bound
    handleprojectType(type: string) {
        this.projectType = type
    }

    @action.bound
    handleVesselType(type: string) {
        this.vesselType = type;
    }

    async init() {
        // const { projectType, vesselType } = await api.getStoInfoMainById(stepRootStore.stoId) || {};
        // this.vesselType = vesselType;
        // this.projectType = projectType;
        await this.findStoInfoAllById(1);
    }

    async updateCurForm(formModel: any, cb?: () => {}) {
        if (stepRootStore.canEdit) {
            const curForm = this.formItemsList[this.curStep];
            const newCurForm = curForm.map((item: any) => {
                return {
                    ...item,
                    propertyValue: formModel[item.code] 
                }
            })
            await api.updateStoInfoAll(newCurForm);
            message.success('Saved successfully')
            this.formItemsList[this.curStep] = newCurForm;

            if (this.curStep < 7) {
                this.next();
            } else {
                this.showConfirm = true;
            }
            return;
        }

        if (this.curStep < 7) {
            this.next();
        } else {
           cb && cb();
        }
    }
    // 保存信息
    async updateFormMessage(formModel:any) {
        const curForm = this.formItemsList[this.curStep];
            const newCurForm = curForm.map((item: any) => {
                return {
                    ...item,
                    propertyValue: formModel[item.code] 
                }
            })
            await api.updateStoInfoAll(newCurForm);
            message.success('Saved successfully')
            this.formItemsList[this.curStep] = newCurForm;
    }

    handleSubmit = async () => {
        this.showConfirm = false;
        this.showSubmitConfirm = true;
        this.loading = true;
        setTimeout(() => {
            this.loading = false;
        }, 1000);
        await api.submitReviewing(stepRootStore.stoId)
    }

}

export default new VesselStore();