import React, { useEffect, useState } from 'react';

import './index.scss';
import FormItem from 'antd/lib/form/FormItem';
import { Input, Form, Modal, Menu } from 'antd';
import { observer } from 'mobx-react';

import CompanyInfo from './components/companyInfo';

import VesselInfo from './components/VesselInfo';

import FundraisingInfo from './components/fundraisingInfo';

import StoReview from './components/storeview';

import Agreement from './components/agreement';

import Approval from './components/approval';

import InitProject from './components/initproject';
import Publish from './components/publish';
import {useInterval,useEventListener} from '../../../../../hooks'

import store from './store';
import rootStore from '../../../../../store'
import { useHistory } from 'react-router-dom';

const list = [
    {
        label: 'Before%Submission',
        key: 'initProject',
    },

    {
        label: 'Fill in%Company Info',
        key: 'companyInfo',
    },

    {
        label: 'Fill in%Vessel Info',
        key: 'vesselInfo'
    }, {
        label: 'Initial%Review',

        key: 'review',
    },
    {
        label: 'Fill in%Fundraising Info',
        key: 'fundraisingInfo',
    },
    {
        label: 'Sign STO%Agreement',
        key: 'agreement',
    },
    {
        label: 'Final%Review',
        key: 'approval',
    }, {
        label: 'STO%Approval',  
        key: 'publish'
    }

];

const renderItem = (curStep: string) => {
    switch (curStep) {
        case 'companyInfo':
            return <CompanyInfo />
        case 'initProject':
            return <InitProject></InitProject>
        case 'vesselInfo':
            return <VesselInfo />
        case 'review':
            return <StoReview></StoReview>
        case 'fundraisingInfo':
            return <FundraisingInfo></FundraisingInfo>
        case 'agreement':
            return <Agreement />;
        case 'approval':
            return <Approval />;
        case 'publish':
            return <Publish/>
        default:
            break;
    }
}


const Steps: React.FC = observer(() => {
    const [menus, setMenus] = useState(list);
    const history = useHistory();
    useEffect(() => {
        initMenus();
        // 判断状态30 可以填写表单， 35 表单填写完毕提交
        if(store.stoStatus === 30 ) {//跳转到FundraisingInfo填写界面
            store.setCurStep('fundraisingInfo');
        }else if(store.stoStatus == 40) {
            store.setCurStep('agreement')
        }else if(store.stoStatus < 30) {
            store.setCurStep('initProject');
        }else if(store.stoStatus >= 50) {
            store.setCurStep('publish');
        }else {
            store.setCurStep('initProject');
        }
    }, [store.stoStatus]);
    useEventListener('mousemove', () => {
        if(!store.stoId) {
            return;
        }
        sessionStorage.setItem('lastTime', Date.now().toString());
    })
    // 开启定时器如果存在stoId，就开启定时器
    useInterval(() => {
        if(!store.stoId) {
            return;
        }
        let lastFrame = 0;
        const lastTime = sessionStorage.getItem('lastTime') || 0;
        if(lastFrame > 0 && Date.now() - lastFrame < 60 * 1000) {
            lastFrame = Date.now();
            return;
        }
        lastFrame = Date.now();
        if(!window.location.hash.startsWith('#/usercenter/sto/apply')) {
            sessionStorage.removeItem('lastItem');
            return;
        };
        if ((Date.now() - +lastTime) > 60 * 1000 * 10 ) {
            rootStore.hasPin = true;
            sessionStorage.setItem('pin', '1');
            sessionStorage.removeItem('lastItem');
        } 
    },2000);
    useEffect(() => {
        // if (store.stoStatus >= 60 && store.curStep === 'publish') {
        //     history.push(`/usercenter/sto/detail/${store.stoId}`)
        // }
    }, [store.curStep])

    const initMenus = () => {
        let menuWithStatus: any = [];
        if (store.stoStatus >= 50 || store.stoStatus === 0) {
            menuWithStatus = menus.map((item: any) => ({
                ...item,
                disable: false
            }))
            setMenus(menuWithStatus);
            return;
        }
        console.log(store.stoStatus)
        switch (store.stoStatus) {
            case 10:
                menuWithStatus = menus.map((item, index) => ({
                    ...item,
                    disabled: index > 2
                }))
                break;
            case 20:
            case 25:
                console.log('-----')
                menuWithStatus = menus.map((item, index) => ({
                    ...item,
                    disabled: index > 3
                }));
                break;
            case 30:
                menuWithStatus = menus.map((item, index) => ({
                    ...item,
                    disabled: index > 4
                }));
                break;
            case 35: 
                menuWithStatus = menus.map((item,index) => ({
                    ...item,
                    disabled: index > 4
                }));
                break;

            case 40:
                menuWithStatus = menus.map((item,index) => ({
                    ...item,
                    disabled: index > 5
                }));
                break;
            case 41:
                menuWithStatus = menus.map((item,index) => ({
                    ...item,
                    disabled: index > 6
                }));
                break;
            case 50:
                menuWithStatus = menus.map((item, index) => ({
                    ...item,
                    disabled: index > 6
                }));
                break;
            default:
                break;
        }
        setMenus(menuWithStatus);
    }

    return (
        <div className="apply-step-wrap">
            <div className="menu-wrap">
                <Menu mode="horizontal" selectedKeys={[store.curStep]}>
                    {
                        menus.map((item: any, index) => (
                            <Menu.Item disabled={item.disabled} onClick={() => {
                                if (store.canEdit) return;
                                store.curStep = item.key
                            }} key={item.key}>
                                {
                                    item.label.split('%').map((text: any, index: any) => {
                                        return (text[index] && (<p>{text}</p>));
                                    })
                                }
                            </Menu.Item>
                        ))
                    }

                </Menu>
            </div>

            <div className="content">
                {renderItem(store.curStep)}
            </div>
        </div>
    )
});

export default Steps;