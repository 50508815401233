import { values } from "mobx";
import moment from "moment";

export const noop = () => { };
interface ILanguageMap {
  [index: string]: string;
}
export const LANGUAGE_MAP: ILanguageMap = {
  "en-US": "English",
  "zh-CN": "中文",
};

export const OFFER_TYPE = [
  // {
  // label: 'Browse Offers',
  // value: 0,
  // },
// {
//   label: 'New Offers',
//   value: 1,
// },
{
  label: "Current Offers",
  value: 1
},
{
  label: 'Past Offers',
  value: 2,
}]


export const PWD_REG = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])[\da-zA-Z]{8,60}$/;

export const EMAIL_REG = /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/;


export const PHONE_COUNTRY: { [index: string]: string } = {
  86: "China",
  686: "Kiribati",
  386: "Slovenia",
  856: "Laos",
  852: "Hong Kong",
  30: "Greece",
  49:  "Germany"

};

export const PHONE_COUNTRYARRAY :{label: number,value: string,dis: string}[] = [
  {label:86,value:'China',dis:'中国'},
  {label:355,value:'Albania',dis:'阿尔巴尼亚'},
{label:213,value:'Algeria',dis:'阿尔及利亚'},
{label:1684,value:'American Samoa',dis:'美属萨摩亚'},
{label:376,value:'Andorra',dis:'安道尔'},
{label:244,value:'Angola',dis:'安哥拉'},
{label:1264,value:'Anguilla',dis:'安圭拉'},
{label:1268,value:'Antigua and Barbuda',dis:'安提瓜和巴布达'},
{label:54,value:'Argentina',dis:'阿根廷'},
{label:374,value:'Armenia',dis:'亚美尼亚'},
{label:297,value:'Aruba',dis:'阿鲁巴'},
{label:61,value:'Australia',dis:'澳大利亚'},
{label:43,value:'Austria',dis:'奥地利'},
{label:994,value:'Azerbaijan',dis:'阿塞拜疆'},
{label:1242,value:'Bahamas',dis:'巴哈马'},
{label:973,value:'Bahrain',dis:'巴林'},
{label:880,value:'Bangladesh',dis:'孟加拉国'},
{label:1246,value:'Barbados',dis:'巴巴多斯'},
{label:375,value:'Belarus',dis:'白俄罗斯'},
{label:32,value:'Belgium',dis:'比利时'},
{label:501,value:'Belize',dis:'伯利兹'},
{label:229,value:'Benin',dis:'贝宁'},
{label:1441,value:'Bermuda',dis:'百慕大群岛'},
{label:975,value:'Bhutan',dis:'不丹'},
{label:591,value:'Bolivia',dis:'玻利维亚'},
{label:387,value:'Bosnia and Herzegovina',dis:'波斯尼亚和黑塞哥维那'},
{label:267,value:'Botswana',dis:'博茨瓦纳'},
{label:55,value:'Brazil',dis:'巴西'},
{label:673,value:'Brunei',dis:'文莱'},
{label:359,value:'Bulgaria',dis:'保加利亚'},
{label:226,value:'Burkina Faso',dis:'布基纳法索'},
{label:257,value:'Burundi',dis:'布隆迪'},
{label:855,value:'Cambodia',dis:'柬埔寨'},
{label:237,value:'Cameroon',dis:'喀麦隆'},
{label:1,value:'Canada',dis:'加拿大'},
{label:238,value:'Cape Verde',dis:'开普'},
{label:1345,value:'Cayman Islands',dis:'开曼群岛'},
{label:236,value:'Central African Republic',dis:'中非共和国'},
{label:235,value:'Chad',dis:'乍得'},
{label:56,value:'Chile',dis:'智利'},
{label:57,value:'Colombia',dis:'哥伦比亚'},
{label:269,value:'Comoros',dis:'科摩罗'},
{label:682,value:'Cook Islands',dis:'库克群岛'},
{label:506,value:'Costa Rica',dis:'哥斯达黎加'},
{label:385,value:'Croatia',dis:'克罗地亚'},
{label:53,value:'Cuba',dis:'古巴'},
{label:599,value:'Curacao',dis:'库拉索'},
{label:357,value:'Cyprus',dis:'塞浦路斯'},
{label:420,value:'Czech',dis:'捷克'},
{label:243,value:'Democratic Republic of the Congo',dis:'刚果民主共和国'},
{label:45,value:'Denmark',dis:'丹麦'},
{label:253,value:'Djibouti',dis:'吉布提'},
{label:1767,value:'Dominica',dis:'多米尼加'},
{label:1809,value:'Dominican Republic',dis:'多米尼加共和国'},
{label:593,value:'Ecuador',dis:'厄瓜多尔'},
{label:20,value:'Egypt',dis:'埃及'},
{label:503,value:'El Salvador',dis:'萨尔瓦多'},
{label:240,value:'Equatorial Guinea',dis:'赤道几内亚'},
{label:291,value:'Eritrea',dis:'厄立特里亚'},
{label:372,value:'Estonia',dis:'爱沙尼亚'},
{label:251,value:'Ethiopia',dis:'埃塞俄比亚'},
{label:298,value:'Faroe Islands',dis:'法罗群岛'},
{label:679,value:'Fiji',dis:'斐济'},
{label:358,value:'Finland',dis:'芬兰'},
{label:33,value:'France',dis:'法国'},
{label:594,value:'French Guiana',dis:'法属圭亚那'},
{label:689,value:'French Polynesia',dis:'法属波利尼西亚'},
{label:241,value:'Gabon',dis:'加蓬'},
{label:220,value:'Gambia',dis:'冈比亚'},
{label:995,value:'Georgia',dis:'格鲁吉亚'},
{label:49,value:'Germany',dis:'德国'},
{label:233,value:'Ghana',dis:'加纳'},
{label:350,value:'Gibraltar',dis:'直布罗陀'},
{label:30,value:'Greece',dis:'希腊'},
{label:299,value:'Greenland',dis:'格陵兰岛'},
{label:1473,value:'Grenada',dis:'格林纳达'},
{label:590,value:'Guadeloupe',dis:'瓜德罗普岛'},
{label:1671,value:'Guam',dis:'关岛'},
{label:502,value:'Guatemala',dis:'瓜地马拉'},
{label:224,value:'Guinea',dis:'几内亚'},
{label:245,value:'Guinea-Bissau',dis:'几内亚比绍共和国'},
{label:592,value:'Guyana',dis:'圭亚那'},
{label:509,value:'Haiti',dis:'海地'},
{label:504,value:'Honduras',dis:'洪都拉斯'},
{label:852,value:'Hong Kong',dis:'中国香港'},
{label:36,value:'Hungary',dis:'匈牙利'},
{label:354,value:'Iceland',dis:'冰岛'},
{label:91,value:'India',dis:'印度'},
{label:62,value:'Indonesia',dis:'印度尼西亚'},
{label:98,value:'Iran',dis:'伊朗'},
{label:964,value:'Iraq',dis:'伊拉克'},
{label:353,value:'Ireland',dis:'爱尔兰'},
{label:972,value:'Israel',dis:'以色列'},
{label:39,value:'Italy',dis:'意大利'},
{label:225,value:'Ivory Coast',dis:'象牙海岸'},
{label:1876,value:'Jamaica',dis:'牙买加'},
{label:81,value:'Japan',dis:'日本'},
{label:962,value:'Jordan',dis:'约旦'},
{label:7,value:'Kazakhstan',dis:'哈萨克斯坦'},
{label:254,value:'Kenya',dis:'肯尼亚'},
{label:686,value:'Kiribati',dis:'基里巴斯'},
{label:965,value:'Kuwait',dis:'科威特'},
{label:996,value:'Kyrgyzstan',dis:'吉尔吉斯斯坦'},
{label:856,value:'Laos',dis:'老挝'},
{label:371,value:'Latvia',dis:'拉脱维亚'},
{label:961,value:'Lebanon',dis:'黎巴嫩'},
{label:266,value:'Lesotho',dis:'莱索托'},
{label:231,value:'Liberia',dis:'利比里亚'},
{label:218,value:'Libya',dis:'利比亚'},
{label:423,value:'Liechtenstein',dis:'列支敦士登'},
{label:370,value:'Lithuania',dis:'立陶宛'},
{label:352,value:'Luxembourg',dis:'卢森堡'},
{label:853,value:'Macau',dis:'中国澳门'},
{label:389,value:'Macedonia',dis:'马其顿'},
{label:261,value:'Madagascar',dis:'马达加斯加'},
{label:265,value:'Malawi',dis:'马拉维'},
{label:60,value:'Malaysia',dis:'马来西亚'},
{label:960,value:'Maldives',dis:'马尔代夫'},
{label:223,value:'Mali',dis:'马里'},
{label:356,value:'Malta',dis:'马耳他'},
{label:596,value:'Martinique',dis:'马提尼克'},
{label:222,value:'Mauritania',dis:'毛里塔尼亚'},
{label:230,value:'Mauritius',dis:'毛里求斯'},
{label:269,value:'Mayotte',dis:'马约特'},
{label:52,value:'Mexico',dis:'墨西哥'},
{label:373,value:'Moldova',dis:'摩尔多瓦'},
{label:377,value:'Monaco',dis:'摩纳哥'},
{label:976,value:'Mongolia',dis:'蒙古'},
{label:382,value:'Montenegro',dis:'黑山'},
{label:1664,value:'Montserrat',dis:'蒙特塞拉特岛'},
{label:212,value:'Morocco',dis:'摩洛哥'},
{label:258,value:'Mozambique',dis:'莫桑比克'},
{label:95,value:'Myanmar',dis:'缅甸'},
{label:264,value:'Namibia',dis:'纳米比亚'},
{label:977,value:'Nepal',dis:'尼泊尔'},
{label:31,value:'Netherlands',dis:'荷兰'},
{label:687,value:'New Caledonia',dis:'新喀里多尼亚'},
{label:64,value:'New Zealand',dis:'新西兰'},
{label:505,value:'Nicaragua',dis:'尼加拉瓜'},
{label:227,value:'Niger',dis:'尼日尔'},
{label:234,value:'Nigeria',dis:'尼日利亚'},
{label:47,value:'Norway',dis:'挪威'},
{label:968,value:'Oman',dis:'阿曼'},
{label:92,value:'Pakistan',dis:'巴基斯坦'},
{label:680,value:'Palau',dis:'帕劳'},
{label:970,value:'Palestine',dis:'巴勒斯坦'},
{label:507,value:'Panama',dis:'巴拿马'},
{label:675,value:'Papua New Guinea',dis:'巴布亚新几内亚'},
{label:595,value:'Paraguay',dis:'巴拉圭'},
{label:51,value:'Peru',dis:'秘鲁'},
{label:63,value:'Philippines',dis:'菲律宾'},
{label:48,value:'Poland',dis:'波兰'},
{label:351,value:'Portugal',dis:'葡萄牙'},
{label:1787,value:'Puerto Rico',dis:'波多黎各'},
{label:974,value:'Qatar',dis:'卡塔尔'},
{label:242,value:'Republic Of The Congo',dis:'刚果共和国'},
{label:262,value:'Réunion Island',dis:'留尼汪'},
{label:40,value:'Romania',dis:'罗马尼亚'},
{label:7,value:'Russia',dis:'俄罗斯'},
{label:250,value:'Rwanda',dis:'卢旺达'},
{label:1869,value:'Saint Kitts and Nevis',dis:'圣基茨和尼维斯'},
{label:1758,value:'Saint Lucia',dis:'圣露西亚'},
{label:508,value:'Saint Pierre and Miquelon',dis:'圣彼埃尔和密克隆岛'},
{label:1784,value:'Saint Vincent and The Grenadines',dis:'圣文森特和格林纳丁斯'},
{label:685,value:'Samoa',dis:'萨摩亚'},
{label:378,value:'San Marino',dis:'圣马力诺'},
{label:239,value:'Sao Tome and Principe',dis:'圣多美和普林西比'},
{label:966,value:'Saudi Arabia',dis:'沙特阿拉伯'},
{label:221,value:'Senegal',dis:'塞内加尔'},
{label:381,value:'Serbia',dis:'塞尔维亚'},
{label:248,value:'Seychelles',dis:'塞舌尔'},
{label:232,value:'Sierra Leone',dis:'塞拉利昂'},
{label:65,value:'Singapore',dis:'新加坡'},
{label:1721,value:'Saint Maarten (Dutch Part)',dis:'圣马丁岛（荷兰部分）'},
{label:421,value:'Slovakia',dis:'斯洛伐克'},
{label:386,value:'Slovenia',dis:'斯洛文尼亚'},
{label:677,value:'Solomon Islands',dis:'所罗门群岛'},
{label:252,value:'Somalia',dis:'索马里'},
{label:27,value:'South Africa',dis:'南非'},
{label:82,value:'South Korea',dis:'韩国'},
{label:34,value:'Spain',dis:'西班牙'},
{label:94,value:'Sri Lanka',dis:'斯里兰卡'},
{label:249,value:'Sudan',dis:'苏丹'},
{label:597,value:'Suriname',dis:'苏里南'},
{label:268,value:'Swaziland',dis:'斯威士兰'},
{label:46,value:'Sweden',dis:'瑞典'},
{label:41,value:'Switzerland',dis:'瑞士'},
{label:963,value:'Syria',dis:'叙利亚'},
{label:886,value:'Taiwan',dis:'中国台湾'},
{label:992,value:'Tajikistan',dis:'塔吉克斯坦'},
{label:255,value:'Tanzania',dis:'坦桑尼亚'},
{label:66,value:'Thailand',dis:'泰国'},
{label:670,value:'Timor-Leste',dis:'东帝汶'},
{label:228,value:'Togo',dis:'多哥'},
{label:676,value:'Tonga',dis:'汤加'},
{label:1868,value:'Trinidad and Tobago',dis:'特立尼达和多巴哥'},
{label:216,value:'Tunisia',dis:'突尼斯'},
{label:90,value:'Turkey',dis:'土耳其'},
{label:993,value:'Turkmenistan',dis:'土库曼斯坦'},
{label:1649,value:'Turks and Caicos Islands',dis:'特克斯和凯科斯群岛'},
{label:256,value:'Uganda',dis:'乌干达'},
{label:380,value:'Ukraine',dis:'乌克兰'},
{label:971,value:'United Arab Emirates',dis:'阿拉伯联合酋长国'},
{label:44,value:'United Kingdom',dis:'英国'},
{label:1,value:'United States',dis:'美国'},
{label:598,value:'Uruguay',dis:'乌拉圭'},
{label:998,value:'Uzbekistan',dis:'乌兹别克斯坦'},
{label:678,value:'Vanuatu',dis:'瓦努阿图'},
{label:58,value:'Venezuela',dis:'委内瑞拉'},
{label:84,value:'Vietnam',dis:'越南'},
{label:1340,value:'Virgin Islands, British',dis:'英属处女群岛'},
{label:1284,value:'Virgin Islands, US',dis:'美属维尔京群岛'},
{label:967,value:'Yemen',dis:'也门'},
{label:260,value:'Zambia',dis:'赞比亚'},
{label:263,value:'Zimbabwe',dis:'津巴布韦'},
]

export const VESSEL_TYPE = [
  {
    value: "newBuildingVessel", label: "New Building Vessel"
  },
  { value: "tradingVessel", label: "Trading Vessel" },
  {
    value: "demolitionVessel", label: "Demolition Vessel"
  }]

export const OBJECTIVE = [
  {
    value: 'Equity', label: "Equity"
  },
  { value: "Liability", label: "Liability" },
]
//sto旧版状态
//    { value: 10, label: 'Draft' },
//   { value: 20, label: 'Reviewing' },
//   { value: 30, label: 'Review passed' },
//   { value: 40, label: 'agreement signing' },
//   { value: 41, label: 'agreement signing'},
//   { value: 42, label: 'agreement signing'},
//   { value: 50, label: 'Approving' },
//   { value: 60, label: 'Published and Fundraising' },
//   { value: 70, label: 'Fundraising Completed' },
//   { value: 80, label: 'withdrawal Rejected' },
//   { value: 80, label: 'withdrawal Rejected' },
//   { value: 90, label: 'Ongoing' },
//   { value: 25, label: 'Review rejected' },
//   { value: 55, label: 'Approval rejected' },
//   { value: 75, label: 'Refunded' },
//   { value: 76, label: "Refund after transfer"},
//   { value: 85, label: 'withdrawal Rejected' },
//   { value: 100, label: 'Finished' },
//   { value: 35, label: 'Final submit'}
export const STO_STATUS = [
  { value: 10, label: 'Draft' },
  { value: 20, label: 'Under Review' },
  { value: 25, label: 'Initial Review Rejected' },
  { value: 30, label: 'Initial Review Approved' },
  { value: 35, label: 'Second Submission'},
  { value: 40, label: 'Read Agreement'},
  { value: 41, label: 'Read Agreement'},
  { value: 42, label: 'Read Agreement'},
  { value: 50, label: 'Second Review Approved' },
  { value: 55, label: 'Second Review Rejected'},
  { value: 60, label: 'Offer 01' },
  { value: 70, label: 'Offer 02C' },
  { value: 71, label: 'Offer 02A' },
  { value: 75, label: 'Offer 02B1' },
  { value: 76, label: 'Offer 02B2'},
  { value: 80, label: 'STO Settlement' },
  { value: 85, label: 'STO Settlement' },
  { value: 90, label: 'STO Settlement' },
  { value: 100, label: 'STO Finished' },
]

//旧版offer状态
// 1: 'Fundraising in queue 00',
//   2: 'Fundraising In Progress 01',
//   3: 'Fundraising End (Incompleted) 02A',
//   4: 'Fundraising End (Refund) 02B',
//   5: 'Fundraising End (Completed) 02C',
//   6: "Fundraising End(Refund)"
export const RZ_STATUS = {
  1: 'Before Fundraising 00',
  2: 'Fundraising In Progress 01',
  3: 'Fundraising End (Incompleted) 02A',
  4: 'Fundraising End (Refund) 02B',
  5: 'Fundraising End (Completed) 02C',
  6: "Fundraising End(Refund)"
}

export const GOAL = [
  { value: '<1,000,000 USD', label: '<1,000,000 USD' },
  { value: '1,000,000 - 5,000,000 USD', label: '1,000,000 - 5,000,000 USD' },
  { value: '5,000,000 - 10,000,000 USD', label: '5,000,000 - 10,000,000 USD' },
  { value: '10,000,000 - 20,000,000 USD', label: '10,000,000 - 20,000,000 USD' },
  { value: '>20,000,000 USD', label: '>20,000,000 USD' },
]

export const TIME = [
  {
    label: 'This Week',
    value: 1,
  },
  {
    label: 'This Month',
    value: 2,
  },
  {
    label: 'This Year',
    value: 3,
  },
  {
    label: 'Last Year',
    value: 4,
  }
]

export const uploadAction = `${process.env.NODE_ENV === 'development' ? '' : '/mdr'}/api/website/uploadFile`;

export const STO_STATUS_MAP = STO_STATUS.reduce((prev, next) => {
  console.log(prev)
  //@ts-ignore
  prev[next.value] = next.label;
  return prev;
}, {})
