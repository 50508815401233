
import React, { useState, useEffect } from 'react';

import './sto.less';
import { Button,TokenDetailModal } from '../../../../components';
import { formatMoney, formatThreeMoney ,formatMoneyDecimal, formatTime} from '../../../../utils';

import {  Table, Modal, Pagination, } from 'antd'
import { CaretRightOutlined } from '@ant-design/icons'
import dayjs from 'dayjs';
import api from '../../../../api';
import { useHistory } from 'react-router-dom';
import {TableTitle, ButtonIcon} from "./myPortfolio";
import peopleStore from "../../peopleStore";
import './Ex.less'
const columns = (handleDetail: Function) => [
    {
        title: () => <TableTitle title='Operation'/>,
        dataIndex: 'operation',
        key: 'operation',
        align: 'center',
        width:160,
        render: (item: any, row: any) => {
            return [
                <>
                    <ButtonIcon  title='View Details' onClick={() => handleDetail(row)} row={row}><CaretRightOutlined/></ButtonIcon>
                </>
            ]
        }
    },
    {
        title: () => <TableTitle title='Token / Name'/>,
        dataIndex: 'tokenName',
        key: 'tokenName',
        align: 'center',
        // width: '105px',
        
    },

    {
        title: () => <TableTitle title='Vessel / Name' />,
        dataIndex: 'vesselName',
        key: 'vesselName',
        width: '105',
        align: 'center',
      },
   
    {
        title: () => <TableTitle title='STO Price / in MDRX per token'/>,
        dataIndex: 'tokenPrice',
        key: 'tokenPrice',
        align: 'center',
        render: (item: any) => item ? formatMoneyDecimal(Number(item || 0),3) : '--'
        // width: '140px'
    },

    {
        title: () => <TableTitle title='Quantity'/>,
        dataIndex: 'tokenNum',
        key: 'tokenNum',
        align: 'center',
        render: (item: any) => {
            return (
                <div style={{ textAlign: 'right'}}>
                    {item ? formatMoney(item) : '--'}
                </div>
            );
        }
        // width: '105px'
    },

    {
        title: () => <TableTitle title='Value / in MDRX'/>,
        dataIndex: 'tokenValue',
        key: 'tokenValue',
        align: 'center',
        render: (item: any) => item ? formatMoneyDecimal(item,3) : '--'
        // width: '100px'
    },
    {
        title: () => <TableTitle title='Investment / Date'/>,
        dataIndex: 'purchasedate',
        key: 'purchasedate',
        align: 'center',
        // width: '125px',
        render: (item: string) => formatTime(item,'date')
    },
];


const Sto = () => {
    const [total, setTotal] = useState(0);
    const [pageIndex,setPageIndex] = useState(1);
    const [list, setList] = useState([]) as any;
    const [showDetail, setShowDetail] = useState(false);
    const [showTokenDetail,setShowTokenDetail] = useState(false);
    const [TokenDetailId,setTokenDetailId] = useState(0);
    const [totkenName,setTokenName] = useState('');
    const [detail, setDetail] = useState({}) as any;



    const history = useHistory();
    useEffect(() => {
        peopleStore.getPeopleHoldTokenSTO(pageIndex).then(_ => {
            setList(peopleStore.STOTokenList);
        })
    }, [pageIndex])

    const fetchDetail = async (row:any) => {
        setTokenDetailId(row.stoId);
        setTokenName(row.tokenName);
        // const data = await api.getShipperOfferList();
        // const item = data.find((item: any) => item.id === id);
        // if(!item) {
        //     message.info('暂无详情')
        //     return
        // }
        // setDetail(item);
        // setShowDetail(true);
        setShowTokenDetail(true);
    }

    const cols = columns(fetchDetail) as any

    return (
        <div className="token-wrap">
            <Table  pagination={false}  id={'exId'}
                columns={cols} 
                className="table-tr-box" 
                scroll={{ x: 920 }} 
                dataSource={list}
            />
            <Pagination style={{marginTop: '50px'}} className='mdr-pagination'
                        showTotal={(total) => `${total} items  in total`}
                        onChange={(page) =>  setPageIndex(page)}
                        showSizeChanger={false}
                        showQuickJumper={false}
                        pageSize={10}
                        current={pageIndex}
                        defaultCurrent={pageIndex}
                        total={peopleStore.STOTokenTotal} />
           
            <Modal
                visible={showTokenDetail}
                footer={<div style = {{textAlign: "center"}}><Button onClick = {() => setShowTokenDetail(false)}>OK</Button></div>}
                onCancel={() => setShowTokenDetail(false)}
                destroyOnClose
                className='detail-modal'
                centered
                width = {730}
                title={`Token History of "${totkenName}"`}
            >
                <TokenDetailModal id = {TokenDetailId}></TokenDetailModal>
            </Modal>
        </div>
    )
}

export default Sto;
