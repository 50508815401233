export default {
  "reset.dynamic.tips":
    "Dynamic password is valid within 1 hour. Please change password in time.",

  "reset.password": "Reset Password",
  "reset.dynamic": "Dynamic Password",

  'reset.forget.pwd': 'Forgot your password?',

  'reset.login.email': 'Login Email',

  'reset.sms.fail': 'You have failed 3 times，Please contact MDR for assistance',

  'reset.email.verify': 'Get Email Verification Now',

  'reset.email.resend': 'Click here to re-send',

  'reset.pwd.new': 'Set up a new Password',

  'reset.pwd.success': 'password reset succeeded!',

  "reset.steps": JSON.stringify([
    {
      key: "phone",
      value: "Enter phone number",
    },
    {
      key: "sms",
      value: "Phone verification",
    },
    {
      key: "email",
      value: "Mail validation",
    },
    {
      key: "reset",
      value: "Reset Password",
    },
  ]),
};
