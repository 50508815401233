import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import './index.scss';
import { Title, Button, MSelect, CompanyForm } from '../../../../../../../components';
import { Form, Modal, message,Upload, Progress} from 'antd';
import CompanyList from '../../../../../Company/list'
import StepStore from '../../store'
import { SettingOutlined, SearchOutlined, PlusOutlined } from '@ant-design/icons';
import api from '../../../../../../../api';
import { useForm } from 'antd/lib/form/util';
import { uploadAction } from '../../../../../../../constants';
import { getImageUrl } from '../../../../../../../utils';
import stepRootStore from '../../store'

const descList = [
    {
        title: 'Shipowner (STO Applicant)',
        descs: [
            'The person or entity initiating this STO',
        ]
    },
    {
        title: 'Technical Management Company',
        descs: [
            `The company acting as the ship's technical manager.`,
        ]
    },
    {
        title: 'Asset Management Company',
        descs: [
            `The entity acting as the ship's holding company`,
        ]
    },
    {
        title: 'SPV Company',
        descs: [
            'The entity acting as the Special Purpose Vehicle (SPV) for the STO',
        ]
    },
]

const layout = {
    labelCol: { span: 11 },
    wrapperCol: { span: 13 },
};


const CompanyInfo: React.FC = () => {
    const [companyList, setCompanyList] = useState([]);
    const [id, setid] = useState('');
    const [form] = useForm();
    const [showCompanyForm, setshowCompanyForm] = useState(false);
    const [uploadProgress,setUploadProgress] = useState(0)//上传组件进度控制
    const [uploadValue,setUploadValaue] = useState('');
    const [showCompanyList,setshowCompanyList] = useState(false);//列表弹窗
    const { formatMessage: f } = useIntl();
    useEffect(() => {
        init();
        return () => {
            setUploadValaue('');
        }
    }, [showCompanyForm]);
    const init = async () => {
        await fetchCompanyOptions();
        await findStoCompnayById();
    }


    const fetchCompanyOptions = async () => {
        const data = await api.fetchCompanyOptions();
        setCompanyList(data.map((item: any) => ({
            label: item.companyName,
            value: item.id,
        })));
    }

    const findStoCompnayById = async () => {
        const data = await api.findStoCompnayById(stepRootStore.stoId);
        form.setFieldsValue({
            ...data,
        });
        setUploadValaue(data.spvFile)
    }

    const handleClick = () => {
        saveStoCompanyInfo();
    }

    const saveStoCompanyInfo = () => {
       
        form.validateFields().then(async () => {
             // 判断是否上传文件
        if(!uploadValue) {
            message.warning('Please Upload');
            return;
        }
            await api.saveStoCompanyInfo({
                id: stepRootStore.stoId,
                ...form.getFieldsValue(),
                spvFile: uploadValue?uploadValue:''
            });
            stepRootStore.setCurStep('vesselInfo')
        })
    }


    const renderAdd = () => (
        <div className='company-add-helper' onClick={() => {
            setid('');
            setshowCompanyForm(true)
        }}>
            <PlusOutlined />
            <span> Add New Company to my profile</span>
        </div>
    )

    const handleFindCompanyDetail = async () => {
        const id = form.getFieldValue('shipownerId');
        if (!id) return;
        setshowCompanyForm(true);
        setid(id);
    }
    // 文件上传
    const handleUploadChange = (response:any) => {
        const event:any = response.event;
        if (event) { // 一定要加判断，不然会报错,这里还没有完成
            let percent = Math.floor((event.loaded / event.total) * 100) - 12;
            setUploadProgress(percent);
          }
            const resp = response.file.response;
            if (resp !== undefined) {
                if (resp.code === '200') {//成功提示成功，两秒后消失
                    setUploadProgress(100);
                    setTimeout(()=> {
                        setUploadProgress(0);
                    },2000)
                    const { objectName } = resp.data;
                    console.log(objectName);
                    // setVal(objectName);
                    setUploadValaue(objectName)
                } else {
                    setUploadProgress(101);//异常提示错误信息一秒后消失
                    setTimeout(() => {
                        setUploadProgress(0);
                    })
                    message.error(resp.msg)
                }
            }
        
    }
    // 上传文件之前，规范上传类型
    const beforeUploadChange = (file:any) => {
        // 如果是需要上传pdf，则判断是不是pdf不是的话，提示返回
            if(file.type !== 'application/pdf') {
                message.warning(f({ id: 'offer.uploadPdf' }));
                return false;
            }
        return true; 
    }
    // 预览
    const handlePreView = async () => {
        if(!uploadValue) {
            return;
        }
        const url = await getImageUrl(uploadValue);
        window.open(url, 'new_mdr', 'width=600,height=800')
    }

    return (
        <div className="apply-companyinfo-wrap">
            <div className="header row">
                <Title>Select Vessel-related Company Information</Title>

                <p
                    onClick={() => {
                        // window.open('');
                        
                        // window.open('/#/usercenter/company', '_blank', 'height=1200')
                        setshowCompanyList(true);
                    }}
                    className='right row'>
                    <SettingOutlined />
                    Click to manage Company Information
                </p>
            </div>
            <div className="body row">
                <Form
                    form={form}
                    layout='vertical'

                >
                    <div className='row owner-info' >
                        <Form.Item
                            name='shipownerId'
                            rules={[{ required: true, message: 'Shipowner Info is required' }]}
                            label={
                                <div className='row'>
                                    <span>Item A. Shipowner Info</span>
                                    <span style={{ marginLeft: 'auto' }}>(Also known as STO Applicant)</span>
                                </div>
                            }
                        >
                            <MSelect 
                            onDropdownVisibleChange={fetchCompanyOptions} 
                            tailRender={renderAdd} 
                            className='select' 
                            dataSource={companyList}
                            disabled ={!stepRootStore.canEdit}
                             />
                        </Form.Item>
                        <SearchOutlined onClick={handleFindCompanyDetail} className='search-icon' />
                    </div>

                    <Form.Item rules={[{ required: true, message: 'Management Company Info is required' }]} name='managementId' label='Item B. Technical Management Company Info'
                    >
                        <MSelect
                        disabled ={!stepRootStore.canEdit}
                         onDropdownVisibleChange={fetchCompanyOptions} tailRender={renderAdd} className='select' dataSource={companyList} />
                    </Form.Item>


                    <Form.Item rules={[{ required: false }]} name='assetsId' label='Item C. Assets Management Company Info [Optional]'
                    >
                        <MSelect 
                        disabled ={!stepRootStore.canEdit}
                        onDropdownVisibleChange={fetchCompanyOptions} tailRender={renderAdd} className='select' dataSource={companyList} />
                    </Form.Item>

                    <Form.Item name='spvId' rules={[{ required: true, message: 'SPV Company Info is required' }]} label='Item D. SPV Company Info'
                    >
                        <MSelect
                        disabled ={!stepRootStore.canEdit} 
                        onDropdownVisibleChange={fetchCompanyOptions} tailRender={renderAdd} className='select' dataSource={companyList} />
                    </Form.Item>
                    <Form.Item name='spvFile'  label='SPV Ownership Structure'
                    rules={[{ required: true, message: 'Please upload the file' }]}
                    >
                        <div className='row upload-wrap'>
                        <Upload
                            disabled ={!stepRootStore.canEdit}
                            data={{ type: 'other' }}
                            action={uploadAction}
                            accept={'.pdf'}
                            showUploadList={false}
                            onChange={handleUploadChange}
                            beforeUpload = {beforeUploadChange}
                        >
                            <Button type='ghost' disabled ={!stepRootStore.canEdit}>
                                Upload
                            </Button>
                            {uploadProgress > 0 && <Progress percent = {uploadProgress} status ={uploadProgress === 101? 'exception':'active'}></Progress>}
                        </Upload>
                        {
                            uploadValue && <span  style = {{marginLeft:"20px",cursor: 'pointer'}} onClick={() => handlePreView()} className='download'>click to view</span>
                        }
                    </div>
                    </Form.Item>

                    <div className="submit-wrap row">
                        <Button
                            arrowLeft
                            type='ghost'
                            size='middle'
                            onClick={() => stepRootStore.curStep = 'initProject'}
                        >Go Back</Button>
                        <Button
                            onClick={handleClick}
                            arrowRight
                            size='middle'
                        >
                            {stepRootStore.canEdit ? 'Save & Next ' : 'Next'}
                        </Button>
                    </div>

                </Form>
                <div className="info">
                    <p className="info-title">What company info is required?</p>
                    <p className="info-title-desc">
                    Each vessel must disclose its shipowner (STO applicant), technical management company, asset management company, and SPV information. All information must be provided in the requested digital format.
                    </p>

                    <div className="descs">
                        {
                            descList.map(item => (
                                <div className="desc-item">
                                    <p className='title'>{item.title}</p>

                                    {
                                        item.descs.map(item => (
                                            <div className='desc-detail'>
                                                <div className='dot' />
                                                <span>{item}</span>
                                            </div>
                                        ))
                                    }
                                </div>
                            ))
                        }
                    </div>
                </div>
            </div>
            <Modal
                visible={showCompanyForm}
                centered
                footer={null}
                // closable={false}
                maskClosable={false}
                destroyOnClose
                getContainer={false}
                onCancel={() => setshowCompanyForm(false)}
                className='apply-company-modal'
            >
                <div className="row" style = {{borderBottom: "1px solid #ccc",paddingBottom: "10px"}}>
                    <Title>Company Info</Title>
                </div>
                
                <CompanyForm
                    {...layout}
                    colon={false}
                    id={id}
                    style={{ paddingTop: '20px', paddingRight: '100px' }}
                    onSave={() => setshowCompanyForm(false)}
                    onClose={() => setshowCompanyForm(false)}
                />
            </Modal>
            <Modal
                visible={showCompanyList}
                centered
                footer={null}
                // closable={false}
                maskClosable={false}
                destroyOnClose
                getContainer={false}
                onCancel={() => setshowCompanyList(false)}
                width = {1000}
            >
                <CompanyList/>
            </Modal>
        </div>
    )
}

export default CompanyInfo;