export default {
  "offer.search.placehoder": "Please enter keywords to search",

  "offer.tabs": JSON.stringify(["Liability Offer", "Equity Offer"]),

  'offer.search.selected.conditions' : 'Selected conditions:',
  'offer.search.projecttype' : 'Project Type',
  'offer.search.vesseltype' : 'Vessel Type',
  'offer.search.date' : 'Published Date',
  'offer.search.fundraising': 'Fundraising',
  'offer.search.imo': 'IMO Number',
  'offer.search.spvname': 'Vesssel\n/SPV Name',

  'offer.detail.title': ' Set sail on an alternative investment opportunity  \ sign up in the lucrative ship owning industry',

  // 'offer.detail.raisingfound': ' Raising Fund',
  'offer.detail.raisingfound': "Total Available Tokens",
  'offer.detail.fundraisingprogress': 'Current Fundraising Progress',
  // 'offer.detail.purchase': 'You can purchase',
  'offer.detail.purchase': 'Per Deal',
  // 'offer.detail.tokenprice': 'Single Token Price',
  'offer.detail.tokenprice': '1 Token Price',
  'offer.detail.exchangeRate': "Exchange Rate",
  'offer.token.qa': 'What is a token?',

  'offer.becomeshipowner': 'Become Shipowner',
  'offer.viewdetails': 'View Detalis',
  'offer.uploadPdf': 'Please upload the PDF file'

};

                            
