import React, { useEffect, useState } from 'react';
import './index.scss';
import { TabPro, Button } from '../../../../components';
import { observer } from 'mobx-react';
import { useIntl } from 'react-intl';
import store from './store';
import Fundraising from './components/fundraising';
import Transfer from './components/transfer';
import Finish from './components/finish';
import { useHistory, useParams } from 'react-router-dom';
import { useUrlQuery } from '../../../../hooks';


const Detail: React.FC = observer(() => {
    const history = useHistory();
    const { id } = useParams() as any;
    const [menu, setMenu] = useState(store.list)
    const status = useUrlQuery('status');
    const { formatMessage: f } = useIntl();
    useEffect(() => {
        // if (+status !== 75) return;
        // const newMenu = [...menu]
        // newMenu.pop();
        // setMenu(newMenu)
        // 根据状态值生成对应的menu
    }, [id]);
    useEffect(() => {
        store.stoId = id || '';
        store.init();
    },[id])
    useEffect(() => {
        setMenu(store.list)
    },[store.list])
    const renderItem = () => {
        if(store.list.length === 3) {//三个都显示
            switch (store.cur) {
                case 0:
                    return <Fundraising></Fundraising>
                case 1:
                    return <Transfer></Transfer>
                case 2:
                    return <Finish></Finish>
                default:
                    return null;
            }
        }
        if(store.list.length === 2) {
            if(store.list[1] === 'Fundraising Transfer') {//第一个标签页，第二个标签页
                switch (store.cur) {
                    case 0:
                        return <Fundraising></Fundraising>
                    case 1:
                        return <Transfer></Transfer>
                    default:
                        return null;
                }
            }else if(store.list[1] === 'Finish Offer') {//第一个标签页，第三个标签页
                switch (store.cur) {
                    case 0:
                        return <Fundraising></Fundraising>
                    case 1:
                        return <Finish></Finish>
                    default:
                        return null;
                }
            }
        }
        if(store.list.length === 1) {
            switch (store.cur) {
                case 0:
                    return <Fundraising></Fundraising>
                default:
                    return null;
            }
        }
        
    }
    return (
        <section className='common-wrap sto-detail-wrap'>
            <p className='page-title'>STO management</p>
            <p className='page-title'> Secured STO submission portal</p>
            <div className={`tab-wrap menu-len-${menu.length}`}>
                <TabPro
                    onChange={(val) => {
                        if(store.list[1] === 'Fundraising Transfer') {//如果是第二个列是退款信息就发送请求获取
                            if(val === 1) {
                                store.fundraisingTransfer();
                            }
                        }
                        store.cur = val
                    }}
                    // disabled
                    active={store.cur} 
                    data={menu}></TabPro>
            </div>

            <div className="sto-content">
                <div className="header">
                    <div className="back-wrap">
                        <Button onClick={() => {
                            history.push(`/usercenter/sto/apply?stoId=${id}`);
                        }} type='ghost' arrowLeft></Button>
                    </div>
                </div>

                <div className="sto-details">
                    {
                        renderItem()
                    }
                </div>
            </div>
        </section>
    )
})

export default Detail;