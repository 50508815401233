import React, { useState, useEffect } from 'react';
import { Title, Button, SMSInput } from '../../../../components';

import './index.scss';
import { formatMoney,formatLingMoney, getAllOptions,checkSmsUser, sendSms,sendSmsVerificationWithUser, checkSms, getUserInfo,getAllOptionsValue,transLocal, formatTwoMoney } from '../../../../utils';
import {
    Radio, Input, Modal, InputNumber, message
} from 'antd';
import {useHistory, useLocation} from 'react-router-dom';
import store from '../store';
import { observer } from 'mobx-react';
import api from '../../../../api';
import OfflineMessage from './components/offlineMessage'
import publicStore from '../../../../store'

const channel = [
    {
        type: 'paypal',
        value: 1,
        img: require('../../../../assets/image/paypal.png'),
    },
    // {
    //     type: 'wechat',
    //     value: 2,
    //     img: require('../../../../assets/image/wepay.png'),
    // },
    // {
    //     type: 'alipay',
    //     value: 3,
    //     img: require('../../../../assets/image/alipay.png'),
    // }
]

const Topup = observer(() => {
    const [channelType, setChannelType] = useState(1);
    const [onlineType, setOnlineType] = useState(1);
    const [payhelp, setPayHelp] = useState(false);
    const [payConfirm, setPayConfirm] = useState(false);
    const [cur, setCur] = useState(0);
    const [value, setValue] = useState('');
    const [rate, setRate] = useState(1);
    const [miniRecharge,setMiniRecharge] = useState(1000);
    const [sueryDay,setSureDay] = useState(14);
    const [verifySms, setVerifySms] = useState(false);
    const [total, setTotal] = useState('') as any;
    const [question, setQuestion] = useState('View common payment questions');
    const [affectLoading,setAffectLoading]= useState(false);//不能发二次请求控制
    const [offlineMessage,setOfflineMessage] = useState(false);
    const [offlineNumber,setOfflineNumber] = useState('');
    const [inType, setInType] = useState<1 | 2>(2)
    const history = useHistory();
    const { state } = useLocation()
    const linkBack = () => {
        history.goBack();
    }
    // useEffect(() => {
    //     // @ts-ignore
    //     if(state && state.inType && [1,2].includes(state.inType)) {
    //         // @ts-ignore
    //         setInType(state.inType)
    //     }
    // },[state])
    useEffect(() => {
        init();
        store.fetchList(sessionStorage.getItem('inType') || 1);
       
    }, []);
    const init = async () => {
        const optionsValue = await getAllOptionsValue();
        const rate = await getAllOptions('rechargeRate');
        setRate(optionsValue['rechargeRate'] || 1);
        setMiniRecharge(optionsValue['miniRecharge'] || 1000)
        setSureDay(optionsValue['investorCertEffective'] || 14)
    }
    useEffect(() => {
        handleChange(1);
    }, [rate]);
    const handleConfirm = async () => {
        if(!verifySms) {
            return;
        }
        if(affectLoading) {
            return;
        }
        setAffectLoading(true);
        try {
            const url = await api.deposit({
                total,
                custom: value,
                mdrx: value,
                des: getUserInfo()?.email,
                rechargeType: onlineType,
                platform: channelType,
                inType: sessionStorage.getItem('inType') || 1
            });
            setAffectLoading(false);
            if (onlineType === 2) {
                // 打开提示弹窗
                // 获取到流水号
                setOfflineNumber(url);
                setOfflineMessage(true);
                // Modal.success({
                //     content: `The application has been submitted and will be processed by the MDR administrator within ${sueryDay} days.`,
                //     onOk: () => {
                //         history.push('/usercenter/wallet');
                //     }
                // });
                return;
            } else {
                setPayConfirm(true);
                setTimeout(() => {
                    window.open(url);
                },500)
            }
        }catch(error) {
            setAffectLoading(false);
        }

    }

    const handleNext = async () => {
        if (!total) return;
        if(!miniRecharge || total < miniRecharge) {
            message.warning('Your charge is lower than the minimum charge, please try again');
            return;
        }
        setCur(1);
        // await sendSms();
        await sendSmsVerificationWithUser();
    }

    const handleChange = (val: any) => {
        // if (!/^\d+$/.test(val.toString())) {
        //     return;
        // }
        // 最大值 200万
        if (val >= 2000001) {
            const MaxMoney = 2000000
            const total = accMul(MaxMoney, rate)
            setValue(MaxMoney.toString());
            setTotal(Math.floor(total * 100) / 100);
            return
        }
        const total = accMul(val || 0, rate)
        setValue(val);
        setTotal(Math.floor(total * 100) / 100);
    }
    const accMul = (arg1: number, arg2: number) => {
        var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length;
        }
        catch (e) {
        }
        try {
            m += s2.split(".")[1].length;
        }
        catch (e) {
        }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    }

    const handleCheck = async (code: string) => {
        await checkSmsUser(code);
        
        setVerifySms(true)
    }
    // 打开线下充值的信息弹框
    const showOfflineMessage = () => {
        setOfflineMessage(true);
    }
    return (
        <section className="common-wrap wallet-topup">
            <p className='page-title'>Set sail on an alternative investment opportunity</p>
            <p className='page-title'>in the lucrative ship owning industry</p>

            <div className="topup-pannel">
                <div className="header">
                    <Title>My Wallet</Title>
                </div>
                {
                    cur === 0 ?
                        (<div className="content">
                            <div className='row'>
                                <Title>Available Cash</Title>
                                <p className='total'><span className='value'>{transLocal(Number(store.totaMoney))}</span>MDRX</p>
                                <p className='rate'>Exchange rate 1 MDRX = {rate} USD</p>
                            </div>

                            <div className="row">
                                <div className="topup-form">
                                    <div className="row">
                                        <p className='label'>Select Deposit Channel</p>
                                        <Radio.Group defaultValue={onlineType} onChange={(e) => setOnlineType(e.target.value)} buttonStyle='outline'>
                                            <Radio.Button value={1}>Online</Radio.Button>
                                            <Radio.Button value={2}>Offline</Radio.Button>
                                        </Radio.Group>
                                    </div>
                                    <div className="row">
                                        <p className="label">Deposit amount</p>
                                        <InputNumber min={0.5} value={Number(value)} 
                                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                        parser={(value:any) => value.replace(/\$\s?|(,*)/g, '')}//增加千分位设置 
                                         onChange={handleChange} 
                                         style={{ width: '200px', marginRight: '15px' }} 
                                         className='m-input' ></InputNumber>
                                        <p>MDRX</p>
                                    </div>

                                    <div className="row">
                                        <p className="label">You should pay</p>
                                        <Input value={formatLingMoney(total)} readOnly style={{ width: '262px' }} className='m-input' suffix='USD'></Input>
                                    </div>

                                    {
                                        onlineType === 1 ? (
                                            <div className="row">
                                                <p className="label">Select payment channel</p>
                                                <div className="chanel-wrap">
                                                    {
                                                        channel.map((item,index) => (
                                                            <div
                                                                key ={index}
                                                                onClick={() => setChannelType(item.value)}
                                                                className={`item ${item.value === channelType ? 'active' : ''}`}>
                                                                <img src={item.img}></img>
                                                            </div>
                                                        ))
                                                    }
                                                </div>
                                            </div>
                                        ) : null
                                    }

                                    <div className="row btn-wrap" style = {{marginBottom: onlineType === 2? "10px": "40px"}}>
                                        <Button onClick={linkBack} size='small' arrowLeft type='ghost'> Back</Button>
                                        <Button onClick={handleNext} size='small' arrowRight>Next</Button>
                                    </div>
                                    {/* <div className = "btn-wrap viewDetailButton">
                                        {onlineType === 2 && <Button onClick={showOfflineMessage} size = "small" >&nbsp;&nbsp;View Remittance Instruction&nbsp;&nbsp;</Button>}
                                    </div> */}
                                </div>
                                <div className="info-wrap">
                                    <div className="row">
                                        <span className="tips-circle"></span>
                                        <p className='desc'>Each deposit must be at least {miniRecharge} USD</p>
                                    </div>

                                    <div className="row">
                                        <span className="tips-circle"></span>
                                        <p className='desc'>Deposits over 2,000,000 USD require two confirmations</p>
                                    </div>
                                    <div className="row">
                                        <span className="tips-circle"></span>
                                        <p className='desc'>If you wish to make an offline deposit, contact MDR after submitting the application to confirm the transaction</p>
                                    </div>
                                </div>
                            </div>
                        </div>) : (
                            <div className="content">
                                <div className="row">
                                    <div className='row result'>
                                        <Title>Deposit amount</Title>
                                        <p className='total'><span className='value'>{formatLingMoney(value)}</span>MDRX</p>
                                    </div>

                                    <div className='row result' style={{ marginLeft: 'auto' }}>
                                        <Title>You should pay</Title>
                                        <p className='total'><span className='value'>{formatLingMoney(total)}</span>USD</p>
                                    </div>
                                </div>


                                <div className="sms-wrap">
                                    <SMSInput success={verifySms} onDone={handleCheck} hint='A 6-digit code has been sent to your phone' showRetry />
                                </div>
                                <div className="btn-wrap">
                                    <Button onClick={() => {setCur(0);setVerifySms(false)}} size='small' arrowLeft type='ghost'>Back</Button>
                                    <Button onClick={handleConfirm} size='small' arrowRight >{onlineType === 1 ? 'Next' : ' Submit'} </Button>
                                </div>
                            </div>
                        )
                }
            </div>
            <Modal
                footer={null}
                visible={payConfirm}
                closable={false}
                className='pay-confirm'
            >
                <p className='desc'>
                    Whether the payment is successful?
                </p>

                <div className="btn-wrap">
                    <Button onClick={() => {
                        setPayConfirm(false);
                        setPayHelp(true);
                    }} size='small' type='ghost' >No</Button>
                    <Button onClick={() => {
                        setPayConfirm(false);
                        history.push('/investment/manage?active=1');
                    }} size='small' >Yes</Button>
                </div>
            </Modal>

            <Modal
                footer={null}
                visible={payhelp}
                closable={false}
                className='pay-help'
            >
                <p className='desc'>
                    Payment is in trouble，you can
                    </p>

                <Radio.Group
                    defaultValue={question}
                    buttonStyle='outline'
                    onChange={(e) => setQuestion(e.target.value)}
                >
                    <Radio.Button value="common payment questions">View common payment questions</Radio.Button>
                    <Radio.Button value="Contact MDR for assistance">Contact MDR for assistance.</Radio.Button>
                </Radio.Group>

                <div className="btn-wrap">
                    <Button onClick={() => {
                        if(question === 'common payment questions') {
                            setPayHelp(false);
                            history.push(`/about?keyword=How to deposit funds&id=2`)
                        }else {
                            setPayHelp(false);
                            history.push(`investment/manage?active=1`)
                        }
                        return;
                    }
                    } type='ghost' size='small' >Ok</Button>
                </div>
            </Modal>
            {/* 线下充值时候展示的信息 */}
            <Modal
                footer={null}
                visible={offlineMessage}
                closable={false}
                width = {740}
            >
                <OfflineMessage serialNumber = {offlineNumber} auditDay = {sueryDay} ></OfflineMessage>
                <div style = {{
                    textAlign: "center"
                }}>
                    <Button onClick={() => {
                        // setPayHelp(false);
                        // history.push(`/about?keyword=Need Help&id=9`)
                        setOfflineMessage(false);
                        history.push('/investment/manage?active=0')
                    }
                    } type='ghost' size='small' >Ok</Button>
                </div>
            </Modal>
        </section >
    )
});

export default Topup;
