import React, { useState } from 'react';

import './index.scss';
import { Title, Button } from '../../../../../components';
import FormItem from 'antd/lib/form/FormItem';
import { Input, Form, Modal } from 'antd';

import BeatLoader from 'react-spinners/BeatLoader';
import { useForm } from 'antd/lib/form/util';
import api from '../../../../../api';
import pinCodeStore from '../steps/store'
interface IPin {
    onNext: Function;
}



const Pin: React.FC<IPin> = (Props) => {
    const [show, setshow] = useState(false);
    const [loading, setloading] = useState(true);
    const [form] = useForm();
    const savePin = () => {
        form.validateFields().then(async () => {
           const pinCode = form.getFieldValue('pinCode');
        //    await api.userUpdate({pinCode});
            pinCodeStore.setPinCode(pinCode);//保存pinCode,在第一步保存
           setshow(true);
           setloading(true);
           setTimeout(() => {
               setloading(false);
           }, 1000);
        });
    }
    return (
        <div className="pin-wrap">
            <Title style={{ paddingTop: '28px', paddingLeft: '33px', textAlign: 'left' }}>You’re NOW opening a NEW STO Application</Title>
            <p className='subtitle'>Secure your Submission</p>
            <Form
                hideRequiredMark
                form={form}
                className='pin-form'
            >
                <div>
                    <FormItem rules={[
                        {
                            required: true,
                            message: 'pin-code is required'
                        }
                    ]} name='pinCode' label='Set Up Pin-code'>
                        <Input.Password  type='password' className='m-input pin-input'></Input.Password>
                    </FormItem>
                </div>

                <FormItem
                    name='confirmPin'
                    label='Confirm Pin-code'
                    dependencies={['pincode']}
                    rules={
                        [
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('pinCode') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two pin-code that you entered do not match!');
                                },
                            })
                        ]
                    }
                >
                    <Input.Password type='password'
                        className='m-input pin-input'></Input.Password>
                </FormItem>
                <div className="pin-tips">
                    <ul>
                        <li>Pin codes are separate from passwords. It can include any amount of letters and/or numbers.</li>
                        <li>Each STO project has its own pin code.</li>
                        <li>Pin codes are used to secure your STO applications. If an STO application is inactive for more than 15 minutes, the correct pin code must be entered to resume.</li>
                    </ul>
                    {/* <span className="circle" />
                    <span className='desc'>
                        Pin-code is used to protect your ‘STO Application’. This is a webpage access-
                        control code. ‘STO Application’ access is valid every 15 minutes. Pin-code is
                        needed to re-valid your entry.
                    </span> */}
                </div>
                <div className = "footerInfo">
                <Button onClick={savePin} arrowRight className='submit-btn'>
                    Submit & Proceed
                </Button>
                
                <div className="fotterMessage">
                    <span className="circle" />
                    <span className='desc'>
                        All information submitted for STO is confidential and private.</span>
                </div>
                </div>
            </Form>

            <Modal
                visible={show}
                centered
                footer={null}
                closable={false}
            >

                <div className="pin-modal-content">
                    <Title style={{ marginBottom: '50px' }}>Pin-code is confirmed</Title>

                    <BeatLoader loading={loading} margin="12px" color="#164A78"></BeatLoader>

                    <span className='desc'>System is generating New STO Application, click to Proceed</span>

                    <Button arrowRight onClick={Props.onNext} className='next-btn' size='middle'>
                        Next
                </Button>
                </div>

            </Modal>
        </div>
    )
}

export default Pin;