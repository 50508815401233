import { observable, action, computed } from "mobx";
import { ILoginRes } from "../api/type";
import cookie from 'js-cookie';
import api from "../api";
import { getUserInfo } from "../utils";
import { ConsoleSqlOutlined } from "@ant-design/icons";

export class Store {
  // @observable isLogin: boolean = false;

  @observable lang: string = "en-US";

  @observable hasPin = false;

  @observable userInfo = null as any;

  @observable moneyInType = 1;
  

  @action.bound
  changeLang(lang: string) {
    this.lang = lang;
  }
  getFullName() {
    const userInfo = sessionStorage.getItem('userInfo');
    if (!userInfo) {
      if(!this.userInfo) {
        return;
      }else {
        const { surname, givenName } = this.userInfo;
        return `${surname} ${givenName}`
      }
    }
    const { surname, givenName } = JSON.parse(userInfo) || {};
    return `${surname} ${givenName}`
  }

  @computed
  get isVerify() {
    return this.userInfo ? this.userInfo?.status === 1 : false;
  }
  @computed
  get adminAuditAuth() {
    return this.userInfo? this.userInfo.status : ''
  }
  @computed
  get getAuthType() {
    return this.userInfo ? this.userInfo.type : ''
  }
  @computed
  get hasLogin() {
    if (this.userInfo && this.userInfo.id) {
      return true;
    }
    return false

  }
  @computed
  get getAuthStatus() {
    return this.userInfo? this.userInfo.status : ''
  }


  logout(cb?: Function) {
    sessionStorage.clear();
    // this.isLogin = false;
    window.location.href = '/#/register/login';
    this.userInfo = null;
    cb && cb();
  }

  login(userInfo: any) {
    this.userInfo = userInfo;
    sessionStorage.setItem('token', cookie.get('Authorize') || '');
    sessionStorage.setItem('userInfo', JSON.stringify(userInfo));
  }

  @computed
  get currentUserInfo() {//从当前的session中读取userInfo信息
    return sessionStorage.getItem('userInfo');
  }
  @computed
  get appLocal() {
    switch (this.lang) {
      case "zh-CN":
        return require("../locales/zh-CN").default;
      case "en-US":
        return require("../locales/en-US").default;
      default:
        return require("../locales/zh-CN").default;
    }
  }

  /**
   * 是否是一期的投资者
   */
  @computed
  get isFirstTZZ() {
    if(!this.userInfo) {
      return false
    }
    if(typeof this.userInfo.type !== 'string' || !this.userInfo.type) {
      return false
    }
    return this.userInfo.type.split(',').includes('1')
  }
  @computed
  get isSecondTZZ() {
      if(!this.userInfo) {
          return false
      } 
      // if(typeof this.userInfo.type !== 'string' || !this.userInfo.type) {
      //     return false
      // }
      if(this.userInfo.status && this.userInfo.status.toString().indexOf('1') > -1) {
        return true
      }
      // return this.userInfo.type.split(',').includes('1')
  }

  /**
   * 把当前身份转成数组
   */
  @computed
  get getCrrentAuthType() {
    if(!this.userInfo) {
      return []
    }
    if(typeof this.userInfo.type !== 'string' || !this.userInfo.type) {
      return []
    }
    return this.userInfo.type.split(',').map((item:any) => !isNaN(Number(item))).filter((item:any) => item)
  }

  userInfoListener = () => {
    const userInfo = getUserInfo();
    this.userInfo = userInfo;
  }

}

export default new Store();
