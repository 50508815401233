import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Modal } from 'antd';
import { useStores } from '../../../../hooks';
// import api from '../../../../api';

const ShipOwner: React.FC = observer(() => {
    // const { formatMessage: f } = useIntl();
    const [text,setText] = useState(0);
    const { rootStore } = useStores();
    const [showModal, setShowModal] = useState(false)
    const history = useHistory();

    const hanldeQualified = () => {
        // 是否登录
        if(!rootStore.hasLogin) {
            history.push('/register/login');
            return;
        }
       checkVerif();

    }
    const checkVerif = async() => {
        // 是否是投资者 如果是跳转到对应页面
        if(rootStore.getCrrentAuthType.includes(1)) {
            return history.push('/usercenter/invest');
        }
         //判断是否重复提交
         //不是投资者判断是否重复提交
       if(rootStore.getAuthStatus === 2) {
        Modal.warning({
            content: `Investor certification information has been submitted, please wait patiently`,
            onOk: () => {
                return;
            }
        });
        return
       }
       history.push('/usercenter/auth');
    }
    useEffect(() => {
        setText(5);
    },[rootStore.hasLogin])
    const handleToAbout = (keyword: string) => {
        history.push(`/about?keyword=${keyword}`);
    }
    const toBuyer = () => {
        history.push('/about/buyer')
    }

   const peopleHandler1 = () => {
    if (!rootStore.hasLogin) {
        return
    }
         //不是投资者判断是否重复提交
     if(rootStore.getAuthStatus === 2 ) {
        Modal.warning({
            content: `Investor certification information has been submitted, please wait patiently`,
            onOk: () => {
                return;
            }
        }); 
        return;
      }
      if (!rootStore.isFirstTZZ && !rootStore.isSecondTZZ) {
        setShowModal(true)
        return
      }
      history.push('/usercenter/invest')
   }

   const peopleHandler2 = () => {
    //不是投资者判断是否重复提交
    if(rootStore.getAuthStatus === 2 ) {
        Modal.warning({
            content: `Investor certification information has been submitted, please wait patiently`,
            onOk: () => {
                return;
            }
        }); 
        return;
        }
        if (!rootStore.isFirstTZZ && !rootStore.isSecondTZZ) {
        setShowModal(true)
        return
        }
        history.push('/usercenter/wallet')
    }

    return (
        <div className='shipowner-menu mdr-navbar-dropdown'>
            <div className="row">
                <span style={{cursor: 'pointer'}} className='label' onClick = {toBuyer}>Buyer Qualification</span>
                {/* <a onClick={hanldeQualified} className='label important' style={{ marginLeft: '64px' }}>{rootStore.getAuthType !== 3 ? 'Qualified Investor' : f({ id: 'overlay.GetQualifiedNow' })}</a> */}
            </div>
            {/* <div className="row" style={{ marginLeft: '17px' }}>
                <a onClick={() => handleToAbout("Eligibility")} className='label'>{f({ id: 'overlay.Eligibility' })}</a>
            </div>
            <div className="row" style={{ paddingLeft: '17px' }}>
                <a onClick={() => handleToAbout("How it works")} className='label'>{f({ id: 'overlay.Howitworks' })}</a>
            </div> */}
            {
                <div className="row"  style={{ paddingLeft: '17px' }}>
                    {/* history.push('/usercenter/invest') */}
                    <a  className={['label', (rootStore.isFirstTZZ || rootStore.isSecondTZZ ) ? '' : 'noLink'].join(' ')} onClick={() =>   peopleHandler1()} >Investment History</a>
                </div>
            }

            {/* {
                // isShowDeposit()
                <div className="row"  style={{ paddingLeft: '17px' }}>
                    <a className={['label', (rootStore.isFirstTZZ || rootStore.isSecondTZZ ) ? '' : 'noLink'].join(' ')} onClick={() => peopleHandler2() } >Deposit Now</a>
                </div>
            } */}

            {/* <div className="row">
                <Link to='/offer' className='label'>Browser Offers</Link>
            </div> */}
            {/* <div className="row">
                <Link to='/offer?offerType=1' className='label'>New Offers</Link>
            </div> */}
            <div className="row">
                <Link to='/offer?offerType=1' className='label'>Current Offers</Link>
            </div>
            <div className="row">
                <Link to='/offer?offerType=2' className='label'>Past Offers</Link>
            </div>
            {/* <div className="row" style={{justifyContent: 'space-between', alignItems: 'flex-start'}}>
                <span className='label'>{f({ id: 'overlay.NeedHelp' })}</span>
                <div className='col' style={{alignItems: 'flex-end'}}>
                        <span className="label">Phone:+423 300 20 10</span>
                        <span className="label">welcome@crowdlitoken.com</span>
                </div>
            </div> */}
            <Modal visible={showModal} footer={null} centered onCancel={() => setShowModal(false)}>
                <p>Please go to <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setShowModal(false); history.push('/usercenter/auth') }}>buyer qualification</span> to proceed with more service&gt;&gt;</p>
            </Modal>
        </div>
    )
})

export default ShipOwner;
