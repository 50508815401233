import React, { useEffect, useState } from 'react';
import { Title, Button } from '../../../../../../../components';
import './index.scss';
import { Timeline } from 'antd';
import store from '../../store';
import api from '../../../../../../../api';

const titleMap: { [index: string]: any } = {
    1: 'STO review passed',
    0: 'STO review Rejected',
    2: "STO application is under review"
}

const status: { [index: string]: any } = {
    0: 'Reviewed Rejected',
    1: 'Review Passed'
}

const StoReview: React.FC = () => {
    const [reviewlist, setlist] = useState([]);
    useEffect(() => {
        fetchReView();
    }, [])

    const fetchReView = async () => {
        const data = await api.reviewingMdrTodos(store.stoId);
        setlist(data)
    }

    const curResult: any = reviewlist[0] || {};
    const ispassed = curResult.auditStatus && curResult.auditStatus === 1;
    const getColor = (status:number) => {
        if(status === 2) {
            return 'gray';
        }else if(status === 1){
            console.log('blue');
            return 'green';
        }else if(status === 0) {
            return 'red';
        }else {
            return 'gray';
        }
    }
    return (
        <div className='sto-review-wrap'>
            <div style={{ marginBottom: '30px' }} className="row">
                <Title>{titleMap[curResult.auditStatus? curResult.auditStatus: 2 ]}</Title>
            </div>
                <div className="reivew-content">
                <Timeline>
                    {
                        // 只返回最新信息
                        reviewlist.map((item: any,index:number) => (
                            index === 0?
                            (<Timeline.Item color={getColor(item.auditStatus? item.auditStatus: 2)}>
                                <div className='review-item'>
                                    {/* 成功 */}
                                   {item.auditStatus === 1 && (<p><span className={[item.auditStatus === 1 ? 'success' : ''].join(' ')}>{status[item.auditStatus]}</span> at {item.approvalTime} </p>)}
                                    {/* 拒绝 */}
                                    {
                                        item.auditStatus === 0 && (
                                            <p>Rejected Reason: {item.rejectionReason || ''}</p>
                                        )
                                    }
                                    {/* 审核状态 */}
                                    {item.auditStatus !== 1 && (
                                        <p>{titleMap[item.auditStatus === 0 || item.auditStatus === 1? item.auditStatus: 2]}</p>
                                    )}
                                    {/* 提交时间 */}
                                    <p>
                                        Submitted for review at {item.applicationTime}
                                    </p>
                                </div>
                            </Timeline.Item>):(null)
                            
                        ))
                    }
                </Timeline>
            </div>

            <div className="btn-wrap">
                <Button
                    arrowLeft
                    type='ghost'
                    size='middle'
                    onClick={() => store.setCurStep('vesselInfo')}
                >Go Back</Button>
                {
                    store.stoStatus <= 25 ? null : (
                        <Button
                            size='middle'
                            style={{ marginLeft: '20px' }}
                            onClick={() => store.setCurStep(ispassed ? 'fundraisingInfo' : 'companyInfo')}
                            arrowRight>
                            {
                                ispassed ? 'Fill in Fundraising Info Now' : 'Modify STO Information'

                            }
                        </Button>
                    )
                }

            </div>
        </div>
    )
}

export default StoReview;