import React, { useEffect, useState } from 'react';
import { List } from 'antd';
import './index.scss';
import { Title, Button } from '../../../../../../../../../components';
import { observer } from 'mobx-react';
import store from '../../store';
import { formatMoney, transLocal,getImageUrl } from '../../../../../../../../../utils';
import dayjs from 'dayjs';
import { STO_STATUS } from '../../../../../../../../../constants';

// actualFundraising: "7000.00",总筹资金额
// allTokenNow: 7已经卖出去的token  
// endDate: "2020-08-15 00:00:00" 结束时间
// isTransfer: false 是否给船东转过账
// lastDate: "2020-07-16 16:48:05" 访问的时间
// leftDays: 29  剩余筹款天数
// projectObjective: "Liability" 股权还是债券
// raisingFunds: "10000.00"  要筹款的总金额
// startDate: "2020-07-16 00:00:00" 开始时间
// status: 2 offer状态
// statusDesc: "Fundraising In Progress"  offer状态对应显示的文字
// stoId: 55stoId
// subtotalValue: 10000  
// tokenName: "1111"  token名称
// tokenNumber: 10  筹集的Token数目
// tokenPrice: 1000  每个token的加个
// tokenUnit: 7     卖出的token
// totalFundraising: 7000   总筹资金额
// totalReceived: 7700   收到的金额，带手续费
// transactionFee: 700    手续费
// userHoldNumber: 7 用户购买的token
const OfferDetail = observer(() => {
    const [showRefound,setShowRefound] = useState([]) as any;
    const { fundraising, transfer,finishData } = store;
    const { raisingFunds, projectObjective,fundraisingDays, tokenName,isTransfer, lastDate, fundraisingComplateDate, endDate, statusDesc, status, tokenNumber, tokenPrice, subtotalValue, userHoldNumber, startDate, leftDays, totalFundraising, actualFundraising } = fundraising || {};
    const isDone = +status === 5;
    const {subjectItem} = transfer;
    useEffect(() => {
        store.init();
        // checkRefound();
    }, []);
    const checkRefound = () => {
        const fundRaisingDatas = finishData.find((item:any) => item.type === "REFUND_FUNDRAISING");
        setShowRefound(fundRaisingDatas);
    }
    const handleDownload = async() => {
        if(!subjectItem) {
            return;
        }
        const url = await getImageUrl(subjectItem);
        window.open(url, 'new_mdr', 'width=600,height=800')
    }

    // 第一列信息
    const firstData = [
        {
            key: 'Token Name',
            value: tokenName,
            extra: ''
        },
        {
            key: 'Token Type',
            value: projectObjective,
            extra: ''
        },
        {
            key: 'Actual Fundraising Amount',
            value: transLocal(subtotalValue),
            extra: "USD"
        },
        {
            key: 'Total Tokens issued',
            value: tokenNumber? tokenNumber.toLocaleString(): '',
            extra: 'Tokens'
        },

        {
            key: 'Token price',
            value: transLocal(tokenPrice),
            extra: 'MDRX per token'
        },

        // {
        //     key: 'Total Fundraising ',
        //     value: transLocal(totalFundraising),
        //     extra: `MDRX`
        // },

        {
            key: 'Fundraising Start Date',
            value: dayjs(startDate).format('YYYY-MM-DD'),
            extra: ''
        },

        {
            key: 'Fundraising Duration',
            value: fundraisingDays,
            extra: 'Days'
        },

        {

            // key: 'Current Fundraising Status',
            key: 'Fundraising End Date',
            //@ts-ignore
            value: dayjs(endDate).format('YYYY-MM-DD'),
            extra: ''
        }
    ]
    // 第二列信息
    const secondData =  fundraising.status === 3 || 
                        fundraising.status === 4 ||
                        fundraising.status === 5 ||
                        fundraising.status === 6 ? [
        {
            key: 'Current STO Application Status',
            value: STO_STATUS.filter(item =>  item.value === store.stoStatus )[0]?.label,
            // extra: ''
        },
        {
            key: 'Current Fundraising Progress',
            value: transLocal(actualFundraising),
            extra: 'MDRX'
        },
        {
            key: "Fundraising progress",
            value: `${Math.floor((userHoldNumber / tokenNumber) * 10000) / 100}%`,
            extra: `${Math.floor((userHoldNumber / tokenNumber) * 10000) / 100 === 100? 'Completed': ''}`
        },
        {
            key: 'Current Fundraising Status Code',
            value: statusDesc?statusDesc: '',
            extra: ''
        },
    ]: [
        {
            key: 'Current STO Application Status',
            value: STO_STATUS.filter(item =>  item.value === store.stoStatus )[0]?.label,
            extra: ''
        },
        {
            key: 'Current Fundraising Progress',
            value: transLocal(actualFundraising),
            extra: 'MDRX'
        },
        {
            key: "Fundraising progress",
            value: `${Math.floor((userHoldNumber / tokenNumber) * 10000) / 100}%`,
            extra: `${Math.floor((userHoldNumber / tokenNumber) * 10000) / 100 === 100? 'Completed': ''}`
        },
        {
            key: 'Current Offer Valid for',
            value: leftDays,
            extra: 'Days'
        },
        {
            key: 'Current Fundraising Status Code',
            value: statusDesc?statusDesc: '',
            extra: ''
        },
    ]
    // 第三列信息
    const refundData = [
        {
            key: 'Token Name',
            value: tokenName,
            extra: ''
        },
        {
            key: 'Token Type',
            value: projectObjective,
            extra: ''
        },
        // 
        {
           key:"Total Realization Amount",
           value: isTransfer? transLocal(transfer.actualTransferShipowner) : '',
           extra: "MDRX"
        },
        {
            key: "Fundraising Realization Slip",
            value: "download",
            extra: ""
        }
    ]




    return (
        <div className="detail-fundraising">
            <Title>Fundraising Information</Title>
            <div className="fundraisingForm">
                <List
                    bordered
                    dataSource={firstData.map((item: any) => JSON.stringify(item))}
                    renderItem={(item: string) => {
                        const items: any = JSON.parse(item);
                        return (
                            <List.Item className='row property-item'>
                                {/* <div className='r0w'> */}
                                <p className='label'>{items.key}</p>
                                <p className='value'><span style = {{color: "#60a5e2"}}>{items.value}</span>&nbsp;<span>{items.extra}</span></p>
                                {/* </div>    */}
                            </List.Item>
                        )
                    }}
                />
            </div>
            {/* 第二列信息 */}
            <Title>{    fundraising.status === 3 || 
                        fundraising.status === 4 ||
                        fundraising.status === 5 ||
                        fundraising.status === 6?'Fundraising End': 'Fundraising in Progress'}</Title>
            {/* 时间 */}
                <span className='list-title'>Last update by{dayjs(lastDate).format('DD/MM/YYYY h:mm')}</span>
            <div className="fundraisingForm">
                <List
                    bordered
                    dataSource={secondData.map((item: any) => JSON.stringify(item))}
                    renderItem={(item: string) => {
                        const items: any = JSON.parse(item);
                        return (
                            <List.Item className='row property-item'>
                                {/* <div className='r0w'> */}
                                <p className='label'>{items.key}</p>
                                <p className='value'><span style = {{color: "#60a5e2"}}>{items.value}</span>&nbsp;<span>{items.extra}</span></p>
                                {/* </div>    */}
                            </List.Item>
                        )
                    }}
                />
            </div>
            <div>

            </div>
            {
               isTransfer ? (//如果有转账信息，就展示
                    <>
                        <Title>Fundraising Realization Slip</Title>
                        {/* 时间 */}
                        {/* <span className='list-title'>时间</span> */}
                        <div className="fundraisingForm">
                            <List
                                bordered
                                dataSource={refundData.map((item: any) => JSON.stringify(item))}
                                renderItem={(item: string) => {
                                    const items: any = JSON.parse(item);
                                    return (
                                        <List.Item className='row property-item'>
                                            <p className='label'>{items.key}</p>
                                            <p className='value' 
                                            onClick = {() => {
                                                if(items.key==='Fundraising Realization Slip') {
                                                    handleDownload()
                                                } 
                                            }}
                                            style = {{cursor: "pointer"}}
                                            ><span style = {{color: "#60a5e2"}}>{items.value}</span>&nbsp;<span>{items.extra}</span></p>
                                        </List.Item>
                                    )
                                }}
                            />
                        </div>
                    </>
                ) : (
                        null
                    )
            }
        </div>

    )
})

export default OfferDetail