import React, {
    useRef,
    MutableRefObject,
    useState,
    useEffect,
    useReducer, Component
} from 'react';

import './index.scss';
import { sendSms } from '../../utils';
import api from '../../api';
import { message } from 'antd';
import { CheckOutlined, YahooFilled } from '@ant-design/icons';

interface ISMSInput {
    length?: number;
    onDone?: Function;
    retryTime?: number;
    spliterIndex?: number;
    showRetry?: boolean;
    phoneNumber?: string;
    hint?: string;
    success?: boolean;
    isEmail?: string//传入的话 根据传入的字符串类判断是动态验证码登录还是找回密码邮箱
    countryCode?: string; //动态登录验证码需要获取到用户的国家区号
}
interface IState {
    values: string;
    left: number;
    showSuccess: boolean;
    leftDisabled: boolean;
    spliterIndex: number;
    length: number;
}
class SMSInput extends Component<ISMSInput, IState> {
    public hideInput: React.RefObject<HTMLInputElement> = React.createRef();
    public timer: any;
    public disableFlag: boolean = true;
    state: IState = {
        values: '',
        left: this.props.retryTime || 120,
        showSuccess: this.props.success || false,
        leftDisabled: false,
        spliterIndex: this.props.spliterIndex || 2,
        length: this.props.length || 6
    };
    public componentDidMount = () => {
        this.hideInput.current?.focus();
        this.calcleft();
    }
    public componentWillUnmount = () => {
        clearInterval(this.timer);
    }
    public componentWillReceiveProps = (props: ISMSInput) => {
        if (props.success) {
            // 这样会覆盖内部 email的更新!
            this.setState({ showSuccess: props.success });
        }

    }



    private calcleft = () => {
        const { left } = this.state;
        const that = this;
        this.timer = setInterval(() => {
            const copy = this.state.left;
            this.setState({
                left: copy - 1
            }, () => {
                if (this.state.left === 0) {
                    clearInterval(that.timer);
                    this.setState({
                        left: this.props.retryTime || 120
                    })
                    that.disableFlag = false;
                }
            })
        }, 1000)
    }



    private handleResend = async () => {
        if (this.disableFlag) {
            return;
        }
        this.disableFlag = true;
        if (this.props.isEmail && this.props.isEmail === 'tempPwd') {//动态邮箱验证码登录
            if (!this.props.countryCode || !this.props.phoneNumber) {
                return;
            }
            try {
                await api.sendEmailDynamicPassword({
                    code: this.props.countryCode,
                    phoneNumber: this.props.phoneNumber,
                });
                this.calcleft();
            }catch(error) {
                this.disableFlag = false;
            }
            return;
        }
        if (this.props.isEmail && this.props.isEmail === 'email') {//重置密码邮箱验证码
            if (!this.props.phoneNumber) {
                return;
            }
            try {
                await api.sendPoneNumberEmail({
                    phoneNumber: this.props.phoneNumber,
                });
                this.calcleft();
            } catch (error) {
                this.disableFlag = false;
            }
            return
        }
        if(this.props.isEmail && this.props.isEmail === 'emailCheck') {//普通发送邮箱验证码
            if(!this.props.phoneNumber) {
                return;
            }
            try {
                const email = this.props.phoneNumber;
                await api.sendEmail(email);
                this.calcleft();
            } catch (error) {
                this.disableFlag = false;
            }
            return;
        }
        try {
            await sendSms(this.props.phoneNumber);
            this.calcleft();
            message.success('A 6-digit code has been sent to your phone')
        } catch (error) {
            this.disableFlag = false;
        }


    }

    private handleChange = (e: any) => {
        const { length } = this.state;
        this.setState({
            values: e.target.value.slice(0, length),
            showSuccess: false
        })
        if (e.target.value.length === length) {

            this.props.onDone && this.props.onDone(e.target.value);
        }
    }
    render() {
        const { values, left, showSuccess, leftDisabled, spliterIndex, length } = this.state;
        const { hint } = this.props;
        return (
            <div className='sms-input-wrap'>
                {
                    hint ? <p className='hint'>{hint}</p> : null
                }
                <input
                    className="hiden"
                    ref={this.hideInput}
                    maxLength={length}
                    onChange={this.handleChange}
                    value={values} />
                <div onClick={() => this.hideInput.current?.focus()} className="sms-input-box">
                    {
                        new Array(length).fill('').map((item, index) => (
                            <> <input
                                key={index}
                                maxLength={1}
                                value={values.substring(index, index + 1)}
                                className="sms-item"
                                readOnly

                            />
                                {
                                    spliterIndex === index ? <span className="line"></span> : null
                                }
                            </>
                        ))
                    }

                    {
                        showSuccess ?
                            <CheckOutlined className='icon' style={{ color: '#164a78' }} /> : null}
                </div>
                {
                    this.props.showRetry ? <p onClick={this.handleResend} className={`resend ${this.disableFlag ? 'disabled' : ''}`}>  Click to resend
                    {
                            this.disableFlag ? (<span className='time'> {left}s</span>) : null
                        }
                    </p> : null
                }
            </div>

        );
    }
}
// const SMSInput: React.FC<ISMSInput> = ({ success, length = 6, hint, onDone, spliterIndex = 2, ...props }) => {

//     const [values, setValues] = useState('');

//     const [second, setSecond] = useState(10) as any;
//     const [leftDisabled,setLeftDisabled] = useState(false);
//     const [showSuccess, setShowSuccess] = useState(success);
//     // const [leftState,leftDispatch] = useReducer(leftReducer,{left: 10,resetDisabled: false})

//     //@ts-ignore
//     const hideInput: React.RefObject<HTMLInputElement> = useRef();
//     // useEffect(()=> {
//     //     setSecond(left.current);
//     //     console.log('进入执行了一次');
//     // },[left.current])
//     useEffect(() => {
//         hideInput.current?.focus();
//         calcleft();
//         //@ts-ignore
//         return () => clearInterval(timer);
//     }, []);

//     useEffect(() => {
//         setShowSuccess(success);
//     }, [success])

//     const calcleft = () => {
//         // if (!props.showRetry || leftState.left === 0) return;
//         timer = setInterval(() => {
//             console.log('进行值');
//             console.log(left.current);
//             left.current = left.current - 1;
//             // setSecond((second:any) => second - 1);
//             if(left.current === 0){
//                 console.log('需要停止');
//                 clearInterval(timer);
//                 setSecond(10);
//                 // leftDispatch({type: "setNoDisabled"});
//                 setLeftDisabled(false)
//             }
//         }, 1000)
//     }



//     const handleResend = async () => {
//         if(leftDisabled) {
//             return;
//         }
//         console.log('发送了')
//         setLeftDisabled(true);
//         await sendSms(props.phoneNumber);
//         calcleft();
//         message.success('A 6-digit code has been sent to your phone')
//     }

//     const handleChange = (e: any) => {
//         setValues(e.target.value.slice(0, length));
//         setShowSuccess(false);
//         if (e.target.value.length === length) {

//             onDone && onDone(e.target.value);
//         }
//     }

//     return (
//         <div className='sms-input-wrap'>
//             {
//                 hint ? <p className='hint'>{hint}</p> : null
//             }
//             <input
//                 className="hiden"
//                 ref={hideInput}
//                 maxLength={length}
//                 onChange={handleChange}
//                 value={values} />
//             <div onClick={() => hideInput.current?.focus()} className="sms-input-box">
//                 {
//                     new Array(length).fill('').map((item, index) => (
//                         <> <input
//                             key={index}
//                             maxLength={1}
//                             value={values.substring(index, index + 1)}
//                             className="sms-item"
//                             readOnly

//                         />
//                             {
//                                 spliterIndex === index ? <span className="line"></span> : null
//                             }
//                         </>
//                     ))
//                 }

//                 {
//                 showSuccess ?
//                     <CheckOutlined className='icon' style={{ color: '#164a78' }} /> : null}
//             </div>
//             {
//                 props.showRetry ? <p onClick={handleResend} className={`resend ${second} > 0 ? 'disabled' : ''}`}>  Click to resend
//                 {
//                         second > 0 ? (<span className='time'> {second}s</span>) : null
//                 }
//                 当前值：{second}
//                 </p> : null
//             }
//         </div>

//     );
// };

export default SMSInput;