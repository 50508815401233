import React, { useState, useRef,useEffect } from 'react';
import { useIntl } from 'react-intl';

import { SMSInput, Title } from '../../../../../components';
import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import './index.scss'
import api from '../../../../../api';
import store from '../../store';
import FormItem from 'antd/lib/form/FormItem';

const SMS: React.FC = () => {

    const [isDone, setIsDone] = useState(false)

    const [value, setValue] = useState(false)

    const { formatMessage: f } = useIntl();
    

    const onDone = async (value: string) => {
        try {
            await store.checkSms(value);
            setValue(true);
            store.smsVerify = true;
        } catch (error) {
            setIsDone(true);
            setValue(false);
            store.smsVerify = false;
        }
    }

    const isError = !value && isDone;
    return (
        <div className='login-sms'>
            {/* <Title>{f({ id: 'register.login.sms' })}</Title> */}

            <span className='sms-title' >{f({ id: 'register.sms.title' })}</span>
            <FormItem
                // validateStatus={isError ? 'error' : 'success'}
                // help={!isError ? null : "digit code error"}
                style={{ display: 'flex' }}
                name='code' className='sms-wrap'>
                <SMSInput success={value} showRetry onDone={onDone} length={6} phoneNumber = {store.email}></SMSInput>
            </FormItem>
            {/* <span onClick={store.sendSms} className='send' >{f({ id: 'register.sms.send' })}</span> */}
        </div>
    )
}

export default SMS;