import axios, {
    AxiosInstance, AxiosResponse, AxiosError, AxiosRequestConfig,
} from 'axios';
import md5 from 'md5';
import rootStore from '../../store';

import { message,Modal } from 'antd';

axios.defaults.withCredentials = true;

const prefix = process.env.NODE_ENV === 'production' ? '/mdr' : '';

// 默认设置
const defaultAxiosConfig = {
    showError: true, // 显示error
    allResponse: false, // 不需要全部信息，只要后端返回data
};

interface AxiosConfig extends AxiosRequestConfig {
    showError?: boolean;
    // 是否需要全部的响应内容
    allResponse?: boolean;
}

interface StatusConfig extends AxiosRequestConfig {
    _startTime?: number;
}

interface params {
    [index: string]: any
}

// 后端返回的数据
interface BEResponseData {
    code: string;
    data: any;
    msg: string;
    success: boolean;
}
interface InitGlobalParamsRetObj {
    [index: string]: any;
}



const axiosInstance: AxiosInstance = axios.create({
    withCredentials: true,
});

// 请求拦截器
axiosInstance.interceptors.request.use((config: StatusConfig) => {
    config._startTime = Date.now();
    const userInfo = sessionStorage.getItem('userInfo');
    const globalParams: params = {
        createUser: (userInfo ? JSON.parse(userInfo).id : ''),
    };
    // const token = cookie.get('Authorize');

    if (!config.headers['content-type']) {
        config.headers['content-type'] = 'application/json';
    }




    // 修改post，用x-www-form-urlencoded方式发送

    // if(token) {
    config.headers['Authorize'] = sessionStorage.getItem('token');

    // if (config.headers['Authorize']) {
    //     rootStore.isLogin = true;
    // }
    // }
    // 如果参数是FormData格式
    // if (config.data instanceof FormData) {
    //     Object.keys(globalParams).forEach(((key: string) => {
    //         config.data.append(key, globalParams[key]);
    //     }));
    // } else { // 对象格式
    //     config.data = Object.assign(globalParams, config.data);
    //     config.data = qs.stringify(config.data);
    // }

    if (Array.isArray(config.data)) {
        return config;
    }
    config.data = Object.assign(globalParams, config.data);

    if (config.data.password) {
        config.data.password = md5(config.data.password);
    }

    if (config.data.oldPassword) {
        config.data.oldPassword = md5(config.data.oldPassword);
    }

    if (config.data.newPassword) {
        config.data.newPassword = md5(config.data.newPassword);
    }

    return config;
});

// 响应拦截器
axiosInstance.interceptors.response.use(async (response: AxiosResponse<BEResponseData>) => {
    const { showError, allResponse } = response.config as AxiosConfig;
    const { data = '', msg = 'Network request error', success, code } = response.data || {};
    if (code === '421') {
        window.location.hash = '#/register/login';
        rootStore.logout();
        return Promise.resolve();
    }



    if (success) {
        if (allResponse) {
            return response.data;
        } else {
            return data;
        }
    }



    if (showError && msg) {
        if(code === "WEBSITE.0015") {
            Modal.info({
                title: "Disclaimer",
                centered: true,
                content: msg,
                icon: null,
                okText: "Accept",
                onOk: () => {
                  
                }
            })
            
        }else if(code === "TSPUSM.0001" || code === "TSPUSM.0002" || code ===  "TSPUSM.0016" || code === "WEBSITE.0004") {
            Modal.error({
                className:  "passwordError",
                centered: true,
                content: msg,
                zIndex: 1050,
                onOk: () => {
                  
                }
            })
        }else {
            message.error(msg);
        }
        
    }



    return Promise.reject(response.data);


}, (error: AxiosError) => {
    const { status, statusText } = error.response as AxiosResponse;
    message.error(error.message);
    return Promise.reject(new Error(`Network request error ${status} ${statusText}）`));
});

interface AxiosPromise<T = any> extends Promise<T> { }

export const request = {
    get<T = any>(url: string, params?: any, config: AxiosConfig = defaultAxiosConfig): AxiosPromise<T> {
        config = Object.assign({}, config, {
            params,
        });
        return axiosInstance.get(`${prefix}${url}`, config) as AxiosPromise;
    },
    post<T = any>(url: string, data?: object, config: AxiosConfig = defaultAxiosConfig): AxiosPromise<T> {
        return axiosInstance.post(`${prefix}${url}`, data, config) as AxiosPromise;
    },
    submit<T = any>(url: string, data?: FormData, config: AxiosConfig = defaultAxiosConfig): AxiosPromise<T> {
        return axiosInstance.post(`${prefix}${url}`, data, config) as AxiosPromise;
    },
};

export default request;
