import React, { useState, useRef,useEffect } from 'react';
import { useIntl } from 'react-intl';

import { CheckOutlined, MailOutlined, PhoneOutlined } from '@ant-design/icons';
import { SMSInput } from '../../../../components';
import './index.scss'
import { checkSms } from '../../../../utils';
import store from '../../store';
import api from '../../../../api';

const SMS: React.FC = () => {

    useEffect(() => {
        return () => {
            store.smsVerify = false;
        }
    },[])
    const [success, setSuccess] = useState(false);

    const [errcount, seterrcount] = useState(0);

    const { formatMessage: f } = useIntl();

    const onDone = async (value: string) => {
        try {
            await api.checkSms({ phoneNumber: store.fullPhoneNumber, code: value });
            setSuccess(true)
            store.smsVerify = true;
        } catch (error) {
            setSuccess(false)
            seterrcount(val => val + 1);
        }
    }


    return (
        <div className='reset-sms'>
            <div className='sms-wrap'>
                <SMSInput success={success} showRetry onDone={onDone} length={6} phoneNumber = {store.fullPhoneNumber}></SMSInput>
            </div>
            {
                errcount >= 3 ? [
                    <div className="fail-helper">
                        <span className='tips-circle'></span>
                        <p className='tips'>{f({ id: 'reset.sms.fail' })}</p>
                    </div>,
                    <div className="contact-wrap">
                        <p className='row'>
                            <PhoneOutlined className='call icon' />
                            <span className='contact'> Phone:+423 300 20 1</span>
                        </p>
                        <p className='row'>
                            <MailOutlined className='icon' />
                            <span className='contact'>welcome@crowdlitoken.com</span>
                        </p>
                    </div>
                ] : null
            }
        </div>
    )
}

export default SMS;