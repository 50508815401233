import React, { useEffect,useState } from 'react';
import { useIntl } from 'react-intl';
import './index.scss';
import { MSelect } from '../../../../../components/index';
import { Input,DatePicker } from 'antd';
import FormItem from 'antd/lib/form/FormItem';
import store from '../../../store';
import api from '../../../../../api'
import { data } from '../../../../Offer/Index/mock';
import { IeCircleFilled } from '@ant-design/icons';
import moment, {Moment} from 'moment';


interface IProps {
    handleNationalityChange?: Function;
    value?:string;
}
// nationality  code 
const Identity: React.FC<IProps> = (props:IProps) => {
    const { formatMessage: f } = useIntl();
    const [selectData,setSelectData]  = useState<any>([]);

    const handleChange = (key: string, e: any) => {
        store.updateModel({ [key]: e.target.value })
    }
    useEffect(() => {
        getData();
    },[]);
    const getData = async () => {
        const data = await api.Nationality();
        data.forEach((item:any) => {
            item.lable = item.nationality;
            item.value = item.nationality;
        })
        const copyData = data.map((item:any) => {
          return  {label: item.nationality, value: item.nationality}
        })
        setSelectData(copyData);
    }
    const selectChange = (item:string) => {
        store.updateModel({'nationality': item})
    }
    const datePickerChange = (e:Moment | null,dataString: string) => {
        store.updateModel({'passprotExpiration': dataString})
    }
    // 设置只能选今天之后的时间
    const disabledDate = (current:moment.Moment) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    return (
        <div className="identity-wrap">
            {/* <Title style={{marginBottom: '30px'}}>{f({id: 'register.identity'})}</Title> */}
            <div className="input-item">
                <span className='label'>{f({ id: 'register.identity.personal.label' })}</span>
                <FormItem name='personalInden'>
                    <Input className='m-input' onChange={(e) => handleChange('personalInden', e)} placeholder={f({ id: 'register.identity.personal.placeholder' })} style={{ width: '100%' }} />
                </FormItem>
            </div>
            <div className="input-item data-pick">
                <span className = "label">Passport Expiration Date</span>
                <div className="location-wrap">
                    <FormItem
                        name='passprotExpiration'
                    >
                        <DatePicker
                            onChange = {datePickerChange}
                            size = "large"
                            disabledDate={disabledDate}
                        />
                    </FormItem>
                </div>
            </div>
            <div className="input-item">
                <span className='label'>Nationality</span>
                <div className="location-wrap">
                    <FormItem
                        name='nationality' rules={[{ required: true }]}
                    >
                        <MSelect
                            allowClear
                            value={props.value || ''}
                            onChange={selectChange}
                            className='Nationality'
                            dataSource={selectData}
                            showSearch={true}
                            // optionFilterProp={'children'}
                        />
                    </FormItem>
                </div>
            </div>

            <div className="input-item">
                <span className='label'>{f({ id: 'register.identity.company.label' })}</span>
                <FormItem
                    name='companyBussiness'
                >                                      
                    <Input onChange={(e) => handleChange('companyName', e)} className='m-input' placeholder={f({ id: 'register.identity.company.placeholder' })} style={{ width: '100%' }} />
                </FormItem>
            </div>

        </div>
    )
}

export default Identity;