import React from 'react';
import './offlineMessage.scss'
const OfflineMessage = (props: {serialNumber: string,auditDay:number}) => {
    const {serialNumber,auditDay} = props;
    return (
        <div className = "offlineMessage">
            <div className = "title">MDR’s Remittance Instruction</div>
            <div className = "content">
                Please follow below steps for offline deposit to your wallet on MDR. 
                Your transaction will be handled in 3-7 business days.
            </div>
            {/* 步骤一： */}
            <div className = "title">Step 1. Generate Bill</div>
            <div className = "content">
                Input your target deposit amount and click ‘Next’，
                your deposit request can also be found in ‘My Wallet’. 
                Remittance information will be informed and provided into your registration mailbox too.
            </div>
            {/* 步骤二 */}
            <div className = "title">Step 2. Remittance Information</div>
            <div className = "contentTwo">
                <p><strong>Bank Name:</strong> HSBC Hong Kong</p>
                <p><strong>Bank Address :</strong> 1 Queen’s Road Central, Hong Kong</p>
                <p><strong>Swift Code:</strong> HSBCHKHHHKH</p>
                <p><strong>Beneficiary:</strong> Marine Digital Republic Ltd.</p>
                <p><strong>A/C No:</strong> 817729890838</p>
            </div>
            {/* 转账天数提示 */}
            <div className = "title">Step 3. Acceptance</div>
            <div className = "content">
            The deposit request will be processed by MDR administration within {auditDay} days.
            </div>
            <div className = "remindMessage">
                Please also state below number in the notes of the remittance:&nbsp;{serialNumber}&nbsp;[Serial Number]
            </div>
            {/* logo部分 */}
            <div className = "logo">
                <img alt="logo" className="logo" src={require('../../../../../assets/image/logo.png')} />
            </div>
        </div>
    )
};

export default OfflineMessage;