import {observable, action} from "mobx";
import {getUserInfo} from "../../../../utils";
import api from "../../../../api";
import rootStore from "../../../../store";

class IndexStore {
    @observable exTableList: Array<any> =  []
    @observable stoTotal = 0
    @observable stoTotalMoney = 0
    @observable stoTableList: Array<any> =  []
    @observable exTotal = 0
    @observable exTotalMoney = 0
    @observable lockMoney = 0
    @action.bound
    async fetchTwoList(pageIndex?: number, pageSize?: number) {
        if(!rootStore.hasLogin) {
            return
        }
        const userInfo = getUserInfo();
        const { list, total }  = await api.moneyPageList({
            pageIndex: pageIndex || 1,
            pageSize: pageSize || 5,
            param: {
                inType: 2,
                type: userInfo?.type
            }
        }) || {};
        this.exTotal = total || 0
        this.exTableList = list || []
        this.exTotalMoney = Array.isArray(list) && list.length ? list[0].allIncomeMoney || 0 : 0
        this.lockMoney = Array.isArray(list) && list.length ? list[0].lockMoney || 0 : 0
    }
    @action.bound
    async fetchList(pageIndex?:number, pageSize?: number) {
        if(!rootStore.hasLogin) {
            return
        }
        const userInfo  = getUserInfo()
        const {list, total} = await api.moneyPageList({
            pageIndex: pageIndex|| 1,
            pageSize: pageSize || 5,
            param: {
                inType: 1,
                type: userInfo?.type
            }
        }) || {}
        this.stoTotal = total || 0
        this.stoTableList = list || []
        this.stoTotalMoney = Array.isArray(list) && list.length ? list[0].allIncomeMoney || 0 : 0
    }
}

export default new IndexStore();
