import React, { useState, useEffect } from 'react';
import Button from '../../components/Button';
import Tab from '../../components/TabPro';

import ShipOwner from './component/ShipOwner';
import Investors from './component/Invertors';
import { useIntl } from 'react-intl';
import { useUrlQuery } from '../../hooks';

import { useParams, useHistory } from 'react-router-dom';
import { useObserver } from 'mobx-react';

import './index.scss';


const Introudce: React.FC = () => {
    const { type } = useParams();
    const history = useHistory();
    const [active, setActive] = useState(type === 'shipowner' ? 0 : 1);
    const { formatMessage: f } = useIntl();
    const whatSTO = useUrlQuery('whatSTO');

    useEffect(() => {
        setActive(type === 'shipowner' ? 0 : 1);
    }, [type]);
    useEffect(() => {
        if(whatSTO && whatSTO === '1'){
            setTimeout(() => {
                scrollToAnchor("whatSto");
            },500) 
        }
    },[whatSTO])
    const scrollToAnchor = (anchorId:string) => {
        if (anchorId) {
            let anchorElement = document.getElementById(anchorId);
            if(anchorElement) {    
                anchorElement.scrollIntoView({block: 'start', behavior: 'smooth'});
            }
        }
    }


    return useObserver(() => (
        <section className='introudce common-wrap'>
            <div className="introudce-content">
                <div className='title-wrap'>
                    {
                        active === 0 ? (<div>
                            <div className='title' style = {{marginBottom: "0px",lineHeight:"60px"}}>{f({ id: 'introduce.titleOne1' })}</div>
                            <div className='title' style = {{lineHeight: ""}}>{f({ id: 'introduce.titleTwo2' })}</div>
                        </div>) : <div className='title'>{f({ id: 'introudce.title0' })}</div>
                    }
                    <Button
                        onClick={() => {
                            history.push('/offer')
                        }}
                        size='large'>{f({ id: 'introudce.title.btn' })}</Button>
                </div>

                <div className='main-wrap'>

                    <div className="tab-wrap" >
                        <Tab
                            active={active}
                            onChange={setActive}
                            data={[f({ id: 'introudce.main.tab.0' }), f({ id: 'introudce.main.tab.1' })]}
                        />
                    </div>

                    <main id = "stoParent">
                        <div className='main-common'>
                            <p className="main-title">
                                {f({ id: 'introudce.shipowner.title' })}
                            </p>
                            <p className="main-title-desc">
                                {f({ id: 'introudce.shipowner.title.desc' })}
                            </p>
                        </div>

                        {
                            active === 0 ? <ShipOwner /> : <Investors />
                        }

                    </main>
                </div>
            </div>
        </section>
    ))
}

export default Introudce;