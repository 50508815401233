/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import { Title,Button } from '../../components';
import { useHistory } from 'react-router-dom';
import './index.scss';
import {  Button as Abt, Modal,Steps } from 'antd';
import { useIntl } from 'react-intl';
import rootStore from '../../store'
import {getCurrentUserInfo,getUserInfo} from '../../utils'
import api from '../../api'



const Buyer = () => {
    const { formatMessage: f } = useIntl();
    const history = useHistory();
    const [test,setTest] = useState(1);
    const {Step} = Steps;
    const [showText,setShowText] = useState('')
    useEffect(() => {
        
        const {id} = getUserInfo() || {};
        if( id)  {
            api.findUserById({id}).then(res => {
                sessionStorage.setItem('userInfo', JSON.stringify(res));
                rootStore.userInfo =res
                if (!rootStore.hasLogin) { // 未登录
                    return setShowText('Get Verified >>')
                }
                if (rootStore.isSecondTZZ) { // 已认证
                    return setShowText('You\'re verified.')
                }
                if ( [2, '2'].includes(rootStore.adminAuditAuth)) { // 审核中
                    return setShowText('Buyer qualification is under review')
                }
                return setShowText('Get Verified >>')
            })
            
                
        }
        if (!rootStore.hasLogin) { // 未登录
            return setShowText('Get Verified >>')
        }
        if (rootStore.isSecondTZZ) { // 已认证
            return setShowText('You\'re verified.')
        }
        if ( [2, '2'].includes(rootStore.adminAuditAuth)) { // 审核中
            return setShowText('Buyer qualification is under review')
        }
       
        return setShowText('Get Verified >>')
        
    },[rootStore.hasLogin, rootStore.isSecondTZZ, rootStore.adminAuditAuth])

    const hanldeQualified = () => {
        // 是否登录
        const hasLogin = rootStore.hasLogin;
        if(!hasLogin) {
            Modal.confirm({
                content:`You need to log in or register an account to continue with our service.`,
                onCancel: () => {
                    return history.push('/register/login');
                },
                onOk:() => {
                    return history.push('/register/signup');
                },
                okText: "Register",
                cancelText: "Log in"
            })
            return;
        }
        if (rootStore.isSecondTZZ) { 
            return false
        }

        
       checkVerif();
    }
    useEffect(() => {
        setTest(test+1);
    },[rootStore.hasLogin,rootStore.getAuthType,rootStore.getAuthStatus])
    const checkVerif = () => {
         // 按断状态是否包含1已登录  包含则通过，不允许进入认证页面
        if(rootStore.userInfo && rootStore.userInfo.status &&  rootStore.userInfo.status.toString().indexOf("1") > -1) {
            return;
        }
        // 认证中
        if(rootStore.userInfo && rootStore.userInfo.status &&  rootStore.userInfo.status.toString().indexOf('2') > -1) {
            Modal.warning({
                content: `Investor certification information has been submitted, please wait patiently`,
                onOk: () => {
                    return;
                }
            });
            return;
        }
           history.push('/usercenter/auth');
    }
    return (
        <section className="common-wrap about">
            <p className='page-title'>{f({ id: 'home.banner.title' })}</p>
            <p className='page-title'>{f({ id: 'home.banner.subTitle' })}</p>
            {/* <p className='page-title'>{f({ id: 'introudce.title0' })}</p> */}
            <div className="search-wrap">
                <div className="search">
                    {/* <input value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Please enter keywords to search' className='m-input search-input'></input>
                    <Button onClick={() => {
                        if(!title) return;
                        fetchList('', title)
                    }}
                    size='middle' className="search-btn">Search</Button> */}
                </div>
            </div>

            <div className="list-wrap">
                <div className = "help">How to buy a token?</div>
                <div className = "steps">
                    <div className = "stepTop">
                        <div className = "circle">1</div>
                        <div className = "line"></div>
                        <div className = "circle">2</div>
                        <div className = "line"></div>
                        <div className = "circle">3</div>

                    </div>
                    <div className = "stepValue">
                        <div>
                            <div className = "title">
                                Register
                            </div>
                            <div className = "description">
                            Create a user account at <span style = {{textDecoration : "underline"}}><a href = "https://www.shipdigitalbank.com/">shipdigitalbank.com</a></span>
                            </div>
                        </div>
                        <div>
                            <div className = "title">
                            Buyer qualification
                            </div>
                            <div className = "description">
                                Submit your information for verification
                            </div>
                        </div>
                        <div>
                            <div className = "title">
                            Deposit and Buy
                            </div>
                            <div className = "description">
                            Manage your account and begin investing
                            </div>
                        </div>
                    </div>
                </div>
                <div className="header">
                    <Title>Buyer Qualification</Title>
                </div>
                <div className = "contentBuyer">
                <p>MDR is committed to providing information regarding shipping projects including ship-assets, employment, and management for investors and shipowners. MDR is open to all investors who are legally eligible to participate. All investors are required to register and pass MDR’s investor verification process to begin investing.</p>
	            <p>Before proceeding, you may familiarize yourself with the eligibility requirements for purchase of vessel tokens issued by shipowners on MDR.</p>
                <p>The following are required to register as an investor:</p>
                <ul>
                    <li>Your Nationality</li>
                    <li>Your Personal ID and Passport</li>
                    <li>Your Entity information (if you are making investments on behalf of an entity)</li>
                </ul>
                <p>
                    <Button
                    type="primary"
                    onClick = {hanldeQualified}

                    >
                        {/* {rootStore.hasLogin && rootStore.getAuthType === 3 ? "Get Verified>>": "You're verified."} */}
                        {showText}
                    </Button>
                </p>
                <p>Important Notes:</p>
                    <ul>
                        <li>This verification process determines investor eligibility for MDR. MDR reserves the right to report this information to regulatory authorities and deny investor eligibility for any reason.</li>
                        <li>MDR will not be held responsible or liable for any results produced by this verification process or any legal consequences caused by relaying of information provided during the verification process to regulatory authorities.</li>
                    </ul>
                <p>Click below to learn more about MDR STOs.</p>
                <p>
                <Button
                    type= "primary"
                    onClick = {() => {window.location.href = "/#/introduce/shipowner"}}
                >Learn about STO&nbsp;&gt;&gt;</Button>
                </p>
                </div>
            </div>
        </section>
    )
}

export default Buyer;
