import React from 'react';

import './index.scss';

interface IButton {
    children?: React.ReactNode;
    onClick?: Function;
    className?: string;
    style?: React.CSSProperties;
    type?: 'primary' | 'ghost';
    flex?: boolean;
    size?: 'large' | 'middle' | 'small';
    arrowRight?: boolean;
    arrowLeft?: boolean;
    disabled?:boolean;
}

const Button: React.FC<IButton> = (props) => {
    const { style, arrowRight, arrowLeft,disabled = false } = props;
    const clazzName = props.type === 'primary' ? '' : props.type;
    return (
        <div
            // tabIndex={1}
            style={style}
            className={["mdr-button", props.className, clazzName, props.size || 'middle', props.flex ? 'flex' : '', disabled? 'disabled': ''].join(' ').trim()}
            onClick={() => props.onClick && !disabled && props.onClick()}
        >
           { arrowLeft && (<img alt='icon' className='icon-left' src={require('../../assets/image/arrow.png')}/>) }
            {props.children}
            {
                arrowRight && (<img alt='icon' src={require('../../assets/image/arrow.png')}/>)
            }
        </div>
    );
}

export default Button;
