import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Link, useHistory } from 'react-router-dom';
import { getUserInfo } from '../../../../utils';
import { message } from 'antd';
import { useStores } from '../../../../hooks';
import { observer } from 'mobx-react';

const ShipOwner: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();
    const { rootStore } = useStores();
    const [rightText, setRightText] = useState('');

    const history = useHistory();

    useEffect(() => {
        setRightText(getRightText())
    }, []);

    const handleToAbout = (keyword?: string) => {
        history.push(`/introduce/shipowner?whatSTO=${1}`);
    }

    const handleAuth = () => {
        if (!rootStore.hasLogin) {
            message.error( "Please login first to proceed with more services >>");
            history.push('/register/login')
            return;
        }

        if (rootStore.userInfo.status === undefined) {
            history.push('/usercenter/sto/apply');
            return
        }
        if (rootStore.userInfo ?.status === 0) {
            return;
        }
        history.push('/usercenter/sto');
    }

    const getRightText = () => {
        const { status } = rootStore.userInfo || {};
        if (status === undefined) {
            return 'Get Qualified Now'
        } else if (status === 0) {
            return 'Get Qualified Now'
        } else {
            return 'Qualified Investor'
        }
    }

    const handleToStoList = () => {
        if (!rootStore.hasLogin) {
            message.error('Please login first to proceed with more services >>');
            history.push('/register/login')
            return;
        }
        history.push('/usercenter/sto')
    }
    const handleToStoList1 = () => {
        if (!rootStore.hasLogin) {
            message.error('Please login first to proceed with more services >>');
            history.push('/register/login')
            return;
        }
        history.push('/usercenter/sto/apply')
    }
    const handleToHelp = () => {
        history.push('/about?keyword=Buyer Qualification&id=3');
    }
    return (
        <div className='shipowner-menu mdr-navbar-dropdown'>
            {/* <div className="row">
                <span className='label'>Existing Shipowner</span>
            </div> */}
            <div className="row" >
                <a onClick={() => handleToAbout('Eligibility')} className='label'>{f({ id: 'What is an STO' })}</a>
            </div>

            <div className="row" >
            <a onClick={handleToStoList1} className='label'>Apply for STO</a>

            </div>
            <div className="row">
                <a onClick={handleToStoList} className='label'>{f({ id: 'STO Management' })}</a>
            </div>
            <div className="row" style={{ justifyContent: 'space-between', alignItems: 'flex-start' }}>
                <span
                // onClick={() => handleToHelp()}
                 className='label'>{f({ id: 'overlay.NeedHelp' })}</span>
                <div className='col' style={{ alignItems: 'flex-end' }}>
                    {/*<span className="label">+86-10-64930505</span>*/}
                    <span className="label">+49 408221-76111</span>
                    <span className="label">service@shipdigitalbank.com</span>
                </div>

            </div>
        </div>
    )
})

export default ShipOwner;
