import React from 'react';
import { useIntl } from 'react-intl';


import './index.scss';
import { Link } from 'react-router-dom';
import FormItem from 'antd/lib/form/FormItem';
import store from '../../store';
import { Title } from '../../../../../components';
import { Input } from 'antd';

const Email: React.FC = () => {
    const { formatMessage: f } = useIntl();

    return (
        <div className='login-email-wrap'>
            <div style={{marginTop: '10px'}} className="input-item">
                <span className='label'>Email address</span>
                <FormItem name="email" rules={[{ required: true, type: 'email' }]}>
                    <Input onChange={(e) => store.email = e.target.value} className='m-input' placeholder={f({ id: 'register.login.email.placeholder' })} style={{ width: '100%' }} />
                </FormItem>

            </div>

            <div className="input-item form-group password">
                <span className='label'>{f({ id: 'register.login.pwd.label' })}</span>
                <FormItem name='password' rules={[{required: true}]}>
                    <Input onChange={(e) => store.password = e.target.value} type='password' className='m-input' placeholder={f({ id: 'register.login.pwd.placeholder' })} style={{ width: '100%' }} />
                </FormItem>

            </div>


            <div className="checkbox-wrap">
                <input id='cx' type="checkbox" />
                <label htmlFor="cx">{f({ id: 'remeberme' })}</label>

                <Link to='/resetpwd' className='forget-pwd'>{f({ id: 'forgetpwd' })}</Link>
            </div>

        </div>


    )
}

export default Email;