import { observable, computed } from "mobx";
import api from "../../../../api";
import { getAllOptions } from "../../../../utils";

class Store {
  @observable cur = 0;

  @observable stoId = '';

  @observable list = [
    'Fundraising',
    // 'Fundraising Transfer',
    // 'Cash withdrawal', 'Dividend', 
    // 'Finish Offer'
]
@observable finishData  = [] as any;
  @observable fundraising: {
    actualFundraising: number;
    endDate: string;
    leftDays: number;
    raisingFunds: number;
    startDate: string;
    status: number;
    stoId: number;
    subtotalValue: number;
    tokenNumber: number;
    tokenPrice: number;
    totalFundraising: number;
    totalReceived: number;
    transactionFee: number;
    userHoldNumber: number;
    statusDesc:string;
    isTransfer:boolean;//是否给船东装过账
    fundraisingComplateDate: string;//筹款完成时间
  } = {} as any;

  @observable transfer :  {
    actualTransferShipowner: number;
    totalFundraising: number;
    transactionFee: number;
    transferShipowner: number;
    transferTime: string;
    transactionFeeRate: number;
  }  = {} as any;

  @observable transactionFee = 0;

  fetchFundraisingInfo = async () => {
    const data = await api.fundraisingInfo(this.stoId) || {};
    const finishData = await api.stoInvestorList(this.stoId);
    this.finishData = finishData;
    this.fundraising = data;
    this.list = [
      'Fundraising'
    ]
    // 第二个显示转账后，finish
    if(data.isTransfer){
      this.list.push('Fundraising Transfer');
    }
    //第三个显示//转账前后， finish
    if((data.status === 4 || data.status === 6) || finishData.find((item: any) => item.type === 'Finished'))  {
      this.list.push('Finish Offer')
    }
  }

  fundraisingTransfer = async () => {
    const data = await api.fundraisingTransfer(this.stoId) || {};
    this.transfer = data;
  }

  fetchAllOptions = async () => {
   const data = await api.findStoApprovalById(this.stoId);
  //  this.transactionFee = data;
  console.log(data)
  }

  handleNext = () => {
    // 转账后退款，结束，请求接口拿到信息
    if(this.fundraising.isTransfer) {
      this.fundraisingTransfer();
      this.cur = 1;
    }
  }
  init() {
    this.fetchFundraisingInfo();
    this.fetchAllOptions();
  }
}

export default new Store();