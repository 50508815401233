import React, { useEffect, useState, useRef } from 'react';
import { TabPro, Title, Button } from '../../../../components';
import './index.scss'
import { STO_STATUS_MAP } from '../../../../constants';
import { Table, Pagination, Button as Abt, Popconfirm, message } from 'antd';
import { useHistory } from 'react-router-dom';
import api from '../../../../api';
import dayjs from 'dayjs';
import { transLocal } from '../../../../utils';
import { ColumnsType } from 'antd/es/table';
import {CaretRightOutlined} from '@ant-design/icons'
import {createFromIconfontCN} from '@ant-design/icons'


const MyIcon = createFromIconfontCN({
    scriptUrl: '//at.alicdn.com/t/font_2221356_i0s91sh6ahc.js', 
  });
const dataSource = new Array(10).fill('').map(() => ({
    projectType: 'Trading Vessel',
    vesselType: '1,500 teu less',
    name: 'HAMMONIA BALTICA',
    initDate: '2020-01-31',
    fundraising: '100,000 MDRX',
    updateDate: '2020-02-27',
    status: 'Approving'
}));
interface SToList {
    key: number;
    name: string;
}
const Sto: React.FC = () => {
    const history = useHistory();
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);
    const refColumns = useRef() as any;
    useEffect(() => {
        fetchList();

    }, [pageIndex]);


    const fetchList = async () => {
        const userInfo = sessionStorage.getItem('userInfo');
        try {
            const { list, total } = await api.getStoList(({
                pageIndex,
                pageSize: 10,
                param: {
                    createUser: userInfo ? JSON.parse(userInfo).id : ''
                } as any
            })) || {};
            setTotal(total || 0)
            setList(list || [])


        } catch (error) {

        }
    }

    const getOngoingCount = () => {
        // const ongoing = list.filter((item: any) => item.status === 90);

        // if(ongoing.length === 0) return 'no';

        return list.length;
    }

    const deleteItem = async (id: string) => {
        await api.deleteStoById(id);
        message.success("draft sto delete success");
        fetchList();
    }





    const handleViewDetail = (row: any) => {
        // if (row.status >= 60) {
        //     history.push(`/usercenter/sto/detail/${row.id}?status=${row.status}`);
        //     return;
        // }
        history.push(`/usercenter/sto/apply?stoId=${row.id}`)
    }

    const handleNew = () => {
        history.push('/usercenter/sto/apply')
    }
    const columns: ColumnsType<SToList> = [
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'projectNumber',
            width: 100,
            // fixed: 'right',
            render: (item: any, row: any) => {
                return [
                    row.status === 10 ? <Popconfirm
                        title="Are you sure delete this sto?"
                        onConfirm={() => deleteItem(row.id)}
                        okText="Yes"
                        cancelText="No"
                    >
                        <div className= "deleteBtn">
                            <Abt type='link' style={{ fontSize: '12px' }}>Delete<span className = "deleteIcon"><MyIcon type="iconshanchu" /></span></Abt>
                        </div>
                    </Popconfirm>

                        : null,
                    <div className = "viewDetail">
                    < Abt type='link' style={{ fontSize: '12px' }} onClick={() => handleViewDetail(row)}> View Detail</Abt >
                    <span className = "iconDetail"><CaretRightOutlined /></span>
                    </div>
                ]
            }
        },
        {
            title: 'Project Number',
            dataIndex: 'projectNumber',
            key: 'projectNumber',
            width: '90px',
            align: 'center',
        },
        {
            title: 'Project Type',
            dataIndex: 'projectTypeName',
            key: 'projectTypeName',
            width: '105px',
            align: 'center',
        },
        {
            title: 'Vessel Type',
            dataIndex: 'vesselTypeName',
            key: 'vesselTypeName',
            width: '105px',
            align: 'center',
        },
        {
            title: 'Vessel / SPV Name',
            dataIndex: 'vesselName',
            key: 'vesselName',
            width: '120px',
            align: 'center',
        },

        // {
        //     title: 'Fundraising',
        //     dataIndex: 'fundraising',
        //     key: 'fundraising',
        //     width: '100px',
        //     render: (item:any) => transLocal(Number(item))
        // },
        {
            title: 'Inititate Date',
            dataIndex: 'createTime',
            key: 'createTime',
            width: '105px',
            align: 'center',
            render: (item: string) => dayjs(item).format('YYYY-MM-DD')
        },
        {
            title: ' Latest Update Date',
            dataIndex: 'modifyTime',
            key: 'modifyTime',
            width: '105px',
            align: 'center',
            render: (item: string) => dayjs(item).format('YYYY-MM-DD')
        },
        {
            title: ' Status',
            dataIndex: 'status',
            key: 'status',
            // fixed: 'right',
            width: '100px',
            align: 'center',
            //@ts-ignore
            render: (item: string) => STO_STATUS_MAP[item]
        },
    ]
    return (
        <section className='common-wrap sto-list'>
            <p className='page-title'>My STO Management</p>
            <p className='page-title'> Safe and secured STO Submission System. </p>

            <div className="tab-wrap">
                <TabPro active={0} data={['My STO Management']} />
            </div>

            <div className="content-wrap">
                <div className="header row">
                    <div className='title-wrap'>
                        <Title>My STO Applications</Title>
                        {getOngoingCount() === 0 && <span className='tips'>You currently have {getOngoingCount()} ongoing STO application</span>}
                    </div>
                    <Button onClick={handleNew} arrowRight size='middle'>Create New Application</Button>
                </div>
                <div className="sto-table">
                    <Table pagination={false} rowKey ='id' columns={columns} dataSource={list} scroll={{ x: "100%" }}></Table>
                    <Pagination
                        style={{ marginTop: '50px' }}
                        className='mdr-pagination'
                        showTotal={(total) => `${total} items in total`}
                        onChange={(page) => {
                            setPageIndex(page);
                        }}
                        showSizeChanger={false}
                        showQuickJumper={false}
                        defaultCurrent={pageIndex}
                        total={total}
                    />
                </div>
            </div>
        </section>
    )
}

export default Sto;