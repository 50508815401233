import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import store from './store';
import { Button, Title, Tab } from '../../../components';
import { useIntl } from 'react-intl';
import './index.scss';
import Email from './component/Email';
import SMS from './component/SMS';
import { Form,Modal } from 'antd';
import { useStores } from '../../../hooks';
import { useHistory } from 'react-router-dom';


const renderFormItem = (curStep: string) => {

    switch (curStep) {
        case 'email':
            return <Email />
        case 'phone':
            return <SMS />
        default:
            return null;
    }

}

const Login: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();
    const history = useHistory();

    const { rootStore } = useStores();
    const [form] = Form.useForm();

    useEffect(() => {
        store.setCurStep('email');
        return () => {
            store.smsVerify = false;
        }
    }, [])



    const loginStep = JSON.parse(f({ id: 'register.login.steps' })) as [{ key: string, value: string }];
    store.loginStep = loginStep.map(item => item.key);

    const handleNext = () => {
        form.validateFields().then(store.handleNextStep)
    }

    const handleLogin = () => {
        // rootStore.toggleLogin(true);
        history.push('/');
    }

    store.setLoginCb(handleLogin);



    return <>
        <div className="step-wrap login-step-wrap">
            <img alt='register' src={require('../../../assets/image/register.jpg')}></img>
        </div>
        <Form form={form} className="form-wrap">
            <div className="register-login-nav">
                <Title style={{ textAlign: 'center' }}>Log in</Title>
                <Tab disabled active={store.curStep === 'email' ? 0 : 1} simple data={['Email', 'Phone']}></Tab>
            </div>
            {renderFormItem(store.curStep)}
            <div className="btn-wrap">
                <Button
                    className='next-btn'
                    size='small'
                    onClick={handleNext}
                >
                    {f({ id: store.curStep === 'phone' ? 'register.login' : 'register.next' })}
                    <img src={require('../../../assets/image/arrow.png')} alt="arrow" />
                </Button>
            </div>

        </Form>
    </>
});

export default Login;