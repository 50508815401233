import React, { useState,useEffect } from 'react';
import { List } from 'antd';
import './index.scss';
import { Title, Button } from '../../../../../../components';
import { observer } from 'mobx-react';
import store from '../../store';
import { formatMoney } from '../../../../../../utils';
import dayjs from 'dayjs';


const Fundraising = observer(() => {

    const { fundraising, transfer } = store;

    const [isDone, setIsDone] = useState(false);

    useEffect(() => {
        setIsDone(store.fundraising.status === 5);
    },[store.fundraising])
    const data = [
        {
            key: 'Number of Tokens user Hold',
            value: fundraising.userHoldNumber,
            extra: 'Tokens'
        },
        {
            key: 'Token price',
            value: formatMoney(fundraising.tokenPrice),
            extra: 'MDRX per Token'
        },
        {
            key: 'Subtotal value',
            value: formatMoney(fundraising.subtotalValue),
            extra: 'MDRX'
        },

        {
            key: 'Transaction fee via MDR',
            value: formatMoney(fundraising.transactionFee),
            extra: `MDRX (${transfer.transactionFeeRate * 100}%)`,
        },

        {
            key: 'Actual transfer',
            value: transfer.actualTransferShipowner ? formatMoney(transfer.actualTransferShipowner) : '',
            extra: 'MDRX'
        },

        {
            key: 'Transfer time',
            value: dayjs(transfer.transferTime).format('YYYY-MM-DD'),
            extra: ''
        },
    ];

    return (
        <div className="detail-transfer">
            <Title>{isDone ? 'Fundraising transferred' : 'Fundraising completed, MDR administrator is transferring' }</Title>
            <span className='list-title'>Fundraising details</span>
            <List
                bordered
                dataSource={data.map(item => JSON.stringify(item))}
                renderItem={(item: string) => {
                    const items: any = JSON.parse(item);
                    return (
                        <List.Item className='row property-item'>
                            {/* <div className='r0w'> */}
                            <p className='label'>{items.key}</p>
                            <p className='value'>{items.value}</p>
                            <p className='extra'>{items.extra}</p>
                            {/* </div>    */}
                        </List.Item>
                    )
                }}
            />

            <div className="btn-wrap">
                <Button style={{marginRight: '10px'}} type='ghost' arrowLeft size='small' 
                onClick={() => { store.cur = 0 }} 
                >Go Back</Button>

                {//转账后退款 和finish
                   (store.finishData.find((item: any) => item.type === 'Finished') || store.fundraising.status === 6) && <Button type='ghost' arrowRight size='small' 
                    onClick={() => { store.cur = 2 }}
                     >Next</Button>
                }
            </div>
        </div >

    )
})

export default Fundraising