import React, { useState, useEffect } from 'react';
import { Button } from '../../../components';
import './index.scss';
import moment from 'moment';
import { Menu, DatePicker, Pagination } from 'antd';
import { useHistory } from 'react-router-dom';
import api from '../../../api';

const { RangePicker } = DatePicker;

const TIME = [
    {
        label: 'This Week',
        value: 'week',
    },
    {
        label: 'This Month',
        value: 'month',
    },
    {
        label: 'This Quarter',
        value: 'quarter',
    },
    {
        label: 'This Year',
        value: 'year',
    },
    {
        label: 'Last Year',
        value: 'lastYear',
    }
]

const menus = [...TIME];


const PressList = () => {
    const [cur, setCur] = useState(menus[0].label);
    const [index, setindex] = useState(1);
    const [list, setlist] = useState([]) as any;
    const [total, settotal] = useState(0);
    const [timeInterval, setTime] = useState('');

    const [timeRange, setTimeRange] = useState([]) as any;

    const [title, setTitle] = useState('');

    const history = useHistory();
    useEffect(() => {
        fetchList();
    }, [timeInterval])

    const handleTimeChange = (item: any) => {
        if (cur === item.label) {
            setCur('')
            setTime('')
        } else {
            setCur(item.label)
            setTime(item.value)
        }
    }

    const handleTimeRangeChange = (value: any) => {
        console.log(value);
        if(value) {
            setTimeRange(value.map((item: string) => moment(item).format('YYYY-MM-DD')));
        }else {
            setTimeRange([]);
        }
        setCur('');
        setTime('');
    }

    const fetchList = async () => {
        const param = timeRange[0] || timeRange[1] ? {
                keyword: title,
                timeInterval: timeInterval,
                startTime: timeRange[0] || '',
                endTime: timeRange[1] || '',
                statusParam: [1]
        }: {
                keyword: title,
                timeInterval: timeInterval,
                statusParam: [1]
        }
        const { total, list = [] } = await api.fetchNewsList({
            pageIndex: index,
            pageSize: 50,
            param
        }) || {};
        setlist(list);
        settotal(total);
    }
    return (
        <section className="common-wrap press-list">
            <p className='page-title'>Articles, blogs, and updates</p>
            {/* <p className='page-title'>in the lucrative ship owning industry</p> */}

            <div className="search-wrap">
                <div className="search">
                    <input onChange={e => setTitle(e.target.value)} placeholder='Please enter keywords to search' className='m-input search-input'></input>
                    <Button onClick={() => fetchList()
                    } size='middle' className="search-btn">Search</Button>
                </div>
            </div>

            <div className="list-wrap">
                <div className="header">
                    <Menu mode="horizontal" selectedKeys={[cur]}>
                        {/* {
                            menus.map((item: any, index) => (
                                <Menu.Item onClick={() => handleTimeChange(item)} key={item.label}>
                                    {item.label}
                                </Menu.Item>
                            ))
                        } */}

                    </Menu>
                    <div className="customer-date-wrap">
                        <span className='label'>Duration</span>
                        <RangePicker
                        onChange={handleTimeRangeChange}
                        style={{ width: '210px' }}></RangePicker>

                        <Button onClick={() => fetchList()} type='ghost'>Confirm</Button>
                    </div>
                </div>

                <div className="list">
                    {
                        list.map((item: any) => (
                            <div className='press-item row' key = {item.id}>
                                <img src={item.showPicture} alt="" />
                                <div className='detail'>
                                    <p className='title' style = {{textAlign: "left"}}>{item.title}</p>
                                    {/* <p className='desc'>{item.remark}</p> */}

                                    <Button size='small' onClick={() => {
                                        history.push(`/press/${item.id}`)
                                    }} type='ghost'>more</Button>
                                </div>
                            </div>
                        ))
                    }
                </div>

                <Pagination className='mdr-pagination'
                    showTotal={(toal) => `${toal} items in total`}
                    showSizeChanger={false} showQuickJumper total={total} />
            </div>
        </section>
    )
}

export default PressList;
