import React, { useState,useEffect } from 'react';
import { useIntl } from 'react-intl';

import { CheckOutlined, CloseCircleOutlined } from '@ant-design/icons';
import './index.scss'
import SMSInput from '../../../../../components/SMSInput';
import { useObserver } from 'mobx-react';
import store from '../../../store';
import { Title } from '../../../../../components';
import FormItem from 'antd/lib/form/FormItem';

const SMS: React.FC = () => {

    const [value, setValue] = useState(false);
    const [success, setSuccess] = useState(false);
    useEffect(() => {
        return () => {
            store.smsVerify = false;
        }
    },[])

    const { formatMessage: f } = useIntl();
    const onDone = async (value: string) => {
        try {
            await store.checkSms({ phoneNumber: store.phone, code: value });
            setValue(true);
            store.smsVerify = true;
        } catch (error) {
            setValue(false);
            store.smsVerify = false;
        }
    }

    const isError  = !value && success;

    return useObserver(() => (
        <div className='register-sms-wrap'>
              <span className='sms-title' >{f({ id: 'register.sms.title' })}</span>
            <FormItem
                // validateStatus={ isError ? 'error' : 'success'}
                
                // help={ isError ? "digit code error" : ''}
                style={{display: 'flex'}}
                name='code' className='sms-wrap'>

                <SMSInput success={value} phoneNumber={store.phone} showRetry onDone={onDone} length={6}></SMSInput>
            </FormItem>
        </div>
    ))
}

export default SMS;