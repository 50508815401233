import React, { useState } from 'react';
import { useIntl } from 'react-intl';

import './index.scss';
import FormItem from 'antd/lib/form/FormItem';
import { Input } from 'antd';
import { PWD_REG } from '../../../../constants';
import store from '../../store';

const Password: React.FC = () => {
    const { formatMessage: f } = useIntl();
    return (
        <div className='reset-pwd-wrap'>
            <div className="input-item">
                <span className='label'>{f({ id: 'reset.pwd.new' })}</span>
                <FormItem
                    name='password'
                    required
                    rules={
                        [
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value) {
                                        return Promise.reject(f({ id: 'register.password.one.placeholder' }));
                                    } else if (!PWD_REG.test(getFieldValue('password'))) {
                                        return Promise.reject(f({ id: 'register.password.one.note' }))
                                    }else if (value && PWD_REG.test(getFieldValue('password'))) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(f({ id: 'register.password.one.placeholder' }));
                                },
                            })
                        ]
                    }
                >
                    <Input.Password type='password' className='m-input' placeholder={f({ id: 'register.password.one.placeholder' })} style={{ width: '100%' }} />
                </FormItem>



            </div>

            <div className="input-item form-group">
                <span className='label'>{f({ id: 'register.password.confirm' })}</span>
                <FormItem
                    name='confirm'
                    required
                    dependencies={['password']}
                    rules={
                        [
                            { required: true, message: "Confirm password is required" },
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        store.password = value;
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords that you entered do not match!');
                                },
                            })
                        ]
                    }
                >
                    <Input.Password type='password' className='m-input' placeholder={f({ id: 'register.password.confirm.placeholder' })} style={{ width: '100%' }} />
                </FormItem>
            </div>
        </div>
    )
}

export default Password;