import React, {useEffect, useState} from 'react';
import './index.less'
import {TabPro} from '../../components'
import MyPortfolio from "./components/myPortfolio/myPortfolio";
import {useUrlQuery} from "../../hooks";
import MyList from "./components/myList/myList";
import MyWallet from "./components/myWallet/myWallet";
import Summary from "./components/Summary/Summary";
const PeopleMessage = () => {
    const [active, setActive] = useState(2)
    const tabData = [ 'My Wallet',  'My Portfolio', 'Summary']
    // const tabMap = [<MyList/>, <MyWallet/>, <MyPortfolio active={active}/>]
    const tabMap = [<MyWallet/>, <MyPortfolio active={active}/>, <Summary/>]
    const query = useUrlQuery('active')
    useEffect(() => {
        if(['0', '1','2'].includes(query)) {
            setActive(Number(query))
        }
    }, [query])
    const handleChange = (index: number) => {
        setActive(index)
    }
    return (
        <section className='common-wrap sto-apply'>
            <p className='page-title'>My Investment Management</p>
            <div className="tab-wrap">
                <TabPro active={active}  onChange={handleChange} data={tabData}/>
            </div>
            <div className='content-wrap'>
                {
                    tabMap[active]
                }
            </div>
        </section>
    )
}
export default PeopleMessage
