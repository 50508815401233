import React, { useEffect, useState,useCallback, useLayoutEffect, useRef } from 'react';
import { TabPro, Title, Button,TokenDetailModal } from '../../../../components';
import './index.scss'
import { STO_STATUS_MAP, VESSEL_TYPE } from '../../../../constants';
import { Table, Pagination, Button as Abt,Modal, Space, InputNumber } from 'antd';
import { useHistory } from 'react-router-dom';
import api from '../../../../api';
import dayjs from 'dayjs';
import {ColumnsType} from "antd/es/table";
import { formatMoney,formatLingMoney } from '../../../../utils';
import {CaretRightOutlined} from '@ant-design/icons'
import {pipeline} from "stream";

interface SToList {
    projectType: string,
    vesselType: string,
    name: string,
    initDate: string,
    fundraising: string,
    updateDate: string,
    status: string,
    number: number,
    useNumber: number,
    expireTime: string,
    id: number,
    relationId: number,
    investment: number
    purchaseDate: string,
    tokenName: string,
    operState: number, // 状态是0代表不能被操作
    latestUpdateDate: string,
    date:String
}

// const dataSource:Array<SToList> = new Array(10).fill('').map(() => ({
//     projectType: 'Trading Vessel',
//     vesselType: '1,500 teu less',
//     name: 'HAMMONIA BALTICA',
//     initDate: '2020-01-31',
//     fundraising: '100,000 MDRX',
//     updateDate: '2020-02-27',
//     status: 'Approving'
// }));

const findLabelByVal = (data : [], value: string) => {
    const item = data.find((item: any) => item.value === value) || {} as any;
    return item.label || '--'
}
let columns = [] as any;


const InvestList: React.FC = () => {
    const history = useHistory();
    const [list, setList] = useState([]) as any
    const [total, setTotal] = useState(0);
    const [detailModal,setDetailModal] = useState(false);
    const [selectRow,setSelectRow] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);
    const [transTokenFlag, setTransTokenFlag] = useState(false)
    const [currentRow, setCurrentRow]  = useState<Partial<SToList>>({})
    const [sellTokenNumber,setSellTokenNumber] = useState(0)
    const [onOkLoading, setOnOkLoading] = useState(false)
    const [currentLink ,setCurrentLink] = useState(false)
    useEffect(() => {
        fetchList();
    }, [pageIndex]);

    const fetchList = async (orderBy?:string) => {
        try {
            const { list, total } = await api.selectMyInvestment({
                pageIndex,
                pageSize: 10,
                orderBy: orderBy? orderBy: ''
            }) || {};
            setTotal(total || 0)
            setList(list || [])
        } catch (error) {

        }

    }
    const getTokenNumber = (number: number) => {
        console.log(number)
        setSellTokenNumber(number)
    }
    const handleViewDetail = (row: any) => {
        // setSelectRow(row.id);
        // setDetailModal(true);
        history.push(`/usercenter/invest/detail/${row.id}`);
    }

    const handleNew = () => {
        history.push('/offer')
    }
    const transforOffer = (row: any) => {
        console.log('转换token')
        console.log(row)
        setCurrentRow(row)
        setCurrentLink(currentLink => !currentLink)
    }
    const submitSellToken = () => {
        setOnOkLoading(true)
        if(+sellTokenNumber <= 0) {
            Modal.warning({
                content: '123',
                centered: true
            })
            setOnOkLoading(false)
            return
        }
        setTimeout(() => {
            console.log(currentRow)
            console.log(sellTokenNumber)
            setOnOkLoading(false)
            setTransTokenFlag(false)
        }, 3000)
    }
    const firstUpdate = useRef(true);
    useLayoutEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        else {
            console.log(currentRow)
            setTransTokenFlag(true)
        }
    }, [currentLink]);
    const tableSortChange = (pagination:any, filters:any,sorter:any) => {
        const {order,columnKey} = sorter;
        let orderBy = '' as string;
        if(!order) {
            orderBy = '';
        }else {
            orderBy = `${columnKey === 'purchaseDate'? 'tmb.create_time': 'tsa.token_name'} ${order === "ascend"?'ASC':'DESC'}`
        }
        fetchList(orderBy)
    }
    const columns:ColumnsType<SToList> = [
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            fixed: 'left',
            align: 'center',
            width: 140,
            render: (item: any, row: any) => {
                console.log(JSON.parse(JSON.stringify(row)).display)
                return  (
                    <div style = {{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
                        <Abt
                            className = "viewDetail"
                            type='link' style={{ fontSize: '12px' }}
                            onClick={() => handleViewDetail(row)}>
                            View Detail
                            <span className = "iconDetail"><CaretRightOutlined /></span>
                        </Abt>

                        {
                            JSON.parse(JSON.stringify(row)).status === 70? <Abt
                            className = "viewDetail"
                            type='link' style={{ fontSize: '12px' }}
                            onClick={() => transforOffer(row)}>
                            Transfor to Ex Offer
                            </Abt> : null
                        }


                    </div>
                )
            }
        },
        {
            title: 'Purchase Date & Time',
            dataIndex: 'purchaseDate',
            key: 'purchaseDate',
            width: 250,
            align:'center',
            render: (item: string) => dayjs(item).format('YYYY-MM-DD HH:mm:ss'),
            sorter: true
        },
        {
            title: 'Investment',
            dataIndex: 'investment',
            key: 'investment',
            width: '130px',
            align:'center',
            render: (item: any) => item ? formatMoney(item) : '--'
        },
        // {
        //     title: 'Purchase Date',
        //     dataIndex: 'purchaseDate',
        //     key: 'purchaseDate',
        //     width: '170px',
        //     render: (item: string) => dayjs(item).format('YYYY-MM-DD'),
        // },
        {
            title: "Token Name",
            dataIndex: "tokenName",
            key: "tokenName",
            width: "170px",
            align:'center',
            sorter: true
        },
        {
            title: 'Project Type',
            dataIndex: 'projectType',
            key: 'projectType',
            width: 250,
            align:'center',
            render: (value: string) => {
                return findLabelByVal(VESSEL_TYPE as [], value);
            }
        },
        {
            title: 'Vessel Type',
            dataIndex: 'vesselType',
            key: 'vesselType',
            align:'center',
            width: '150px'
        },
        {
            title: 'Vessel / SPV Name',
            dataIndex: 'vesselName',
            key: 'vesselName',
            align:'center',
            width: 260
        },
        {
            title: 'Transaction Status',
            dataIndex: 'status',
            key: 'status',
            align:'center',
            width: 160,
            //@ts-ignore
            // render: (item: string) =>
            render:(item:string) => 'Success'
        },
        {
            title: 'Token Update Date',
            dataIndex: 'latestUpdateDate',
            key: 'latestUpdateDate',
            width: 160,
            align:'center',
            render: (item: string) => dayjs(item).format('YYYY-MM-DD')
        }
    ]
    const toTwoClick = (e: number) => {
        if(currentRow?.operState === 0) {
            return setTransTokenFlag(false)
        }
        if(!currentRow) {
            return
        }
        setOnOkLoading(true)
        api.insertTokenApply({relationId: currentRow.relationId,tokenId: currentRow.id, quantity: e}).then(res => {
            if(res.code == 200) {
                setTransTokenFlag(false)
            }
            window.location.reload();
            /*Modal.warning({
                content: res.msg || 'please try again'
            })*/
        }).finally(() =>setOnOkLoading(false))
    }
    return (
        <section className='common-wrap invest-list'>
            <p className='page-title'>Investment management</p>
            <p className='page-title'> Confidential validation for accredited investors</p>

            <div className="tab-wrap">
                <TabPro active={0} data={['Investment History']} />
            </div>

            <div className="content-wrap">
                <div className="header row">
                    <div className='title-wrap'>
                        <Title>Investment History</Title>
                        {/* <span className='tips'>You currently have no ongoing STO application</span> */}
                    </div>

                    <Button onClick={handleNew}  size='middle'>Investment Opportunities</Button>
                </div>

                <div className="invest-table table-list-nav">
                    <Table pagination={false}
                    columns={columns}
                    dataSource={list}
                    scroll = {{x: 1300}}
                    onChange = {tableSortChange}
                    >
                    </Table>
                    <Pagination style={{ marginTop: '50px' }} className='mdr-pagination'
                        showTotal={(total) => `${total} items in total`}
                        onChange={(page) => setPageIndex(page)}
                        showSizeChanger={false}
                        showQuickJumper
                        defaultCurrent={pageIndex}
                        total={total}
                    />
                </div>
            </div>
            <Modal
                visible ={detailModal}
                // centered
                footer={<div style = {{textAlign: "center"}}><Button onClick = {() => setDetailModal(false)}>OK</Button></div>}
                onCancel={() =>{setDetailModal(false)}}
                className='invest-modal'
                closable={true}
                destroyOnClose = {true}
                width = {730}
            >
               <TokenDetailModal id = {selectRow} />
            </Modal>
            <Modal
                centered
                destroyOnClose
                title='Notice'
                visible={transTokenFlag}
                onCancel={() => setTransTokenFlag(false)}
                onOk={() => submitSellToken()}
                okButtonProps = {{disabled: onOkLoading, loading: onOkLoading}}
                cancelButtonProps={{disabled: onOkLoading, loading: onOkLoading}}
                footer = {null}
            >
                {
                    currentRow?.operState !== 0 ? <ConfirmTrans currentRow={currentRow} disabled = {onOkLoading} clickHandler={toTwoClick}/>
                    : <WaitTransToken currentRow={currentRow} disabled= {onOkLoading} clickHandler= {toTwoClick} />

                }
            </Modal>
        </section>
    )
}

export default InvestList;

const ConfirmTrans = (props: {currentRow: Partial<SToList>, clickHandler?:(e:number) => void, disabled: boolean}) => {
    const [cellNumber, setCellNumber] = useState(0)
    const { currentRow = {} } = props
    const clickHandler = () => {
        if(props.disabled) {
            return
        }
        if(!cellNumber) {
            Modal.warning({
                content: 'Please fill in the number of tokens to resell'
            })
            return
        }
        props.clickHandler && props.clickHandler(cellNumber)
    }
    return(
        <div style = {{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <div style = {{fontSize: '16px', marginBottom: '30px', alignSelf: 'flex-start'}}>
             <Space>
            You are requesting transfer this part of portfolio you own to EX market for retail.
            </Space>
            </div>
            <div style = {{fontSize: '16px', marginBottom: '30px', alignSelf: 'flex-start' }}>
                <Space>
                    <div className='Transferrable-title'>Total Transferrable Tokens:</div>
                     {formatLingMoney(currentRow.number || 0)}
                    {/* <InputNumber min={0} max={isNaN(Number(currentRow.number)) ? 0 : Number(currentRow.number)} value = {cellNumber} onChange={e => setCellNumber(isNaN(Number(e)) ? 0 : Number(e))}/> */}
                </Space>
                <br />
                <Space>
                   <div  className='Transferrable-title'>Transferred Amount on EX:</div>{  formatLingMoney(currentRow.useNumber || 0)}
                </Space>
            </div>
            <div style = {{fontSize: '16px', marginBottom: '30px', alignSelf: 'flex-start' }}>
                <Space>
                    <div  className='Transferrable-title' style={{ fontWeight: 900}}>My Requesting Quantity:</div>
                    <InputNumber min={0} max={isNaN(Number(currentRow.number)) ? 0 : Number(currentRow.number)} value = {cellNumber || 0} onChange={e => setCellNumber(isNaN(Number(e)) ? 0 : Number(e))}/>
                </Space>
            </div>
            <div style = {{fontSize: '16px', marginBottom: '30px', alignSelf: 'flex-start' }}>
             <Space>
             EX market is a platform freely for exchange. Once this transfer is approved, please go to your EX portfolio to check latest status of your investment.
            </Space>
            </div>
            {/* <p style = {{fontSize: '16px', marginBottom: '10px', alignSelf: 'flex-start' }}>You've requested transfer this part of portfolio you
                owned to EX market.
            </p> */}
            {/* <p style = {{fontSize: '16px', marginBottom: '10px', alignSelf: 'flex-start'}}>EX market is a platform freely for exchange. Once this transfer is approved, please go to your EX portfolio to check latest status of your investment.</p> */}
            {/* <p style = {{fontSize: '16px', marginBottom: '20px', alignSelf: 'flex-start'}}>Once this transfer is approved, please go to your <span style = {{textDecoration: 'underline'}}>EX
                portfolio</span> to check latest status of your investment.</p> */}
            <Abt loading={props.disabled} onClick={() => clickHandler()}>Yes, I want to submit this request.</Abt>
        </div>
    )
}

const WaitTransToken = (props:{ currentRow: Partial<SToList>, clickHandler?: Function, disabled: boolean}) => {
        const { currentRow = {} } = props
        const { date = ''} = currentRow
        const clickHandler = () => {
        if(props.disabled) {
            return
        }
        props.clickHandler && props.clickHandler()
    }
    return (
        <div style = {{display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column'}}>
            <p style = {{fontSize: '16px', marginBottom: '10px', alignSelf: 'flex-start' }}>Message at {date}</p>
            <p style = {{fontSize: '16px', marginBottom: '10px', alignSelf: 'flex-start'}}>Your request is submitted successfully. Your portfolio will be updated once your request is approved.</p>
            <Abt loading={props.disabled} style = {{width: '200px'}} onClick={() => clickHandler()}>OK</Abt>
        </div>
    )
}
