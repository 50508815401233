import React from 'react';
import {
    Router,
    withRouter,
} from 'react-router-dom';
import { renderRoutes } from 'react-router-config';

import App from '../App';
import Home from '../Pages/Home';
import Introudce from '../Pages/Introudce';
import Register from '../Pages/Register';

import OfferIndex from '../Pages/Offer/Index';

import ResetPwd from '../Pages/ResetPwd';

import OfferDetail from '../Pages/Offer/Detail';

import UserWallet from '../Pages/UserCenter/Wallet';

import WalletTopup from '../Pages/UserCenter/Wallet/Topup';

import WalletWithDraw from '../Pages/UserCenter/Wallet/Withdraw';

import UserSto from '../Pages/UserCenter/Sto/list';

import StoDetail from '../Pages/UserCenter/Sto/detail';

import UserStoApply from '../Pages/UserCenter/StoApply';

import InvestList from '../Pages/UserCenter/Investment/list';

import InvestDetail from '../Pages/UserCenter/Investment/detail';

import PressList from '../Pages/Press/list';

import PressDetail from '../Pages/Press/detail';

import CompanyDetail from '../Pages/UserCenter/Company/detail';

import CompanyList from '../Pages/UserCenter/Company/list';

import Message from '../Pages/UserCenter/Message';

import About from '../Pages/About';

import Account from '../Pages/UserCenter/Account';

import Certification from '../Pages/UserCenter/Certification';
import Buyer from '../Pages/Buyer';

import PeopleMessage from "../Pages/peopleMessage";

import { createHashHistory } from 'history';




export const hashHistory = createHashHistory();

export const allRoutes = [
    {
        path: '/',
        exact: true,
        component: Home,
    },

    {
        path: '/introduce/:type',
        exact: true,
        component: Introudce,
    },
    {
        path: '/register/:type',
        exact: true,
        component: Register,
    },
    {
        path: '/resetpwd',
        exact: true,
        component: ResetPwd,
    },
    {
        path: '/offer',
        exact: true,
        component: OfferIndex,
    },
    {
        path: '/offer/:id',
        exact: true,
        component: OfferDetail,
    },
    {
        path: '/usercenter/wallet',
        exact: true,
        component: UserWallet,
    },
    {
        path: '/usercenter/sto',
        exact: true,
        component: UserSto,
    },
    {
        path: '/usercenter/sto/detail/:id',
        exact: true,
        component: StoDetail
    },
    {
        path: '/investment/manage',
        exact: true,
        component: PeopleMessage
    },
    {
        path: '/usercenter/sto/apply',
        exact: true,
        component: UserStoApply,
    },
    {
        path: '/usercenter/invest',
        exact: true,
        component: InvestList
    },
    {
    
        path: '/usercenter/company/',
        exact: true,
        component: CompanyList
    },
    {
        path: '/usercenter/company/detail',
        exact: true,
        component: CompanyDetail
    },
    {
        path: '/usercenter/invest/detail/:id',
        exact: true,
        component: InvestDetail
    },
    {
        path: '/press',
        exact: true,
        component: PressList,
    },
    {
        path: '/press/:id',
        exact: true,
        component: PressDetail,
    }, 
    {
        path: '/usercenter/wallet/topup',
        exact: true,
        component: WalletTopup,
    },
    {
        path: '/usercenter/wallet/withdraw',
        exact: true,
        component: WalletWithDraw,
    },
    {
        path: '/usercenter/message/:type',
        exact: true,
        component: Message,
    },
    {
        path: '/about',
        exact: true,
        component: About,
    }, 
    {
        path: '/usercenter/account',
        exact: true,
        component: Account,
    },
    {
        path: '/usercenter/auth',
        exact: true,
        component: Certification,
    },
    {
        path: '/about/buyer',
        exact:true,
        component: Buyer
    }
]


const AppTmp = withRouter(App);

const RouteAll: React.FC = () => {
    return (
        <Router history={hashHistory}>
            <AppTmp>
                {
                    renderRoutes(allRoutes.map(item => ({ ...item, key: item.path })))
                }
            </AppTmp>
        </Router>
    )
};

export default RouteAll;
