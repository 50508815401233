/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';

import './index.scss';
import { TabPro } from '../../../components';

import Pin from './components/pin';
import Steps from './components/steps';
import {Modal} from 'antd';
import { useHistory } from 'react-router-dom';
import { useUrlQuery } from '../../../hooks';
import store from './components/steps/store';
import rootStore from '../../../store';
import api from '../../../api';
import { observer } from 'mobx-react';

const ApplySto: React.FC = observer(() => {
    const stoId = useUrlQuery('stoId');
    const history = useHistory();
    const [active, setactive] = useState(0);

    useEffect(() => {
        // if(!rootStore.hasLogin) {
        //     Modal.warning({
        //       content: "Please login before operation",
        //       onOk: () => {
        //           return history.push('/#/register/login');
        //       }  
        //     })
        // }
        setactive(!stoId ? 0 : 1);
        store.setStoId(stoId);
        store.stoStatus  = 0;
        fetchStoInfo();
    }, [stoId]);


    const fetchStoInfo = async () => {
        if(!stoId) return;
       const data =  await api.getStoInfoMainById(stoId)
       store.stoStatus = data.status;
    }

    const handleChange = (index: number) => {
        if (index === 0) {
            history.push('/usercenter/sto');
        }
    }

    const hanldePinNext = () => {
        setactive(1);
    }

    return (
        <section className='common-wrap sto-apply'>
            <p className='page-title'>STO management</p>
            <p className='page-title'> Secured STO submission portal</p>

            <div className="tab-wrap">
                <TabPro active={1} onChange={handleChange} data={['My STO Management', 'New STO Application']}></TabPro>
            </div>
            <div className='content-wrap'>
                {
                    active === 0 ? <Pin onNext={hanldePinNext} /> : <Steps />
                }
            </div>
        </section>
    )
});

export default ApplySto;