import React, { useState } from 'react';

import './index.scss';

interface ICard {
    fold: boolean;
    title: string;
    children: any;
    singleNode?:boolean;
}

const Folder: React.FC<ICard> = (props) => {
    const [fold,setold] = useState(props.fold);
    return (
        <div className='card'>
            <div className="card-head">
                {!props.singleNode && <img alt='icon' onClick={() => setold(val => !val)} src={require(`../../assets/image/${fold ? 'add': 'min'}.png`)} />}
                <p className="title">{props.title}</p>
                {/* <Button onClick={() =>setold((val:boolean) => !val)} type='ghost' size='middle'>
                    {fold ? 'Expand' : 'Retract'}
                </Button> */}
            </div>
            {
                !fold ? <div className="card-body">
            
                {props.children }
           
            </div> : null
            }
        </div>
    )
}

export default Folder