import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { MDown, MSelect, Button, Title } from '../../../../../components/index';
import './index.scss';
import { observer } from 'mobx-react';
import store from '../../../store';
import Form, { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { Input } from 'antd';
import { values } from 'mobx';
import Identity from '../Identity';
import Company from '../Company';

const Personal: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();
    useEffect(() => {
        store.getAllLocation();
    }, []);



    const handleLocationChange = (item: any) => {
        store.updateLocation(item)
    }

    const handleCityChange = (item: string) => {
        store.updateModel({ city: item })
    }
    const handleNationalityChange = (item:string) => {
        console.log(item);
        store.updateModel({nationality:item});
        console.log(store.model.nationality);
    }

    const handleUserNameChange = (e: any, key: string) => {
        const value = e.target.value
        store.updateModel({ [key]: value })
        sessionStorage.setItem(key, value);
    }

    const givenameLabel = f({ id: 'register.person.givename' });
    const surfNameLabel = f({ id: 'register.person.surname' });
    return (
        <div className='register-personal'>
            {/* <Title>{f({ id: 'register.person' })}</Title> */}
            <div style={{ marginTop: '20px' }} className="row">
                <div className="input-item">
                    <span className='label'>{givenameLabel}</span>
                    <FormItem name={givenameLabel} rules={[{ required: true }]}>
                        <Input onChange={(e) => handleUserNameChange(e, 'givenName')} className='m-input username' placeholder={f({ id: 'register.person.givename.placeholder' })} />
                    </FormItem>
                </div>
                <div className="input-item m-l-15">
                    <span className='label'>{surfNameLabel}</span>
                    <FormItem name={surfNameLabel} rules={[{ required: true }]}>
                        <Input onChange={(e) => handleUserNameChange(e, 'surname')} className='m-input username' placeholder={f({ id: 'register.person.surname.placeholder' })} />
                    </FormItem>
                </div>
            </div>
            {/* <div className="form-group"> */}
            <div className="input-item">
                <span className='label'>{f({ id: 'register.person.country' })}</span>
                <div className="location-wrap">
                    <FormItem
                        name={'country'} rules={[{ required: true }]}
                    >
                        <MSelect
                            allowClear
                            value={store.model.location}
                            onChange={handleLocationChange}
                            className='username'
                            dataSource={store.location}
                            showSearch={true}
                            optionFilterProp={'children'}
                        />
                    </FormItem>
                    <FormItem
                        name={'city'} rules={[{ required: true }]}
                    >
                        <MSelect
                            allowClear
                            value={store.model.city}
                            onChange={handleCityChange}
                            className='username'
                            showSearch={true}
                            optionFilterProp={'children'}
                            style={{ marginLeft: '15px' }}
                            dataSource={store.city} />
                    </FormItem>

                </div>
            </div>
            <Identity  handleNationalityChange = {handleNationalityChange} value = {store.model.nationality}/>

            <Company />
            {/* </div> */}
        </div>
    )
})

export default Personal;