import React, { useState ,useEffect} from 'react'

import './index.scss';

const activeBg = require('../../assets/image/tab-pro-default.png');

const defaultBg = require('../../assets/image/tab-pro-active.png');

interface ITabPro {
    data: Array<string>;
    active: number;
    disabled?: boolean;
    onChange?: (index: number) => void;
}

const TabPro: React.FC<ITabPro> = (props) => {
    const [active, setActive] = useState(props.active);
    useEffect(() => {
        setActive(props.active)   
    }, [props.active])
    const handleChange = (index: number) => {
        if (props.disabled) return;
        setActive(index)
        props.onChange && props.onChange(index);
    }
    return (
        <div className={`tab-pro ${props.disabled ? 'disabled' : ''}`}>
            {
                props.data.map((item: string, index: number) => (
                    <div key={index} onClick={() => handleChange(index)} className={`item item-${index} ${active === index ? 'active' : ''} ${index === 0 && props.data.length > 1 ? 'first' : ''}`}>
                        <img src={index === active ? activeBg : defaultBg} className='bg' />
                        {item}
                    </div>
                ))
            }
        </div>
    )
}

export default TabPro;