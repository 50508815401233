import { observable, action, runInAction, computed } from "mobx";
import { ILocation, ICity, ICodeCheck, ISignUpReq } from "../../../api/type";
import api from "../../../api";
import { FormInstance } from "antd/lib/form";
import { message } from "antd";
import { getUserInfo } from "../../../utils";
import { data } from "../../Offer/Index/mock";

interface Idown {
    label: string;
    value: string;
}

class Store {
    @observable active = 1;

    @observable curStep = "email";

    registerStep: Array<string> = [];

    @observable isSms: boolean = false;

    @observable isRegisterDone = false;

    @observable location: Array<Idown> = [];
    @observable city: Array<Idown> = [];
    @observable locationValue:string ='';
    @observable cityValue:string = '';

    currentForm: FormInstance = {} as FormInstance;

    @observable smsVerify: boolean = false;

    @observable model = {
        city: "",
        companyAddress: "",
        companyName: "",
        email: "",
        givenName: "",
        location: "",
        password: "",
        phoneCountry: "+86",
        phoneNumber: "",
        surname: "",
    };

    @observable userInfo: {
        city: string;
        companyAddress: string;
        companyName: string;
        createTime: string;
        email: string;
        givenName: string;
        id: number;
        location: string;
        password: string;
        phoneCountry: string;
        phoneNumber: string;
        pinCode: string;
        surname: string;
        type: number;
        status:string,
        updateTime: string;
    } = {} as any;
    // ----------
    async fetchUserInfo() {
        const { id } = getUserInfo() || {};
        // 如果不存在id就退出

        if(!id) {
            message.warning('Please log in and try again')
            window.location.href = "#/register/login"
            return;
        }
        const userInfo = await api.findUserById({ id });
        this.userInfo = userInfo;
        // 拿到所有的国家
        this.getAllLocation();
       
    }

    updateUserInfo = async (data: any, cb?: Function) => {
        console.log(data);
        await api.userUpdate(data);
        cb && cb();
    }




    // -------------

    @computed
    get phone() {
        const { phoneCountry, phoneNumber } = this.model;
        return `${phoneCountry}${phoneNumber}`;
    }

    @action.bound
    updateModel = (params: any) => {
        this.model = {
            ...this.model,
            ...params,
        };
    };

    checkExist = async () => {
        const data = await api.selectExiestUser({
            phoneCountry: this.model.phoneCountry,
            phoneNumber: this.model.phoneNumber,
        });
        if (data === 0) return true;
        message.error('This mobile number has been used')
        return false;
    }

    updateLocation = (location: string, cb?:Function) => {
        this.getCityByLocation(location, cb);
    };

    sendSms = () => {
        const { phoneCountry, phoneNumber } = this.model;

        if (!phoneCountry || !phoneNumber) return;
        api.sendSms({ phoneNumber: this.phone });
    };

    checkSms = (params: ICodeCheck) => {
        return api.checkSms(params);
    };

    getAllLocation = async () => {
        try {
            const location = await api.getLocation();
            runInAction(() => {
                this.location = location.map((item) => ({
                    value: item.id,
                    label: item.location,
                }));
            });
           const checkLocation = this.location.filter(item => item.label === this.userInfo.location);
           this.locationValue = checkLocation.length === 1?checkLocation[0].value: ''
            this.getCityByLocation(checkLocation.length === 1?checkLocation[0].value: '');
        } catch (error) { }
    };

    getCityByLocation = async (location: string, cb?:Function) => {
        if (!location) return;
        try {
            const cityList = await api.getCityById(location);
            runInAction(() => {
                this.city = cityList.map((item) => ({
                    value: item.id,
                    label: item.city,
                }));
                const checkCity = this.city.filter(item => item.label === this.userInfo.city);
                this.cityValue = checkCity.length === 1?checkCity[0].value: '';
               cb && cb(cityList[0].id)
            });
        } catch (error) { }
    };

    async initLocation() {
        await this.getAllLocation();
    }

    signup = async () => {
        try {
            await api.signup(this.model as ISignUpReq);
            this.isRegisterDone = true;
        } catch (error) { }
    };

    @action.bound
    setActive(active: number) {
        localStorage.setItem("isRegisterDone", "1");
        this.active = active;
    }

    @action.bound
    setCurStep(curStep: string) {
        this.curStep = curStep;
    }

    handleNextStep = async () => {
        if (this.curStep === "phone" && !this.isSms) {
            const data = await this.checkExist();
            if (!data) return;
            this.sendSms();
            this.isSms = true;
            return;
        }

        if (this.curStep === "phone" && this.isSms && !this.smsVerify) {
            return;
        }

        if (this.curStep === "phone" && this.isSms && this.smsVerify) {
            this.isSms = false;
        }

        if (this.curStep === "personal") {
            this.signup();
            return;
        }

        const index = this.registerStep.findIndex((item) => item === this.curStep);

        const next = index + 1;
        if (next < this.registerStep.length) {
            this.setCurStep(this.registerStep[next]);
        }
    };

    handleBack = () => {
        const index = this.registerStep.findIndex((item) => item === this.curStep);

        if (this.curStep === "phone" && this.isSms) {
            this.isSms = false;
            return;
        }

        const next = index - 1;
        if (next < this.registerStep.length) {
            this.setCurStep(this.registerStep[next]);
        }
    };
}

export default new Store();
