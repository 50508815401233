import React, { useEffect, useState } from 'react';

import { useIntl } from 'react-intl';

import { useObserver } from 'mobx-react';
import store from './store';
import SignUp from './Signup';
import { useParams } from 'react-router-dom';
import LogIn from './LogIn';
import {Modal} from 'antd';
import './index.scss';



const Register: React.FC = () => {
    const { formatMessage: f } = useIntl();

    const { type } = useParams();

    

    useEffect(() => {
        store.setActive(type === 'login' ? 1 : 0)
        Modal.info({
            title: "Notification",
            centered: true,
            content: <span>Internal Test Only. However, you are free to register your interest although you are not allowed to invest online for this period. Alternatively you can  contact us by email: <a href="mailto:service@shipdigitalbank.com">service@shipdigitalbank.com</a> if you have any enquiries.</span>,
            icon: null,
            okText: "Accept",
            onOk: () => {
              
            }
        })
    }, [type]);

    useEffect(() => {
        store.getAllLocation();
    },[])

    return useObserver(() => (
        <section className="common-wrap register-wrap ">
            <p className='page-title'>{f({ id: 'register.title' })}</p>
            <p className='page-title'>{f({ id: 'register.subTitle' })}</p>

            <div className="register-card">
                {/* <Tab disabled active={store.active} data={JSON.parse(f({ id: 'register.tab' }))} ></Tab> */}
                <div className="register-form">
                    {
                        store.active === 0 ? <SignUp /> : <LogIn />
                    }
                </div>
            </div>
        </section>
    ))
}

export default Register;