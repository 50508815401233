import { observable, runInAction } from "mobx";
import api from "../../../../../../../api";
import stepRootStore from '../../store'
import rootStore from '../../../../../../../store';
import {message} from 'antd';

class Store {
    @observable projectType = '';

    @observable vesselType = '';

    @observable projectObjective = '';

    @observable projectNo = '';

    @observable vesselTypeList = [];

    @observable addFlag = false;

    async fetchVesselTypeList(name:any) {
        const data = await api.fetchProjectOptions({projectType:name});
        this.vesselTypeList = data || [];
    }

    //@ts-ignore
    async init(form) {
        if (!stepRootStore.stoId) return;
        const data = await api.getStoInfoMainById(stepRootStore.stoId) || {};
        form.setFieldsValue({
            ...data
        });
        stepRootStore.stoStatus = data.status;
        this.projectNo = data.projectNumber;
    }

    back() {
        stepRootStore.curStep = 'companyInfo';
    }

    async updateStoInfoMain(data: any) {
        if(this.addFlag) {
            return;
        }
        this.addFlag = true;
        if (!stepRootStore.stoId) {//增加
            const result = await api.addStoInfoMain({...data,pinCode: stepRootStore.pinCode});
            await api.addStoInfoAll({
                ...data,
                id: result,
                pinCode: stepRootStore.pinCode
            })
            stepRootStore.setStoId(result);
        } else {
            await api.updateInfoMain({
                ...data,
                id: stepRootStore.stoId,
            });
        }
        this.addFlag =false;
        stepRootStore.curStep = 'companyInfo';

    }
    // 只更新表单
    async updateFormMessage(data:any) {
        if (!stepRootStore.stoId) {
            const result = await api.addStoInfoMain(data);
            await api.addStoInfoAll({
                ...data,
                id: result,
            })
            stepRootStore.setStoId(result);
        } else {
            await api.updateInfoMain({
                ...data,
                id: stepRootStore.stoId,
            });
            message.info('Data has been saved');
        }
    }


    startPinCheck = () => {
        if (!stepRootStore.stoId) return;
        if (sessionStorage.getItem('pin') === '1') return;

        sessionStorage.setItem('lastTime', Date.now().toString());
        
        const startTime = () => {
            let timer: any = null;
            let lastFrame = 0;
            cancelAnimationFrame(timer);
            timer = requestAnimationFrame(function fn() {
                const lastTime = sessionStorage.getItem('lastTime') || 0;

                console.log(lastFrame > 0 && Date.now() - lastFrame < 60 * 1000);

                if(lastFrame > 0 && Date.now() - lastFrame < 60 * 1000) {
                    lastFrame = Date.now();
                    return;
                }
                lastFrame = Date.now();

                
                
                if(!window.location.hash.startsWith('#/usercenter/sto/apply')) {
                    console.log('1111')
                    cancelAnimationFrame(timer);
                    sessionStorage.removeItem('lastItem');
                    return;
                };
                // 60 * 1000 * 10
                if ((Date.now() - +lastTime) > 6000 ) {
                    rootStore.hasPin = true;
                    sessionStorage.setItem('pin', '1');
                    sessionStorage.removeItem('lastItem')
                    cancelAnimationFrame(timer)
                } else {
                    timer = requestAnimationFrame(fn);
                }
            });
        }

        // startTime();
    }

}

export default new Store();