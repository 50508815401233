import React from 'react';
import './index.scss';
import { Title, CompanyForm, Button } from '../../../../components';
import { useUrlQuery } from '../../../../hooks';
import { useHistory } from 'react-router-dom';

const layout = {
    labelCol: { span: 7 },
    wrapperCol: { span: 13 },
};

const CompanyDetail = () => {
    const id = useUrlQuery('id');
    const history = useHistory();
    return (
        <section className="common-wrap company-detail">
            <p className='page-title'>STO management</p>
            <p className='page-title'>Secured STO submission portal</p>

            <div className="detail-wrap">
                <div className="header row">
                    <Title>Company Information</Title>

                    {
                        id ? (
                            <p className='info'>
                                <span>ID:10213100000134 </span>
                                <span>Last Saved 2020-01-05 12:28:01</span>
                            </p> 
                        ) : null
                    }
                </div>

                <div className="back-wrap">
                    <Button onClick={() => history.goBack() } type='ghost' arrowLeft></Button>
                </div>

                <div className="company-form-wrap">
                    <CompanyForm 
                        colon={false} 
                        labelAlign={'right'}
                         {...layout}  
                         id={id} 
                         arrow
                         onClose={() => history.goBack()}
                         closeText="Go Back"
                    />
                </div>

            </div>
        </section>
    )
}

export default CompanyDetail;