import React, { useEffect, useState } from 'react';

import './index.scss'
import { Button,Title } from '../../../components';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../api';
import dayjs from 'dayjs';
import Item from 'antd/lib/list/Item';

const PressDetail = () => {
    const history = useHistory();

    const [news, setNews] = useState({}) as any;

    const [list ,setList] = useState([]) as any;

    const {id} = useParams();

    useEffect(() => {
        findItem();
        findList();
    }, [id]);
    
    const handleback = () => {
        history.push('/press')
    }

    const findList = async () => {
        const {  list = [] } = await api.fetchNewsList({
            pageIndex: 1,
            pageSize: 3,
            param: {
                timeInterval: 'year',
                statusParam:[1]
            }
        }) || {};
        const newList = list.filter((item: any) => item.id !== id ).slice(0,2);
        setList(newList);
    }

    

    const findItem = async () => {
       const data = await api.findNewsById(id) || {};

       setNews(data)
    }

    return (
        <section className="common-wrap press-detail">
            <p className='page-title'>Set sail on an alternative investment opportunity</p>
            <p className='page-title'>in the lucrative ship owning industry</p>
            <div className="detail-content">
                <div className="header">
                    <p className="press-no">
                    Press  {dayjs(news.createTime).format('YYYY-MM-DD')}
                    </p>

                    <Button size='small' onClick={handleback} arrowLeft type='ghost'></Button>
                </div>
                <div className="body">
                    <Title >{news.title}</Title>

                    <div className="row" style={{alignItems: 'stretch'}}>
                    <div className="artical">
                        <div className="summary row">
                            <img src={news.showPicture} style = {{width: "100%"}} alt=""/>

                        </div>
                        <div className="desc">

                               {news.remark}
                        </div>
                        <div className="main-content" dangerouslySetInnerHTML={{
                            __html: news.content
                        }}>

                       
                            
                        </div>

                        <div className="back-wrap">
                            <Button onClick={handleback} type='ghost' size='middle' arrowLeft>Back to List</Button>
                        </div>
                    </div>
                    <div className="more-wrap">
                            <div className="section-title">
                            More reading
                            </div>

                            {
                                list.map((item: any, index: number) => (
                                    <div onClick={() => {
                                        history.push(`/press/${item.id}`)
                                    }} key={index} className={`more-item ${index === 1 ? 'no-border' : ''}`}>
                                           <img  src={item.showPicture} alt=""/> 
                                           <div className = "descParent">
                                                <p className='desc' 
                                                style = {{width:"186px"}} 
                                                dangerouslySetInnerHTML={{__html: item.title}}>
                                                </p>
                                           </div>
                                    </div>
                                ))
                            }
                    </div>
                    </div>       
                </div>
            </div>
        </section>
    )

}

export default PressDetail;