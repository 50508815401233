/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from 'react';
import { Modal, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useStores } from '../../../../hooks';
import { observer, useObserver } from 'mobx-react';
import publicRootStore from '../../../../store'


const Usercenter: React.FC = observer(() => {
  const { rootStore } = useStores();
  const [test, setTest] = useState(1);
  const [showModal, setShowModal] = useState(false)
  const history = useHistory();

  const handleInvestment = () => {
    checkVerif();
  }
  const checkVerif = async () => {
    // 是否是投资者 如果是跳转到对应页面
    if (rootStore.getCrrentAuthType.includes(1)) {
      history.push('/usercenter/invest');
      return;
    }//判断是否重复提交
    //不是投资者判断是否重复提交
    if (rootStore.getAuthStatus === 2) {
      Modal.warning({
        content: `Investor certification information has been submitted, please wait patiently`,
        onOk: () => {
          return;
        }
      });
      return;
    }
    history.push('/usercenter/auth');
  }
  useEffect(() => {
    setTest(5)
  }, [rootStore.userInfo])
  useEffect(() => {
    setTest(6);
  }, [rootStore.userInfo.type])

  const peopleHandler = (key: number) => {
    // if (publicRootStore.userInfo.type.indexOf(1) > -1 ) {
    //   return  history.replace(`/investment/manage?active=${key}`);
    // }
     //不是投资者判断是否重复提交
     if(rootStore.getAuthStatus === 2 ) {
      Modal.warning({
          content: `Investor certification information has been submitted, please wait patiently`,
          onOk: () => {
              return;
          }
      }); 
      return;
    }
    if (!rootStore.isFirstTZZ && !rootStore.isSecondTZZ) {
      setShowModal(true)
      return
    }
    history.replace(`/investment/manage?active=${key}`);
  }

  const MessageHandler = () => {
    history.replace('/usercenter/message/shopowner');
  }

  return useObserver(() => (
    <div className='usercenter mdr-navbar-dropdown'>
      {/* 已经登录并且已经是投资者 */}
      {
        rootStore.hasLogin && (rootStore.getCrrentAuthType.includes(1))
        && <div className="row">
          <span onClick={handleInvestment} className='label'>Investments Management</span>
        </div>
      }
      
      <div className='row'>
      {/* {rootStore.isFirstTZZ || rootStore.isSecondTZZ} */}
        <span className={['label', (rootStore.isFirstTZZ || rootStore.isSecondTZZ ) ? '' : 'noLink'].join(' ')} onClick={() => peopleHandler(0)}> My Wallet</span>
      </div>

      <div className='row'>
        <span className={['label', (rootStore.isFirstTZZ || rootStore.isSecondTZZ ) ? '' : 'noLink'].join(' ')} onClick={() => peopleHandler(1)}>My Portfolio</span>
      </div>
      <div className='row'>
        <span className='label' onClick={MessageHandler}>My Messages</span>
      </div>
      <div className='row'>
        <span className={['label', (rootStore.isFirstTZZ || rootStore.isSecondTZZ ) ? '' : 'noLink'].join(' ')} onClick={() => peopleHandler(2)}>Account Summary</span>
      </div>

      {/* <div className="row">
                <Link className='label' to='/usercenter/sto'>My STO Management</Link>
            </div> */}

      <div className="row">
        <Link className='label' to='/usercenter/account'>Account Settings</Link>
      </div>

      {

        rootStore.getCrrentAuthType.includes(1) ? <div className="row">
          <Link className='label' to='/usercenter/wallet'>My Wallet</Link>
        </div> : null

      }



      <div className="row">
        <a
          onClick={() => { rootStore.logout() }}
          className='label'>Log out</a>
      </div>
      <Modal visible={showModal} footer={null} centered onCancel={() => setShowModal(false)}>
        <p>Please go to <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => { setShowModal(false); history.push('/usercenter/auth') }}>buyer qualification</span> to proceed with more service&gt;&gt;</p>
      </Modal>
    </div>
  ))
})

export default Usercenter;
