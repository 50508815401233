import React, { useEffect, useState } from 'react';
import './index.scss';
import { TabPro, Button, Title } from '../../../../components';
import { observer } from 'mobx-react';

import store from './store';

import Fundraising from './components/fundraising';

import Divide from './components/divide';
import Finish from './components/finish';
import Purchase from './components/purchase';
import Refund from './components/refund';
import { useHistory, useParams } from 'react-router-dom';
import api from '../../../../api';
import { formatMoney,transLocal,formatLingMoney } from '../../../../utils';
import { Timeline } from 'antd';

const Detail: React.FC = observer(() => {
    const history = useHistory();
    const [list, setList] = useState([]);
    const { id } = useParams();

    const [sum, setSum] = useState({}) as any;

    useEffect(() => {
        fetchInvestDetail();
    }, [])

    const fetchInvestDetail = async () => {
        const data = await api.stoInvestorList(id as string) || [];
        const sum = data.find((item: any) => item.type === 'Sum');
        setSum(sum);
        setList(data);
    }

    //     [
    //         {
    //         "transfee":5.0,
    //         "purchase":"2020-06-26 13:55:06",
    //         "tokenPrice":1000.0,
    //         "subTotal":4900000.0,
    //         "type":"Purchase token",
    //         "totalPay":5145000.0,
    //         "transactionFee":245000.0,
    //         "numberOfToken":4900
    //     },
    // 	{

    // 		type:Refund   //退款
    // 		"numberOfToken":4900
    // 		"tokenPrice":1000.0,
    // 		subTotal：（不含手续费）
    // 		totalPay:      //总付款
    // 		transactionFee:'' //手续费
    // 		"purchase":"2020-06-26 11:14:34",

    // 	}


    // 	{
    // 		type:Finished  //结束
    // 		"purchase":"2020-06-26 11:14:34",
    // 	}
    //   ]


    const renderItem = (data: any) => {
        console.log(data)
        switch (data.type) {
            case 'Fundraising'://筹款
                return <Fundraising dataSource={data}></Fundraising>
            case 'Purchase token'://购入
                return <Purchase dataSource={data} />;
            case 'Transfer_To_EX'://转售
                return <Purchase dataSource={data} />;
            case 'divident'://分红
                return <Divide dataSource={data}></Divide>
            case 'Finished'://结束
                return <Finish dataSource={data}></Finish>
            case 'Refund'://退款
                return <Refund dataSource={data} />;
        }
    }

    return (
        <section className='common-wrap invest-detail-wrap'>
            <p className='page-title'>My Investments Management</p>
            <p className='page-title'>Safe and confidential validation process for</p>
            <p className='page-title'>accredited investors</p>

            <div className="tab-wrap">
                <TabPro
                    active={0}
                    data={['Investment details']}></TabPro>
            </div>

            <div className="invest-content">
                <div className="header">
                    <div className="back-wrap">

                        <Button onClick={() => {
                            // history.push(`/usercenter/invest`);
                            history.goBack();
                        }} type='ghost' arrowLeft></Button>
                    </div>
                </div>

                <div className="invest-details">
                    <div className="mcc-wrap">
                    <Title>{sum.tokenName}</Title>

                        <div className="mcc-info row">
                            <div className='section-item'>
                                <p className='section-title'>
                                    My investment
                                </p>

                    <p className='item'>Number of tokens I bought:  <span className='value'>{formatLingMoney(sum.numberOfToken || 0)}</span> Tokens</p>

                                <p className='item'>Token price: <span className='value'>{transLocal(sum.tokenPrice)}</span> MDRX per token</p>
                                <p className='item'>Subtotal value: <span className='value'>{transLocal(sum.subTotal)}</span> MDRX</p>
                            </div>

                            <div className='section-item'>
                                <p className='item'>
                                    Dividend income: <span className='value'>{sum.projectObjective === 'Equity'? sum.dividendIncome: transLocal(sum.dividendIncome)}</span>{sum.projectObjective === 'Equity'? 'Token': "MDRX"}
                                </p>
                            </div>
                        </div>
                    </div>
                    {/* "dividendIncome":3000.0,
        "projectObjective":"Equity",
        "tokenPrice":2000.0,
        "subTotal":12000.0,
        "type":"Sum",
        "numberOfToken":6
     */}
                    <div className="event-wrap">
    <Title>{sum.tokenName}</Title>

                        <div className="time-line">
                            <Timeline>
                                {
                                    list.filter((item: any) => {return item.type !== 'Sum' && item.type !== "REFUND_FUNDRAISING" && item.type !== "Refund all"}).map((item, index) => (

                                        <Timeline.Item color={index === 0 ? '#164A78' : '#D0D0D0'}>
                                            {
                                                renderItem(item)
                                            }
                                        </Timeline.Item>

                                    ))
                                }
                            </Timeline>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
})

export default Detail;
