import React from 'react';
import { useIntl } from 'react-intl';

import { Title } from '../../../../../components';
import FormItem from 'antd/lib/form/FormItem';
import { PWD_REG } from '../../../../../constants';
import store from '../../../store';
import './index.scss';
import { Input } from 'antd';

const Password: React.FC = () => {
    const { formatMessage: f } = useIntl();
    return (
        <div className="password-wrap">
            <Title style={{ marginBottom: '40px' }}>{f({ id: 'register.account' })}</Title>
            <div className="input-item">
                <span className='label'>password</span>
                <FormItem
                    name='password'
                    required
                    rules={
                        [
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (value && PWD_REG.test(getFieldValue('password'))) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(f({ id: 'register.password.one.placeholder' }));
                                },
                            })
                        ]
                    }
                >
                    <Input type='password' className='m-input' placeholder={f({ id: 'register.password.one.placeholder' })} style={{ width: '100%' }} />
                </FormItem>

            </div>

            <div className="input-item form-group">
                <span className='label'>Confirm Password</span>
                <FormItem
                    name='confirm'
                    required
                    dependencies={['password']}
                    rules={
                        [
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        store.updateModel({ password: value })
                                        return Promise.resolve();
                                    }
                                    return Promise.reject('The two passwords that you entered do not match!');
                                },
                            })
                        ]
                    }
                >
                    <Input type='password' className='m-input' placeholder={f({ id: 'register.password.confirm.placeholder' })} style={{ width: '100%' }} />
                </FormItem>

            </div>
        </div >
    )
}

export default Password;