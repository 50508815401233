import React, { useEffect, useState } from 'react';

import { Button as Abtn } from 'antd'
import { getUserInfo, updateUserInfo } from '../../../../../utils';

import './index.scss';
import { ArrowRightOutlined } from '@ant-design/icons';
import rootStore from '../../../../../store'
import { useObserver } from "mobx-react";
const Safe = () => {
    const [userInfo, setUserInfo] = useState({}) as any;

    useEffect(() => {
        updateUserInfo();
        const user = getUserInfo();
        setUserInfo(user);
    }, []);

    // const renderItem = () => { 
    //     const { status, type } = userInfo;
    //     switch (status) {
    //         case undefined://未认证
    //             return (
    //                 // <>
    //                 //     {
    //                 //         type === 1 && (//投资者
    //                 //             <div className='approve'>
    //                 //                 <p className='title'>Your investor certification application is under verify.</p>
    //                 //                 <p className='reason'>Reasons for rejection: xxxxx</p>
    //                 //                 <Abtn href='#/usercenter/auth' type='link' >Click to view details </Abtn>
    //                 //             </div>
    //                 //         )
    //                 //     }
    //                 //     {

    //                 //         type === 2 && (
    //                 //             <div className='approve'>
    //                 //                 <p className='title'>Your shipowner certification is under verify.</p>
    //                 //                 <Abtn href='#/usercenter/sto/apply?stoId=23' type='link' >Click to view details </Abtn>
    //                 //             </div>
    //                 //         )

    //                 //     }
    //                 // </>
    //                 <div>
    //                     <Abtn href='#/usercenter/auth' type='link' >Register as an investor <ArrowRightOutlined></ArrowRightOutlined> </Abtn>
    //                     {/* <Abtn href='#/usercenter/auth' type='link' >Create an MDR STO<ArrowRightOutlined></ArrowRightOutlined> </Abtn> */}
    //                 </div>
    //             )
    //         case 0://被拒绝
    //             return (
    //                 // <>
    //                 //     {
    //                 //         type === 1 && (
    //                 //             <div className='approve'>
    //                 //                 <p className='title'>Your investor certification application has been rejected.</p>
    //                 //                 {/* <p className='reason'>Reasons for rejection: xxxxx</p> */}
    //                 //                 <Abtn href='#/usercenter/auth' type='link' >Click to modify and reapply for certification </Abtn>
    //                 //             </div>
    //                 //         )
    //                 //     }
    //                 //     {

    //                 //         type === 2 && (
    //                 //             <div className='approve'>
    //                 //                 <p className='title'>Your shipowner certification application has been rejected.</p>
    //                 //                 <p className='reason'>Reasons for rejection: xxxxx</p>
    //                 //                 <Abtn href='#/usercenter/sto/apply?stoId=23' type='link' >Click to modify and reapply for certification </Abtn>
    //                 //             </div>
    //                 //         )

    //                 //     }
    //                 // </>
    //                 <div className='approve'>
    //                     <p className='title'>Your investor certification application has been rejected.</p>
    //                     {/* <p className='reason'>Reasons for rejection: xxxxx</p> */}
    //                     <Abtn href='#/usercenter/auth' type='link' >Click to modify and reapply for certification.</Abtn>
    //                 </div>
    //             )
    //         case 1://认证成功
    //             return (
    //                 <div>
    //                     <div className="row">
    //                         <p>Qualified investor</p>  <Abtn href='#/usercenter/auth' type='link' > Click to view details </Abtn>
    //                     </div>

    //                 </div>
    //             )
    //         case 2://认证中
    //             return(
    //                 <div className='approve'>
    //                     <p className='title'>Your investor certification is awaiting approval</p>
    //                     <Abtn href='#/' type='link' >Back Home</Abtn>
    //                 </div>
    //             )
    //         default:
    //             return null;
    //     }
    // }

    const renderItem = () => {
        const userInfo = getUserInfo();
        const { status2, type, status } = userInfo;
        if (type.indexOf('1') > -1 && type.indexOf('4') > -1) {
            return (
                <div>
                    <div className="row">
                        <p>Qualified & Retail Investor</p>
                    </div>

                </div>
            );
        } else if (type.indexOf('4') > -1) {
            return (
                <div>
                    <div className="row">
                        <p>Retail Investor</p>
                    </div>

                </div>
            );
        } else {
            if ( status === 2) {
                return (
                    <div className='approve'>
                        <p className='title'>Your investor certification is awaiting approval</p>
                        <Abtn href='#/' type='link' >Back Home</Abtn>
                    </div>
                );
            } else {
                return (
                    <div>
                        <div className="row">
                            <p>There is no buyer qualification record found.</p>
                        </div>

                    </div>
                );
            }

        }
    }

    return useObserver(() => (
        <div className='safe-wrap'>
            {
                renderItem()

            }

        </div>
    ))
}

export default Safe;
