import React, { useEffect, useState } from 'react';
import { TabPro, Button } from '../../../components';
import './index.scss'
import api from '../../../api';
import { useHistory } from 'react-router-dom';

const img = 'http://img.zcool.cn/community/01062858d66567a801219c77babce4.jpg@1280w_1l_2o_100sh.jpg';

enum CATEGORY_MAP { 'sto' = 1, 'password', 'auth', 'qa' }

const Message = () => {

    const [active, setActive] = useState(0);

    const [list, setList] = useState([]) as any;

    const history = useHistory();



    useEffect(() => {
        console.log(active)
        if (active === 0) {
            fetchShipOwner();
        } else {
            fetchInvest();
        }
    }, [active]);

    const fetchShipOwner = async () => {
        const { list } = await api.fetchShipMessage({
            pageSize: 50,
            pageIndex: 1,
        }) || {};
        setList(list || []);
    }

    const fetchInvest = async () => {
        const { list } = await api.fetchInvestMessage({
            pageSize: 50,
            pageIndex: 1,
        }) || {};
        setList(list || []);

    }

    const handleToAccount = () => {
        history.push('/usercenter/account')
    }

    const renderItem = (item: any) => {
        switch (item.category) {
            case CATEGORY_MAP.sto:
                return (<div className="message-item project">
                    <div className="img-wrap">
                        <img src={img} />
                    </div>

                    <div className="row right">
                        <div className='info-wrap'>
                        {/* HAMMONIA BALTICA(IMO NUMBER : 9481534) */}
                            <p className="title">{item.content}</p>
                            <p className='desc'>{item.describe}  <span>{item.createTime}</span></p>
                        </div>

                        <Button onClick = {() => {history.push(`/usercenter/invest/detail/${item.foreignId}`)}}>View Detalis</Button>
                    </div>
                </div>)
            case CATEGORY_MAP.password:
                return (<div className="message-item system">
                    <div className="img-wrap">
                        <img src={require('../../../assets/image/key.png')} />
                    </div>

                    <div className="row right">
                        <div className='info-wrap'>
                            <p className="title">{item.describe}</p>
                            <p className='desc'>Offering is Finished  <span>2020-04-24 09:26:29</span></p>
                        </div>
                    </div>
                </div>);
            case CATEGORY_MAP.auth:
                return (
                    <div className="message-item system">
                        <div className="img-wrap">
                            <img src={require('../../../assets/image/auth2.png')} />
                        </div>

                        <div className="row right">
                            <div className='info-wrap'>
                                <p className="title">{item.describe}</p>
                                <p className='desc'><span>{item.createTime}</span></p>
                            </div>

                            <Button onClick={handleToAccount}>View Detalis</Button>
                        </div>
                    </div>
                );
            case CATEGORY_MAP.qa:
                return (
                    <div className="message-item system">
                        <div className="img-wrap">
                            <img style={{height: '58px', width: '58px'}} src={require('../../../assets/image/qa.png')} />
                        </div>

                        <div className="row right">
                            <div className='info-wrap'>
                                <p className="title">Q: {item.describe}</p>
                                <p className="title">A: {item.content}</p>
                <p className='desc'><span>{item.createTime}</span></p>
                            </div>
                        </div>
                    </div>)
            default:
                return null;
        }
    }

    return (
        <section className='common-wrap message-wrap'>
            <p className='page-title'>Set sail on an alternative investment opportunity</p>
            <p className='page-title'> in the lucrative ship owning industry</p>

            <div className="tab-wrap">
                <TabPro onChange={setActive} active={active} data={['For Shipowners', 'For Investors']} />
            </div>

            <div className="list-wrap">
                <div className="header">Messages</div>
                {
                    list.map((item: any) => renderItem(item))
                }
            </div>
        </section>
    )
}

export default Message;