import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useHistory } from 'react-router-dom';
import { observer } from 'mobx-react';
import { Button } from 'antd';
import { useStores } from '../../../../hooks';

const About: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();

    const { rootStore } = useStores();

    const history = useHistory();

    const hanldeQualified = () => {
        history.push('/usercenter/auth');
        
    }

    const handleToAbout = (keyword: string,id:string) => {
        history.replace(`/about?keyword=${keyword}&id= ${id}`);
      
    }



    return (
        <div className='shipowner-menu mdr-navbar-dropdown'>
            <div className="row">
                <span className='label' onClick={() => handleToAbout("How to register","1")}>FAQ</span>
            </div>
            <div className="row">
                <a onClick={() => handleToAbout("About MDR","7")} className='label'>About MDR</a>
            </div>
            <div className="row">
                <a onClick={() => handleToAbout("About shipping market","8")} className='label'>About Marine Market</a>
            </div>
            <div className="row">
                <a onClick={() => handleToAbout("About STO","10")} className='label'>About STO</a>
            </div>
            <div className="row">
                <a onClick={() => handleToAbout("KYC Guidelines","11")} className='label'>KYC Guidelines</a>
            </div>
            {/* <div className="row" style={{ marginLeft: '17px' }}>
                <a onClick={() => handleToAbout("Buyer Qualification","3")} className='label'>Buyer Qualification</a>
            </div> */}
            <div className="row">
                <span className='label' onClick={() => handleToAbout("User Terms, Disclaimer, and Privacy Policy","12")}>User Terms, Disclaimer, and Privacy Policy</span>
            </div>
            {/* <div className="row" style={{ paddingLeft: '17px' }}>
                <a onClick={() => handleToAbout("Legal Statement","4")} className='label'>Legal Statement</a>
            </div>
            <div className="row" style={{ paddingLeft: '17px' }}>
                <a onClick={() => handleToAbout("KYC Policy","5")} className='label'>KYC Policy</a>
            </div> */}

        </div>
    )
})

export default About;