import React, { useState, useEffect,useRef } from 'react';
import './index.scss'
import { Input, message } from 'antd';
import { useStores } from '../../hooks';
import { getUserInfo } from '../../utils';
import api from '../../api'
import store from '../../Pages/UserCenter/StoApply/components/steps/store'


const PinCode = () => {
    const [code, setCode] = useState('');
    const [errCount, setErrCount] = useState(0);
    const [affectLoading,setAffectLoading] = useState(false);
    const [left,setLeft] = useState(60);
    const [resetLoading,setResetLoading] = useState(false);
    const [test,setTest] = useState(1);
    const { rootStore } = useStores();
    const leftRef = useRef(0)
    let timer:any;
    useEffect(() => {
        return () => {
            clearInterval(timer);
            setResetLoading(false);
            setAffectLoading(false);
        }
    },[])
    useEffect(() => {
        setTest(test + 1);
    },[rootStore.hasPin])
    const hanldeCodeCheck = async () => {
        if(!rootStore.hasLogin) {
            rootStore.hasPin = false;
            sessionStorage.setItem('pin', '0');
            rootStore.logout();
            return;
        }
        if(affectLoading) {
            return;
        }
        if (errCount >= 3) {
            message.error('Password errors exceeds 3 times, please retry log in');
            rootStore.hasPin = false;
            sessionStorage.setItem('pin', '0');
            rootStore.logout();
            return
        }
        // 发送请求验证
        setAffectLoading(true);
        if(!rootStore.hasLogin) {
            rootStore.hasPin = false;
            sessionStorage.setItem('pin', '0');
            rootStore.logout();
            return;
        }
        try {
            const stoId = store.stoId;
            const data = await api.checkPinCode({id: stoId,pinCode: code.trim()})
            if(!data) {//失败
                setAffectLoading(false);
                message.error('The PinCode is incorrect.');
                setErrCount((count) => count + 1);
                return;
            }else {//成功
                setAffectLoading(false);
                rootStore.hasPin = false;
                sessionStorage.setItem('pin', '0');
            } 
        }catch(error) {
            setAffectLoading(false);
            message.error('The PinCode is incorrect.');
            setErrCount((count) => count + 1);
            return;
        }
    }
    useEffect(() => {
        leftRef.current = left;
    },[left])
    const resendEmail = () => {
        if(!rootStore.hasLogin) {
            rootStore.hasPin = false;
            sessionStorage.setItem('pin', '0');
            rootStore.logout();
            return;
        }
        if(resetLoading) {
            return;
        }
        setResetLoading(true);
        api.findPinCode(store.stoId);
        leftChange();
    }
    const leftChange = () => {
        clearInterval(timer);
        timer = setInterval(() => {
            setLeft(left => left -1);
            if(leftRef.current === 0) {
                clearInterval(timer);
                setResetLoading(false);
                setResetLoading(false);
                setLeft(60);
            }
        },1000);
    }
    return (
        <div className='pin-code-wrap'>
             <div className = "translater">
            </div>
            <div className = "content">
                <p className = "dataTitle">Data Protection</p>
                <img className='logo' src={require('../../assets/image/lock.png')} alt="" />
                <p className = "titleHeader">
                    You work in progress is currently secured by MDR.
                </p>
                <p className = "titleHeader">
                Use Pin-code to resume service.
                </p>
                <div className='pin-input'>
                    <Input.Password value={code} onChange={(e) => setCode(e.target.value)} placeholder='Enter pin code' className='m-input' style={{ width: '335px' }} />
                    <img onClick={hanldeCodeCheck} className='back' src={require('../../assets/image/pin-back.png')} alt="" />
                </div>
                <div>
                        <p onClick = {resendEmail} className={`resend ${resetLoading ? 'disabled' : ''}`}> <span className ={`sendButton ${resetLoading ? "disabled" : ''}`} >Send pin-code</span>
                        {
                                resetLoading ? (<span className='time'> {left}s</span>) : null
                        }<span>&nbsp;to your mailbox if needed.</span></p>

                    </div>
                </div>
        </div>
    )
}

export default PinCode;