import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { MSelect, Title, Button, SMSInput } from '../../../../../components';



import './index.scss'
import { PHONE_COUNTRY,PHONE_COUNTRYARRAY } from '../../../../../constants';
import { Input, message } from 'antd';
import { observer } from 'mobx-react';
import store from '../../store';
import FormItem from 'antd/lib/form/FormItem';
import api from '../../../../../api';
import { sendSms } from '../../../../../utils';

const Phone: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();

    const [phoneCountry, setphoneCountry] = useState(store.userInfo.phoneCountry);

    const [phoneNumber, setPhoneNum] = useState(store.userInfo.phoneNumber);

    const [readyOnly, setReadOnly] = useState(true);

    const [isCheck, setisCheck] = useState(false);

    const [smsCheck, setSmsCheck] = useState(false);

    useEffect(() => {
        setPhoneNum(store.userInfo.phoneNumber);
        setphoneCountry(store.userInfo.phoneCountry);
    }, [store.userInfo.phoneCountry, store.userInfo.phoneNumber])

    // const phoneMap = Object.keys(PHONE_COUNTRY).map(item => ({
    //     label: `+${item} ${PHONE_COUNTRY[item]}`,
    //     value: `+${item}`
    // }));
    const phoneMap = PHONE_COUNTRYARRAY.map(item => ({
        label: `+${item.label} ${item.value}`,
        value: `+${item.label}`
    }))

    const handlePhoneCountryChange = (value: string) => {
        setphoneCountry(value)
    }

    const handleNumChange = (e: any) => {
        setPhoneNum(e.targe.value);
    }

    const hanldeSMS = async (value: string) => {
        await api.checkSms({
            phoneNumber: `${phoneCountry}${phoneNumber}`,
            code: value,
        });
        message.success('Verification code checked');
        setSmsCheck(true)

    }

    const handleEdit = () => {
        setReadOnly(false);
        if (!readyOnly && phoneNumber) {
            setisCheck(true);
            sendSms(`${phoneCountry}${phoneNumber}`);
            setSmsCheck(false);
        }
    }

    const handleSave = () => {
        if(!smsCheck) return;
        store.updateUserInfo({
            phoneCountry,
            phoneNumber,
        }, () => {
            message.success('Mobile number is updated');
            setReadOnly(true);
            setisCheck(false);
        })
    }



    return (
        <div className="detail-phone">

            {
                !isCheck ? (
                    <div className="display-panel">
                        <div className='number-wrap'>
                            <div className="col">
                                <span className='label'>{f({ id: 'register.phone.country' })}</span>
                                <MSelect
                                    showSearch
                                    value={phoneCountry}
                                    className='code-select'
                                    dataSource={phoneMap}
                                    disabled={readyOnly}
                                    onChange={handlePhoneCountryChange}
                                    optionLabelProp="value"
                                    filterOption={(input, option: any) => {
                                        return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                    }}
                                    renderOpionItem={(label: string) => {
                                        const labelList = label.split(' ');
                                        return <div className="phone-item">
                                            {labelList.map((item: any) => <span className='code'>
                                                {item}
                                            </span>)}
                                        </div>

                                    }}

                                >
                                </MSelect>
                            </div>
                            <div className="col">
                                <span className='label'>{f({ id: 'register.phone.number' })}</span>

                                <Input
                                    type='tel'
                                    value={phoneNumber}
                                    onChange={handleNumChange}
                                    // onBlur={checkExist}
                                    readOnly={readyOnly}
                                    placeholder={f({ id: 'register.phone.placeholder' })}
                                    className='m-input number-input'
                                />


                            </div>


                        </div>

                        <div className="btn-wrap">
                            {/* <Button onClick={handleEdit} size='small'>{readyOnly ? 'Edit' : 'Next'} </Button> */}
                        </div>
                    </div>

                ) : (
                    <div className="sms-panel">

                        <p className='label'>Enter the 6-digit verification code in your phone</p>
                        <SMSInput success={smsCheck} onDone={hanldeSMS} showRetry /> 

                        <div className="btn-wrap">
                            <Button onClick={handleSave} size='small'> Submit </Button>
                        </div>
                    </div>
                )
           }
        </div>
    )
})

export default Phone;