import {observable, action} from "mobx";
import api from '../../api'
import { data } from "../Offer/Index/mock";

export type IExToken = {
    "costPrice": string,
    "currentPrice": string,
    "quantity": string,
    "returnValue": string,
    "tokenId": string,
    "tokenName": string,
    "value": string,
    "vesselId": string,
    "vesselName": string
}
export type ISTOToken = {
    "createTime": string,
    "days": string,
    "id": string,
    "stoId": string,
    "tokenName": string,
    "tokenNum": string,
    "tokenPrice": string,
    "totalValue": string,
    "vesselName": string,
}


export type IWalletCollFlag = {
    ex: boolean, // 二期的钱包
    sto: boolean, // 一i去钱包标注
    exToken: boolean, // 一期token持有
    stoToken: boolean // 二期token持有
}


class PeopleMessageStore {
    @observable walletCollFlag: IWalletCollFlag = {
        ex: true,
        sto: false,
        exToken: false,
        stoToken: false
    }

    @observable exTokenList: Array<IExToken> = []
    @observable exTokenListTotal: number = 0
    @observable exTokenTotal: number = 0
    @observable stoTokenTotal: number = 0

    @observable STOTokenTotal = 0
    @observable STOTokenList: Array<any> = []
    @observable STOTokenSum = 0

    @action.bound
    setCollFlag(data: IWalletCollFlag) {
        this.walletCollFlag = Object.assign({}, {...data})
    }

    @action.bound
    async getOrderHistoryTableList(pageIndex?: number, pageSize?: number) {
        const data = await api.queryExTokenHave({
            pageIndex: pageIndex || 1,
            pageSize: pageSize || 10,
            param: {},
            count: true
        })
        const {total = 0, list = []} = data
        this.exTokenList = list
        this.exTokenListTotal = total
        if(Array.isArray(list) && list.length) {
            this.exTokenTotal = list[0].total || 0
        }
    }
    @action.bound
    async getPeopleHoldTokenSTO(pageIndex?: number, pageSize?: number) {
        const data = await api.queryPeopleHoldTokenSTO({
            pageIndex: pageIndex || 1,
            pageSize: pageSize || 10,
            param: {},
            count: true
        })
        const {total, list} = data
        this.STOTokenList = list || []
        this.STOTokenTotal = total || 0
        if(Array.isArray(list) && list.length) {
            this.STOTokenSum = list[0].totalValue || 0
        }
    }
    @action.bound
    getData() {
        return new Promise(resolve => {
            // setTimeout((
        //    const data = api.getPriceTokenList (data:any){
        //         pageSize: 5,
        //         pageIndex: 1,
        //         param: {},
        //         count: true
        //        }
            // ) => resolve(20), 1000)
        })
    }

}

export default new PeopleMessageStore();
