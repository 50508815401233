/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect,useRef } from 'react';
import { Button, Title } from '../../components';
import './index.scss';
import { Menu, Button as Abt, Modal } from 'antd';
import SubMenu from 'antd/lib/menu/SubMenu';
import api from '../../api';
import { useUrlQuery } from '../../hooks';


// const menus = [
//     {
//         label: 'FAQ',
//         id: "FAQ",
//         children: [
//             {
//                 id:1,
//                 label: 'How to register'
//             },
//             {
//                 id:2,
//                 label: 'How to deposit funds'
//             },
//             {
//                 id:3,
//                 label: 'How to withdraw funds'
//             },
//             {
//                 id:4,
//                 label: 'How to buy ships'
//             },
//             {
//                 id:5,
//                 label: 'How to protect your account'
//             },
//             {
//                 id:6,
//                 label: 'How to contact MDR'
//             },
            
//         ]
//     },
//     {
//         id:7,
//         label: 'About MDR',
//     },
//     {
//         label: 'About Marine Market',
//         id: 'About Marine Market',
//         children: [
//             {
//                 id:8,
//                 label: 'About shipping market'
//             },
//             {
//                 id:9,
//                 label: 'Shipping terms'
//             },
//         ]
//     },
//     {
//         id:10,
//         label: 'About STO',
//     },
//     {
//         id:11,
//         label: 'KYC Guidelines',
//     },
//     {
//         id:12,
//         label: 'User Terms, Disclaimer, and Privacy Policy',
//     },
// ]
const menus = [
    {
        label: 'FAQ',
        id: "FAQ",
        children: [
            {
                id:1,
                label: 'How to register'
            },
            {
                id:2,
                label: 'How to deposit funds'
            },
            {
                id:3,
                label: 'How to withdraw funds'
            },
            {
                id:4,
                label: 'How to protect your account'
            },
            {
                id:5,
                label: 'How to buy ships'
            },
            {
                id:6,
                label: 'How to contact MDR'
            },
        ]
    },
    {
        id:7,
        label: 'About MDR',
    },
    {
        label: 'About Marine Market',
        id: "About Marine Market",
        children: [
            {
                id:8,
                label: 'About shipping market'
            },
            {
                id:9,
                label: 'Shipping terms'
            },
        ]
    },
    {
        id:10,
        label: 'About STO',
    },
    {
        id:11,
        label: 'KYC Guidelines',
    },
    {
        id:12,
        label: 'User Terms, Disclaimer, and Privacy Policy',
    },
]

const About = () => {
    const [index, setindex] = useState(1);
    const [list, setlist] = useState([]) as any;
    const [title, setTitle] = useState('');
    const [contentPage,setContentPage] = useState() as any;
    const keyword = useUrlQuery('keyword');
    const parameId = useUrlQuery('id');
    const [menuItem,setMenus] = useState([]) as any;
    const currentId= useRef('1');
    const arrayIndex = ['1','2','3','4','5','6','7','8','9','10','11','12']
    useEffect(() => {
        arrayIndex.includes(parameId? parameId.trim(): '')? currentId.current = parameId.trim() : currentId.current = '1'
    },[parameId])
    useEffect(() => {
        let keyData = '';
        if(keyword && keyword.trim() === 'How it works') {
            keyData = 'About MDR'
            currentId.current = '1'
        }else if(keyword === 'Eligibility') {
            keyData = 'Legal Statement'
            currentId.current = '4'
        }else {
            keyData = keyword;
        }
        if(keyData === "How to register") {
            // 回到顶端
            goTop();
        }
        setTitle(keyword || '');
        fetchList(parameId? '':'1', keyData);//根据当前值获取到对应的列表信息
        setMenus(menus.slice());
    }, [keyword,parameId])
    const goTop = () => {
        document.body.scrollIntoView();
    }
    const fetchList = async (id: string, title?: string) => {
        const { list } = await api.queryhelp({
            pageIndex: 1,
            pageSize: 50,
            param: {
                relationId: id,
                title,
            }
        }) || {};
        setlist(list || []);
        showDetail(list[0] || {})
    }


    const handleSelect = (item: any) => {
        console.log(item);
        currentId.current = item.key
        fetchList(item.key);
    }

    const showDetail = async (item: any) => {
        if(!item.id) {
            return;
        }
        const info = await api.queryHelpById(item.id);
        setContentPage(info);
    }


    return (
        <section className="common-wrap about">
            <p className='page-title'>MDR integrates experienced ship operations, IT, and financial investment, giving new impetus to the development of the global shipping industry.</p>
            <div className="search-wrap">
                <div className="search">
                    {/* <input value={title} onChange={(e) => setTitle(e.target.value)} placeholder='Please enter keywords to search' className='m-input search-input'></input>
                    <Button onClick={() => {
                        if(!title) return;
                        fetchList('', title)
                    }}
                    size='middle' className="search-btn">Search</Button> */}
                </div>
            </div>

            <div className="list-wrap">
                <div className="header">
                    <Title> About MDR</Title>
                </div>

                <div className="main">
                    <div className="left-menu" id = "left-menus">
                        <Menu
                            // defaultSelectedKeys={[]}
                            defaultOpenKeys={['FAQ','About Marine Market']}
                            style={{ width: 250 }}
                            mode="inline"
                            onClick = {handleSelect}
                            // onSelect={handleSelect}
                        >
                            {
                                menuItem.map((item:any) => [
                                    !item.children ? 
                                        <Menu.Item key={item.id} 
                                        style = {{
                                            backgroundColor: item.id === Number(currentId.current)? "#adb4b8": "",
                                            lineHeight: item.id == 12? "27px": "40px",
                                            height: item.id === 12?"54px": "40px"
                                        }}
                                        >
                                            {item.label}
                                        </Menu.Item> : (
                                        <SubMenu key={item.id || ''} title={item.label}>
                                            {
                                                item.children.map((i:any) => (
                                                    <Menu.Item key={i.id} title = {i.label} style = {{backgroundColor: i.id === Number(currentId.current)? "#adb4b8": ""}}>
                                                        {i.label}
                                                    </Menu.Item>
                                                ))
                                            }
                                        </SubMenu>

                                    )


                                ]
                                )
                            }


                        </Menu>
                    </div>
                    <div className="content">
                        {
                            list.map(((item: any, index: number) => (
                                <div className="row help-item">
                                    {/* <div className="index">{index + 1}</div> */}
                                    <Abt 
                                    // onClick={() => {showDetail(item)}} 
                                    type='link'>{item.title}</Abt>
                                </div>
                            )))
                        }
                        {
                            contentPage && <div className='modal-body'>
                            {
                                contentPage.showPicture ? (
                                    <img src={contentPage.showPicture} alt="" />
                                ) : null
                            }
                            <div className='content'  dangerouslySetInnerHTML={{ __html: contentPage.content }}></div>
                            </div>
                        }
                    </div>
                </div>
            </div>
        </section>
    )
}

export default About;