import React, { useState, useEffect } from 'react';
import { UpOutlined, DownOutlined } from '@ant-design/icons';

import './index.scss'

interface ISort {
    value: number;
    label: string;
    onChange?: Function;
    keyValue: string;
}
const Sort: React.FC<ISort> = (props) => {
    // const [val, setVal] = useState( 0);

    // useEffect(() => {
    //     setVal(props.value)
    // }, [props.value]);
    
    const handleChange = () => {
        props.onChange && props.onChange(props.keyValue);
    }
    return (
        <div onClick={handleChange} className='mdr-sort'>
            {props.label}
            <p className='arrow-wrap'>
                <UpOutlined className={props.value === 1 ? 'active' : ''} />
                <DownOutlined className={props.value === 2 ? 'active' : ''} />
            </p>
        </div>
    )
}

export default Sort;