import React, { useEffect, useState } from 'react';
import './index.scss';
import { Title, Button } from '../../../../../../../components';
import { observer } from 'mobx-react';
import Approval from './components/finalReview'
import OfferDetail from './components/offerDetail'
import CurpStore from '../../store'
import PublishForShipOwner from  './components/publishForShipowner/publishForShipOwner'
const Publish = observer(() => {
    const [currentStep,setCurrentStep] = useState(0);
    return (
        <div className = "publish">
           {
               currentStep ===0?(
                // <Approval></Approval>
                <PublishForShipOwner></PublishForShipOwner>
               ): (
                <OfferDetail></OfferDetail>
               )
           }
            <div>
            <Button
                    arrowLeft
                    type='ghost'
                    size='middle'
                    onClick={() => {
                        // 如果是发布详情就跳转到上个页面
                        if(currentStep === 0) {
                            CurpStore.setCurStep('approval')
                        }else{
                            setCurrentStep(0);
                        }
                        
                    }}
                >Go Back</Button>
                {/* {
                    currentStep === 1? null : (
                        <Button
                            size='middle'
                            style={{ marginLeft: '20px' }}
                            onClick={() => setCurrentStep(1)}
                            arrowRight>
                            {
                                'View Offer Details'

                            }
                        </Button>
                    )
                } */}

            </div>
        </div>

    )
})

export default Publish