import React, { useEffect, useState } from 'react';
import './index.scss';
import { Title, } from '../../../../../../../../../components';
import { Input } from 'antd';
import stepRootStore from '../../../../store';
import api from '../../../../../../../../../api';
import { STO_STATUS } from '../../../../../../../../../constants';
import moment from 'moment';

const PublishForShipOwner: React.FC = () => {
    // 第三列信息 active unActive
    const [currentPublishStatus, setCurrentPublishStatus] = useState<'inactive' | 'Active'>('inactive');
    const [fundraising, setFundraising] = useState(0);
    const [fundrasingStatus, setFundraisingStatus] = useState('');
    const [leftDays, setLeftDays] = useState(0);
    const [fundraingingProgress, setFundraisingProgress] = useState(0);
    const stoId = stepRootStore.stoId;
    const stoStatus = stepRootStore.stoStatus;
    // 请求得到当前sto的状态
    console.log(stoId);
    console.log(stoStatus);
    useEffect(() => {
        getRate();
        setStoStatus();
    }, []);
    // const 获取汇率信息
    const getRate = async() => {
        const optionsData = await api.options() || {}
        const rate = optionsData['rechargeRate'] || 1;
        console.log(rate);
        getFundrasingInfo(rate);
    }
    // 获取当前筹款信息
    const getFundrasingInfo = async (rate:number) => {
        if (stoId === undefined || stoId === null) {
            return;
        }
        if (stoStatus >= 60) {
            const data = await api.fundraisingInfo(stoId);
            console.log(data);
            setLeftDays(data.leftDays || 0);
            // Math.floor((userHoldNumber / tokenNumber) * 10000) / 100
            const userHoldNumber = data.userHoldNumber || 0;
            const tokenNumber = data.tokenNumber === 0 ? 1 : data.tokenNumber;
            const progress = isNaN(Math.floor((userHoldNumber / tokenNumber) * 10000) / 100) ? 0 : Math.floor((userHoldNumber / tokenNumber) * 10000) / 100
            setFundraisingProgress(progress);
            const tokenPrice = data.tokenPrice || 0;
            const lastDate = data.lastDate || '';
            const startDate = data.startDate || '';
            const status = data.status || 0;
            if (lastDate && startDate) {
                if (moment(lastDate).isBefore(startDate)) {
                    setCurrentPublishStatus('inactive');
                } else if(status === 2) {
                    setCurrentPublishStatus('Active');
                }else {
                    setCurrentPublishStatus('inactive');
                }
            }
            if (!isNaN(Number(userHoldNumber)) && !isNaN(Number(tokenPrice)) && !isNaN(rate)) {
                const MdrxValue = accMul(Number(userHoldNumber), Number(tokenPrice));
                const usdValue  = accMul(MdrxValue,rate);
                setFundraising(usdValue);
            }
        }
    }
    const accMul = (arg1: number, arg2: number) => {
        var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length;
        } catch (e) { }
        try {
            m += s2.split(".")[1].length;
        } catch (e) { }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    }
    // 设置当前sto的状态
    const setStoStatus = () => {
        if (stoStatus === undefined || stoStatus === null) {
            return;
        }
        const filterArray = STO_STATUS.filter(item => item.value === stoStatus);
        setFundraisingStatus(filterArray.length === 1 ? filterArray[0].label : '');
    }
    return (
        <div className="detail-fundraising">
            <Title>Publish Offer</Title>
            <div className="fundraisingForm">
                <div className="formItem">
                    <span className="itemLabel">Current STO Application Status</span>
                    <Input value={fundrasingStatus} disabled className="input" style={{ width: "450px" }} />
                </div>
                <div className="formItem">
                    <span className="itemLabel">Current Fundraising Progress</span>
                    <Input value={'USD  ' + fundraising.toLocaleString()} disabled className="input" />
                    <span className="extraValue">
                        <strong style = {{color: "#1989fa"}}>{fundraingingProgress}%</strong>
                        {fundraingingProgress === 100 ? 'Completed' : ''}</span>
                </div>
                <div className="formItem">
                    <span className="itemLabel">Current Fundraising Status</span>
                    <Input value={currentPublishStatus} disabled className="input" style={{ width: "200px", color: currentPublishStatus === 'Active'?'#1989fa': 'block' }} />
                    {currentPublishStatus === 'Active' && <span className="extraValue"><strong style = {{color: "#1989fa"}}>{leftDays}</strong>&nbsp;Days Left</span>}
                </div>
            </div>
        </div>
    )
}

export default PublishForShipOwner