import React, { useEffect, useState } from 'react';
import './index.scss';
import Form, { useForm } from 'antd/lib/form/Form';
import vesselStore from '../../store';
import DynamicFormItem, { IDtem } from '../../../../../../../../../components/DynamicFormItem';
import { Button } from '../../../../../../../../../components';
import { formDataParser } from '../../../../../../../../../utils';
import StepRootStore from '../../../../store';

interface IProps {
    data: []
}

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

const Basic: React.FC<IProps> = (props) => {
    const { data } = props;
    const [form] = useForm();
    const [fileMap, setFileMap] = useState({});


    useEffect(() => {
        form.setFieldsValue(formDataParser(data, false));
    }, [data]);

    if (data.length === 0) return null;

    const handleSave = () => {
        form.validateFields().then(() => {
            vesselStore.updateCurForm({ ...form.getFieldsValue(), ...fileMap });
        })
    }
    const saveVessialBasicMessage = () => {
        vesselStore.updateFormMessage({...form.getFieldsValue(),...fileMap})
    }
    return (
        <div className='sales-doc'>
            <Form
                form={form}
                {...layout}
                labelAlign='right'
                layout='horizontal'
                colon={false}
            >
                {
                    data.map((item: any) =>
                        <DynamicFormItem
                            readonly={!StepRootStore.canEdit}
                            form={form}
                            key={item.id}
                            data={item as IDtem}
                            onFileChange={(code, value) => {
                                //@ts-ignore
                                fileMap[code] = value;
                                setFileMap(fileMap)
                            }}
                        />
                    )

                }
            </Form>

            <div className="button-wrap">
                <Button
                    arrowLeft
                    type='ghost'
                    size='middle'
                    style={{ marginRight: '23px' }}
                    onClick={() => vesselStore.setCurStep(3)}
                >
                    Go Back
            </Button>
            {
                StepRootStore.canEdit &&  
                    <Button
                    type='ghost'
                    size='middle'
                    style={{ marginRight: '23px' }}
                    onClick={saveVessialBasicMessage}
                    >
                        Save
                    </Button>
                }
                <Button
                    arrowRight
                    size='middle'
                    onClick={handleSave}
                >
                    Next
                </Button>
            </div>

        </div>
    );
}

export default Basic;