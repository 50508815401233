import React from 'react';
import { useStores } from '../../hooks';
import './index.scss';
import { useIntl } from 'react-intl';
import { Route, Link } from 'react-router-dom';

const Footer: React.FC = () => {
    const { formatMessage: f } = useIntl();
    const routerList = JSON.parse(f({ id: 'footer.router' })) as [{ name: string, router: string }];

    return (
        <footer className="footer-wrap">
            <div className="content">
                <div className="left">
                    <div className='links'>
                        {
                            routerList.map(item => (
                                item.router? <Link key={item.router} to={item.router}>{item.name}</Link>: <a>{item.name}</a>
                            ))
                        }
                    </div>
                    <div className='cookie'>
                        <p>{f({ id: 'footer.cookie' })}</p>
                        <p>{f({ id: 'footer.auth' })}</p>
                    </div>
                </div>
                <div className="right">
                    {/* <img className='call' alt='call' src={require('../../assets/image/call.png')} />
                    <div className="spliter" />
                    <img className='mail' alt='call' src={require('../../assets/image/mail.png')} /> */}

                    <img className='qrcode' alt='qrcode' src={require('../../assets/image/qrcode.png')} />
                </div>
            </div>
        </footer>
    )
}

export default Footer;