import React, { useEffect, useState } from 'react';
import { MSelect, Button, Title } from '../../../../../../../components';
import './index.scss';
import { observer } from 'mobx-react';

import { BeatLoader } from 'react-spinners';

import vesselStore from './store';
import stepOneStore from '../../store'

import Basic from './components/basic';
import MoreBasic from './components/moreBasic';
import TechInfo from './components/techInfo';
import SaleDoc from './components/salesdoc';
import Employment from './components/employment';
import FinancialPlan from './components/financialPlan';
import Company from './components/company';

import { VESSEL_TYPE } from '../../../../../../../constants';
import { TreeSelect, Modal } from 'antd';
import { RightOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';


const steps: { [index: string]: string } = {
    2: 'More%Vessel Info',
    3: 'Further%Technical Info',
    4: 'Sales documents',
    5: 'Employment Status',
    6: 'Financial Plan',
    7: 'Technical%Management Company',
}

const VesselInfo: React.FC = observer(() => {
    const history = useHistory();
    const [BasicSelectDataDisabled,setBasic] = useState<Array<{code: string, disabled: boolean}>>([]);

    useEffect(() => {
        vesselStore.setCurStep(1)
        vesselStore.init();
        vesselStore.fetchVesselTypeList();
    }, []);

    useEffect(() => {
        vesselStore.findStoInfoAllById(vesselStore.curStep);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [vesselStore.curStep]);
    const getDisabledItem = (code:string,disabled:boolean) => {
        // 判断如果存在的code 就重新赋值disabled，如果不存在就push
        let flag = false;
        BasicSelectDataDisabled.forEach(item => {
            if(item.code === code){
                item.disabled = disabled;
                flag = true;
            }
        })
        !flag && BasicSelectDataDisabled.push({code,disabled});
        setBasic(BasicSelectDataDisabled.slice());
    }
    const renderItem = () => {
        switch (vesselStore.curStep) {
            case 1:
                return <Basic data={vesselStore.formItemsList[1]} getDisabledItem = {getDisabledItem} disabledItem = {BasicSelectDataDisabled} />
            case 2:
                return <MoreBasic data={vesselStore.formItemsList[2]} />
            case 3:
                return <TechInfo data={vesselStore.formItemsList[3]} />
            case 4:
                return <SaleDoc data={vesselStore.formItemsList[4]} />
            case 5:
                return <Employment data={vesselStore.formItemsList[5]} />
            case 6:
                return <FinancialPlan data={vesselStore.formItemsList[6]} />
            case 7:
                return <Company data={vesselStore.formItemsList[7]} />
            default:
                return null;
        }
    }

    return (
        <div className='vessel-wrap'>

           { false && <div className="vessel-type-select-wrap">
                <MSelect
                    // style={{ marginLeft: '-182px' }}
                    value={vesselStore.projectType}
                    dataSource={VESSEL_TYPE}
                    onChange={vesselStore.handleprojectType}
                />

                <TreeSelect
                    // showSearch
                    // style={{ width: '100%' }}
                    className='mdr-select'
                    value={vesselStore.vesselType}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    placeholder="Please select"
                    allowClear
                    treeDefaultExpandAll
                    treeData={vesselStore.vesselTypeList}
                    onChange={vesselStore.handleVesselType}
                />
{/* 
                {
                    vesselStore.curStep === 1 && (
                        <Button
                            size='middle'
                            type='ghost'
                            onClick={() => vesselStore.handleConfirm()}
                        >Confirm</Button>
                    )
                } */}
            </div>}
            <div className="vessel-content-wrap">
                {
                    // 如果是第一项不展示
                    vesselStore.curStep !== 1 && (
                        <div className='sto-vessel-step-wrap'>
                            {
                                Object.keys(steps).map((item: string) => (
                                    <div className='row'>
                                        <p 
                                        // // 查看的时候可以跳转
                                        // onClick= {()=> {
                                        //     if(stepOneStore.canEdit) {
                                        //         return;
                                        //     }
                                        //     vesselStore.setCurStep(+item);
                                        // }}
                                        className={['item', vesselStore.curStep === +item ? 'active' : ''].join(' ')}>
                                            {
                                                steps[item].split('%').map(item => <p>{item}</p>)
                                            }
                                        </p>
                                        {
                                            +item !== 7 && <RightOutlined />
                                        }
                                    </div>
                                ))
                            }
                        </div>
                    )
                }
                {renderItem()}
            </div>
            
            {/* 展示checked信息 */}
            <Modal
                centered
                closable={false}
                footer={null}
                className='vessel-check-modal'
                visible={vesselStore.showConfirm}
            >
                <Title style={{ marginBottom: '24px' }}>Missing Document Warning</Title>

                {
                    Object.keys(steps).map(item => (
                        <div className='check-item'>
                            <span className='step-name'>{steps[item].replace('%', ' ')}</span>

                            <span className='check-status'>checked</span>
                        </div>
                    ))
                }

                <div className="modal-footer">
                    <Button
                        arrowLeft
                        type='ghost'
                        onClick={() => vesselStore.showConfirm = false}
                    >Go Back</Button>
                    <Button
                        style={{ marginLeft: '20px' }}
                        onClick={vesselStore.handleSubmit}
                        arrowRight>Next</Button>
                </div>


            </Modal>

            {/* 成功的信息 */}
            <Modal
                centered
                closable={false}
                footer={null}
                className='vessel-submit-modal'
                visible={vesselStore.showSubmitConfirm}
            >
                <Title style={{ marginBottom: '24px' }}>STO process submitted</Title>

                <BeatLoader loading={vesselStore.loading}  margin="12px" color="#164A78"></BeatLoader>

                <span className='submit-desc'>

                Your submission is under review, MDR will provide feedback within 60 business days.
                            
                </span>

                <div className="modal-footer">
                    
                    <Button
                        style={{ marginLeft: '20px' }}
                        onClick={() => {
                            vesselStore.showSubmitConfirm =false;
                            history.push('/usercenter/sto')
                        }}
                        arrowRight>My STO Management</Button>
                </div>


            </Modal>

        </div>
    );
});

export default VesselInfo;