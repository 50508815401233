import React from 'react';

import { Select } from 'antd';
import { SelectProps } from 'antd/lib/select';

import './index.scss';

const { Option } = Select;

interface Item {
    label: string;
    value: string;
}

const data = [{
    label: '测试1',
    value: '1'
},
{
    label: '测试2',
    value: '2'
}]

interface IMSelect extends SelectProps<any> {
    dataSource: Array<Item>;
    renderOpionItem?: Function;
    tailRender?: Function;
    selectCode?:string;
    onMSelectChange?: Function;
    selectChecks?: {
        required?: boolean;
        disabled?: boolean;
        checksValue: [
            {value: string, checkValue:string,disabled: boolean,controlCode: string}
        ]
    },
}


const MSelect: React.FC<IMSelect> = (selectProps) => {
    const { dataSource, className, tailRender, ...props } = selectProps;
    const onTChange = (value:string) => {
        // 判断如果传递有checksValue则说明有控制需要传递onChange事件
        if(props.selectChecks  && props.selectChecks.checksValue) {
            // 根据选中的value进行判断如果和checksValue一致向上传递 需要控制的code和code的defaultValue和是否禁用
            const {checksValue} = props.selectChecks;
            checksValue.forEach(item => {
                if(item.value === value) {
                    props.onMSelectChange && props.onMSelectChange({"code": item.controlCode,"checkValue": item.checkValue,"disabled": item.disabled});
                }
            }) 
        }
      
    }
    const renderDropDown = (menu: React.ReactElement) => {
        return <div>
            {menu}
            {tailRender?.()}
        </div>
    }
    console.log(props.disabled) 
    return <div className='mdr-select'>
    <Select
            className={["select-customize-input", className || ''].join(' ')} {...props} dropdownRender={renderDropDown} disabled = {props.selectChecks?.disabled? props.selectChecks.disabled: props.disabled} onSelect = {onTChange}>
            {
                dataSource.map((item,index) => (
                    <Option key={index} value={item.value}>{props.renderOpionItem ? props.renderOpionItem(item.label) : item.label}</Option>
                ))
            }
        </Select>
    </div>
    }

export default MSelect;
