import React, { useEffect } from 'react';
import { observer } from 'mobx-react';

import store from './store';
import { Button, Title, Tab, TabPro } from '../../../components';
import { useIntl } from 'react-intl';
import Email from './component/Email';
import SMS from './component/SMS';
import Phone from './component/Phone';
import Personal from './component/Personal';
import Company from './component/Company';
import Identity from './component/Identity';
import Done from './component/Done';

import Password from './component/Password';
import { Form,message } from 'antd';
import { useHistory } from 'react-router-dom';
import './index.scss'
import Safe from './component/Safe';
import rootStore from '../../../store'
const stepMap: { [index: string]: number } = {
    email: 0,
    phone: 1,
    password: 2,
    personal: 3,
    safe: 4,
}

const list = ['email', 'phone', 'password', 'personal', 'safe'];

const titleMap: { [index: string]: string } = {
    email: 'Email Verificaton',
    phone: 'Phone verification',
    password: 'Change Password',
    personal: 'Personal Details',
    safe: 'User Qualification', 
}

const renderFormItem = (curStep: string) => {

    switch (curStep) {
        case 'email':
            return <Email />
        case 'phone':
            return  <Phone />
        case 'password':
            return <Password></Password>
        case 'personal':
            return <Personal></Personal>;
        // case 'company':
        //     return <Company></Company>;
        case 'accout':
            return <Password></Password>;
        // case 'identity':
        //     return <Identity></Identity>
        case 'safe': 
            return <Safe />

        default:
            return null;
    }
}

const SignUp: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();

    const [form] = Form.useForm();
    const history = useHistory();
    const registerStep = JSON.parse(f({ id: 'register.steps' })) as [{ key: string, value: string }];
    store.registerStep = registerStep.map(item => item.key);

    useEffect(() => {
        store.fetchUserInfo();
        store.setCurStep('email')
    }, [])

    const handNext = () => {
        form.validateFields().then(store.handleNextStep)
    }

    if (store.isRegisterDone) {
        return <Done></Done>
    }

    const imgName = store.curStep === 'personal' ? 'register-long.jpg' : 'register.jpg';

    return (
        <div className="common-wrap account-setting">
            <p className='page-title'>Set sail on an alternative investment opportunity</p>
            <p className='page-title'>in the lucrative ship owning industry</p>

            <div className="tab-wrap">
                <TabPro active={0} data={['Account Settings']} />
            </div>

            <div className='account-content'>
                <div className={`step-wrap ${store.curStep}`}>
                    <img alt='register' src={require(`../../../assets/image/${imgName}`)}></img>
                </div>
                <div className="content-right form-wrap">
                    <div className="nav-wrap">
                        <Title style={{ textAlign: 'center' }}>{titleMap[store.curStep]}</Title>
                        <Tab onChange={(value: any) => {
                            store.curStep = list[value];
                        }} active={stepMap[store.curStep]} simple data={['Email', 'Phone', 'Change password', 'Personal details', 'User qualification']}></Tab>
                    </div>
                    {renderFormItem(store.curStep)}

                </div>

            </div>
        </div>
    )
});

export default SignUp;