import React, { useEffect, useState } from 'react';
import store from '../store';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Autoplay, Navigation, Pagination } from 'swiper/core';
import { Button } from 'antd';
import 'swiper/swiper.less';
import 'swiper/components/navigation/navigation.less';
import 'swiper/components/pagination/pagination.less';
import 'swiper/components/scrollbar/scrollbar.less';
import './swiper.scss'

interface ISwiper {
    activeIndex: any
}

const SwiperBox: React.FC<ISwiper> = (props) => {
    let checkedIndex = props.activeIndex
    let swiperDom:any
    let numberList = [0,1,2,3,4,5,6,7,8,9,10,11,12]




    return (
        <div>
            <Swiper
                // slidesPerView={1}
                initialSlide={numberList[checkedIndex]}
                centeredSlides={true}
                spaceBetween={10}
                navigation
                onSwiper={(swiper) => {
                    swiperDom = swiper;
                }}
                onSlideChange={(e) =>{
                    checkedIndex = e.activeIndex
                    setTimeout(() => {
                        if (swiperDom) {
                            swiperDom.slideTo(e.activeIndex,500,false);
                        } 
                    }, 300);
                }}
                pagination={{ clickable: true }}
                className='swiperBox'
                >
                {
                    // @ts-ignore
                    store.vesselPhoto.map((item: string, index: any) => (
                        <SwiperSlide>
                            <img className='yulanImg' alt='' onClick={
                                ()=>{
                                    // setSwiperIndex(index)
                                    checkedIndex = index
                                    setTimeout(() => {
                                        if (swiperDom) {
                                            swiperDom.slideTo(index,500,false);
                                        } 
                                    }, 300);
                                }
                            } key={item} src={item}></img>

                        </SwiperSlide>
                    ))
                }
            </Swiper>
            <div className='scorllBox'>
                {
                    // @ts-ignore
                    store.vesselPhoto.map((item: string, index: any) => (
                            <img className='yulanSmallImg' onClick={
                                ()=>{
                                    // setSwiperIndex(index)
                                    checkedIndex = index
                                    setTimeout(() => {
                                        if (swiperDom) {
                                            swiperDom.slideTo(index,500,false);
                                        } 
                                    }, 300);
                                    
                                }
                            } alt='' key={index} src={item}></img>
                    ))
                }
                
            </div>
        </div>

    )
};

export default SwiperBox;
