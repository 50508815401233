import React, { useState, useEffect } from 'react';
import { TabPro, Button, Title } from '../../../components';
import { MSelect } from '../../../components/index';
import './index.scss'
import {
    Input, Upload, message, Modal, Form, Radio, InputNumber, DatePicker
} from 'antd';
import { uploadAction } from '../../../constants';
import { BeatLoader } from 'react-spinners';
import { useForm } from 'antd/lib/form/Form';
import { getUserInfo, getImageUrl, updateUserInfo, formDataParser } from '../../../utils';
import api from '../../../api';
import { useHistory } from 'react-router-dom';
import moment from "moment";
import rootStore from '../../../store/index'

const img = 'http://img.zcool.cn/community/01062858d66567a801219c77babce4.jpg@1280w_1l_2o_100sh.jpg';

const Certification = () => {
    const [curStep, setCurStep] = useState(0);

    const [showSelect, setShowSelect] = useState(false);

    const [showModal, setShowModal] = useState(false);

    const [investTarget, setInvestTarget] = useState('A persion');

    const history = useHistory();

    const [disabled, setDisabled] = useState(false);

    const [loading, setLoading] = useState(false);
    const [affectLoading, setAffectLoading] = useState(false);
    const [uploadData, setUploadData] = useState({
        legalCardUrl: '',
        idCardUrl: '',
        businessLicenseUrl: '',
        license: '',
        legalPersonId: ""
    }) as any;
    const [countryData, setCountryData] = useState<Array<{ label: string, value: string, numCode: number }>>([])
    const [indentiryData, setIdentiryData] = useState(0)
    const [form] = useForm();
    const [userCardUrl,setUserCardUrl] = useState('');
    const statusArray = [0, 1, 2];//审批状态： 0 拒绝，1，通过，2认证中
    // status 0拒绝， 1通过，2认证中  只有拒绝才可以修改
    useEffect(() => {
        getNationality();//获取国籍信息
        initChoseState();
        initForm();
        return () => {
            setAffectLoading(false);
        }
    }, []);
    useEffect(() => {

    },[getUserInfo()])

    // code: "AFG"
    // countryName: "Afghanistan"
    // investment: "t"
    // nationality: "Afghan"
    // numCode: 4
    //获取国籍信息
    const getNationality = async () => {
        const data = await api.Nationality();
        data.forEach((item: any) => {
            item.lable = item.nationality;
            item.value = item.nationality;
        })
        const copyData = data.map((item: any) => {
            return { label: item.nationality, value: item.nationality, numCode: item.numCode }
        })
        setCountryData(copyData);

    }
    useEffect(() => {
        if (loading) {
            setTimeout(() => {
                setLoading(false);
            }, 1500);
        }
    }, [loading])
    useEffect(() => {
        setCurStep(investTarget === 'A persion' ? 0 : 1);
        // 如果认证过,根据认证信息，进入不同的页面
        setPage();
        initForm();
    }, [investTarget]);
    const setPage = async () => {
        const userInfo = getUserInfo() || {};
        // 审批中审批通过都需要跳转页面
        if (userInfo.status === 1 || userInfo.status === 2) {
            setCurStep(userInfo.investorsType === 'A persion' ? 0 : 1);
        }
    }
    const initChoseState = async () => {
        const userInfo = getUserInfo() || {};
        // 通过和认证中都不可编辑，被拒绝以后也可以编辑
        const isDisabled = (userInfo.status === 1 || userInfo.status === 2);
        setDisabled(isDisabled);
        // 是否展示弹窗， 身份是普通用户或者船东，并且null 或者 为0
        setShowSelect((rootStore.getCrrentAuthType.includes(3) || rootStore.getCrrentAuthType.includes(2)) && (userInfo.status === 0 || !userInfo.status));
    }
    const initForm = async () => {
        const userInfo = getUserInfo() || {};
        api.findInvestorAuthById({id:userInfo.id}).then(res=> {
            if(res && res.passprotExpiration) {
                res.passprotExpiration = moment(res.passprotExpiration);
                // setUserCardUrl(res.idCardUrl);
                form.setFieldsValue({
                    ...res
                });
            }
            
        });
        // const userInfo = getUserInfo() || {};
        // if(userInfo.passprotExpiration) {
        //     userInfo.passprotExpiration = moment(userInfo.passprotExpiration);
        // }
        // const company = userInfo.company || {};
        // const investorAuthDO = userInfo.investorAuthDO || {};
        // // 如果状态值是2，或者1，需要根据审核中的状态，设置页面，如果不是根据选择的设置页面
        // if(userInfo.status === 2 || userInfo.status === 1) {
        //     if(userInfo.investorsType === 'A persion') {
        //         // if (userInfo.status === 1) {
        //              form.setFieldsValue({
        //                 ...userInfo,
        //                 ...investorAuthDO
        //             });
        //         // }
               
        //     }else {
        //         // form.setFieldsValue({
        //         //     ...userInfo,
        //         //     ...company
        //         // });
        //     }
        // }else {
        //     if(investTarget === 'A persion') {
        //         // form.setFieldsValue({
        //         //     ...userInfo,
        //         //     ...investorAuthDO
        //         // });
        //     }else {
        //         // form.setFieldsValue({
        //         //     ...userInfo,
        //         //     ...company
        //         // });
        //     }
        // }
    }

    const selectChange = (value: any) => {
        countryData.forEach(item => {
            if (item.value === value) {
                setIdentiryData(item.numCode);
            }
        })
    }

    const handleUploadChange = (code: any, resp: any) => {
        const { file } = resp;
        const response = file.response;

        if (response) {
            if (response.code === '200') {
                const { objectName } = response.data;
                // setVal(objectName);
                uploadData[code] = objectName;
                const data = {
                    ...uploadData,
                    [code]: objectName
                }
                setUploadData(data);
            } else {
                message.error(resp.msg || 'File upload unsuccessful. Please try again')
            }
        }

    }
    // 上传校验
     // 上传文件之前，规范上传类型
     const beforeUploadChange = (file:any) => {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png';
            if (!isJpgOrPng) {
              message.error('Please select image (.jpg/.png/.jpeg) format to upload!');
              return false;
            }
            return true;
        }

    const getImgData = (key: string) => {
        const userInfo = getUserInfo() || {};
        const {company }= getUserInfo() || {};
        const {investorAuthDO}= getUserInfo() || {};
        return uploadData[key] || userInfo[key] || (company && company[key]) || (investorAuthDO &&investorAuthDO[key])  || '';
    }
    



    const hanldeNext = () => {
        const userInfo = getUserInfo();
        // // 如果已经认证成功就跳转到首页
        // if (rootStore.getCrrentAuthType.includes(1)) {
        //     history.push('/#/');
        //     return;
        // } 
        // 如果已经认证成功就跳转到首页
        if (userInfo.type.toString().search('1') > -1) {
            history.go(-1)
            return;
        }
        // 没有认证成功，等待审核
        if (userInfo.status === 2) {
            Modal.warning({
                content: "Investor certification information has been submitted, please wait patiently",
                onOk: () => {
                    history.push('/');
                    return;
                }
            })
            return;
        }

        if (curStep === 0) {
            form.validateFields().then(() => {
                if (getImgData('idCardUrl')) {//如果填写信息
                    // 发送请求进行认证
                    form.validateFields().then(async () => {
                        if (affectLoading) {
                            return;
                        }
                        const formData = form.getFieldsValue();
                        formData.investorsNationality = indentiryData;//给定国籍信息
                        const userInfo = getUserInfo();
                        const data = {
                            ...userInfo,
                            ...formData,
                            idCardUrl: getImgData('idCardUrl'),
                            investorsType: investTarget,
                            inType: 1
                        }
                        setAffectLoading(true);
                        // 如果信息为空就给后台为空
                        Object.keys(data).forEach(item => {
                           data[item] = data[item] === undefined? '': data[item];
                        })
                        // 如果传递的有city属性就删除防止错误入库
                        if(data.hasOwnProperty('city')) {
                            delete data.city;
                        }
                        data.passprotExpiration = moment(data.passprotExpiration).format('yyyy-MM-DD HH:mm:ss')
                        try {
                            await api.investorCert({
                                ...data,
                            });
                            Modal.success({
                                content: "Buyer qualification information is submitted successfully",
                                onOk: () => {
                                    setAffectLoading(false);
                                    updateUserInfo();
                                    setShowModal(true);
                                    setLoading(true);
                                    history.push('/about/buyer');
                                    // window.history.back(); 
                                   
                                        history.go(0);
                                }
                            })
                        }catch(error) {
                            setAffectLoading(false);
                            return;
                        }
                    })
                } else {
                    Modal.warning({
                        content: "Please upload a Copy of ID card or passport",
                        onOk: () => {
                            return;
                        }
                    })
                }
            })
        } else {
            form.validateFields().then(async () => {
                if (!getImgData('license')) {
                    Modal.warning({
                        content: "Please upload",
                        onOk: () => {
                            return;
                        }
                    })
                    return;
                }
                if (!getImgData('legalPersonId')) {
                    Modal.warning({
                        content: "please upload",
                        onOk: () => {
                            return;
                        }
                    })
                    return;
                }
                if (affectLoading) {
                    return;
                }
                const formData = form.getFieldsValue();
                const userInfo = getUserInfo();

                const data = {
                    ...userInfo,
                    ...formData,
                    // businessLicenseUrl: getImgData('businessLicenseUrl'),
                    // legalCardUrl: getImgData('legalCardUrl'),
                    license: getImgData('license'),
                    legalPersonId: getImgData('legalPersonId'),
                    investorsType: investTarget,
                    inType: 1
                }
                // 如果传递的有city属性就删除防止错误入库
                if(data.hasOwnProperty('city')) {
                    delete data.city;
                }
                setAffectLoading(true);
                try {
                    await api.investorCert({
                        ...data,
                    });
                    Modal.success({
                        content: "Buyer qualification information is submitted successfully",
                        onOk: () => {
                            setAffectLoading(false);
                            updateUserInfo();
                            setShowModal(true);
                            setLoading(true);
                            history.push('/');
                        }
                    })
                }catch(error) {
                    setAffectLoading(false);
                    return;
                }
            }, (error) => {
                console.log(error);
            })
        }
    }

    const download = async (name: string) => {
        const obj = getImgData(name);
        const url = await getImageUrl(obj);
        window.open(url, 'new_mdr', 'width=600,height=800')
    }
    // 设置只能选今天之后的时间
    const disabledDate = (current:moment.Moment) => {
        // Can not select days before today and today
        return current && current < moment().endOf('day');
    }

    // 是否可编辑
    const isEdit = () => {
        const userInfo = getUserInfo();
        // 如果已经认证成功就跳转到首页
        if (userInfo.type.toString().search('1') > -1) {
            return true
        }
        return false
    }

    return (
        <section className='common-wrap certification-wrap'>
            <p className='page-title'>Set sail on an alternative investment opportunity in the lucrative ship owning industry</p>
            {/* <p className='page-title'> process for accredited investors.</p> */}

            {/* <div className="tab-wrap">
                <TabPro active={0} data={['For Shipowners', 'For Investors']} />
            </div> */}
            <div className="main-panel">
                <Title>Investor Information</Title>

                <p className="desc">
                    MDR believes that money laundering and other illegal activities endanger the development of digital asset transactions, corrupt social ethos, violate the rights of users, undermine the foundation of digital asset trading platforms, and increase operational risks. In order to prevent money laundering, terrorist financing activities, and other lapses in KYC protocol, MDR is fully complicit with all relevant anti-money laundering and anti-terrorist financing laws.
                </p>
                <Form
                    form={form}
                    colon={false}
                    labelAlign={'left'}
                    // hideRequiredMark
                    className="form-wrap"
                >
                    {
                        curStep === 0 ? (

                            <div className="row basic">
                                <Form.Item  rules={[{ required: true, message: 'Given Name is required' }]} name='authenticationGivenName' label='Given Name' className="input-item">
                                    {/* <span className="label">Given Name</span> */}
                                    <Input readOnly={disabled} placeholder='Enter your given name' className='m-input'></Input>

                                </Form.Item>

                                <Form.Item rules={[{ required: true, message: 'Surname is required' }]} name='authenticationSurname' label='Surname' className="input-item">
                                    <Input readOnly={disabled} placeholder='Enter your Surname' className='m-input'></Input>
                                </Form.Item>

                                <Form.Item name='idCardUrl' label='Copy of ID card or passport (.jpg/.jpeg/.png)' className="input-item upload" rules = {[
                                ]}>
                                    <div className="row">
                                        <Input value={getImgData('idCardUrl')} readOnly style={{ width: '250px' }} className='m-input'></Input>
                                        <Upload
                                            data={{ type: 'other' }}
                                            action={uploadAction}
                                            showUploadList={false}
                                            disabled={disabled}
                                            beforeUpload = {beforeUploadChange}
                                            accept={'image/jpeg,image/jpg,image/png'}
                                            onChange={(resp) => handleUploadChange('idCardUrl', resp)}
                                        >
                                            <Button size='small' type='ghost'>Upload</Button>
                                        </Upload>
                                    </div>

                                    {
                                        getImgData('idCardUrl') ? <span onClick={() => download('idCardUrl')} className='download'>click to view</span> : null
                                    }


                                </Form.Item>
                                <Form.Item name="investorsNationality" className="input-item"
                                initialValue = ""
                                label='Nationality' rules={[{ required: true }]}>
                                    <MSelect
                                        allowClear
                                        style={{ width: "200px" }}
                                        onChange={selectChange}
                                        disabled={disabled}
                                        className='Nationality'
                                        dataSource={countryData}
                                        showSearch={true}
                                    // optionFilterProp={'children'}
                                    />
                                </Form.Item>
                                <Form.Item
                                style={{ width: '205px'}}
                                    name="passprotExpiration" className="input-item"
                                           initialValue = ""
                                           label='Passport Expiration Date' rules={[{ required: true }]}>
                                    <DatePicker
                                        size = "large"
                                        format= "YYYY-MM-DD"
                                        disabled = {disabled}
                                        disabledDate={disabledDate}
                                    />
                                </Form.Item>
                                <div className="cardName">
                                    <p>Personal bank account information</p>
                                    {/* 银行卡信息填写 */}
                                    <Form.Item
                                        name="accountName"
                                        className="input-item"
                                        label="Personal Account Name  [optional]"
                                    >
                                        <Input readOnly={disabled} placeholder='Enter your Personal Account Name' className='m-input width-input'></Input>
                                    </Form.Item>
                                    <Form.Item
                                        name="accountNumber"
                                        className="input-item"
                                        label="Personal Account Number  [optional]"
                                        rules={[
                                            { pattern: /^\d+$/, message: "You can only enter Numbers 0-9" }
                                        ]}
                                    >
                                        <Input readOnly={disabled} placeholder='Enter your Bank card number' className='m-input width-input'></Input>
                                    </Form.Item>
                                    <Form.Item
                                        name="personalAddress"
                                        className="input-item"
                                        label="Personal Address [optional]"
                                    >
                                        <Input readOnly={disabled} placeholder='Enter your Personal Address' className='m-input width-input'></Input>
                                    </Form.Item>
                                    <Form.Item
                                        name="bankName"
                                        className="input-item"
                                        label="Bank Name [optional]"
                                    >
                                        <Input readOnly={disabled} placeholder='Enter your Bank Name' className='m-input width-input'></Input>
                                    </Form.Item>
                                    <Form.Item
                                        name="bankAddress"
                                        className="input-item"
                                        label="Bank Address [optional]"
                                    >
                                        <Input readOnly={disabled} placeholder='Enter your Bank Address' className='m-input width-input'></Input>
                                    </Form.Item>
                                    <Form.Item
                                        name="swift"
                                        className="input-item"
                                        label="SWIFT [optional]"
                                    >
                                        <Input readOnly={disabled} placeholder='Enter your SWIFT' className='m-input width-input'></Input>
                                    </Form.Item>
                                    <Form.Item
                                        name="iban"
                                        className="input-item"
                                        label="IBAN (if applicable) [optional]"
                                    >
                                        <Input readOnly={disabled} placeholder='-' className='m-input width-input'></Input>
                                    </Form.Item>
                                </div>
                            </div>

                        ) : (
                                <div className="row company-info">
                                    {/* <Form.Item name='companyName' rules={[{ required: true, message: 'Company name is required' }]} label='Company name' className="input-item">
                                        <Input readOnly={disabled} className='m-input'></Input>
                                    </Form.Item>

                                    <Form.Item name='legalName' rules={[{ required: true, message: 'Name of legal representative of the company is required' }]} className="input-item" label='Name of legal representative of the company'>
                                        <Input readOnly={disabled} className='m-input'></Input>
                                    </Form.Item>
                                    <Form.Item name='companyAddress' rules={[{ required: true, message: 'Registered Office Address is required' }]} className="input-item" label='Registered Office Address'>
                                        <Input readOnly={disabled} className='m-input'></Input>
                                    </Form.Item>

                                    <Form.Item name='legalAddress' rules={[{ required: true, message: 'Address of the Company’s legal representative is required' }]} className="input-item" label='Address of the Company’s legal representative'>
                                        <Input readOnly={disabled} className='m-input'></Input>
                                    </Form.Item>


                                    <Form.Item name='businessLicenseUrl' label='Business License' className="input-item upload">
                                        <div className="row">
                                            <Input value={getImgData('businessLicenseUrl')} readOnly style={{ width: '250px' }}  className='m-input'></Input>
                                            <Upload
                                                data={{ type: 'other' }}
                                                action={uploadAction}
                                                showUploadList={false}
                                                disabled={disabled}
                                                onChange={(resp) => handleUploadChange('businessLicenseUrl', resp)}
                                            >
                                                <Button size='small' type='ghost'>Upload</Button>
                                            </Upload>
                                        </div>

                                        {
                                            getImgData('businessLicenseUrl') ? <span onClick={() => download('businessLicenseUrl')} className='download'>click to view</span> : null
                                        }
                                    </Form.Item>

                                    <Form.Item name='legalCardUrl' label='A copy of Company’s legal representative’s ID or Passport' className="input-item upload">
                                        <div className="row">
                                            <Input value={getImgData('legalCardUrl')} readOnly style={{ width: '250px' }} className='m-input'></Input>

                                            <Upload
                                                data={{ type: 'other' }}
                                                action={uploadAction}
                                                disabled={disabled}
                                                showUploadList={false}
                                                onChange={(resp) => handleUploadChange('legalCardUrl', resp)}
                                            >
                                                <Button size='small' type='ghost'>Upload</Button>
                                            </Upload>
                                        </div>

                                        {getImgData('legalCardUrl') ? <span onClick={() => download('legalCardUrl')} className='download'>click to view</span> : null}
                                    </Form.Item> */}
                                    <div>
                                    <Form.Item name='companyName'   label='Company Name' rules={[
                                        { required: true, message: 'Please Enter CompanyName' }
                                    ]}
                                        className="input-item"
                                    >
                                        <Input className='m-input' readOnly={disabled}></Input>
                                    </Form.Item>

                                    <Form.Item name='license'
                                        label='Business Registration Certificate / Certificate of Incorporation&nbsp;(.jpg/.jpeg/.png)'
                                        className="input-item upload"
                                    >
                                        <div className="row">
                                            <Input className='m-input' value={getImgData('license')} readOnly />
                                            <Upload
                                                data={{ type: 'other' }}
                                                action={uploadAction}
                                                showUploadList={false}
                                                disabled={disabled}
                                                beforeUpload ={beforeUploadChange}
                                                accept={'image/jpeg,image/jpg,image/png'}
                                                onChange={(resp) => handleUploadChange('license', resp)}
                                            >
                                                <Button size='small' type='ghost'>Upload</Button>
                                            </Upload>
                                        </div>
                                        {getImgData('license') ? <span onClick={() => download('license')} className='download'>click to view</span> : null}
                                    </Form.Item>
                                    <Form.Item name='placeRegistration'
                                        className="input-item"
                                        label='Place of Registration'
                                        rules={[
                                            { required: true, message: 'Please Enter the Place of Registration ' }
                                        ]}>
                                        <Input className='m-input' disabled={disabled}></Input>
                                    </Form.Item>

                                    <Form.Item name='registeredCapital'
                                        className="input-item"
                                        label='Registered Capital(USD)'
                                        rules={[
                                            { required: true, message: 'Please Enter the Registered Capital' }
                                        ]}
                                    >
                                        <InputNumber
                                            className="ant-input-number"
                                            style={{ width: "345px", height: "45px", lineHeight: "45px" }}
                                            disabled={disabled}
                                            precision={2}
                                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                                            parser={(value: any) => value.replace(/\$\s?|(,*)/g, '')}//增加千分位设置
                                        ></InputNumber>

                                    </Form.Item>

                                    <Form.Item name='legalPerson'
                                        className="input-item"
                                        label='Managing Director or Director'
                                        rules={[
                                            { required: true, message: 'Please Enter Value' }
                                        ]}
                                    >
                                        <Input className='m-input' disabled={disabled}></Input>
                                    </Form.Item>


                                    <Form.Item name='legalPersonId' label={`Copy of Managing Director or Director's Passport (.jpg/.jpeg/.png)`}
                                        className="input-item upload">
                                        <div className="row">
                                            <Input className='m-input' value={getImgData('legalPersonId')} readOnly />
                                            <Upload
                                                data={{ type: 'other' }}
                                                action={uploadAction}
                                                showUploadList={false}
                                                disabled={disabled}
                                                beforeUpload= {beforeUploadChange}
                                                accept={'image/jpeg,image/jpg,image/png'}
                                                onChange={(resp) => handleUploadChange('legalPersonId', resp)}
                                            >
                                                <Button size='small' type='ghost'>Upload</Button>
                                            </Upload>
                                        </div>
                                        {getImgData('legalPersonId') ? <span onClick={() => download('legalPersonId')} className='download'>click to view</span> : null}
                                    </Form.Item>
                                    <Form.Item name='companyEmail' label='Company Email'
                                        className="input-item"
                                        rules={[
                                            { required: true, message: 'Please Enter Company Email' }
                                        ]}>
                                        <Input className='m-input' disabled={disabled}></Input>
                                    </Form.Item>

                                    <Form.Item name='bankName' label= {`Company's Bank Name`}
                                        className="input-item"
                                        rules={[
                                            { required: true, message: "Please Enter the Company's Bank Name" }
                                        ]}
                                    >
                                        <Input className='m-input' disabled={disabled}></Input>
                                    </Form.Item>

                                    <Form.Item name='bankAddress' label= {`Company's Bank Address`}
                                    className = "input-item"
                                        rules={[
                                            { required: true, message: "Please Enter the Company's Bank Address" }
                                        ]}>
                                        <Input className='m-input' disabled={disabled}></Input>
                                    </Form.Item>

                                    <Form.Item name='swiftCode' label='SWIFT Code'
                                    className = "input-item"
                                        rules={[
                                            { required: true, message: 'Please Enter Value' }
                                        ]}
                                    >
                                        <Input className='m-input' disabled={disabled}></Input>
                                    </Form.Item>

                                    <Form.Item name='accountName' label='ACCOUNT Name'
                                    className = "input-item"
                                        rules={[
                                            { required: true, message: 'Please Enter Value' }
                                        ]}>

                                        <Input className='m-input' disabled={disabled}></Input>
                                    </Form.Item>
                                    </div>
                                </div>
                            )
                    }
                    <div className="btn-wrap">
                        <Button
                            arrowRight={curStep === 0 || !disabled}
                            onClick={hanldeNext}
                        >
                            {/* {disabled ? 'Back' : 'Submit'} */}
                            {isEdit() ? 'Back' : 'Submit'} 
                        </Button>
                    </div>
                </Form>
            </div>
            <Modal
                visible={showSelect}
                footer={null}
                centered

                closable={false}
            >
                <div className='invest-target'>
                    <p className='desc'>Investors must undergo verification to use the MDR platform</p>
                    <Title>I want to invest as</Title>
                    <Radio.Group value={investTarget} onChange={(e) => setInvestTarget(e.target.value)} size="large">
                        <Radio.Button value="A persion">A person</Radio.Button>
                        <Radio.Button value="A business entity">An Entity</Radio.Button>
                        {/* <Radio.Button value="A trust corporation">A trust corporation</Radio.Button>
                        <Radio.Button value="A financial institute">A financial institute</Radio.Button> */}
                    </Radio.Group>

                    <div className="btn-wrap">
                        <Button onClick={() => setShowSelect(false)} arrowRight size='small' >Next</Button>
                    </div>
                </div>


            </Modal>
            <Modal
                visible={showModal}
                footer={null}
                closable={false}
            >

                <div className="cert-submit-modal">
                    <Title style={{ marginBottom: '24px' }}>Investor Verification in progress</Title>

                    <BeatLoader loading={loading} margin="12px" color="#164A78"></BeatLoader>

                    <span className='desc'>
                        Once your application is completed,you can start the way owning ships.
                </span>

                    <div className="btn-wrap">
                        <Button onClick={() => {
                            setShowModal(false);
                            history.push('/');
                        }}>Submitted</Button>
                    </div>
                </div>


            </Modal>
        </section>
    )
}

export default Certification;

