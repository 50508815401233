import React, { useState, useEffect } from 'react';
import { useIntl, createIntlCache } from 'react-intl';
import { observer } from 'mobx-react';
import { Input, message } from 'antd';

import store from '../../store';
import './index.scss'
import { Button, Title, SMSInput } from '../../../../../components';
import { EMAIL_REG } from '../../../../../constants';
import { sendEmail } from '../../../../../utils';
import api from '../../../../../api';

const Email: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();

    const [readyOnly, setReadOnly] = useState(true);

    const [isCheck, setisCheck] = useState(false);

    const [emailCheck, setEmailCheck] = useState(false);
    const [affectLoading,setAffectLoading] = useState(false);

    const [email, setEmail] = useState(store.userInfo.email);

    useEffect(() => {
        setEmail(store.userInfo.email);
    }, [store.userInfo.email])

    const handleCheck = async () => {
        if(affectLoading) {
            return;
        }
        setAffectLoading(true);
        try {
            api.sendEmail(email)//发送邮件验证码
            setAffectLoading(false);
            message.success('Verification code sent')
            setisCheck(true);
            setEmailCheck(false);
        }catch(error) {
            setAffectLoading(false);
        }
       
    }

    const handleCodeCheck = async (value: string) => {
       await api.checkEmail({
            email,
            code: value,
        });
        setEmailCheck(true);
    }

    const handleEdit = () => {//不能编辑
        if (readyOnly) {
            setReadOnly(false);
            return;
        }

        if (EMAIL_REG.test(email)) {
            if (email === store.userInfo.email) return;
            handleCheck();
        } else {
            message.error('email is not a valid email')
        }
    }

    const handleSave = () => {
        if(!emailCheck) return;
        store.updateUserInfo({
            email,
        }, () => {
            setReadOnly(true);
            setisCheck(false);
            message.success('Mailbox info is updated');
        })
    }

    console.log(store.userInfo.email)
    const isShowBtu = () => {
        if (store.userInfo && store.userInfo.status  && store.userInfo.status.toString().indexOf('1') > -1) {
            return ''
        }
        return (
            <Button onClick={handleCheck} style={{ marginLeft: '15px' }} type='ghost' size='small' arrowRight>Get Email Verification Now</Button>

        )
    }
    return (
        <div className='account-email'>
            {
                !isCheck ? (
                    <div className='wrap'>
                        <div className="input-item">
                            <span className='label'>Email address</span>
                            <Input value ={email} onChange={e => setEmail(e.target.value)} readOnly={readyOnly} className='m-input'></Input>
                        </div>

                        <div className="btn-wrap row">


                            {/* <Button size='small' onClick={handleEdit}>{
                                readyOnly ? 'Edit' : 'Next'
                            }</Button> */}
                            {/* {

                                isShowBtu()
                            } */}
                            
                        </div>
                    </div>
                ) : (
                        <div className='check-wrap'>
                            <Title>Get Email Verification Now</Title>

                            <p className='tips'>
                                We’ve sent you a link to your Email,&nbsp;go to your Email {email}
                                 &nbsp; and get your dynamic password.
                            </p>
                            <p className='tips email-tips'>Enter the 6-digit verification code in your Email.</p>
                            <SMSInput success={emailCheck} isEmail = {'emailCheck'}  phoneNumber = {email} onDone={handleCodeCheck} showRetry></SMSInput>

                            <div className="btn-wrap">
                                <Button onClick={handleSave} size='small'>Submit</Button>
                            </div>
                        </div>
                    )
            }
        </div>
    )
})

export default Email;