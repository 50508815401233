import { observable, action, computed } from "mobx";

type steps = 'companyInfo' | 'initProject' | 'vesselInfo' | 'review' | 'fundraisingInfo' | 'agreement' | 'approval' | 'publish' | string
class Store {
    @observable curStep: steps = 'initProject';

    @observable stoId: string = ''; 

    @observable vessleType = '';
    @observable vessleTypeName = '';

    @observable stoStatus  = 0;

    @observable pinCode = '';

    @action.bound
    setCurStep(step: steps) {
        this.curStep = step;
    }

    @action.bound
    setStoId(id: any) {
        this.stoId = id;
    }

    @action.bound
    setPinCode(number:string) {
        this.pinCode = number;
    }

    @computed
    get canEdit() {
        const status = +this.stoStatus;
        return status === 0 || status === 25 || +status === 10
    }
    @action.bound
    setVessleType(value:string) {
        this.vessleType = value;
    }
    @action.bound
    setVessleTypeName(value:string) {
        this.vessleTypeName = value
    }
}

export default new Store();