import { observable, computed } from "mobx";
import { getUserInfo, formatMoney } from "../../../utils";
import api from "../../../api";

export class Store {
    @observable totaMoney  = '';

    @observable allIncomeMoney = 0;


    fetchList = async (inType:any) => {
        const userInfo = getUserInfo();
        const { list, total }  = await api.moneyPageList({
           pageIndex: 1,
           pageSize: 1,
           param: {
            type: userInfo.type === 4 ? 1: userInfo.type,
            inType:inType
           }
       }) || {};

       this.totaMoney = list[0] ? list[0].allIncomeMoney : 0;
       this.allIncomeMoney = list[0] ? list[0].allIncomeMoney : 0;
    }
}

export default new Store();