import React, { useState, useEffect } from 'react';
import { Form, Input, Tag,Timeline } from 'antd';
import { Title, Button } from '../../../../../../../components';
import './index.scss';
import store from '../../store';
import api from '../../../../../../../api';
import { useForm } from 'antd/lib/form/util';
import { getImageUrl } from '../../../../../../../utils';
import Carousel, { ModalGateway, Modal } from 'react-images';
import { SearchOutlined } from '@ant-design/icons';
import { useHistory } from 'react-router-dom';

const titleMap: any = {
    2: 'STO Application is under approval',
    0: 'STO Applicaiton is approval Rejected',
    1: 'Sto application approved',
}
const status: { [index: string]: any } = {
    0: 'Reviewed Rejected',
    1: 'Review Passed'
}
const Approval: React.FC = () => {
    const history = useHistory();
    const [approveResult, setApproveResult] = useState({} as any);

    useEffect(() => {
        fetchApproveResult();//shuuy
    }, []);

    const fetchApproveResult = async () => {
        const data = await api.approvingMdrTodo(store.stoId);
        setApproveResult(data);
    }
    const getColor = (status:number) => {
        if(status === 2) {
            return 'gray';
        }else if(status === 1){
            return 'green';
        }else if(status === 0) {
            return 'red';
        }else {
            return 'gray';
        }
    }

    return (
        <div className='sto-review-wrap'>
            <div style={{ marginBottom: '30px' }} className="row">
                <Title>{titleMap[approveResult.auditStatus ? approveResult.auditStatus : 2]}</Title>
            </div>
            <div className="reivew-content">
                <Timeline>
                    <Timeline.Item color={getColor(approveResult.auditStatus ? approveResult.auditStatus : 2)}>
                        <div className='review-item'>
                            {/* 成功 */}
                            {approveResult.auditStatus === 1 && (<p><span className={[approveResult.auditStatus === 1 ? 'success' : ''].join(' ')}>{status[approveResult.auditStatus]}</span> at {approveResult.approvalTime} </p>)}
                            {/* 拒绝 */}
                            {
                                approveResult.auditStatus === 0 && (
                                    <p>Rejected Reason: {approveResult.rejectionReason || ''}</p>
                                )
                            }
                            {/* 审核状态 */}
                            {approveResult.auditStatus !== 1 && (
                                <p>{titleMap[approveResult.auditStatus === 0 || approveResult.auditStatus === 1 ? approveResult.auditStatus : 2]}</p>
                            )}
                            {/* 提交时间 */}
                            <p>
                                Submitted for review at {approveResult.applicationTime}
                            </p>
                        </div>
                    </Timeline.Item>
                </Timeline>
            </div>

            <div className="btn-wrap">
                <Button
                    arrowLeft
                    type='ghost'
                    size='middle'
                    onClick={() => store.setCurStep('agreement')}
                >Go Back</Button>
                {
                    store.stoStatus < 50 ? null : (
                        <Button
                            size='middle'
                            style={{ marginLeft: '20px' }}
                            onClick={() => store.setCurStep('publish')}
                            arrowRight>
                            {
                               'STO Approval Info'

                            }
                        </Button>
                    )
                }

            </div>
        </div>
    )
}

export default Approval;