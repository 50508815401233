import React from 'react';
import { List } from 'antd';
import './index.scss';
import { Title, Button, Folder } from '../../../../../../components';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { formatMoney } from '../../../../../../utils';


interface IProps {
    dataSource: any
}


const Finish: React.FC<IProps> = ({ dataSource }) => {
    
    const handleToStoDetail = () => {
        const url = `${window.location.origin}/#/usercenter/sto/apply?stoId=${100}`
        window.open(url)
    }

    const { purchase } = dataSource;


    return (
        <div className="detail-investment">
            <Folder fold={true} title={`${dayjs(purchase).format('YYYY-MM-DD')} Finished`} singleNode ={true}>                
            </Folder>
        </div>

    )
}

export default Finish;