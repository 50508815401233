export default {
  "register.title": "Set sail on an alternative investment opportunity in the lucrative ship owning industry",
  // "register.subTitle": "Alternative marine assets management tool",
  "register.subTitle": " ",
  'register.start': 'Get registration started now',

  "register.tab": JSON.stringify(["Register", "Log In"]),

  "register.steps": JSON.stringify([
    {
      key: "email",
      value: "Email",
    },
    {
      key: "phone",
      value: "Phone",
    },
    {
      key: "personal",
      value: "Personal Details",
    },

    {
      key: "company",
      value: "Company Details",
    },
    {
      key: "accout",
      value: "Accout Protection",
    },
    {
      key: "identity",
      value: "Identity Verification",
    },
  ]),


  "register.next": "Next",

  "register.back": "Back",

  "register.done": "Done",

  "register.email.title": "What is your Email address?",
  "register.email.placeholder": "Enter your email",
  "register.email.err": "wrong email ",

  "register.phone.title": "What is your phone number?",
  "register.phone.title01": "Phone verification",
  
  
  "register.phone.country": "Country",
  "register.phone.number": "Number",
  "register.phone.placeholder": "Enter your phone number",
  "register.sms.title": "A 6-digit code has been sent to your phone",

  "register.sms.send": "Click to resend",

  'register.person': 'Personal Details',

  "register.person.givename": 'Given Name',

  "register.person.givename.placeholder": 'Enter your given name',



  "register.person.surname": 'Surname',

  "register.person.surname.placeholder": 'Enter your surname',

  "register.person.country": 'Location',

  "register.company" : 'Company Details',

  "register.company.name": 'Registration Country [Optional]',

  "register.company.name.placeholder": 'Registration Country',

  "register.company.address": 'Registration Address [Optional]',

  "register.company.address.placeholder": 'Registration Address',

  'register.company.checkbox':"This Account is registered on behalf of  the company.",
  'register.account': 'Account Protection',
  'register.password.one.placeholder': 'Set up a Password',
  // 'register.password.one.note': 'Password should combination of upper and lower case letters with numbers, no less than 8 digits.',
  'register.password.one.note': 'Password should be a combination of upper and lower case letters with numbers, no less than 8 digits.',

  'register.password.confirm': 'Confirm Password',
  'register.password.confirm.placeholder': 'Same as password',
  'register.identity': 'Identity Verification Information',
  // 'register.identity.personal.label': "Passport number [Optional]",
  'register.identity.personal.label': "Passport Number",
  'register.identity.personal.placeholder': "Passport number",

  'register.identity.company.label': "Company Name [Optional]",

  'register.identity.company.placeholder': "Company Name",

  'register.done.tips': 'Congratulations!',



  'register.done.subtips': 'your registration has been completed! Please start your voyage with MDR now.',

  'register.done.autologin': 'In {time} seconds, site will automatically jump to the login page.You can also click the button below to proceed manually.',


  'register.login.email.label': 'Usermail',

  'register.login.email.placeholder': 'Enter your email',

  'register.login.steps': JSON.stringify([
    {
      key: 'email',
      value: 'Email',
    },
    {
      key: 'phone',
      value: 'Phone',
    },
  ]),

  'register.login.sms': 'Verification of log in',

  'register.login.pwd.label': 'Password',

  'register.login.pwd.placeholder': 'Password',

  'register.login': 'Log In',






};
