import React, { useState,useEffect} from 'react';

import { useIntl } from 'react-intl';

import { MDown, SMSInput } from '../../../../components';

import { PHONE_COUNTRY } from '../../../../constants';


import './index.scss'
import { observer } from 'mobx-react';

import { CheckOutlined } from '@ant-design/icons';

import store from '../../store';
import { checkSms } from '../../../../utils';
import api from '../../../../api';



const Email: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();

    const [value, setValue] = useState(false);
    const [success, setSuccess] = useState(false);
    useEffect(() => {
        return () => {
            store.emailVerify = false;
        }
    },[])
    const onDone =  async (val: string) => {
        setSuccess(true)
        //邮箱验证码登录
        if(store.isTempPwd) {
           try{
            const data =  await api.checkDynamicPassword({Email: store.email,code: val});
            store.emailVerify = true;
            store.emailLoginUserInfo = data;
            setValue(true);
           }catch(error) {
            setValue(false);
            store.emailVerify = false;
           }
           return;
        }
        //重置密码
        try {
            await api.checkEmail({ email: store.email, code: val });
            setValue(true);
            store.emailVerify = true;
            if (store.isTempPwd) {
                store.dynamicCode = val;
            }
        } catch (error) {
            setValue(false)
        }
    }

    return (
        <div className='reset-email'>
            <p className='info'>
                We’ve sent you a link to your Email,go to your Email {store.email} and get your Email verified.
            </p>
            <div className='sms-wrap'>
                <SMSInput 
                success={value} 
                isEmail = {store.isTempPwd? 'tempPwd': 'email'} //动态登录验证码和重置密码验证码
                 phoneNumber = {store.phoneNumber} 
                 countryCode = {store.country}
                 showRetry onDone={onDone} 
                 length={6} ></SMSInput>

                {/* {
                    (value && success) ?
                         <CheckOutlined className='icon' style={{ color: '#164a78' }} /> :  null
                } */}

            </div>
        </div>
    )
});

export default Email;