import React, { useEffect } from 'react';
import { useIntl } from 'react-intl';

import { MDown, MSelect } from '../../../../components';

import { PHONE_COUNTRY,PHONE_COUNTRYARRAY } from '../../../../constants';


import './index.scss'
import { observer } from 'mobx-react';
import { Input } from 'antd';
import store from '../../store';
import FormItem from 'antd/lib/form/FormItem';

const Phone: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();

    // const phoneMap = Object.keys(PHONE_COUNTRY).map(item => ({
    //     label: `+${item} ${PHONE_COUNTRY[item]}`,
    //     value: `+${item}`
    // }));
    const phoneMap = PHONE_COUNTRYARRAY.map(item => ({
        label: `+${item.label} ${item.value}`,
        value: `+${item.label}`
    }))
    useEffect(() => {
        store.passport = '';//初始状态置空
    },[]);
    const handlePhoneCountryChange = (value: string) => {
        store.country = value;
    }
    return (
        <div className='reset-phone'>
            {/* <p className="title">{f({ id: 'register.phone.title' })}</p> */}
            <div className='number-wrap'>
                <div className="col">
                    {/* <span className='label'>{f({ id: 'register.phone.country' })}</span> */}
                    {/* <MSelect
                        showSearch
                        dataSource={phoneMap}
                        renderItem={(item: any) => {
                            const code = parseInt(item.value);
                            return (
                                <div className='phone-item'>
                                    <span className='code'>{item.value}</span>
                                    <span className='country'>{PHONE_COUNTRY[code]}</span>
                                </div>
                            )
                        }
                        }
                    /> */}

                    <FormItem rules={[{required: true}]} name='country' label={f({ id: 'register.phone.country' })}>
                        <MSelect
                            showSearch
                            value={store.country}
                            className='code-select'
                            dataSource={phoneMap}
                            onChange={handlePhoneCountryChange}
                            optionLabelProp="value"
                            filterOption={(input, option: any) => {
                                return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                            }}
                            renderOpionItem={(label: string) => {
                                const labelList = label.split(' ');
                                return <div className="phone-item">
                                    {labelList.map((item: any) => <span className='code'>
                                        {item}
                                    </span>)}
                                </div>

                            }}

                        >
                        </MSelect>
                    </FormItem>


                </div>
                <div className="col">
                    <FormItem 
                    rules={[
                        {required: true,message:"Please enter your cell phone number"},
                        {pattern: /^(0|86|17951)?(13[0-9]|15[012356789]|166|17[3678]|18[0-9]|14[57])[0-9]{8}$/, 
                        message: "Please enter the correct phone number format"}
                    ]} 
                    name='phoneNumber' label='Number'>
                    <Input
                        value={store.phoneNumber}
                        placeholder={f({ id: 'register.phone.placeholder' })}
                        className='m-input number-input'
                        onChange={(e) => store.phoneNumber = e.target.value}
                    />
                    </FormItem>
                    {/* <span className='label'>{f({ id: 'register.phone.number' })}</span> */}
                    
                </div>
            </div>

            <div className="input-item">
                <FormItem 
                // rules={[{required: true}]}//必填去掉，如果注册时候填入信息的话，就提示必须填写
                label='Passport Number' name='passport'>
                    <Input
                        className='m-input'
                        value={store.passport}
                        onChange={(e) => store.passport = e.target.value}
                    />
                </FormItem>
                {/* <p className="label">Passport Number</p> */}

            </div>

            <div className='phone-tips-wrap row'>
                <span className='tips-circle' ></span>
                <p className='tips'>A dynamic password valid for one hour will be sent.</p>
            </div>
        </div >
    )
});

export default Phone;