import React from 'react';

import { observable, computed, values } from "mobx";
import api from "../../../api";
import { getImageUrl, formatMoney, getUserInfo, download,transLocal } from "../../../utils";
import rootStore from "../../../store";
import store from "../../../store";
import { message,Modal,Button } from "antd";
import { IDtem } from "../../../components/DynamicFormItem";
import dayjs from "dayjs";
import Item from 'antd/lib/list/Item';
const {confirm} = Modal;

class Store {
    @observable allStoInfo = [[], [], [], [], [], [], []] as any;

    @observable stoInfoList = [] as any;

    @observable stoid = '';

    @observable mainInfo = {} as any;

    @observable approveinfo = {} as any;

    @observable fundraisingInfo = [] as any;

    @observable companyInfo = [] as any;

    @observable showInvest = false;

    @observable techImages = [] as any;

    @observable csvData = [] as any;

    @observable tokenNumber = 0 as number;

    @observable stoStatus = 2 as number;


    // @observable urlMap = {
    //     503: 'https://i.loli.net/2020/05/31/4vRyOZWcL7V5Sfr.jpg',
    //     505: 'https://i.loli.net/2020/05/31/lONWQsI8fBx1Sg4.png',
    // } as any;

    getMainInfo = async () => {
        const data = await api.getStoInfoMainById(this.stoid)
        this.mainInfo = data;
    }

    @computed
    get tokenHighlights() {
        const { tokenHighlights } = this.approveinfo;
        return tokenHighlights ? tokenHighlights.split('*#*') : [];
    }

    @computed
    get transFee() {
        const { serviceRate = 0 } = this.approveinfo;
        return `${serviceRate * 100}%`;
    }
    @computed
    get transFeeNumber() {
        const {serviceRate = 0} = this.approveinfo;
        return serviceRate;
    }

    @computed
    get vesselPhoto() {
        const { vesselPhoto = [] } = this.approveinfo;
        return vesselPhoto;
    }

    @computed
    get offername() {
        const { vesselName, projectNumber } = this.mainInfo || {};
        return vesselName || projectNumber;
    }

    @computed
    get techDownload() {
        return this.allStoInfo[2]?.filter((item: any) => item.type = 'file').filter((item: any) => item.propertyValue && item.propertyValue.includes('/'))
    }


    async getTechImages() {
        const imgs = this.techDownload?.filter((item: any) => {
            const code = item.code as string;
            return code.includes('504') || code.includes('505')
        });
        if (!imgs) return;
        const imgList = await Promise.all(imgs.map((item: any) => getImageUrl(item.propertyValue)));
        this.techImages = imgList.filter(item => item);
    }

    async getCSVData() {
        const financial = this.allStoInfo[5] || [];
        // const csv = financial[0] ? financial[0].propertyValue : '';
        // if (!csv) return;
        // const data = await api.getUrlCsvDatas(csv) || [];
        this.csvData = financial;
    }

    @computed
    get salesDownload() {
        //return this.stoInfoList?.filter((item: any, index: number) => item.businessNum === 11 && item.code.includes('12')) || [];
         return this.allStoInfo[3].filter((item: any) => item.type = 'file').filter((item: any) => item.propertyValue && item.propertyValue.includes('/'))
    }

    @computed
    get stoList() {//第一个标签页
        // 改为获取到所有businessNum的值
        const first = this.stoInfoList?.filter((item: any, index: number) => item.businessNum === 1) || [];
        const second = this.stoInfoList?.filter((item: any, index: number) => item.businessNum === 2) || [];
        const third = this.stoInfoList?.filter((item: any, index: number) => item.businessNum === 3) || [];
        // const first = this.allStoInfo[0]?.filter((item: any, index: number) => item.businessNum === 1) || [];
        // const second = this.allStoInfo[0]?.filter((item: any, index: number) => item.businessNum === 2) || [];
        // const third = this.allStoInfo[0].filter((item: any, index: number) => item.businessNum === 3) || [];
        return [first, second, third];

    }
    @computed
    get stoMoreVessel() {//More Vessel Info
        const first = this.stoInfoList?.filter((item:any,index:number) => item.businessNum === 4) || [];
        return [first]
    }

    @computed
    get stoTechnicalManagementCompany() {
        const first = this.stoInfoList.filter((item:any,index:number) => item.businessNum === 7) || [];
        return [first];
    }
    @computed
    get getStoTechnicalManagementCompany() {
        const first = this.stoInfoList.filter((item:any,index:number) => item.businessNum === 5) || [];
        return [first];
    }
    @computed
    get getStoCommercialManagementCompany() {
        const first = this.stoInfoList.filter((item:any,index:number) => item.businessNum === 8) || [];
        return [first];
    }
    @computed
    get getFundraisingInfoData() {
        return this.stoInfoList?.filter((item:any,index:number) => item.businessNum === 10) || [];
    }

    getAllStoInfo = async () => {
        const data = await Promise.all([1, 2, 3, 4, 5, 6,7,8].map(async (item) => await api.findStoInfoAllById(this.stoid, item))) || [];
        this.allStoInfo = data;
        this.stoInfoList = data.reduce((prev, next) => {
            prev.push(...(next || []));
            return prev;
        }, [])

        this.getTechImages();
        this.getCSVData();
    }

    getReviewInfo = async () => {
        const data = await api.findStoApprovalById(this.stoid) || {}
        data.vesselPhoto = data.vesselPhoto ? await Promise.all(data.vesselPhoto.split(',').filter((item: string) => item).map(async (img: string) => await getImageUrl(img))) : []
        this.approveinfo = data;
    }

    getCompanyInfo = async () => {
        const data = await api.findStoCompnayById(this.stoid) || {};

        const labels = ['Shipowner',  'Assets Management Company', 'SPV Company','SPV Ownership Structure'];
        const values = [data.shipownerName,  data.assetsName, data.spvName,data.spvFile]

        const res = labels.map((item, index) => ({
            label: item,
            value: values[index]
        }));

        this.companyInfo = res;
    }

    getFundraisingInfo = async () => {
        const data = await api.findStoFundraisingById(this.stoid) || {};
        const result = [
            {
                label: 'Currency of Raising Funds',
                value: formatMoney(data.currency || 0),
            },
            {
                label: 'New Building Contract Price of Vessel',
                value: formatMoney(data.contractPrice  || 0)
            },
            {
                label: 'Necessary Start-up Costs',
                value: formatMoney(data.startCosts  || 0)
            },
            {
                label: 'Maximum Amount to be Raised',
                value: formatMoney(data.maximum  || 0)
            },
            {
                label: 'Minimum Amount to be Raised',
                value: formatMoney(data.minimum  || 0)
            },
            {
                label: 'Start Time of Raising Funds',
                value: dayjs(data.fundsStart).format('YYYY-MM-DD')
            },
            {
                label: 'Duration of Raising Funds',
                value: data.fundsDuration
            },
            {
                label: 'Share Proportion Retained by Controlling Shareholder',
                value: data.controlling
            },
            {
                label: 'STO Prospectus',
                value: data.prospectus ? <a onClick={() => this.handlePreview(data.prospectus)}>preview</a> : null,
            }
        ]
        this.fundraisingInfo = result;
        this.stoStatus = data.status;
    }

    init() {
        this.getMainInfo()//获取相关信息
        this.getAllStoInfo();
        this.getReviewInfo();
        this.getFundraisingInfo();
        this.getCompanyInfo();
    }


    getValueByKey(key: string) {
        const item = this.stoInfoList.find((item: any) => item?.code.endsWith(key));
        return item.propertyValue;
    }

    handleInvest = async  () => {
        const userInfo = getUserInfo();
        if (!userInfo) {
            //@ts-ignore
            message.info('Please log in first to use this');
            window.scrollTo({ top: 0, behavior: 'smooth' });
            //@ts-ignore
            document.querySelectorAll('.login.ant-dropdown-trigger')[0]?.click();
            return;
        } else if ((userInfo.type !== 1 || userInfo.type !== 4) && userInfo.status !== 1) {//当前还不是投资者
            // 如果是认证过的，就提示认证过了，不跳转页面
            if(userInfo.status === 2) {
                Modal.warning({
                    content: `Investor certification information has been submitted, please wait patiently`,
                    onOk: () => {
                        return;
                    }
                });
                return
            }
            Modal.warning({
                content: `Please complete buyers’ qualification first, go now`,
                onOk: () => {
                    window.location.href = '/#/usercenter/auth';
                }
            });
            return;
        }
        try {
            const stiToenData =  await api.checkSTOToken(this.stoid);
            if(!stiToenData) {
                  Modal.warning({
                    content: `The selected token has sold out!`,
                    onOk: () => {
                        window.location.href = '/#/offer';
                    }
                });
             return;
           }
            const data = await api.checkSTOEnd(this.stoid);
            if(data) {
              Modal.warning({
                content: `The selected project is expired!`,
                onOk: () => {
                    window.location.href = '/#/offer';
                }
            });
            return;
            }

        }catch (error) {
           console.error('error');
        }
        // 获取剩余token
        this.getTokenNumber();
        this.showInvest = true;
    }
    getTokenNumber = async () => {
        let data = await api.checkResidueToken(this.stoid);
        if(data < 0) {
            data = 0;
        }
       this.tokenNumber = data;
    }
    handlePreview = async (objName: string) => {
        if(!objName) {
            return;
        }
        const url = await getImageUrl(objName);
        window.open(url, '', "width=600,height=800");
    }
    downloadSubject = async (objName:string) => {
        if (!objName) return;
        const url = await getImageUrl(objName);
        window.open(url, 'new_mdr', 'width=600,height=800')//改为小窗口弹出提供下载
    }


    handleDownload = async (item:string) => {
      const data = await api.checkInvestorPaySTO(this.stoid);
      if(!data) {
          return message.info('Please purchase before downloading')
      }

        const objName = item;
        if (!objName) return;
        const url = await getImageUrl(objName);
        window.open(url, 'new_mdr', 'width=600,height=800')//改为小窗口弹出提供下载
        // download(url, objName);
    }

    parseData = (item: IDtem) => {
        const { propertyValue } = item;
        if(!propertyValue) return '';
        switch (item.type) {
            case 'date':
                return dayjs(propertyValue).format('YYYY-MM-DD');
            case 'month':
                return dayjs(propertyValue).format('MM/YYYY');
            case 'year':
                return dayjs(propertyValue).format('YYYY');
            case 'number':
                return Number(propertyValue).toLocaleString();
            case 'file':
                return 'Download'
            default:
                return propertyValue || ''
        }
    }

    getOtherLabelValue = (item:IDtem)  => {
        if(item.hasOwnProperty('checks')) {
            try {
                const {checks} = item;
                const checksData = JSON.parse(checks);
                if(checksData.hasOwnProperty('alias')) {
                    return checksData['alias'].trim();
                }
                return item.name.trim();
            }catch(error) {
                return item.name;
            }
        }else{
            return item.name
        }


    }
}

export default new Store();

// other/other_1592491756085.csv
