import React, { useState, useEffect, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Dropdown, Badge } from 'antd';
import { DownOutlined, AlertTwoTone } from '@ant-design/icons';
import { useStores } from '../../hooks';
import { LANGUAGE_MAP } from '../../constants';
import Shipowner from './compoent/shipowner';
import About from './compoent/about';
import Invest from './compoent/invest';
import Usercenter from './compoent/usercenter';
import './index.scss';
import { observer } from 'mobx-react';
import LoginModal from './compoent/Login';
import { getUserInfo } from '../../utils';



const NavBar: React.FC = observer(() => {
    const { rootStore } = useStores();
    const ref = useRef();
    const [showlogin, setshowlogin] = useState(false);
    const [showSignIn, setShowSignIn] = useState(true);
    const [stateHasLogin,setStateHasLogin] = useState() as any;
    const { formatMessage: f } = useIntl();
    const history = useHistory();
    // useEffect(() => {
    //     const isRegisterDone = localStorage.getItem('isRegisterDone');
    //     console.log(isRegisterDone)
    //     setShowSignIn(isRegisterDone !== '1');
    //     const user = {
    //         ...getUserInfo(),
    //         userName: rootStore.getFullName(),
    //     }
    //     setUserInfo(user);
    // }, [rootStore.isLogin]);
    useEffect(() => {
        setStateHasLogin(rootStore.hasLogin);
    },[rootStore.hasLogin]);
    return (
        <header className="navbar-wrap">
            <img alt="logo" className="logo" src={require('../../assets/image/logo.png')} />

            <div className="menu-wrap">
                <div className="nav-menu">
                    <Link to="/" title={f({ id: 'navbar.home' })}>{f({ id: 'navbar.home' })}</Link>
                </div>


                <Dropdown overlay={<Invest></Invest>}>

                    <div className="nav-menu">
                        <Link to="/introduce/investor" title={f({ id: 'navbar.investmentOffering' }).split(' ')[0]}>{
                            f({ id: 'navbar.investmentOffering' }).split(' ').map(item => <p key={item}>{item}</p>)
                        }</Link>
                        <DownOutlined className="dropdown-icon" />
                    </div>

                </Dropdown>

                <Dropdown overlay={(
                    <Shipowner />
                )}>

                    <div className="nav-menu">
                        <Link to="/introduce/shipowner" title={f({ id: 'navbar.existingShipowners' }).split(' ')[1]}>{
                            f({ id: 'navbar.existingShipowners' }).split(' ').map(item => <p key={item}>{item}</p>)
                        }</Link>
                        <DownOutlined className="dropdown-icon" />
                    </div>

                </Dropdown>


                <Dropdown overlay={<About />}>
                    <div className="nav-menu">
                        <Link to="/about" title={f({ id: 'navbar.aboutMdr' }).split(' ')[0]}>{
                            f({ id: 'navbar.aboutMdr' }).split(' ').map(item => <p key={item}>{item}</p>)
                        }</Link>
                        <DownOutlined className="dropdown-icon" />
                    </div>
                </Dropdown>

                <span className="nav-menu">
                    <Link to="/press">{f({ id: 'navbar.press' })}</Link>
                </span>
            </div>



            {
                rootStore.hasLogin ? (
                    <div className="navbar-right">
                        <Dropdown overlay={<Usercenter />}>
                            <div className="user-info">
                                {/* {
                                    userInfo.type !== 3 ? <img alt='icon' src={require('../../assets/image/auth.png')}></img> : null
                                } */}

                                {/* <Badge count={10} showZero offset={[15, -5]}> */}
                                <span onClick={() => history.push('/usercenter/message/shopowner')} className='username'> {rootStore.getFullName()}</span>
                                {/* </Badge> */}
                            </div>
                        </Dropdown>

                        <div className="help">
                        {/* <Link to="/about?keyword=Buyer Qualification&id=3"> Need  help</Link> */}
                        </div>
                    </div>
                )
                    : (
                        <div className='navbar-right'>

                            {
                                !rootStore.hasLogin && (<div className="signup">
                                    <Link to='/register/signup'>Register</Link>
                                </div>)
                            }


                            <Dropdown
                                onVisibleChange={(value:any) => {
                                    //@ts-ignore
                                    const errorModal = document.querySelector('.passwordError');
                                    if(errorModal !== null) {//错误弹窗
                                        if(showlogin) {//并且当前是显示状态，不隐藏
                                            return;
                                        }
                                    }
                                    setshowlogin(val => !val);
                                }}
                                overlayStyle={{ right: '20px' }}
                                placement='bottomRight'
                                trigger={['click']}
                                visible = {showlogin}
                                overlay={<LoginModal visibleChange={showlogin} />}>
                                <div className='login'>
                                    {
                                        <span>{f({ id: 'login' })}</span>
                                    }
                                </div>
                            </Dropdown>


                        </div>
                    )
            }

            {/* <LoginModal></LoginModal> */}
        </header>
    )
})

export default NavBar;
