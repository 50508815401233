import React, { useEffect, useState } from 'react';
import { Button, Table, Pagination } from 'antd';
import api from '../../api';
import { getUserInfo } from '../../utils';
import { useHistory } from 'react-router-dom';
import { TableTitle, ButtonIcon } from "../../Pages/peopleMessage/components/myPortfolio/myPortfolio";
import {formatThreeMoney,formatMoney} from "../../utils";
interface IProps {
    // data: SElectRow;
    id: Number;
}
export interface SElectRow {
    expireTime: string;
    fundraising: number;
    id: number;
    investment: number;
    latestUpdateDate: string;
    projectNumber: string;
    projectType: string;
    purchaseDate: string;
    status: number;
    vesselName: string;
    vesselType: string;
}
// addType: "-"
// createTime: "2020-06-29 18:06:44"
// id: 358
// list: [{…}]
// published: "2020-06-29 00:00:00"
// sourcePurpose: "Investor purchase"
// stoId: 454
// tokenName: "先不要删"
// tokenNum: 20
// tokenPrice: 10
// userHoldToken: 20
// userOperation: "Sell"
const ExTokenDetailModal: React.FC<IProps> = ({ id }) => {
    const [requestData, setRequestData] = useState() as any;
    const [pageIndex, setPageIndex] = useState(1)
    const [total, setTotal] = useState(0);
    // const [params, setParams] = useState({
    //     createUser: getUserInfo().id , count: true, pageIndex: pageIndex, "pageSize": 5, "param": { "tokenId": id }
    // });
    const history = useHistory();
    const userOperation = {
        "tokenDate": {
            value: "tokenDate",
            addType: ""
        },
        "tokenTime": {
            value: "00:00:00",
            addType: ""
        },
        "orderType": {
            value: "orderType",
            addType: ""
        },
        "unitPrice": {
            value: "unitPrice",
            addType: 0.00
        },
        "quantity": {
            value: "quantity",
            addType: 0
        },
        "orderNumber": {
            value: "orderNumber",
            addType: 0
        },

        

    } as any;
    const columns = [
        {
            title:  <TableTitle title='Date' />,
            dataIndex: 'tokenDate',
            key: 'tokenDate',
            align:'center' as 'center'
            // width: '105px',
        },
        {
            title:<TableTitle title='Time' />,
            dataIndex: 'tokenTime',
            key: 'tokenTime',
            align:'center' as 'center'
            // width: '105px',
        },
        {
            title:  <TableTitle title='Type' />,
            dataIndex: 'orderType',
            key: 'orderType',
            align:'center' as 'center',
            render: (item: any, row: any) => {
                if (row.orderType == '1' || row.orderType == '2') {
                    return (
                        <div style={{ textAlign: 'center'}}>
                            Buy
                        </div>
                    )
                    // return 'Buy'
                }
                if (row.orderType == '5') {
                    return (
                        <div style={{ textAlign: 'center'}}>
                            Transfer In
                        </div>
                    )
                    // return 'Transfer In'
                }
                return (
                    <div style={{ textAlign: 'center'}}>
                        Sell
                    </div>
                )
            }
        },
        {
            title: <TableTitle title='Unit Price' />,
            dataIndex: 'unitPrice',
            key: 'unitPrice',
            align:'center' as 'center',
            render: (item: any) => {
                return (
                    <div style={{textAlign:'right'}}>{formatThreeMoney(Number(item || 0))}</div>
                );
            }
        },
        {
            title: <TableTitle title='Quantity' />,
            dataIndex: 'quantity',
            key: 'quantity',
            align:'center' as 'center',
            render: (item: any) => {
                return (
                    <div style={{textAlign:'right'}}>{formatMoney(Number(item).toFixed(0))}</div>
                );
            }
        },
        {
            title:<TableTitle title='Transaction Number' />,
            dataIndex: 'orderNumber',
            key: 'orderNumber',
            align:'center' as 'center'
        },
    ]
    useEffect(() => {
        getListData();//根据id获取列表
    }, [id]);
    const getListData = async () => {
        let userIfno = getUserInfo();
        console.log(pageIndex);
        let params = { createUser: userIfno.id , count: true, pageIndex: pageIndex, "pageSize": 10, "param": { "tokenId": id } }
        const data = await api.queryMyTokenDetail(params);
        setTotal(data.total);
        //拿到数据去除，筹款和结束, 分红只显示equarity
        setRequestData(data.list);
    }
    const getPageListData = async (page:Number) => {
        let userIfno = getUserInfo();
        console.log(pageIndex);
        let params = { createUser: userIfno.id , count: true, pageIndex: page, pageSize: 8, param: { tokenId: id } }
        const data = await api.queryMyTokenDetail(params);
        setTotal(data.total);
        //拿到数据去除，筹款和结束, 分红只显示equarity
        setRequestData(data.list);
    }
    return (
        <div>
            <Table pagination={false} columns={columns} className="table-box-nav" dataSource={requestData}></Table>
            <Pagination
                style={{ marginTop: '50px' }}
                className='mdr-pagination'
                showTotal={(total) => `${total} items in total`}
                onChange={(page) => {
                    console.log(page);
                    setPageIndex(page);
                    getPageListData(page);//根据id获取列表
                }}
                // showSizeChanger={false}
                // showQuickJumper={false}
                defaultCurrent={1}
                // total={peopleStore.exTokenListTotal}
                total={total}
            />
        </div>
    )
}

export default ExTokenDetailModal;