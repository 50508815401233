import React, {useEffect} from 'react';
import {Button as Abt} from 'antd'
import './myPortfolio.less'
import Ex from './Ex'
import Sto from './STO'
import {formatTwoMoney} from '../../../../utils';
import { DividerSpan } from '../myWallet/myWallet'
import PeopleStore from "../../peopleStore";
import {useObserver} from "mobx-react";
import peopleStore from "../../peopleStore";
type IProps = {
    active: number
}
const MyPortfolio = (props: IProps) => {
    useEffect(() => {
        peopleStore.getOrderHistoryTableList().then()
        peopleStore.getPeopleHoldTokenSTO().then()
    }, [])
    return useObserver(() => (
        <div className="portfolio-content">
            <p className = "title-p">My portfolio is the integrated portal to examine tokens you held.</p>
            
            <DividerSpan
                currColl={PeopleStore.walletCollFlag.stoToken}
                title = "STO"
                // subTitle={peopleStore.STOTokenSum + 'MDRX'}
                subTitle={  formatTwoMoney(Number(peopleStore.STOTokenSum || 0))}
                titleLabel = 'MDRX'
                clickHandler={() => PeopleStore.setCollFlag(Object.assign({}, PeopleStore.walletCollFlag, {stoToken: !PeopleStore.walletCollFlag.stoToken}))}/>
            {!PeopleStore.walletCollFlag.stoToken && <Sto/>}
            <DividerSpan
                currColl={PeopleStore.walletCollFlag.exToken}
                title = "EX"
                // subTitle= {peopleStore.exTokenTotal + 'MDRX'}
                subTitle= {formatTwoMoney(Number(peopleStore.exTokenTotal || 0))}
                titleLabel = 'MDRX'
                clickHandler={() => PeopleStore.setCollFlag(Object.assign({}, PeopleStore.walletCollFlag, {exToken: !PeopleStore.walletCollFlag.exToken}))}/>
            {!PeopleStore.walletCollFlag.exToken && <Ex/>}
        </div>
    ))
}
export default MyPortfolio




type IButtonIconProps<T> = {
    title: string,
    onClick?: (row: T) => void
    row: T,
    children: React.ReactNode | null
}

const ButtonIcon = <T,>(props: IButtonIconProps<T>) => {
    return (
        <div className="viewDetail">
            <Abt type='link' style={{fontSize: '12px'}}
                 onClick={() => props.onClick && props.onClick(props.row)}> {props.title}</Abt>
            <span className="iconDetail">{props.children}</span>
        </div>

    )
}

const ButtonIconTable = <T,>(props: IButtonIconProps<T>) => {
    return (
        <div className="viewDetail table-btu" >
            <Abt type='link' style={{fontSize: '12px'}}
                 onClick={() => props.onClick && props.onClick(props.row)}> {props.title}</Abt>
            <span className="iconDetail">{props.children}</span>
        </div>

    )
}

const TableTitle = (props: { title: string }) => {
    const {title} = props
    return (
        <div className = "header-title">
            {title && title.includes('/') ?
                <>
                    <span>{title.split('/')[0]}</span>
                    <span>{title.split('/')[1]}</span>
                </>
                :
                <span>{title}</span>
            }
        </div>
    )
}
export {TableTitle, ButtonIcon, ButtonIconTable}
