import React, { useEffect,useState } from 'react';
import {Button,Table,Pagination} from 'antd';
import api from '../../api';
import { useHistory } from 'react-router-dom';
import {TableTitle} from "../../Pages/peopleMessage/components/myPortfolio/myPortfolio";
import './index.less'

interface IProps {
    // data: SElectRow;
    id: number;
}
export interface SElectRow {
    expireTime: string;
    fundraising: number;
    id: number;
    investment: number;
    latestUpdateDate: string;
    projectNumber: string;
    projectType: string;
    purchaseDate: string;
    status: number;
    vesselName: string;
    vesselType: string;
}
// addType: "-"
// createTime: "2020-06-29 18:06:44"
// id: 358
// list: [{…}]
// published: "2020-06-29 00:00:00"
// sourcePurpose: "Investor purchase"
// stoId: 454
// tokenName: "先不要删"
// tokenNum: 20
// tokenPrice: 10
// userHoldToken: 20
// userOperation: "Sell"
const TokenDetailModal: React.FC<IProps> = ({id}) => {
    const [requestData,setRequestData] = useState() as any;
    const [total,setTotal] = useState(0);
    const [page,setPage] = useState(0);
    const history = useHistory();
    const userOperation = {
        "Transfer_To_EX" :{
            value: "Transfer to EX",
            addType: "-"
        },//转售
        "Purchase token" :{
            value: "Purchase",
            addType: "+"
        },//购入
        "Fundraising": {
            value: "筹款",
            addType: ""
        },//筹款
        "Refund": {
            value: "refund",
            addType: "-"
        },//退款
        "Finished": {
            value: "Finished",
            addType: ""
        },//结束
        "divident": {
            value: "Equity dividend",
            addType: "+"
        },//分红
        "Sum": {
            value: "Sum",
            addType: ""
        }
    } as any;
    const columns = [
        {
            title: <TableTitle title='Time'/>,
            dataIndex: 'purchase',
            key: 'purchase',
            align:'center' as 'center'
            // width: '105px',
        },
        {
            title: <TableTitle title='Type'/>,
            dataIndex: 'addType',
            key: 'addType',
            align:'center' as 'center',
            render:(item:any,row:any) => {
                if(row.type === "divident") {
                    if(row.projectObjective === 'Liability') {
                        return '';
                    }else {
                        return row.addType || '+'
                    }
                }else {
                    return userOperation[row.type].addType;
                }
            }
        },
        {
            title: <TableTitle title='Change'/>,
            dataIndex: 'userHoldUnit',
            key: 'userHoldUnit',
            align:'center' as 'center',
            // width: '105px',
            render:(item:any,row:any) => {
                if(row.type === 'divident') {
                    if(row.projectObjective === 'Liability') {//债券不显示
                        return '';
                    }else {//股权显示
                        const value = row.fundDividend?.totalDividendsDistributed
                        if(value === 0) {
                            return `${value}Tokens`;
                        }else if(value < 0) {
                            return `${Math.abs(value)}Tokens`;
                        }else if(value > 0) {
                            return `${value}Tokens`
                        }else {
                            return ''
                        }
                    }
                }else if(row.type === "Purchase token") {
                    return `${row.numberOfToken? row.numberOfToken: ''}Token`
                }else if(row.type === "Refund") {
                    return `${row.numberOfToken? row.numberOfToken: ''}Token`
                }else if(row.type === "Transfer_To_EX") {
                    return `${row.numberOfToken? row.numberOfToken: ''}Token`
                }else {
                    return '';
                }
            }
        },
        {
            title:<TableTitle title='User Operation'/>,
            dataIndex: 'userOperation',
            key: 'userOperation',
            align: 'center' as 'center',
            render:(item:any,row:any) => {
                return userOperation[row.type]? userOperation[row.type].value : "";
            }
        },
        {
            title: <TableTitle title='Operation'/>,
            dataIndex: 'operation',
            key: 'operation',
            align:'center' as 'center',
            render: (item: any, row: any) => {
                return (
                    <Button
                        onClick={() => {
                            history.push(`/usercenter/invest/detail/${id.toString()}`)
                        }}
                        type='link'
                        style={{ fontSize: '12px' }}>View Detail</Button>)
            }
        }
    ]
    useEffect(() => {
        getListData();//根据id获取列表
    },[id]);
    const getListData = async() => {
        const data = await api.stoInvestorList(id.toString());
        console.log(JSON.parse(JSON.stringify(data)));
        //拿到数据去除，筹款和结束, 分红只显示equarity
        setRequestData(data.filter((item:any) => item.type === 'Transfer_To_EX' || item.type === 'Purchase token' || item.type === 'Refund' || (item.type === 'divident' && item.projectObjective === "Equity")));
    }
    return (
      <div>
          <Table className='table-box-nav' pagination={false} columns={columns} dataSource={requestData}></Table>
      </div>
    )
}

export default  TokenDetailModal;
