import React, { useEffect, useState } from 'react';
import { List } from 'antd';
import './index.scss';
import { Title, Button } from '../../../../../../components';
import store from '../../store';
import { useParams } from 'react-router-dom';
import api from '../../../../../../api';
import { formatMoney} from '../../../../../../utils';
import dayjs from 'dayjs';


const cols = (data: any) => [
    {
        key: 'Number of tokens refunded',
        value: data.numberOfToken,
        extra: 'Tokens'
    },
    {
        key: 'Token price',
        value: formatMoney(data.tokenPrice),
        extra: 'MDRX per Token'
    },
    {
        key: 'Subtotal refund',
        value: formatMoney(data.subTotal),
        extra: 'MDRX'
    },
    {
        key: 'Transaction fee refunded',
        value: formatMoney(data.transactionFee),
        extra: `MDRX（${data.transfee * 100}%）`
    },
    {
        key: 'Total refunded',
        value: formatMoney(data.totalPay),
        extra: 'MDRX'
    },
    {
        key: 'Refunded Time',
        value: dayjs(data.purchase).format('YYYY-MM-DD'),
        extra: ''
    },
]
const fundRaisingDataCols = (data:any) => [
    {
        key: "Total Refunded",
        value: data.subTotal,
        extra: 'MDRX'
    },
    {
        key: 'Refunded Time',
        value: dayjs(data.purchase).format('YYYY-MM-DD'),
        extra: ''
    },
]

const Finished = () => {
    const { id } = useParams();

    const [finish, setFinished] = useState(null);

    const [refund, setRefund] = useState(null);
    
    const [fundRaisingData,setFundRaisingData] = useState(null);

    useEffect(() => {
        fetchDetail();
    }, [store.finishData]);

    const fetchDetail =  () => {
        const data = store.finishData;
        const finishs = data.find((item: any) => item.type === 'Finished');

        const refunds = data.find((item: any) => item.type === 'Refund all');
        const fundRaisingDatas = data.find((item:any) => item.type === "REFUND_FUNDRAISING")
        setFinished(finishs);

        setRefund(refunds);
        setFundRaisingData(fundRaisingDatas)
    }
    const handleBack = () => {
        if((store.fundraising.status === 6 && store.fundraising.isTransfer) || finish) {//跳转到
            store.cur = 1;
        }else {
            store.cur = 0;
        }
    }
    const data = cols(refund || {});
    const fundRaisingDataColumns = fundRaisingDataCols(fundRaisingData || {});
    return (
        <div className="detail-finish">
            {
                //@ts-ignore
                finish ? <div className='success-wrap'>
                    <Title>Offering Finished</Title>
                   
                    <span className='list-title'>Finished at 
                   
                    {
                    //@ts-ignore
                    finish.purchase? finish.purchase: ''}</span>
                </div> : null
            }
            
            {
                 
               !finish &&  fundRaisingData?(
                    <div>
                        
                        <Title>Shipowner refunded {
                        fundRaisingData !== null? 
                        // @ts-ignore
                        fundRaisingData.subTotal : 
                        ''} MDRX to MDR </Title>
                        <List
                            bordered
                            dataSource={fundRaisingDataColumns.map(item => JSON.stringify(item))}
                            renderItem={(item: string) => {
                                const items: any = JSON.parse(item);
                                return (
                                    <List.Item className='row property-item'>
                                        <p className='label'>{items.key}</p>
                                        <p className='value'>{items.value}</p>
                                        <p className='extra'>{items.extra}</p>
                                    </List.Item>
                                )
                            }}
                        />
                    </div>
                ):(null)
            }
            {
                // @ts-ignore
            !finish && refund ? <Title>MDR Refunded{refund.totalPay} MDRX to Investors</Title> : null
            }
            {
               !finish && refund ? <>

                    {/* <span className="list-title">MDR Refunded</span> */}
                    <List
                        bordered
                        dataSource={data.map(item => JSON.stringify(item))}
                        renderItem={(item: string) => {
                            const items: any = JSON.parse(item);
                            return (
                                <List.Item className='row property-item'>
                                    {/* <div className='r0w'> */}
                                    <p className='label'>{items.key}</p>
                                    <p className='value'>{items.value}</p>
                                    <p className='extra'>{items.extra}</p>
                                    {/* </div>    */}
                                </List.Item>
                            )
                        }}
                    />
                </> : null
            }

            <div className="btn-wrap">
                <Button
                    arrowLeft
                    type='ghost'
                    onClick={handleBack}
                >Go Back</Button>
            </div>
        </div>

    )
}

export default Finished;