import React from 'react';
import { useIntl } from 'react-intl';
import { observer } from 'mobx-react';
import { Input,Checkbox } from 'antd';


import FormItem from 'antd/lib/form/FormItem';
import store from '../../../store';
import { Title } from '../../../../../components';
import './index.scss'
import { PWD_REG } from '../../../../../constants';
import {useHistory} from 'react-router-dom'
import { CheckboxChangeEvent } from 'antd/lib/checkbox';
const Email: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();
    const history = useHistory();
    const handleChange = (e: any) => {
        store.updateModel({ 'email': e.target.value })
    }
    const readTrimCheckChange = (event:CheckboxChangeEvent) => {
        store.setReadTrimFalg(event.target.checked);
    }

    const getUserTrim = () => {
        window.open('#/about?keyword=User%20Terms,%20Disclaimer,%20and%20Privacy%20Policy&id=12', 'User Terms, Disclaimer, and Privacy Policy');
    }
    return (
        <div className='register-email'>
            {/* <Title>{f({ id: 'register.start' })}</Title>

            <Title sub>
                {f({ id: 'register.email.title' })}
            </Title> */}

            <div className='input-item'>
                <span className='label'>Email address</span>
                <FormItem name='email' rules={[{ required: true, type: 'email', message:"Please use a valid Email address."}]}>
                    <Input
                        onChange={handleChange}
                        value={store.model.email}
                        placeholder={f({ id: 'register.email.placeholder' })}
                        //
                        className='m-input email-input'
                    />
                </FormItem>
            </div>


            <div className="input-item">
                <span className='label'>Password</span>
                <FormItem
                    name='password'
                    required
                    rules={
                        [
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (value && PWD_REG.test(getFieldValue('password'))) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(f({ id: 'register.password.one.note' }));
                                },
                            })
                        ]
                    }
                >
                    <Input.Password type='password' className='m-input' placeholder={f({ id: 'register.password.one.placeholder' })} style={{ width: '100%' }} />
                </FormItem>

            </div>

            <div className="input-item form-group">
                <span className='label'>Confirm password</span>
                <FormItem
                    name='confirm'
                    required
                    dependencies={['password']}
                    rules={
                        [
                            ({ getFieldValue }) => ({
                                validator(rule, value) {
                                    if (value && getFieldValue('password') === value) {
                                        store.updateModel({ password: value })
                                        return Promise.resolve();
                                    }
                                    
                                    return Promise.reject('The two passwords that you entered do not match!');
                                },
                            })
                        ]
                    }
                >
                    <Input.Password type='password' className='m-input'
                        placeholder='Confirm password'
                        // {f({ id: 'register.password.confirm.placeholder' })} 
                        style={{ width: '100%' }} />
                </FormItem>

            </div>
            <div className = "readTerms">
                <Checkbox
                checked = {store.readTrimsFlag}
                onChange = {readTrimCheckChange}
                >I agree to </Checkbox>
                <span
                className = "readTermsLink"
                onClick = {getUserTrim}
                >User Terms, Disclaimer, and Privacy Policy.</span>
            </div>

        </div>
    )
})

export default Email;