import { observable, computed } from "mobx";
import api from "../../../../../../../api";
import { getAllOptions } from "../../../../../../../utils";
import stepRootStore from '../../store';
class Store {
  @observable cur = 0;

  @observable stoId = '';

  @observable list = [
    'Fundraising',
    // 'Fundraising Transfer',
    // 'Cash withdrawal', 'Dividend', 
    // 'Finish Offer'
]
    @observable stoStatus = 0;
@observable finishData  = [] as any;
  @observable fundraising: {
    actualFundraising: number;
    endDate: string;
    leftDays: number;
    raisingFunds: number;
    startDate: string;
    status: number;
    stoId: number;
    subtotalValue: number;
    tokenNumber: number;
    tokenPrice: number;
    totalFundraising: number;
    totalReceived: number;
    transactionFee: number;
    userHoldNumber: number;
    statusDesc:string;
    isTransfer:boolean;//是否给船东装过账
    fundraisingComplateDate: string;//筹款完成时间
    projectObjective: string;
    tokenName:string;
    lastDate: string;//访问时间
    fundraisingDays: string;//后台设置的时间
  } = {} as any;

  @observable transfer :  {
    actualTransferShipowner: number;
    totalFundraising: number;
    transactionFee: number;
    transferShipowner: number;
    transferTime: string;
    transactionFeeRate: number;
    subjectItem: string;///船东转账回执
  }  = {} as any;

  @observable transactionFee = 0;

  fetchFundraisingInfo = async () => {
    const data = await api.fundraisingInfo(this.stoId) || {};
    const finishData = await api.stoInvestorList(this.stoId);
    this.finishData = finishData;
    this.fundraising = data;
    // 根据得到的信息isTransfe,决定是否请求退款信息接口
    const {isTransfer} = data;
    if(isTransfer) {
        this.fundraisingTransfer();
    }
  }

  fundraisingTransfer = async () => {
    const data = await api.fundraisingTransfer(this.stoId) || {};
    this.transfer = data;
  }

  fetchAllOptions = async () => {
   const data = await api.findStoApprovalById(this.stoId);
  console.log(data)
  }
  init() {
    this.stoId = stepRootStore.stoId;
    this.stoStatus =stepRootStore.stoStatus;
    this.fetchFundraisingInfo();
    this.fetchAllOptions();
  }
}

export default new Store();