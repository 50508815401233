import api from "../../../api";
import { observable, values } from "mobx";

import { data } from './mock';
import { getImageUrl } from "../../../utils";
import Item from "antd/lib/list/Item";

interface ipage {
  "count": true,
  "orderBy": "string",
  "pageEndIndex": 0,
  "pageIndex": 0,
  "pageSize": 0,
  "param": {
    "createTime": "2020-05-30T17:28:22.610Z",
    "createTimeEnd": "2020-05-30T17:28:22.610Z",
    "createTimeStart": "2020-05-30T17:28:22.610Z",
    "createUser": 0,
    "currentDate": "2020-05-30T17:28:22.610Z",
    "expireTime": "2020-05-30T17:28:22.610Z",
    "financialGoal": "string",
    "fundraising": 0,
    "fundraisingMax": 0,
    "fundraisingMin": 0,
    "id": 0,
    "imgUrl": "string",
    "keyword": "string",
    "modifyTime": "2020-05-30T17:28:22.610Z",
    "modifyUser": 0,
    "projectName": "string",
    "projectNumber": "string",
    "projectObjective": "string",
    "projectType": "string",
    "projectTypeName": "string",
    "publishTime": "2020-05-30T17:28:22.610Z",
    "status": 0,
    "statusList": [
      0
    ],
    "synopsis": "string",
    "vesselName": "string",
    "vesselType": "string",
    "vesselTypeName": "string"
  }
}


class Store {

  @observable vesselType = [];

  @observable orderBy = {} as any;

  @observable pageIndex = 1;

  @observable offerList = [] as any;

  @observable totalPage = 0;

  @observable total = 0;

  // {
  //   vesselName: '',
  //   fundraisingMax: 0,
  //   fundraisingMin: 0,
  // }
  @observable searchModal = {
    offerType: 1,
  } as any;

  // projectType: null,
  // vesselType: '',
  // vesselName: '',
  // publishTime: null,

  @observable condition = {
    vesselType: {},
  } as any;

  handleSearch = () => {
    const publishDateType = this.condition.publishTime?.value;
    const condition = { ...this.condition };
    delete condition.publishTime;
    this.fetchOfferList({
      ...this.searchModal,
      ...condition,
      publishDateType,
    })
  }

  clearSearch = () => {
    this.searchModal.projectNumber = '';
    this.searchModal.fundraisingMin = '';
    this.searchModal.fundraisingMax = '';
    this.condition = {
      vesselType: {},
    }
    this.handleSearch();
    
  }

  async fetchOptions() {
    const data = await api.fetchOptions();

    this.vesselType = data || [];
  }

  async fetchOfferList(params?: {}) {
    const param = params || {} as any;

    const orderBy = Object.keys(this.orderBy).map(key => `${key} ${this.orderBy[key]}`);
    const data = {
      pageIndex: this.pageIndex,
      pageSize: 50,
      orderBy: orderBy.length > 0 ? orderBy.join(',') : null,
      param: {
        ...param,
        projectType: param.projectType?.value,
        vesselType: param.vesselType?.value
      }
    }

    const { list = [], lastPage, total } = await api.fetchOfferList(data) || [];
    this.total = total;
    this.totalPage = lastPage;
    this.offerList = list;
  }

  handleProjectTypeChange = (item: any) => {
    //@ts-ignore
    if (item.label === this.condition.projectType?.label) {
      //@ts-ignore
      this.condition.projectType = null;
    } else {
      this.condition.projectType = item;
    }

    this.handleSearch();

  }

  handleVesselTypeChange = (item: any) => {
    this.condition.vesselType = item;
    this.handleSearch();
  }

  hanldeTimeChange = (value: any) => {
    //@ts-ignore
    if (value.label === this.condition.publishTime?.label) {
      //@ts-ignore
      this.condition.publishTime = null;
    } else {
      //@ts-ignore
      this.condition.publishTime = {
        label: value.label,
        value: value.value
      }
    }
    this.handleSearch();
  }

  handleInputChange = (key: string, e: any) => {
    //@ts-ignore
    if (key === 'vesselName') {
      this.condition[key] = e.target.value;
      return;
    }
    //@ts-ignore
    console.log(e);
    this.searchModal[key] = e;
  }

  handleOrderChange = (key: string, value: string) => {
    if (+value === 0) {
      // 删除所有属性
      for(let id in this.orderBy) {
        delete this.orderBy[id]
      }
    } else {
      // 删除全部属性
      for(let id in this.orderBy) {
        delete this.orderBy[id];
      }
      this.orderBy[key] = +value === 1 ? 'ASC' : 'DESC'
    }



   


    this.handleSearch();
  }

}

export default Store;