import React, { useState, useEffect } from 'react';

import './index.scss';
import { Title, Button,TokenDetailModal } from '../../../../components';
import { formatMoney } from '../../../../utils';

import { Button as Abt, Table, Pagination, Modal, message } from 'antd'
import dayjs from 'dayjs';
import api from '../../../../api';
import { useHistory } from 'react-router-dom';

// 0:
// addType: "-"
// createTime: "2020-06-27 19:14:52"
// id: 295
// list: Array(2)
// 0:
// addType: "+"
// sourcePurpose: "Initial publish"
// userHoldToken: 100
// userOperation: "Published"
// __proto__: Object
// 1:
// addType: "-"
// createTime: "2020-06-28 11:11:28"
// id: 296
// published: "2020-06-27 00:00:00"
// sourcePurpose: "Investor purchase"
// stoId: 440
// tokenName: "xqtoken"
// tokenNum: 200
// tokenPrice: 1000
// userHoldToken: 200
// userOperation: "Sell"
// __proto__: Object
// length: 2
// __proto__: Array(0)
// published: "2020-06-27 00:00:00"
// sourcePurpose: "Investor purchase"
// stoId: 440
// tokenName: "xqtoken"
// tokenNum: 100
// tokenPrice: 1000
// userHoldToken: 100
// userOperation: "Sell"
// __proto__: Object
// length: 1
// __proto__: Array(0)


const detailCols = (cb: Function) =>  [
    {
        title: 'Time',
        dataIndex: 'createTime',
        key: 'createTime',
        // width: '105px',
    },
    {
        title: 'Type',
        dataIndex: 'addType',
        key: 'addType',
        // width: '105px',
    },
    {
        title: 'Charge',
        dataIndex: 'userHoldToken',
        key: 'userHoldToken',
        // width: '105px',
    },
    {
        title: 'User operation',
        dataIndex: 'userOperation',
        key: 'userOperation',
        // width: '105px',
    },
    {
        title: 'Event',
        dataIndex: 'sourcePurpose',
        key: 'sourcePurpose',
        // width: '105px',
    },
    {
        title: 'Operation',
        dataIndex: 'operation',
        key: 'operation',
        render: (item: any, row: any) => {
            return (
                <Abt
                    onClick={() => {
                        cb();
                    }}
                    type='link'
                    style={{ fontSize: '12px' }}>View Detail</Abt>)
        }
    }
]

const columns = (handleDetail: Function) => [
    {
        title: 'Token Name',
        dataIndex: 'tokenName',
        key: 'tokenName',
        // width: '105px',
    },
    {
        title: 'Number of Tokens',
        dataIndex: 'tokenNum',
        key: 'tokenNum',
        // width: '105px'
    },
    {
        title: 'Token price',
        dataIndex: 'tokenPrice',
        key: 'tokenPrice',
        render: (item: any) => item ? formatMoney(item) : '--'
        // width: '140px'
    },

    {
        title: 'Sub-total of Token Value',
        dataIndex: 'tokenValue',
        key: 'tokenValue',
        render: (item: any) => item ? formatMoney(item) : '--'
        // width: '100px'
    },
    {
        title: 'Investment Date',
        dataIndex: 'purchasedate',
        key: 'purchasedate',
        // width: '125px',
        render: (item: string) => dayjs(item).format('YYYY-MM-DD')
    },
    {
        title: 'Operation',
        dataIndex: 'operation',
        key: 'operation',
        render: (item: any, row: any) => {
            return <Abt onClick={() => handleDetail(row)} type='link' style={{ fontSize: '12px' }}>View Detail</Abt>
        }
    },

];


const Token = () => {
    const [total, setTotal] = useState(0);
    const [pageIndex, setPageIndex] = useState(1);
    const [list, setList] = useState([]);
    const [showDetail, setShowDetail] = useState(false);
    const [showTokenDetail,setShowTokenDetail] = useState(false);
    const [TokenDetailId,setTokenDetailId] = useState(0);
    const [totkenName,setTokenName] = useState('');
    const [detail, setDetail] = useState({}) as any;

    const history = useHistory();

    useEffect(() => {
        fetchTokenList();
    }, [pageIndex])

    const fetchTokenList = async () => {
        const list = await api.getHoldTokenList() || [];
        setList(list);
    }

    const fetchDetail = async (row:any) => {
        setTokenDetailId(row.stoId);
        setTokenName(row.tokenName);
        // const data = await api.getShipperOfferList();
        // const item = data.find((item: any) => item.id === id);
        // if(!item) {
        //     message.info('暂无详情')
        //     return
        // }
        // setDetail(item);
        // setShowDetail(true);
        setShowTokenDetail(true);
    }

    const cols = columns(fetchDetail);

    const detailCol = detailCols(() => {
        setShowDetail(false);
        history.push(`/usercenter/invest/detail/${detail.stoId}`)
    });


    return (
        <div className="token-wrap">
            {/* <div className="summary-wrap row">
                <Title>Available Cash</Title>

                <p className='money'><span className='count'>{formatMoney(10000)}</span><span>MDRX</span></p>

                <Button size='middle' type='ghost'>Withdraw</Button>
            </div> */}

            <Table id={'stoId'} pagination={false} columns={cols} dataSource={list}></Table>
            {/* <Pagination style={{ marginTop: '50px' }} className='mdr-pagination'
                showTotal={(total) => `${total} items in total`}
                onChange={(page) => setPageIndex(page)}
                showSizeChanger={false}
                showQuickJumper
                defaultCurrent={pageIndex}
                total={total}
            /> */}

            {/* <Modal
                visible={showDetail}
                footer={null}
                onCancel={() => setShowDetail(false)}
                destroyOnClose
                className='detail-modal'
                centered
                title={`Token History of "${''}"`}
            >
                <Table id={'stoId'} pagination={false} columns={detailCol} dataSource={detail.list}></Table>
            </Modal> */}
            <Modal
                visible={showTokenDetail}
                footer={<div style = {{textAlign: "center"}}><Button onClick = {() => setShowTokenDetail(false)}>OK</Button></div>}
                onCancel={() => setShowTokenDetail(false)}
                destroyOnClose
                className='detail-modal'
                centered
                width = {730}
                title={`Token History of "${totkenName}"`}
            >
                <TokenDetailModal id = {TokenDetailId}></TokenDetailModal>
            </Modal>
        </div>
    )
}

export default Token;
