import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { MDown, MSelect, Button, Title } from '../../../../../components/index';

import './index.scss';
import { observer } from 'mobx-react';
import store from '../../store';
import Form, { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { Input, message } from 'antd';
import Identity from '../Identity';
import Company from '../Company';

const Personal: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();

    const [location, setLocation] = useState(store.locationValue);

    const companyNameLabel = f({ id: 'register.company.name' });

    const companyAddressLabel = f({ id: 'register.company.address' });

    const [city, setCity] = useState(store.cityValue);

    const [form] = useForm();

    const [readonly, setReadOnly] = useState(true);

    useEffect(() => {
        store.getAllLocation();
    }, []);


    useEffect(() => {
        form.setFieldsValue({ ...store.userInfo })
    }, [store.userInfo.id])

    const handleEdit = () => {
        if (readonly) {
            setReadOnly(false);
            return;
        }
        form.validateFields().then(() => {
            const data = form.getFieldsValue();
            store.updateUserInfo({
                ...data,
                location,
                city,
            }, () => {
                message.success('User information is updated');
                setReadOnly(true);
            });
        });
    }



    const handleLocationChange = async (item: any) => {
        setLocation(item);
        store.updateLocation(item, (value: string) => {
            setCity(value);
        });

    }

    const handleCityChange = (item: string) => {
        setCity(item);
    }

    const givenameLabel = f({ id: 'register.person.givename' });

    const surfNameLabel = f({ id: 'register.person.surname' });

    return (
        <div className='detail-personal'>
            {/* <Title>{f({ id: 'register.person' })}</Title> */}
            <Form form={form}>
                <div style={{ marginTop: '20px' }} className="row">
                    <div className="input-item">
                        <span className='label'>{givenameLabel}</span>
                        <FormItem name='givenName' rules={[{ required: true, message: `${givenameLabel} is required` }]}>
                            <Input readOnly={readonly} className='m-input username' placeholder={f({ id: 'register.person.givename.placeholder' })} />
                        </FormItem>

                    </div>

                    <div className="input-item m-l-15">
                        <span className='label'>{surfNameLabel}</span>
                        <FormItem name='surname' rules={[{ required: true, message: `surfNameLabel is required` }]}>
                            <Input readOnly={readonly} className='m-input username' placeholder={f({ id: 'register.person.surname.placeholder' })} />
                        </FormItem>

                    </div>
                </div>
                {/* <div className="form-group"> */}
                <div className="input-item">
                    <span className='label'>{f({ id: 'register.person.country' })}</span>
                    <div className="location-wrap">

                        <MSelect

                            disabled={readonly}
                            value={+location}
                            onChange={handleLocationChange}
                            className='username'
                            dataSource={store.location}
                        />

                        <MSelect

                            disabled={readonly}
                            value={+city}
                            onChange={handleCityChange}
                            className='username'
                            style={{ marginLeft: '15px' }}
                            dataSource={store.city} />

                    </div>
                </div>

                <div className="input-item">
                    <span className='label'>{f({ id: 'register.identity.personal.label' })}</span>

                    <FormItem name='personalInden'>
                        <Input readOnly={readonly} className='m-input' placeholder={f({ id: 'register.identity.personal.placeholder' })} style={{ width: '100%' }} />
                    </FormItem>


                </div>

                <div className="input-item">
                    <span className='label'>{f({ id: 'register.identity.company.label' })}</span>
                    <FormItem
                        name='companyBussiness'
                    >
                        <Input readOnly={readonly} className='m-input' placeholder={f({ id: 'register.identity.company.placeholder' })} style={{ width: '100%' }} />
                    </FormItem>



                </div>

                <div className="input-item">
                    <span className='label'>{companyNameLabel}</span>
                    <FormItem name="companyName" style={{ marginBottom: '14px' }}>
                        <Input readOnly={readonly} className='m-input' placeholder={f({ id: 'register.company.name.placeholder' })} style={{ width: '100%' }}></Input>
                    </FormItem>
                </div>

                <div className="input-item form-group">
                    <span className='label'>{companyAddressLabel}</span>
                    <FormItem name='companyAddress' style={{ marginBottom: 0 }} >
                        <Input readOnly={readonly} className='m-input m-textarea' placeholder={f({ id: 'register.company.address.placeholder' })} style={{ width: '100%' }} />
                    </FormItem>

                </div>


            </Form>
            <div className="btn-wrap">
                <Button onClick={handleEdit} size='small'> {readonly ? 'Edit' : 'Save'}</Button>
            </div>
            {/* </div> */}
        </div>
    )
})

export default Personal;