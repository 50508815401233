import React from 'react';
import { useIntl } from 'react-intl';

import { Input } from 'antd'

import './index.scss';
import FormItem from 'antd/lib/form/FormItem';
import store from '../../../store';
import { Title } from '../../../../../components';

const { TextArea } = Input;

const Company: React.FC = () => {
    const { formatMessage: f } = useIntl();

    const companyNameLabel = f({ id: 'register.company.name' });

    const companyAddressLabel = f({ id: 'register.company.address' });

    const handleChange = (key: string, e: any) => {
        store.updateModel({ [key]: e.target.value })

    }

    return (
        <div className="company-wrap">
            {/* <Title style={{ marginBottom: '10px' }}>{f({ id: 'register.company' })}</Title> */}
            <div className="input-item">
                <span className='label'>{companyNameLabel}</span>
                <FormItem style={{ marginBottom: '14px' }} name={companyNameLabel}>
                    <Input onChange={(e) => handleChange( 'companyBussiness',e)} className='m-input' placeholder={f({ id: 'register.company.name.placeholder' })} style={{ width: '100%' }}></Input>
                </FormItem>
            </div>

            <div className="input-item form-group">
                <span className='label'>{companyAddressLabel}</span>
                <FormItem style={{ marginBottom: 0 }} name={companyAddressLabel} rules={[{ required: false }]}>
                    <Input onChange={(e) => handleChange('companyAddress', e)} className='m-input m-textarea' placeholder={f({ id: 'register.company.address.placeholder' })} style={{ width: '100%' }} />
                </FormItem>

            </div>

            {/* <div className="checkbox-wrap">
                <input id="company-checkbox" type='checkbox'></input>
                <label htmlFor="company-checkbox" >{f({ id: 'register.company.checkbox' })}</label>
            </div> */}

        </div>
    )
}

export default Company;