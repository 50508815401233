import { ColumnsType } from "antd/es/table";
import { CaretRightOutlined } from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { Pagination, Table, Modal } from "antd";
import { TableTitle, ButtonIcon } from "./myPortfolio";
import peopleStore, { IExToken } from "../../peopleStore";
import { useObserver } from "mobx-react";
import { checkLogin } from "../../../../utils/auth";
import { useHistory } from "react-router-dom";
import { ExTokenDetailModal, Button } from '../../../../components';
import dayjs from 'dayjs';
import {formatMoney,formatTwoMoney,formatThreeMoney,formatMoneyDecimal,formatTime} from "../../../../utils";
import rootStore from '../../../../store'
// import './Ex.less';

const Ex = () => {
  const history = useHistory()
  const [showTokenDetail, setShowTokenDetail] = useState(false);
  const [exDetailsId, setExDetailsId] = useState(1);
  const [pageSize, setPageSize] = useState(4);
  const [dataList, setDataList] = useState([]) as any;
  const [tokenName, setTokenName] = useState('');
  
  const columns: ColumnsType<IExToken> = [
    {
      title: () => <TableTitle title='Operation' />,
      dataIndex: 'operation',
      key: 'projectNumber',
      width: 160,
      align: 'center',
      render: (item: string, row: IExToken) => {
        return [
          <>
            <ButtonIcon onClick={(e) => clickToTrade(e)} title='View Details' row={row}><CaretRightOutlined /></ButtonIcon>
          </>
        ]
      }
    },
    {
      title: () => <TableTitle title='Token / Name' />,
      dataIndex: 'tokenName',
      key: 'tokenName',
      // width: 105,
      align: 'center',
      render: (item: string, row: IExToken) => {
        return (
          <div className="table-box-items" style={{ cursor:'pointer'}} onClick={()=>{
            // if (!row.tokenId) {
            //   return
            // }
            // rootStore.jiamiId = base.jiaMi(row.tokenId)
            // sessionStorage.setItem("filterId",row.tokenId); 
            // sessionStorage.setItem("filterTokenName",row.tokenName); 
            // history.push(`/Exchange?id=${base.jiaMi(row.tokenId)}&name=${row.tokenName || ''}`)
            
          }} >{item}</div>
        )
      }
    },
    {
      title: () => <TableTitle title='Vessel Name' />,
      dataIndex: 'vesselName',
      key: 'vesselName',
      width: '105',
      align: 'center',
    },
    {
      title: () => <TableTitle title='Current Price / in MDRX per token' />,
      dataIndex: 'currentPrice',
      key: 'currentPrice',
      width: '130',
      align: 'center',
      // render: value => formatMoney(isNaN(Number(value)) ? value : Number(value).toFixed(3))  
      render: value => {
        return (
          <div style={{ textAlign: 'right'}}>{formatMoneyDecimal(value || 0 ,3)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
        );
      }
    },
    {
      title: () => <TableTitle title='Cost Price / in MDRX' />,
      dataIndex: 'costPrice',
      key: 'costPrice',
      width: '120',
      align: 'center',
      // render: value => formatMoney(isNaN(Number(value)) ? value : Number(value).toFixed(3))
      // render: value => formatThreeMoney(Number(value || 0))
      render: value => {
        return (
          <div style={{ textAlign: 'right'}}>{formatThreeMoney(Number(value || 0) )}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</div>
          
        );
      }
    },
    {
      title: () => <TableTitle title='Quantity' />,
      dataIndex: 'quantity',
      key: 'quantity',
      width: '120',
      align: 'center',
      // render: value => formatMoney(isNaN(Number(value)) ? value : Number(value).toFixed(3)) 
      render: value => {
        return (
          <div style={{ textAlign: 'right'}}>{formatMoney(value || 0)}</div> 
        );
      }
    },
    {
      title: <TableTitle title='Value / in MDRX' />,
      dataIndex: 'value',
      key: 'value',
      width: '120',
      align: 'center',
      // render: value => formatMoney(isNaN(Number(value)) ? value : Number(value).toFixed(3))
      render: value => {
        return (
          <div style={{ textAlign: 'right'}}>{formatMoneyDecimal( value || 0 ,3)}</div>
        );
      }
    },
    {
      title: <TableTitle title='Return / in MDRX' />,
      dataIndex: 'returnValue',
      key: 'returnValue',
      width: '120',
      align: 'center',
      // render: value => formatMoney(isNaN(Number(value)) ? value : Number(value).toFixed(3))
      render: value => {
        return (
          <div style={{ textAlign: 'right'}}>{formatMoneyDecimal( value || 0 ,3)}</div>
        );
      }
    },
    {
      title: <TableTitle title='Date Since    ' />,
      dataIndex: 'dateSince',
      key: 'dateSince',
      width: '180',
      align: 'center',
      render: (item: string) => formatTime(item,'date')
    }
  ]
  const [pageIndex, setPageIndex] = useState(1)
  useEffect(() => {
    peopleStore.getOrderHistoryTableList(pageIndex).then(_=>{
      setDataList(peopleStore.exTokenList);
    })
  }, [pageIndex])
  const clickToTrade = (row: IExToken) => {
    if (!checkLogin()) {
      history.replace('/register/login')
      return
    }
    if (!row.tokenId) {
      return
    }
    setTokenName(row.tokenName);
    setExDetailsId(Number(row.vesselId));
    setShowTokenDetail(true);

    // history.push(`/Exchange?id=${row.tokenId}&name=${row.tokenName || ''}`)
  }

  return useObserver(() => (
      <div className="ex-content">
        <div className="token-have">
          <span>Total Tokens Held</span>
          <span><strong>{formatTwoMoney(Number(peopleStore.exTokenTotal || 0))}</strong>MDRX</span>
        </div>
        <div className="sto-table">
          <Table id={'stoId'} pagination={false} columns={columns} className="table-tr-box"
                 dataSource={dataList} scroll={{ x: '1200px' }} />
   

          <Pagination style={{marginTop: '50px'}} className='mdr-pagination'
              showTotal={(total) => `${total} items  in total`}
              onChange={(page) =>  setPageIndex(page)}
              showSizeChanger={false}
              showQuickJumper={false}
              pageSize={10}
              current={pageIndex}
              defaultCurrent={pageIndex}
              total={peopleStore.exTokenListTotal} />
          <Modal
              visible={showTokenDetail}
              footer={<div style={{ textAlign: "center" }}><Button onClick={() => setShowTokenDetail(false)}>OK</Button></div>}
              onCancel={() => setShowTokenDetail(false)}
              destroyOnClose
              className='detail-modal'
              centered
              width={730}
              title={`My EX history of ${tokenName}`}
          >
            <ExTokenDetailModal id={exDetailsId}></ExTokenDetailModal>
          </Modal>
        </div>
      </div>
  ))
}
export default Ex
