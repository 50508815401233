import { observable, computed } from "mobx";
import api from "../../api";
import { sendSms } from "../../utils";
import cookie from 'js-cookie';

import rootStore from '../../store'
import { time } from "console";

class Store {
  @observable curStep: string = "reset";

  steps: Array<string> = [];

  @observable isDone: boolean = false;

  @observable isTempPwd: boolean = false;//是否是动态验证码
  @observable tempPwdAffectLoading = false;//动态验证码防止多次点击控制

  @observable email = '';//邮箱

  @observable password = '';//密码

  @observable smsVerify = false;//是否通过了密码校验
  @observable smsVerifyAffectLoading = false;//短信校验下一步防止多次点击控制

  @observable emailVerify = false;//是否通过了邮箱校验


  @observable dynamicCode = '';

  @observable country = '';

  @observable phoneNumber = '';

  @observable passport = '';
  @observable resetAffectLoading = false;
  @observable emailLoginUserInfo = {};//登录成功的userInfo

  @computed
  get nextBtnText() {
    switch (this.curStep) {
      case "phone":
        return "reset.password";
      case "sms":
        return "next";
      case "email":
        if (!this.isTempPwd) {
          return 'next';
        }
        return "reset.login.email";
      default:
        return "next";
    }
  }

  @computed
  get fullPhoneNumber() {
    return `${this.country}${this.phoneNumber}`
  }

  @computed
  get wrapClassName() {
    const clazzNames = ["reset-card", "row"];
    switch (this.curStep) {
      case "reset":
        clazzNames.push("pb-60");
        break;
      case "sms":
        clazzNames.push("pb-32");
        break;
      default:
        break;
    }
    if (this.isDone) {
      clazzNames.push("done");
    }
    return clazzNames.join(" ");
  }

  setIsDone(isDone: boolean) {
    this.isDone = isDone;
  }

  setCurStep(step: string) {
    this.curStep = step;
  }

  

  handleNextStep = async (data?: any) => {
    if(this.curStep === 'email') {
      //动态验证码登录
      if(this.isTempPwd && this.emailVerify) {
      //   const userInfo = await api.login({
      //     password: this.dynamicCode,
      //     email: this.email,
      //   });
        rootStore.login(this.emailLoginUserInfo)
        window.location.href = '/'
        return;
      }
      if(!this.emailVerify) {
        return;
      }
      if(this.emailVerify) {
        this.toNext();
        return;
      }
    }
    if (this.curStep === "reset") {
      if(this.resetAffectLoading) {
        return;
      }
      this.resetAffectLoading = true;
      try {
        await api.resetPassword({
          passportNumber: this.passport? this.passport: '',
          phoneNumber: this.phoneNumber,
          password: data.password,
        })
        this.resetAffectLoading = false;
        this.setIsDone(true);
      }catch(eror) {
        this.resetAffectLoading = false;
      }
      return;
    }

    if (this.curStep === 'phone') {
      if(this.tempPwdAffectLoading) {
        return;
      }
      this.tempPwdAffectLoading = true;
      // 根据手机号码发送验证码
      try {
        await sendSms(this.fullPhoneNumber);
        this.tempPwdAffectLoading = false;
        this.toNext();
        return;
      }catch(error) {
        this.tempPwdAffectLoading = false;
      }
      
    }

    if (this.curStep === 'sms') {
      if (this.isTempPwd && this.smsVerify) {//动态登录
        if(this.smsVerifyAffectLoading) {
          return;
        }
        this.smsVerifyAffectLoading = true;
        try {
          const email = await api.sendEmailDynamicPassword({
            code: this.country,
            phoneNumber: this.phoneNumber,
          });
          this.smsVerifyAffectLoading = false;
          this.email = email;
          this.toNext();
        }catch(error) {
          this.smsVerifyAffectLoading = false;
        }
        return;
      }

      if (this.smsVerify) {
        if(this.smsVerifyAffectLoading) {
          return;
        }
        this.smsVerifyAffectLoading = true;
        try {
          const email=  await api.sendPoneNumberEmail({
            phoneNumber: this.phoneNumber,
          });
          this.smsVerifyAffectLoading = false;
          this.email = email;
          this.toNext();
        }catch(error) {
          this.smsVerifyAffectLoading = false;
        }
       
      }
    }





  };

  toNext() {
    const index = this.steps.findIndex((item) => item === this.curStep);

    const next = index + 1;
    if (next < this.steps.length) {
      this.setCurStep(this.steps[next]);
    }
  }

  handleBack = () => {
    if (this.curStep === "phone") {
      this.isTempPwd = true;
      this.handleNextStep();
      return;
    }
    const index = this.steps.findIndex((item) => item === this.curStep);

    const next = index - 1;
    if (next < this.steps.length) {
      this.setCurStep(this.steps[next]);
    }
  };
}

export default new Store();
