import React from 'react';
import { Button } from '..';

import './index.scss';

interface IFloatCard {
    title: string;
    desc: string;
    btn?: {
        text: string;
        onClick: Function;
    };
    children?: React.ReactNode;
}

const FloatCard: React.FC<IFloatCard> = (props) => {
    const { children, title, desc, btn } = props;
    return (
        <div className='float-card'>
            {
                children ? children : (
                    <>  <div className="left">
                        <p className="title">{title}</p>
                        <p className="desc">{desc}</p>
                    </div>
                        <Button size="small" onClick={btn?.onClick}> 
                        {btn?.text}
                        <img alt='icon' src={require('../../assets/image/arrow.png')}></img>
                    </Button>
                    </>
                )
            }
        </div>

    );
}

export default FloatCard;