import React, { useEffect, useState } from 'react';

import { observer, Provider, Observer, useObserver } from 'mobx-react';

import { IntlProvider } from 'react-intl';
import { ConfigProvider } from 'antd';

import NavBar from './components/NavBar';

import Footer from './components/Footer';



import store from './store'


import { RouteComponentProps, useParams, useHistory } from 'react-router-dom';

import './App.css';
import { PinCode } from './components';
import { updateUserInfo, getUserInfo } from './utils';
import { useEventListener,useInterval } from './hooks';

interface Props extends RouteComponentProps<any, any> {
}

// function setRem() {
//   const baseSize = 192;
//   // 实际设备页面宽度和设计稿的比值
//   let scale = document.documentElement.clientWidth / 1920;
//   scale = scale < 0.75 ? 0.75 : scale;
//   // 计算实际的rem值并赋予给html的font-size
//   document.documentElement.style.fontSize = (baseSize * scale) + 'px';
// }

const App: React.FC<Props> = observer(props => {
  const [hasPin, setHasPin] = useState(false);


  useEffect(() => {
    const pin = sessionStorage.getItem('pin');

    // setHasPin(store.hasPin || pin === '1');
    setHasPin(store.hasPin)
    updateUserInfo()
  }, [store.hasPin]);

  useEffect(() => {
    updateUserInfo();
    store.userInfoListener();
  }, [])



  return (
    <Provider rootStore={store}>
      <IntlProvider locale={store.appLocal.locale} messages={store.appLocal.messages}>
        <ConfigProvider locale={store.appLocal.antd}>
          <div className={`App ${hasPin ? 'pin' : ''}`}>
            <NavBar />
            <section className='content-wrap'>
              {props.children}
            </section>
            <Footer />
            {
              hasPin ? <PinCode /> : null
            }
          </div>
        </ConfigProvider>
      </IntlProvider>
    </Provider>
  )
});

export default App;
