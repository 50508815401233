import {message} from "antd";
import rootStore from '../store/index'
// 查询是否登录
export const checkLogin = () => {
    if (!rootStore.hasLogin) {
        message.error('Please login first to proceed with more services >>');
        return false
    }
    return true
}

