import React, { useEffect } from 'react';

import { useIntl } from 'react-intl';
import { Button, Title, Tab } from '../../components';

import { useObserver } from 'mobx-react';
import store from './store';

import Phone from './component/Phone';
import SMS from './component/SMS';


import './index.scss';
import Email from './component/Email';
import Password from './component/Password';
import Done from './component/Done';
import { useUrlQuery } from '../../hooks';
import Form, { useForm } from 'antd/lib/form/Form';

const stepMap = {
    sms: 1,
    phone: 0,
    email: 2,
    reset: 3,
}

const stepTitleMap = {
    sms: 'A 6-digit code has been sent to your phone',
    phone: 'Enter Phone number',
    email: 'Get Email Verification Now',
    reset: 'Reset your password',
}

const renderFormItem = (curStep: string) => {

    switch (curStep) {
        case 'email':
            return <Email />
        case 'phone':
            return <Phone />
        case 'sms':
            return <SMS></SMS>;
        case 'reset':
            return <Password></Password>
        default:
            return null;
    }
}

const ResetPwd: React.FC = () => {
    const { formatMessage: f } = useIntl();
    const step = useUrlQuery('step');

    const [form] = useForm();
    useEffect(() => {
        return () => {
            store.smsVerify = false;//电话号码验证状态置为空
            store.isTempPwd = false;//是否是动态密码置为false
            store.smsVerifyAffectLoading  = false;//短信验证码多次校验控制
            store.resetAffectLoading = false;//重置密码提交校验
            store.tempPwdAffectLoading = false;//电话验证码多次点击控制置空
            store.emailLoginUserInfo = {};
        }
    },[])
    useEffect(() => {
        store.setCurStep('phone')
        store.setIsDone(false);
    }, [step]);




    const resetStep = JSON.parse(f({ id: 'reset.steps' })) as [{ key: string, value: string }];

    store.steps = resetStep.map(item => item.key);

    const isFirst = store.curStep !== 'phone';

    const handleNext = () => {
        form.validateFields().then(() => {
            store.handleNextStep(form.getFieldsValue())
        });
    }

    const handleBack = () => {
        if(store.curStep === 'phone') {
            form.validateFields().then(() => {
                store.handleBack();
            })
        } else {
            store.handleBack();
        }
    }

    return useObserver(() => (
        <section className="common-wrap">
            <p className='page-title'>{f({ id: 'register.title' })}</p>
            <p className='page-title'>{f({ id: 'register.subTitle' })}</p>

            <div className={store.wrapClassName}>
                {
                    store.isDone ? <Done></Done> : (
                        <>
                            <div className="step-wrap">
                                {/* {
                                    resetStep.map(item => (
                                        <div className={['step', store.curStep === item.key ? 'current' : ''].join(' ')}>{item.value}</div>
                                    ))
                                } */}
                                <img src={require('../../assets/image/register.jpg')} alt="img" />
                            </div>
                            <div className="form-wrap">
                                {/* <p className='card-title'>{f({ id: 'reset.forget.pwd' })}</p> */}

                                <div className="reset-nav">
                                    <p className='start-now'>Password Retrieval</p>

                                    <Title
                                        // @ts-ignore
                                        style={{ textAlign: 'center' }}>{stepTitleMap[store.curStep]}</Title>

                                    <Tab disabled
                                        // @ts-ignore
                                        active={stepMap[store.curStep]} simple data={['Enter Phone', 'Phone verification', 'Mail validation', 'Reset Password']}></Tab>
                                </div>

                                <Form layout='vertical' hideRequiredMark form={form}>
                                    {renderFormItem(store.curStep)}
                                </Form>


                                <div className={['btn-wrap'].join(' ')}>
                                    {
                                        ['sms', 'phone'].indexOf(store.curStep) !== -1 && (
                                            <Button
                                                type='ghost'
                                                className={`dynamic-btn ${!isFirst ? 'narrow' : ''}`}
                                                size='middle'
                                                arrowLeft={isFirst}
                                                onClick={handleBack}
                                            >
                                                {f({ id: store.curStep === 'phone' ? 'reset.dynamic' : 'back' })}
                                            </Button>
                                        )
                                    }

                                    <Button
                                        size='middle'
                                        arrowRight={isFirst}
                                        onClick={handleNext}
                                    >
                                        {f({ id: store.nextBtnText })}
                                    </Button>
                                </div>
                            </div>
                        </>
                    )
                }
            </div>
        </section >
    ))
}

export default ResetPwd;