import { observable, action, runInAction, computed } from "mobx";
import { ILocation, ICity, ICodeCheck, ISignUpReq } from "../../api/type";
import api from "../../api";
import { FormInstance } from "antd/lib/form";
import { message,Modal } from "antd";
interface Idown {
  label: string;
  value: string;
}

class Store {
  @observable active = 1;

  @observable curStep = "email";

  registerStep: Array<string> = [];

  @observable isSms: boolean = false;

  @observable isRegisterDone = false;

  @observable location: Array<Idown> = [];
  @observable city: Array<Idown> = [];

  currentForm: FormInstance = {} as FormInstance;

  @observable smsVerify: boolean = false;

  @observable readTrimsFlag:boolean = false;

  @observable model = {
    city: "",
    companyAddress: "",
    companyName: "",
    email: "",
    givenName: "",
    location: "",
    password: "",
    phoneCountry: "+86",
    phoneNumber: "",
    surname: "",
    nationality: "",
    passprotExpiration: "", //护照过期时间
    registeredResource: 1
  };
  @observable affectLoadingValue = {
    phone: false,
    personal: false

  }
  @computed
  get phone() {
    const { phoneCountry, phoneNumber } = this.model;
    return `${phoneCountry}${phoneNumber}`;
  }

  @action.bound
  updateModel = (params: any) => {
    this.model = {
      ...this.model,
      ...params,
    };
  };

  checkExist = async () => {
    const data = await api.selectExiestUser({
      phoneCountry: this.model.phoneCountry,
      phoneNumber: this.model.phoneNumber,
    });
    if (data === 0) return true;
    message.error('This mobile number has been used')
    return false;
  }

  updateLocation = (location: string) => {
    this.updateModel({ location });
    this.getCityByLocation(location);
  };

  sendSms = () => {
    const { phoneCountry, phoneNumber } = this.model;
    if (!phoneCountry || !phoneNumber) return;
    api.sendSms({ phoneNumber: this.phone });
  };

  checkSms = (params: ICodeCheck) => {
    return api.checkSms(params);
  };

  getAllLocation = async () => {
    try {
      const location = await api.getLocation();
      runInAction(() => {
        this.location = location.map((item) => ({
          value: item.id,
          label: item.location,
        }));
      });
    } catch (error) { }
  };

  getCityByLocation = async (location: string) => {
    if (!location) return;
    try {
      const cityList = await api.getCityById(location);
      runInAction(() => {
        this.city = cityList.map((item) => ({
          value: item.id,
          label: item.city,
        }));
      });
      this.updateModel({ city: this.city[0].value });
    } catch (error) { }
  };

  async initLocation() {
    await this.getAllLocation();
  }

  signup = async () => {
    if(this.affectLoadingValue.personal) {
      return;
    }
    this.affectLoadingValue.personal = true;
    try {
      await api.signup(this.model as ISignUpReq);
      this.isRegisterDone = true;
      this.affectLoadingValue.personal = false;
    } catch (error) {
      this.affectLoadingValue.personal = false;
    }
  };

  @action.bound
  setActive(active: number) {
    localStorage.setItem("isRegisterDone", "1");
    this.active = active;
  }

  @action.bound
  setCurStep(curStep: string) {
    this.curStep = curStep;
  }
  @action.bound
  setReadTrimFalg(flag:boolean) {
    this.readTrimsFlag = flag;
  }

  handleNextStep = async () => {
    // 如果是邮件界面并且没有同意条款提示信息
    if(this.curStep === 'email' && !this.readTrimsFlag) {
      message.warning('You must agree to User Terms, Disclaimer, and Privacy Policy to continue with register.');
      return;
    }
    if (this.curStep === "phone" && !this.isSms) {//电话号码界面，并且不是验证码界面，校验电话号码是否使用
      if(this.affectLoadingValue.phone) {
        return;
      }
      this.affectLoadingValue.phone = true;
      const data = await this.checkExist();
      this.affectLoadingValue.phone = false;
      if (!data) return;
      const { phoneCountry, phoneNumber } = this.model;
      if (!phoneCountry || !phoneNumber) return;
      try {
        await this.phoneModalSure(this.phone)
        this.isSms = true;//先进进入到验证码界面再发送验证码
        this.sendSms();
        return;
      }catch(error) {
        console.log('取消')
        return;
      }
    }

    if (this.curStep === "phone" && this.isSms && !this.smsVerify) {//是验证码界面，验证码不正确
      return;
    }

    if (this.curStep === "phone" && this.isSms && this.smsVerify) {
      this.isSms = false;
    }

    if (this.curStep === "personal") {
      await this.signup();
      return;
    }

    const index = this.registerStep.findIndex((item) => item === this.curStep);

    const next = index + 1;
    if (next < this.registerStep.length) {
      this.setCurStep(this.registerStep[next]);
    }
  };

  handleBack = () => {
    const index = this.registerStep.findIndex((item) => item === this.curStep);

    if (this.curStep === "phone" && this.isSms) {
      this.isSms = false;
      return;
    }

    const next = index - 1;
    if (next < this.registerStep.length) {
      this.setCurStep(this.registerStep[next]);
    }
  };
  phoneModalSure = (phone:string):Promise<Boolean> => {
    return new Promise((resolve,reject) => {
      Modal.confirm({
        content:`Verification code will be sent to '${phone}'. Click 'Next' to proceed.`,
        onCancel: () => {
          reject(false)
        },
        onOk:() => {
          resolve(true)
        },
        centered: true,
        okText: "Sure",
        cancelText: "Cancel"
      })
    })
  }
}

export default new Store();
