import React from 'react'
import { useIntl } from 'react-intl';
import { CheckOutlined } from '@ant-design/icons';
import { Button } from '../../../../components';

import './index.scss'
import { useHistory } from 'react-router-dom';

const Done: React.FC = () => {
    const { formatMessage: f } = useIntl();
    const history = useHistory();
    return (
        <div className='reset-done'>
            <p className="card-title">{f({ id: 'reset.forget.pwd' })}</p>

            <CheckOutlined className='success-icon' />

            <span className='tips'>
                {f({ id: 'register.done.tips' })}
            </span>

            <span className='subtips'>
                {f({ id: 'reset.pwd.success' })}
            </span>

            <Button
                onClick={() => {
                    history.push('/register/login')
                }}
            >
                {f({ id: 'register.login' })}
                <img alt='icon' src={require('../../../../assets/image/arrow.png')}></img>
            </Button>
        </div>
    )
}

export default Done;