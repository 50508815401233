import { request } from "../utils/request";
import {
  ILoginReq,
  ICity,
  ILocation,
  ICodeCheck,
  IEmailCodeCheck,
  ISignUpReq,
  ILoginRes,
  IPage,
  ICountryData,
  IinvestmentPage,
  IHomePage
} from "./type";
import { data } from "../Pages/Offer/Index/mock";
import { url } from "inspector";
interface selectData {
  value: string;
  title: string;
}
export default {
  login: (params: ILoginReq) => request.post<ILoginRes>("/api/website/login", params),
  signup: (params: ISignUpReq) =>
    request.post("/api/website/user/insert", params),
  findPassword: (params: ICodeCheck) =>
    request.post("/api/website/login/findPassword", params),
  getCityById: (id: string) =>
    request.post<Array<ICity>>("/api/website/tool/getCity", { id }),
  getLocation: () =>
    request.post<Array<ILocation>>("/api/website/tool/getCountry"),
  sendSms: (params: ICodeCheck) =>
    request.post("/api/website/tool/sendSmsVerification",
      params,
    ),
  sendSmsVerificationWithUser: (params: ICodeCheck) =>
    request.post("/api/website/tool/sendSmsVerificationWithUser",
    params,
  ),
  sendEmail: (email: string) =>
    request.post("/api/website/tool/sendEmailVerification", { email }),
  sendEmailPwd: (phoneNumber: string) =>
    request.post("/api/website/tool/sendEmailDynamicPassword", { phoneNumber }),
  checkSms: (pararms: ICodeCheck) =>
    request.post("/api/website/tool/checkSmsVerification", pararms),
  checkSmsUser: (pararms: ICodeCheck) => 
  request.post("/api/website/tool/checkSmsVerificationWithUser", pararms),
  checkEmail: (params: IEmailCodeCheck) =>
    request.post("/api/website/tool/checkSendEmailVerification", params),

  fetchOptions: () => request.post('/api/options/getSystemOptionsTree'),

  
  fetchProjectOptions: (params: any) => request.post('/api/options/getSystemOptionsTree',params),

  findAllSto: (params: any) => request.post('/api/stosetup/findAllStoPropertyAndShow', params),
  findOneSto: (params: any) => request.post('/api/stosetup/findShowStoProperty', params),
  findStoPage: () => request.post('/api/stosetup/findStoCategoryPage'),

  fetchCompanyOptions: () => request.post('/api/website/company/getCompanyOptions'),

  findCompanyByPage: (params: IPage) => request.post('/api/website/company/findPage', params),

  saveStoCompanyInfo: (params: any) => request.post('/api/stoInfo/addOrUpdateStoCompany', params),
  findStoCompnayById: (id: string) => request.post('/api/stoInfo/findStoCompanyById', { id }),

  getStoInfoMainById: (id: string) => request.post('/api/stoInfo/getStoInfoMainById', { id }),
  updateInfoMain: (params: any) => request.post('/api/stoInfo/updateStoInfoMain', params),
  addStoInfoAll: (params?: any) => request.post('/api/stoInfo/addStoInfoAll', params),
  findStoInfoAllById: (stoId: string, tabNum?: number) => request.post('/api/stoInfo/findStoInfoAllById', { stoId, tabNum }),
  updateStoInfoAll: (params?: any) => request.post('/api/stoInfo/updateStoInfoAll', params),
  updateStoInfoStatus: (params?: any) => request.post('/api/stoInfo/updateStoInfoStatus',params),//fundingInfo表单，增加单独请求
  submitReviewing: (id: string) => request.post('/api/stoStatus/submitReviewing', { id }),
  getFileUrl: (objectName: string) => request.post('/api/website/getFileUrl', { objectName }),
  getStoList: (params: IPage) => request.post('/api/stoInfo/myInfoMainPage', params),

  reviewingMdrTodos: (id: string) => request.post('/api/stoStatus/reviewingMdrTodos', { relationId: id }),

  agreementSigningMdrTodo: (id: string) => request.post("/api/stoStatus/agreementSigningMdrTodo", { relationId: id }),

  findStoApprovalById: (id: string) => request.post('/api/stoInfo/findStoApprovalById', { id }),

  // approvingMdrTodo: (id: string) => request.post('/api/stoStatus/approvingMdrTodo', { relationId: id }),
  // 同意阅读条款，下一步请求获取到后台审核状态
  approvingMdrTodo: (id: string) => request.post('/api/stoStatus/agreementSigningMdrTodo', { relationId: id }),
  findStoFundraisingById: (id: string) => request.post('/api/stoInfo/findStoFundraisingById', { id }),

  addStoInfoMain: (data: any) => request.post('/api/stoInfo/addStoInfoMain', data),

  insertCompany: (data: any) => request.post('/api/website/company/insert', data),
  findCompanyById: (id: string) => request.post('/api/website/company/findById', { id }),
  updateCompany: (data: any) => request.post('/api/website/company/update', data),

  fetchOfferList: (data: IPage) => request.post('/api/stoInfo/findStoInfoPage', data),

  addStoFundraising: (data: any) => request.post('/api/stoInfo/addStoFundraising', data),

  updateStoFundraising: (data: any) => request.post('/api/stoInfo/updateStoFundraising', data),
    // My Portfolio 详情
    queryMyTokenDetail: (data: any) => request.post('/api/token/queryMyTokenDetail', data),
      // 查询个人收藏token数据
  queryMyTokenList: (data: any) => request.post('/api/token/queryMyTokenList', data),
    // 查询二期持有token
    queryExTokenHave: (data:any) => request.post('/api/token/queryMyToken', data),
      // 删除个人token列表
  removeMyListItem: (data:any) => request.post('/api/token/removeTokenList', data),
    // 查询一期用户持有token
    queryPeopleHoldTokenSTO: (data:any) => request.post('/api/website/moneyBag/getHoldTokenList3', data),

  deleteStoById: (id: string) => request.post('/api/stoInfo/deleteStoById', { id }),

  investProject: (data: any) => request.post('/api/website/moneyBag/investProject', data),

  selectMyInvestment: (data: IinvestmentPage) => request.post('/api/website/moneyBag/selectMyInvestment', data),

  findUserById: (data: any) => request.post('/api/website/user/findById', data),

  fetchNewsList: (data: IPage) => request.post('/api/website/latestNews/selectPageList', data),
  selectExiestUser: (data: any) => request.post('/api/website/user/selectExiestUser', data),
  sendEmailDynamicPassword: (data: any) => request.post('/api/website/tool/sendEmailDynamicPassword', data),
  findNewsById: (id: any) => request.post('/api/website/latestNews/selectById', { id }),

  agreementSigning: (id: string) => request.post('/api/stoStatus/agreementSigning', { id }),

  userUpdate: (data: any) => request.post('/api/website/user/update', data),



  fundraisingInfo: (id: string) => request.post('/api/fundraising/fundraisingInfo', { stoId: id }),

  fundraisingTransfer: (id: string) => request.post('/api/fundraising/fundraisingTransfer', { stoId: id }),

  stoInvestorList: (id: string) => request.get('/api/website/moneyBag/stoInvestorList', { id }),

  investorCert: (data: any) => request.post('/api/website/user/investorCert', data),

  moneyPageList: (data: IPage) => request.post('/api/website/moneyBag/moneyPageList', data),

  changePwd: (data: any) => request.post('/api/website/user/changePassword', data),

  options: () => request.post('/api/website/parameterSetting/select'),

  deposit: (data: any) => request.post('/api/website/moneyBag/deposit', data),

  withdraw: (data: any) => request.post('/api/website/moneyBag/withdraw', data),

  queryhelp: (data: IPage) => request.post('/api/website/helpCenter/selectByConditionList', data),

  queryHelpById: (id: string) => request.post('/api/website/helpCenter/selectById', { id, }),

  fetchInvestMessage: (data: IPage) => request.post('/api/website/message/findInvestorPage', data),

  fetchShipMessage: (data: IPage) => request.post('/api/website/message/findShipperPage', data),

  findFeaturedOfferSto: () => request.post('/api/featuredOffer/findFeaturedOfferSto'),

  checkLoginSms: (data: any) => request.post('/api/website/login/checkLoginSms', data),

  sendPoneNumberEmail: (data: any) => request.post('/api/website/tool/sendPoneNumberEmail', data),
  // 张学平 跳转投资页面
  moneyBaginvestor: (data: any) => request.post('/api/website/moneyBag/investProject', data),
  deleteLoginSms: (data: any) => request.post('/api/website/login/deleteLoginSms', data),

  getUrlCsvDatas: (url: string) => request.post('/api/website/getUrlCsvDatas', { objectName: url }),

  insertQuestion: (question: string) => request.post('/api/website/consultation/insert', { question }),

  checkMoneyEnough: (data: { mdrx: number, relationId: string }) => request.post('/api/website/moneyBag/checkMoneyEnough', { mdrx: data.mdrx, relationId: data.relationId }, { showError: false }),

  investorVerification: () => request.post('/api/website/user/investorVerification', {}, { showError: false }),

  resetPassword: (data: any) => request.post('/api/website/user/resetPassword', data),

  getHoldTokenList: () => request.post('/api/website/moneyBag/getHoldTokenList'),

  Nationality: (investment?:string) => request.post<Array<ICountryData>>('/api/website/tool/countries',{investment}),
  //校验项目是否结束
  checkSTOEnd: (stoid: string) => request.post<{ code: string, msg: string, data: [], success: boolean }>('/api/website/moneyBag/checkSTOEnd', { stoid }, { showError: false }),
  //查询还剩多少token售完
  checkResidueToken: (stoid: string) => request.post('/api/website/moneyBag/checkResidueToken', { stoid }),
  //校验 STO token是否售空
  checkSTOToken: (stoid: string) => request.post('/api/website/moneyBag/checkSTOToken', { stoid }),

  getShipperOfferList: (stoId: string) => request.post('/api/website/moneyBag/getShipperOfferList', { stoId }),
  // 校验是否完成充值
  checkOnlineRecharge: () => request.post('/api/website/moneyBag/checkOnlineRecharge'),

  checkInvestorPaySTO: (stoId: string) => request.post('/api/website/moneyBag/checkInvestorPaySTO', { stoId }),

  // 验证pinCode
  checkPinCode: (data:{pinCode:string,id:string}) => request.post('/api/stoInfo/checkPinCode',data),
  // 找回pinCode
  findPinCode: (id:string) => request.post('/api/stoInfo/findPinCode',{id},{allResponse: false}),
  // stoAgree是否同意
  updateAgreementSignStatus: (data:{stoId:string,status:number}) => request.post('/api/fundStatus/updateAgreementSignStatus',data),
  // 校验邮箱验证码快速登录
  checkDynamicPassword: (data: {Email: string,code: string}) => request.post('/api/website/tool/checkDynamicPassword',data),
  // 获取首页的相关文字信息
  findHomePage:()=> request.post<IHomePage>('/api/website/setup/findhomepage'),
  // 一期转售token
  insertTokenApply: (data:any) => request.post('/api/token/insertTokenApply', data),
  // 查询认证信息
  findInvestorAuthById: (data:any) => request.post('/api/website/user/findInvestorAuthById', data)
};

