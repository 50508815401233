import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, message, Button as Abt } from 'antd';
import Title from '../../../Title';
import Button from '../../../Button'

import './index.scss'
import { Link, useHistory } from 'react-router-dom';

import cookie from 'js-cookie';

import { sendEmail, sendSms, checkSms } from '../../../../utils/index';
import { useForm } from 'antd/lib/form/util';
import { EMAIL_REG } from '../../../../constants';
import api from '../../../../api';
import { useStores } from '../../../../hooks';
import { observer } from 'mobx-react';

const count =120;
let timer:any;
interface props {
    visibleChange: boolean
}

const LoginModal: React.FC<props> = observer((props) => {
    const [form] = useForm();
    const [disabled, setDisabled] = useState(false);
    const [countBegin,setCountBegin] = useState(false);
    const { rootStore } = useStores();
    const history = useHistory();

    const [left, setleft] = useState(count);

    useEffect(() => {
        form.resetFields()
        setDisabled(false)
        setleft(count);
        clearInterval(timer);
    }, [props.visibleChange]);

    useEffect(() => {
        if (countBegin) {
            calcleft();
        }
    }, [countBegin])
    useEffect(() => {
        return() =>{
            setDisabled(false);
            clearInterval(timer); 
            setleft(count)
            setCountBegin(false);
        }
    },[])

    const calcleft = () => {
        timer = setInterval(() => {
            setleft(left => {
                if (left === 0) {
                    clearInterval(timer);
                    setCountBegin(false);
                    setDisabled(false);
                    return count;
                } else {
                    return left - 1;
                }
            })
        }, 1000)
    }

    const hide = () => {
        setleft(count);
        //@ts-ignore
        document.querySelector('.login')?.click();
    }

    const handleLogin = async () => {
        const loginData = form.getFieldsValue() as any;
        
        if(!loginData.email) {
            Modal.error({
                className:  "passwordError",
                centered: true,
                content: 'Please enter email address',
                zIndex: 1050
            })
            return
        }
        if(!loginData.password) {
            Modal.error({
                className:  "passwordError",
                centered: true,
                content: 'Please enter password',
                zIndex: 1050
            })
            return
        }
        if(!loginData.checkcode) {
            Modal.error({
                className:  "passwordError",
                centered: true,
                content: 'Please enter SMS verification code',
                zIndex: 1050
            })
            return
        }

        const userInfo = await api.checkLoginSms({
            email: loginData.email,
            code: loginData.checkcode,
            password: loginData.password
        });
        console.log(userInfo)
        rootStore.login(userInfo)
        hide();
        history.push('/')


    }

    const sendCheckCode = async () => {
        // 如果禁用就不触发事件
        if(disabled) {
            return;
        }
        const loginData = form.getFieldsValue() as any;
        // 如果邮箱和密码为空就不发送请求
        if(!loginData.email) {
            message.warning('Please enter your email account!');
            return;
        }
        if(!loginData.password) {
            message.warning('Please enter your password!');
            return;
        }
        setDisabled(true);
        try {
            await api.login({
                email: loginData.email,
                password: loginData.password,
            });
            setCountBegin(true);
        }catch(error) {
            // 密码不正确，取消禁用
            setDisabled(false);
        }
        
        
    }

    return <div
        className='login-wrap'
        style={{ right: 0 }}
    >
        <Title>Log in</Title>
        <Form
            form={form}
            layout='vertical'
        >
            <Form.Item name="email" label='Email address'>
                <Input className='m-input'></Input>
            </Form.Item>

            <Form.Item name='password' label='Password'>
                <Input type='password' className='m-input'></Input>
            </Form.Item>
            <div style = {{position: "relative"}}>
                <Form.Item name='checkcode' label='Click SEND for code in your mobile' style={{ width: '100%' }}>
                    <Input className='m-input sms-input' style = {{width: "170px"}}></Input>
                </Form.Item>
                <Button onClick={sendCheckCode} type='ghost'  className={`send ${disabled ? 'disabled' : ''}`} size='middle' style = {{fontSize: "14px"}}>
                    {
                        countBegin ? `${left}s` : 'SEND'
                    }
                </Button>
            </div>
        </Form>
        <div className="help-link">
            <Abt
                onClick={() => {
                    hide();
                    history.push('/resetpwd')
                }} type='link'>Forgot Password</Abt>
            <Abt onClick={() => {
                hide();
                history.push('/register/signin')
            }} type='link'>Register</Abt>
        </div>
        <div className="btn-wrap">
            <Button onClick={handleLogin} className='login-btn'>Log in</Button>
        </div>

    </div>
})

export default LoginModal;