import React, { useEffect, useState } from "react";
import './Summary.less'
import {useObserver} from "mobx-react";
import peopleStore from "../../peopleStore";
import walletStore from "../myWallet/walletStore";
import { values } from "mobx";
import {formatMoney, formatTwoMoney} from "../../../../utils";
const Summary = () => {
    const [total, setTotal] = useState(0)
    const [exTotal, setExTotal]  = useState(0)
    const [stoTotal, setStoTotal] = useState(0)
    useEffect(() => {
        walletStore.fetchList().then()
        Promise.all([walletStore.fetchList(),
            walletStore.fetchTwoList(),
            peopleStore.getPeopleHoldTokenSTO(),
            peopleStore.getOrderHistoryTableList()
        ]).then(() => {
            if(!isNaN(Number(walletStore.exTotalMoney))
                && !isNaN(Number(walletStore.stoTotalMoney))
                && !isNaN(Number(peopleStore.STOTokenSum))
                && !isNaN(Number(peopleStore.exTokenTotal))
            ) {
                setTotal((+walletStore.exTotalMoney)
                    + (+walletStore.stoTotalMoney)
                    + (+peopleStore.STOTokenSum)
                    + (+peopleStore.exTokenTotal)
                )
            }
            if(!isNaN(Number(walletStore.exTotalMoney))
                && !isNaN(Number(peopleStore.exTokenTotal))
            ) {
                setExTotal((+walletStore.exTotalMoney)
                    + (+peopleStore.exTokenTotal)
                )
            }
            if(!isNaN(Number(walletStore.stoTotalMoney))
                && !isNaN(Number(peopleStore.STOTokenSum))
            ) {
                setStoTotal(
                    + (+walletStore.stoTotalMoney)
                    + (+peopleStore.STOTokenSum)
                )
            }
        })
    }, [])
    return useObserver(() =>
        (<div className="summary-content">
                <div className="summary-header">
                    <p>Account Summary</p>
                    <p>Account summary is a quick overview of your assets with MDR.</p>
                </div>
                <div className="summary-header-top">
                    <span>Total in account </span>
                    <span>
                <span>{formatTwoMoney(total)} &nbsp;</span>MDRX</span>
                </div>

                <div className="summary-content-box">
                    <div className="summary-content-box-left"></div>
                    <div className="summary-content-box-left-2"></div>
                    <div className="summary-content-box-right">
                        <div className="sub">
                            <span>Subtotal of EX</span>
                            <span><span>{formatTwoMoney(exTotal)} &nbsp;</span>MDRX</span>
                        </div>

                        <p>Site: <a href="https://www.marinedigitalrepublic.com" target="_blank"  >https://www.marinedigitalrepublic.com</a></p>

                        <div className="summary-content-box-right-table">
                            <p>Sub-Account</p>
                            <p>Net Value</p>
                        </div>

                        <div className="summary-content-box-right-table2">
                            <p>EX Wallet</p>
                            <p>{formatTwoMoney(walletStore.exTotalMoney)} MDRX</p>
                        </div>
                        <div className="summary-content-box-right-table3">
                            <p>EX Portfolio</p>
                            <p>{formatTwoMoney(peopleStore.exTokenTotal)} MDRX</p>
                        </div>

                        <div className="line"></div>
                        <div className="sub">
                            <span>Subtotal of STO</span>
                            <span><span>{formatTwoMoney(stoTotal)} &nbsp;</span>MDRX</span>
                        </div>

                        <p>Site: <a href="https://www.shipdigitalbank.com/" target="_blank"  >https://www.shipdigitalbank.com/</a></p>

                        <div className="summary-content-box-right-table">
                            <p>Sub-Account</p>
                            <p>Net Value</p>
                        </div>

                        <div className="summary-content-box-right-table2">
                            <p>STO Wallet</p>
                            <p>{formatTwoMoney(walletStore.stoTotalMoney)} MDRX</p>
                        </div>
                        <div className="summary-content-box-right-table3">
                            <p>STO Portfolio</p>
                            <p>{formatTwoMoney(peopleStore.STOTokenSum)} MDRX</p>
                        </div>

                    </div>
                </div>
            </div>
        ))
}

export default Summary
