import React, { useEffect } from 'react';
import { observer } from 'mobx-react';
import {Modal} from 'antd';
import store from '../store';
import { Button, Title, Tab } from '../../../components';
import { useIntl } from 'react-intl';
import Email from './component/Email';
import SMS from './component/SMS';
import Phone from './component/Phone';
import Personal from './component/Personal';
import Company from './component/Company';
import Identity from './component/Identity';
import Done from './component/Done';

import Password from './component/Password';
import { Form } from 'antd';

const stepMap: { [index: string]: number } = {
    email: 0,
    phone: 1,
    personal: 2,
}

const renderFormItem = (curStep: string) => {
    // curStep = 'personal';
    switch (curStep) {
        case 'email':
            return <Email />
        case 'phone':
            return store.isSms ? <SMS /> : <Phone />
        case 'sms':
            return <SMS></SMS>;
        case 'personal':
            return <Personal></Personal>;
        case 'company':
            return <Company></Company>;
        case 'accout':
            return <Password></Password>;
        case 'identity':
            return <Identity></Identity>
        default:
            return null;
    }
}

const SignUp: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();

    const [form] = Form.useForm();

    const registerStep = JSON.parse(f({ id: 'register.steps' })) as [{ key: string, value: string }];
    store.registerStep = registerStep.map(item => item.key);

    useEffect(() => {
        store.setCurStep('email')
        // store.setCurStep(('identity'))
        store.isRegisterDone = false;
        
        return () => {
            store.readTrimsFlag = false;//同意条款置为false
            store.smsVerify = false;//验证码不通过
            store.isSms = false;
            store.isRegisterDone = false;
        }
    }, [])

    const handNext = () => {
        form.validateFields().then(store.handleNextStep)
    }

    if (store.isRegisterDone) {
        return <Done></Done>
    }

    const imgName = store.curStep === 'personal' ? 'register-long.jpg' : 'register.jpg';

    return (<>
        <div className={`step-wrap ${store.curStep}`}>
            <img alt='register' src={require(`../../../assets/image/${imgName}`)}></img>
        </div>
        <Form form={form} className="form-wrap sign-wrap ">
            <div className="register-login-nav signin">
                <p className='signin-label'>Join MDR</p>
                <Title style={{ textAlign: 'center' }}>Create your account</Title>
                <Tab disabled active={stepMap[store.curStep]} simple data={['Email', 'Phone verification', 'Personal detail']}></Tab>
                {/*  */}
            </div>
            {renderFormItem(store.curStep)}
            <div className="btn-wrap">
                {
                    store.curStep !== 'email' ? (
                        <Button
                            type="ghost"
                            onClick={store.handleBack}
                        >
                            <img className='arrow-left' src={require('../../../assets/image/arrow.png')} alt="arrow" />
                            {f({ id: 'back' })}
                        </Button>
                    ) : null
                }

                <Button
                    size='middle'
                    className='next-btn'
                    onClick={handNext}
                >
                    {f({ id: store.curStep === 'identity' ? 'done' : 'next' })}
                    <img src={require('../../../assets/image/arrow.png')} alt="arrow" />
                </Button>
            </div>

        </Form>
    </>)
});

export default SignUp;