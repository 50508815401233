import React, { useState, useEffect } from 'react';

import './index.scss';


interface ITab {
    data: Array<string>;
    active?: number;
    simple?: boolean;
    onChange?: Function;
    disabled?: boolean;
}

const Tab: React.FC<ITab> = (props) => {
    const { data, simple = false } = props;
    const [active, setActive] = useState(props.active);

    useEffect(() => {
        setActive(props.active);
    }, [props.active])

    const handleChange = (index: number) => {
        if (props.disabled) return;
        setActive(index)
        props.onChange && props.onChange(index);
    }
    return (
        <div className="mdr-tab">
            {
                data.map((item, index) => {
                    const spliter =
                        index !== data.length - 1 ? (
                            <div className='spliter' />) : null;
                    return <>
                        <div
                            key={index}
                            onClick={() => handleChange(index)}
                            className={[
                                'tab-item',
                                simple ? 'simple' : '',
                                (!simple && index === 0) ? 'tab-first' : '',
                                active === index ? 'active' : ''
                            ].join(' ')}
                        >{item}
                            <div className={["border", active === index ? 'active' : '',].join(' ')} />
                        </div>
                        {!simple && spliter}
                    </>
                })
            }
        </div>
    );
}

export default Tab;