import React, { useState, useEffect } from 'react';
import { ArrowLeftOutlined } from '@ant-design/icons';
import './index.scss'
import { Title, Button,CompanyForm } from '../../../../components';
import { Table, Pagination, Button as Abt,Modal } from 'antd';
import dayjs from 'dayjs';
import { useHistory } from 'react-router-dom';
import api from '../../../../api';
import { getUserInfo } from '../../../../utils';
const layout = {
    labelCol: { span: 11 },
    wrapperCol: { span: 13 },
};
const CompanyList = () => {
    const history = useHistory();
    const [list, setList] = useState([]);
    const [total, setTotal] = useState(0);
    const [showCompanyForm,setshowCompanyForm] = useState(false);
    const [pageIndex, setPageIndex] = useState(1);
    const [id,setId] = useState('');
    const [idFlag ,setIdFlag] = useState(true);

    useEffect(() => {
        fetchCompanyList();
    }, [pageIndex]);

    const fetchCompanyList = async () => {
        const userInfo = getUserInfo();
        const { list, total } = await api.findCompanyByPage(({
            pageIndex,
            pageSize: 10,
            param: {
                createUser: userInfo.id
            } as any
        })) || {};
        setList(list);
        setTotal(total);
    }
    const addCompanyForm = () => {
        setId('');
        setshowCompanyForm(true);
    }
    useEffect(() => {
        if(!id) {
            return;
        }   
        setshowCompanyForm(true);
    },[id,idFlag])

    const columns = [
        {
            title: 'CompanyName',
            dataIndex: 'companyName',
            key: 'companyName',
            width: '105px',
        },
        {
            title: 'Place of Registration',
            dataIndex: 'vesselType',
            key: 'vesselType',
            width: '150px'
        },
        {
            title: 'Registrated capital',
            dataIndex: 'vesselName',
            key: 'vesselName',
            width: '140px'
        },

        {
            title: 'Legal representative',
            dataIndex: 'investment',
            key: 'investment',
            width: '150px'
        },
        {
            title: 'Company email',
            dataIndex: 'purchasedate',
            key: 'purchasedate',
            width: '120px',
        },
        {
            title: ' Latest Update Date',
            dataIndex: 'modifyTime',
            key: 'updateDate',
            width: '140px',
            render: (item: string) => dayjs(item).format('YYYY-MM-DD')
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            render: (item: any, row: any) => {
                // return <Abt type='link' onClick={() => history.push(`/usercenter/company/detail?id=${row.id}`)} style={{ fontSize: '12px' }} >View Detail</Abt>
                return <Abt type='link' onClick={() => {
                    setId(row.id);
                    setIdFlag(!idFlag)
                }} style={{ fontSize: '12px' }} >View Detail</Abt>
            }
        },

    ];
    return (
        <section className=' company-list'>
            {/* <p className='page-title'>STO management</p>
            <p className='page-title'>Secured STO submission portal</p> */}

            {/* <div className="list-wrap"> */}
                {/* <div className="header">
                    <ArrowLeftOutlined onClick={() => window.close()} />
                </div> */}
                <div className="title-wrap">
                    <Title>Vessel-related Company Information</Title>

                    {/* <Button onClick={() => history.push('/usercenter/company/detail')} size='middle'>Add New Company</Button> */}
                    <Button onClick={addCompanyForm} size='middle'>Add New Company</Button>
                </div>

                <div className="common-table">
                    <Table pagination={false} columns={columns} dataSource={list}></Table>
                    {
                        total ? <Pagination style={{ marginTop: '50px',textAlign: "center" }} className='mdr-pagination'
                            showTotal={(total) => `${total} items in total`}
                            onChange={(page) => setPageIndex(page)}
                            showSizeChanger={false}
                            showQuickJumper
                            defaultCurrent={pageIndex}
                            total={total}
                        /> : null
                    }
                </div>
            {/* </div> */}
            {/* 弹出层，放置增加的或者查看详情 */}
            <Modal
                visible={showCompanyForm}
                centered
                footer={null}
                // closable={false}
                maskClosable={false}
                destroyOnClose
                getContainer={false}
                onCancel={() => setshowCompanyForm(false)}
                className='apply-company-modal'
            >
                <div className="row" style = {{borderBottom: "1px solid #ccc",paddingBottom: "10px"}}>
                    <Title>Company Info</Title>
                </div>
                
                <CompanyForm
                    {...layout}
                    colon={false}
                    id={id}
                    style={{ paddingTop: '20px', paddingRight: '100px' }}
                    onSave={() => {setshowCompanyForm(false);fetchCompanyList()}}
                    onClose={() => setshowCompanyForm(false)}
                />
            </Modal>
        </section>
    )
}

export default CompanyList;