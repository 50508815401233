import React, {useState, useEffect} from 'react';
import './index.scss';
import {Title, Button} from '../../../../components';
import {transLocal, getAllOptionsValue} from '../../../../utils';
import {Button as Abt, Table, Pagination, Modal} from 'antd'
import {useHistory} from 'react-router-dom';
import OfflineMessage from '../../../UserCenter/Wallet/Topup/components/offlineMessage';
import {TableTitle} from "../myPortfolio/myPortfolio";
import { formatTwoMoney,formatMoneyDecimal,formatTime } from '../../../../utils';
import { useObserver} from 'mobx-react'
import walletStore from "./walletStore";
import publicStore from '../../../../store'

type IProps = {
    inType: 1 | 2
}
let columns: any;
const Cash = ({inType}: IProps) => {
    const [pageIndex,setPageIndex] = useState(1);
    const [offlineMessage, setOfflineMessage] = useState(false);//线下充值弹出的modal层
    const history = useHistory();
    const [auditDay, setAuditDay] = useState(14);
    const [serialNumber, setSerialNumber] = useState('');
    useEffect(() => {
        walletStore.fetchList(1, 5).then()
        getAuditDay().then()
    }, []);

    const getAuditDay = async () => {
        const optionsValue = await getAllOptionsValue()
        setAuditDay(optionsValue['investorCertEffective'] || 14)
    }
    const getViewDetail = (row: any) => {//根据不同的身份跳转到不同的页面
        // 如果是线下充值就展示modal信息
        if (row.sourcePurposeType === 1) {//线下充值
            setSerialNumber(row.serialNumber || '');
            setOfflineMessage(true);
        } else {
            history.push(`/usercenter/invest/detail/${row.relationId}`);
        }
    }
    columns = [

        // {
        //     title: <TableTitle title='Operation'/>,
        //     dataIndex: 'operation',
        //     key: 'operation',
        //     align: 'center',
        //     render: (item: any, row: any) => {
        //         // const list : Array<string> = ['Refund investment' ] ;
        //         // if(!row.relationId) return null;
        //         // 如果是线上充值就不返回信息
        //         if (row.sourcePurposeType === 2 || row.sourcePurpose === 'Withdraw') {
        //             return null;
        //         }
        //         return (
        //             <Abt type='link' onClick={() => {
        //                 getViewDetail(row)
        //             }} style={{fontSize: '12px'}}>View Detail</Abt>
        //         )
        //     }
        // },
        {
            title: <TableTitle title='Time'/>,
            dataIndex: 'createTime',
            key: 'createTime',
            align: 'center',
            width: 200,
            render: (item: string) => formatTime(item,'datetime')
        },
        {
            title: <TableTitle title='Type'/>,
            dataIndex: 'addType',
            key: 'addType',
            align: 'center',
            width: 55,
            render: (item: string) => {
                return (
                    <div style={{ fontSize: '22px'}}>{item}</div>
                )
            }

        },
        {
            title: <TableTitle title="Serial Number"/>,
            dataIndex: "serialNumber",
            key: "serialNumber",
            align: 'center',
            width: 170
        },
        {
            title: <TableTitle title='Amount'/>,
            dataIndex: 'accountChange',
            key: 'accountChange',
            align: 'center',
            width: 100,
            render: (item: string) => formatMoneyDecimal(Number(item),3),

        },

        {
            title: <TableTitle title='Channel'/>,
            dataIndex: 'channel',
            key: 'channel',
            align: 'center',
            width: 200,
            render: (item: string) => item || '--'

        },
        {
            title: <TableTitle title='User Operation'/>,
            dataIndex: 'sourcePurpose',
            key: 'sourcePurpose',
            align: 'center',
            width: 180,
            // render: (item: string) => dayjs(item).format('YYYY-MM-DD')
        },
        {
            title: <TableTitle title='Status'/>,
            dataIndex: 'status',
            key: 'status',
            align: 'center',
            width: 120,
            //@ts-ignore
            render: (item: string) => {
                const map = {
                    1: 'Succeeded',
                    2: 'Under Review',
                    3: 'Cancelled',
                }
                //@ts-ignore
                return map[item];
            }
        },
        {
            title: <TableTitle title='Remark'/>,
            dataIndex: 'remark',
            key: 'remark',
            align: 'center',
            width: 180,
            render: (item: string) => {
                return (
                    <div className='remark-box'>{item}</div>
                );
            }
            //@ts-ignore
            // render: (item: string) => STO_STATUS_MAP[item]
        },


    ];
    const linkToDeposit = () => {
        const path = {
            pathname: '/usercenter/wallet/topup',
            state: {
                inType: inType
            }
        }
        // publicStore.moneyInType = inType
        sessionStorage.setItem('inType',inType.toString())
        history.push(path)
    }
    const linkToWithDraw = () => {
        const path = {
            pathname: '/usercenter/wallet/withdraw',
            state: {
                inType: inType
            }
        }
        // publicStore.moneyInType = inType
        sessionStorage.setItem('inType',inType.toString())
        history.push(path)
    }
    return useObserver(() => (
  
        <div className="cash-wrap">
            <div className="summary-wrap row">
                <Title>Available Cash</Title>
                <p className='money'><span className='count'>{formatTwoMoney(Number(walletStore.stoTotalMoney || 0))}</span><span>MDRX</span></p>
                <div className="btn-wrap">
                    <Button size='middle' onClick={linkToDeposit} type='primary'>Deposit</Button>
                    <Button onClick={linkToWithDraw} size='middle' style={{marginLeft: '15px'}}
                            type='ghost'>Withdraw</Button>
                </div>
                {/*<div className="drop-row">
                    {PeopleMessageStore.stoWalletFlag && <DownOutlined
                        onClick={() => PeopleMessageStore.stoWalletFlag = !PeopleMessageStore.stoWalletFlag}
                        style={{fontSize: '30px'}}/>}
                    {!PeopleMessageStore.stoWalletFlag && <RightOutlined
                        onClick={() => PeopleMessageStore.stoWalletFlag = !PeopleMessageStore.stoWalletFlag}
                        style={{fontSize: '30px'}}/>}
                </div>*/}

            </div>
            <Table
                className='table-box-nav'
                pagination={false}
                columns={columns}
                scroll={{x: 1150}}
                dataSource={walletStore.stoTableList}>
            </Table>
            <Pagination style={{marginTop: '50px'}} className='mdr-pagination'
                        showTotal={(total) => `${total} items  in total`}
                        onChange={(page) =>{
                            setPageIndex(page);
                            walletStore.fetchList(page, 5)
                        } }
                        showSizeChanger={false}
                        showQuickJumper
                        pageSize={5}
                        current={pageIndex}
                        defaultCurrent={pageIndex}
                        total={walletStore.stoTotal}
            />

            {/* 线下充值弹窗弹窗 */}
            <Modal
                footer={null}
                visible={offlineMessage}
                closable={false}
                width={740}
            >
                <OfflineMessage serialNumber={serialNumber} auditDay={auditDay}></OfflineMessage>
                <div style={{
                    textAlign: "center"
                }}>
                    <Button onClick={() => {
                        // setPayHelp(false);
                        // history.push(`/about?keyword=Need Help&id=9`)
                        setOfflineMessage(false);
                    }
                    } type='ghost' size='small'>Ok</Button>
                </div>
            </Modal>
        </div>

    ))
}

export default Cash;

