import React, { useState, useEffect,useRef } from 'react'
import { useIntl } from 'react-intl';
import FormItem from 'antd/lib/form/FormItem'
import { DatePicker, Input, InputNumber, Upload, TreeSelect, Modal as AModal, message,Menu, Dropdown, Progress } from 'antd';
import {UploadOutlined } from '@ant-design/icons';
import MSelect from '../MSelect';
import Button from '../Button';
import { FormInstance } from 'antd/lib/form';
import parse from 'url-parse';
import './index.scss';
import api from '../../api';
import Carousel, { ModalGateway, Modal } from 'react-images';
import { uploadAction } from '../../constants';
import { getImageUrl } from '../../utils';

//@ts-ignore
const PreView = ({ url, fileType, show, onClose }) => {
    if (fileType === 'pdf') {
        return (
            <AModal
                bodyStyle={{ width: '300px', height: '500px' }}
                footer={null}
                visible={show}>
                <embed src={url} width="100%" height="100%" />
            </AModal>
        )
    }
    return (
        <ModalGateway>
            {show ? (
                <Modal onClose={() => onClose()}>
                    <Carousel views={[{ source: url }]} />
                </Modal>
            ) : null
            }
        </ModalGateway>
    )
}


export interface IDtem {
    id: number,
    type: 'text' | 'number' | 'money' | 'select' | 'tree' | 'date' | 'dateRange' | 'year' | 'file' | string,
    valueRange: string,
    code: string,
    name: string,
    remark: string,
    tabNum: number,
    checks: string,
    propertyValue: string,
    propertyId: number,
}

interface IProps {
    data: IDtem;
    form: FormInstance;
    readonly?: boolean;
    onFileChange?: (code: string, value: string) => void;
    onMSelectChange?: (data: {code: string,checkValue: string,disabled: boolean}) => void;
}

interface IRule {
    required?: boolean,
    precision?: number,
    fileType?: string,
    readonly?: boolean,
}

const { RangePicker } = DatePicker;

const DynamicFormItem: React.FC<IProps> = (props: IProps) => {
    const { formatMessage: f } = useIntl();
    const { data, form, readonly, onFileChange, ...formItemProps } = props;
    const [val, setVal] = useState('');
    const [url, setUrl] = useState('');
    const [showPreview, setShowPreview] = useState(false);
    const [fileType, setFileType] = useState('');
    const [multiUploadData,setMultiUploadData] = useState<string[]>([]);
    const [uploadProgress,setUploadProgress] = useState(0)//上传组件进度控制
    const [uploadDisabled,setUploadDisable] = useState(false);//控制上传中禁用上传按钮


    const firstUpdate = useRef(true);
    if (!data.checks) {
        data.checks = JSON.stringify({});
    }
    let rules: IRule = {};

    try {
        rules = JSON.parse(data.checks);
    } catch (error) {
        console.log(data.code, 'checks', error)
    }


    const inputValList: any = [];

    const handleInputChange = (e: any, index?: number) => {
        const value = e.target.value;
        if (index === undefined) {
            form.setFieldsValue({
                [data.code]: value,
            })
        } else {
            inputValList[index] = value;
        }
    }
    useEffect(() => {//初始执行，把valueRange的值放入数组中
        if(data.type === 'multiUpload') {
            data.valueRange && data.valueRange.split(',').forEach(itemChild => {
                multiUploadData.push(itemChild);
            })
            data.propertyValue && data.propertyValue.split(',').forEach(itemChild =>{
                multiUploadData.push(itemChild);
            })
            setMultiUploadData(multiUploadData.slice());
        }
        return () => {//卸载把进度条前置0,upload可上传状态改为false
            setUploadProgress(0);
            setUploadDisable(false);
        }
    },[]);
    useEffect(() => {//多项上传回调
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
       else {
            onFileChange && onFileChange(data.code, multiUploadData.toString());
        }
    },[multiUploadData])
    //@ts-ignore
    const handleUploadChange = (response) => {
        const event:any = response.event;
        if (event) { // 一定要加判断，不然会报错,这里还没有完成
            let percent = Math.floor((event.loaded / event.total) * 100) - 12;
            setUploadProgress(percent);
          }
        if(data.type === 'multiUpload') {//多文件上传
            const resp = response.file.response;
            if(resp !== undefined) {
                setUploadDisable(false);//恢复可上传状态
                if (resp.code === '200') {
                    setUploadProgress(100);
                    setTimeout(()=> {
                        setUploadProgress(0)
                    },1000)
                    const { objectName } = resp.data;
                    multiUploadData.push(objectName);
                    setMultiUploadData(multiUploadData.slice());
                } else {
                    setUploadProgress(101);//异常提示错误信息一秒后消失
                    setTimeout(() => {
                        setUploadProgress(0);
                    })
                    message.error(resp.msg)
                }
            }
        }else {//单文件上传
            const resp = response.file.response;
            if (resp !== undefined) {
                setUploadDisable(false);//恢复可上传状态
                if (resp.code === '200') {//成功提示成功，两秒后消失
                    setUploadProgress(100);
                    setTimeout(()=> {
                        setUploadProgress(0);
                    },2000)
                    const { objectName } = resp.data;
                    console.log(objectName);
                    setVal(objectName);
                    onFileChange && onFileChange(data.code, objectName);
                } else {
                    setUploadProgress(101);//异常提示错误信息一秒后消失
                    setTimeout(() => {
                        setUploadProgress(0);
                    })
                    message.error(resp.msg);
                }
            }
        }

    }
    // 上传文件之前，规范上传类型
    const beforeUploadChange = (file:any) => {
        // 如果是需要上传pdf，则判断是不是pdf不是的话，提示返回
        if(rules.fileType === '.pdf') {
            if(file.type !== 'application/pdf') {
                message.warning(f({ id: 'offer.uploadPdf' }));
                return false;
            }
        }
        if(rules.fileType === '.jpg') {
            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/jpg' || file.type === 'image/png' || file.type === 'image/gif';
            if (!isJpgOrPng) {
              message.error('Please select the image format to upload!');
              return false;
            }
        }
        // 进入上传，设置上传按钮禁用
        setUploadDisable(true);
        return true;
    }

    const handlePreView = async () => {
        const url = await api.getFileUrl(val || data.propertyValue);
        const type = parse(url).pathname.split('.')[1];

        // if (['jpg', 'jpeg', 'png'].indexOf(type) !== -1) {
        //     setFileType('img');
        //     setUrl(url);
        //     setShowPreview(true);
        // }
        // else if (type === 'pdf') {
        //     setFileType('pdf');
        //     setUrl(url);
        //     setShowPreview(true)
        // }
        // else {
            window.open(url, 'new_mdr', 'width=600,height=800');
        // }

    }

    const normalize = (value: any, prevValue: any, prevValues: any) => {
        const typeList = data.type.split(',');
        if (typeList.length === 1) {
            return value;
        }

        if (inputValList.length === 1) {
            return inputValList[0];
        }
        return inputValList.join(',');
    }


    const ele = (data: IDtem, type: string, defaultValue: any, index?: number): any => {
        if (data.code.endsWith('101')) {
            return <Input readOnly disabled={readonly} onChange={(e) => handleInputChange(e, index)} />;
        }
        switch (type) {
            case 'text':
                return <Input readOnly={rules.readonly} disabled={readonly} onChange={(e) => handleInputChange(e, index)} />;
            case 'number':
                console.log(readonly);
                let propsDisable = false // 是否禁用
                let selectChecksInputNumber = {} as any
                try {
                    selectChecksInputNumber = data.checks ? JSON.parse(data.checks)  : {};
                    propsDisable = selectChecksInputNumber && selectChecksInputNumber.disabled
                } catch (error) {
                    console.log(data.code, 'valueRange', error);
                }
                return (
                    <InputNumber
                        disabled={readonly || propsDisable}
                        precision={rules.precision || 0}
                        min={0}
                        formatter={value => {
                            if(value?.toString().indexOf('.') !== -1) {
                                return `${value}`.replace(/(\d{1,3})(?=(?:\d{3})+\.)/g,'$1,');
                            }else {
                                return `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                            }
                        }}
                        parser={(value:any) => value.replace(/[^\d^\.]+/g, '')} 
                    // defaultValue={value}
                    ></InputNumber>
                );
            case 'percentage':
                return(
                    <InputNumber
                        disabled = {readonly}
                        min = {0}
                        max = {100}
                        formatter={value => `${value}%`}
                        parser={(value:any) => value.replace('%', '')}
                    ></InputNumber>
                )
            case 'money':
                return (
                    <InputNumber
                        disabled={readonly}
                        className='m-input'
                        // value={+value}
                        min={0}
                        formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                        parser={(value:any) => {
                            // if (!value) return '';
                            return value.replace(/[^\d^\.]+/g, '')
                        }}
                        precision={rules.precision || 0}
                    />)
                    case 'percentage':
                return(
                    <InputNumber
                        disabled = {readonly}
                        min = {0}
                        max = {100}
                        formatter={value => `${value}%`}
                        parser={(value:any) => value.replace('%', '')}
                    ></InputNumber>
                )
            case 'date':
                return <DatePicker disabled={readonly}></DatePicker>;
            case 'dateRange':
                return <RangePicker disabled={readonly}></RangePicker>;
            case 'year':
                return <DatePicker disabled={readonly} picker="year" />
            case 'month':
                return <DatePicker disabled={readonly} picker='month' />
            case 'select':
                let dataSource = [] as any;
                let selectChecks = {} as any;
                let code = '' as string;
                try {
                    dataSource = data.valueRange ? JSON.parse(data.valueRange) as [] : [];
                    code = data.code ? data.code : '';
                    selectChecks = data.checks ? JSON.parse(data.checks)  : {};
                } catch (error) {
                    console.log(data.code, 'valueRange', error);
                }
                console.log(1);
                return <MSelect
                disabled={readonly}
                dataSource={dataSource}
                onMSelectChange = {props.onMSelectChange && props.onMSelectChange} //回调改变的事件
                selectCode ={code} //传递当前code
                selectChecks = {selectChecks}//传递check属性
                showSearch = {true}
                 />;

            case 'file':
                const download = async () => {
                    const url = await getImageUrl(data.valueRange);

                    window.open(url, 'new_mdr', 'width=600,height=800')

                }
                return (
                    <div className='row upload-wrap'>
                        <Upload
                            data={{ type: 'other' }}
                            action={uploadAction}
                            accept={rules.fileType}
                            showUploadList={false}
                            disabled={uploadDisabled?uploadDisabled:readonly}
                            onChange={handleUploadChange}
                            beforeUpload = {beforeUploadChange}
                        >
                            <Button type='ghost' disabled = {uploadDisabled?uploadDisabled:readonly}>
                                Upload
                            </Button>
                            {uploadProgress > 0 && <Progress percent = {uploadProgress} status ={uploadProgress === 101? 'exception':'active'}></Progress>}
                        </Upload>
                        {
                            data.valueRange && (<a style={{ marginRight: '10px' }} onClick={download}>download sample</a>)
                        }
                        {
                            (data.propertyValue || val) && <a onClick={handlePreView}>preview</a>
                        }
                    </div>
                )
            case 'multiUpload':
                const muiltDown = async (e:any,item:any) => {
                    const url = await getImageUrl(item);
                    window.open(url, 'new_mdr', 'width=600,height=800');
                }
                const muildHandPreView = async (e:any,item:any) => {
                    const url = await api.getFileUrl(item);
                    const type = parse(url).pathname.split('.')[1];
                    if (['jpg', 'jpeg', 'png'].indexOf(type) !== -1) {
                        setFileType('img');
                        setUrl(url);
                        setShowPreview(true);
                    }else {
                        window.open(url, '__blank');
                    }
                }
                const deleteData = (e:any,item:any) => {
                    if(!readonly){
                        const index = multiUploadData.findIndex((items:string) => items === item);
                        if(index !== -1) {
                            multiUploadData.splice(index,1);
                            setMultiUploadData(multiUploadData.slice());
                        }
                    }
                }
                  const initMenu = (data:string[]) => {
                      return(
                          <Menu>
                              {data.map((item:string) =>
                                <Menu.Item>
                                 <span style = {{marginRight: "20px"}} onClick = {(e) => {muiltDown(e,item)}}>download sample</span>
                                 <span style = {{marginRight: "20px"}} onClick = {(e) => {muildHandPreView(e,item)}}>preview</span>
                                 <span onClick = {(e) => {deleteData(e,item)}} style = {{color: "red",display: readonly? 'none': 'inline-block'}}> delete</span>
                                </Menu.Item>
                              )}
                          </Menu>
                      )
                  }
                return(
                    <div className='row upload-wrap'>
                    <Upload
                        data={{ type: 'other' }}
                        action={uploadAction}
                        accept={rules.fileType}
                        showUploadList={false}
                        disabled={uploadDisabled?uploadDisabled:readonly}
                        onChange={handleUploadChange}
                        beforeUpload = {beforeUploadChange}
                    >
                        <Button type='ghost'>Upload</Button>
                    </Upload>
                    <Dropdown overlay = {initMenu(multiUploadData)} placement="bottomLeft">
                        <span  className = "mdr-button" >perview list</span>
                    </Dropdown>
                </div>
                )
            case 'tree':
                let treeData = [] as any;
                try {
                    treeData = data.valueRange ? JSON.parse(data.valueRange) as [] : [];
                } catch (error) {
                    console.log(data.code, 'valueRange', error)
                }
                return (
                    <TreeSelect
                        className='mdr-tree'
                        // value={value}
                        disabled={readonly}
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder="Please select"
                        allowClear
                        treeDefaultExpandAll
                        treeData={treeData}
                    />
                )

            default:
                console.log(type)
                const typeList = type?.split(',');
                const defaultValList = defaultValue?.split(',');
                if (!type || typeList.length === 1) {
                    console.warn('暂不支持的类型')
                    return null;
                }
                return (
                    <div className='row inline-property'>
                        <Input hidden value={defaultValue}></Input>
                        {
                            typeList.map((item, index) => ele(data, item, defaultValList[index], index))
                        }
                    </div>
                )

        }
    }
    // const item = ele(data, data.type, data.propertyValue);

    // if (!item) return null;
    return (
        <div className="dynamic-form-item">
            {
                data.type === 'label' ? (
                    <p className='legend'>{data.name}</p>) : (
                        <>
                            <div className='index'>{data.code}</div>

                            <FormItem
                                {...formItemProps}
                                name={data.code}
                                label={data.name}
                                normalize={normalize}
                                rules={[{ required: rules.required, message: `${data.code} ${data.name} is required` }]}
                            >
                                {ele(data, data.type, data.propertyValue)}
                            </FormItem>
                        </>

                    )

            }




            <PreView show={showPreview} onClose={() => setShowPreview(val => !val)} url={url} fileType={fileType}></PreView>

        </div>
    )
}

export default DynamicFormItem;
