import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { MSelect, Button, TabPro, Title } from '../../../components';

import { Button as ABtn, Pagination, TreeSelect,Tooltip } from 'antd'

import './index.scss';
import { Tag, DatePicker, InputNumber, Input, } from 'antd';
import Sort from '../../../components/Sort';
import { LeftOutlined, RightOutlined, UpOutlined, DownOutlined } from '@ant-design/icons';
import Store from './store';
import dayjs from 'dayjs';
import { useHistory, useParams } from 'react-router-dom';
import { observer } from 'mobx-react';
import { formatMoney } from '../../../utils';
import { VESSEL_TYPE, TIME, OFFER_TYPE } from '../../../constants';
import api from '../../../api';
import { useUrlQuery } from '../../../hooks';


const { RangePicker } = DatePicker;

const store = new Store();


const OfferIndex: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();

    const [showFilter, setShowFilter] = useState(false);

    const offerType =  useUrlQuery('offerType');

    // const projectType = useUrlQuery('projectType');
    const projectType = sessionStorage.getItem('items')|| null;

    const [topOffer, setTopOffer] = useState(null) as any;
    const [sortArray,setSortArray] = useState<{'publish_time':number,'fundraising': number,'expire_time':number}>({
        'publish_time' :0,
        'fundraising': 0,
        'expire_time': 0
    })

    const history = useHistory();

    // const tabs = JSON.parse(f({ id: 'offer.tabs' }));
    useEffect(() => {
        // debugger
        store.searchModal.offerType = offerType ? +offerType : 1;
        // store.searchModal.offerType = 1;
        store.condition.projectType =  projectType ? JSON.parse(projectType) : null;
        store.fetchOptions();
        store.handleSearch();
        fetchTopOffer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [offerType,projectType]);

    const fetchTopOffer = async () => {
        const data = await api.findFeaturedOfferSto();
        setTopOffer(data);
    }

    const handleToDetail = (item: any) => {
        history.push(`/offer/${item.id}`)
    }
    const updateSortValue = (label:string) => {

        //@ts-ignore
        const oldVal = sortArray[label];
        const newVal = oldVal + 1 > 2 ? 0 : oldVal + 1;
        //@ts-ignore
        sortArray[label] = newVal;
        store.handleOrderChange(label,newVal);//把key和新值传递给store
        const copySortArray = {
            'publish_time' :0,
            'fundraising': 0,
            'expire_time': 0
        };
        //如果当前改变了就给其他置空
        for(let id in sortArray) {
            if(id === label) {
                 //@ts-ignore
                copySortArray[id] = sortArray[id];
            }else {
                //@ts-ignore
                copySortArray[id] = 0
            }
        }
        setSortArray(copySortArray);
    }
    return (
        <section className="common-wrap">
            <p className='page-title'>{f({ id: 'home.banner.title' })}</p>
            <p className='page-title'>{f({ id: 'home.banner.subTitle' })}</p>

            <div className="search-wrap">
                <div className="search">
                    <MSelect
                        value={store.searchModal.offerType}
                        onChange={(value) => {
                            store.searchModal.offerType = value;
                            store.handleSearch();
                        }}
                        className="type-select"
                        dataSource={OFFER_TYPE as any}
                        bordered={false}></MSelect>
                    <input
                        value={store.searchModal.keyword}
                        onChange={(e) => {
                            store.searchModal.keyword = e.target.value;
                        }}
                        placeholder={f({ id: 'offer.search.placehoder' })} className='m-input search-input'></input>
                    <Button onClick={store.handleSearch} size='middle' className="search-btn">{f({ id: 'search' })}</Button>
                </div>
            </div>

            <div className='offer-wrap'>
                {/* <Tab data={tabs} ></Tab> */}
                <div className="offer-main">



                    <div className="condition-wrap">
                        <div className="result">
                            <span className='label'>
                                {f({ id: 'offer.search.selected.conditions' })}
                            </span>

                            <div className="result-tags-wrap">
                                {
                                    //@ts-ignore
                                    Object.keys(store.condition).map(item => store.condition[item] && store.condition[item].label ? (
                                        <Tag
                                            style={{
                                                fontSize: '14px',
                                                background: '#fff',
                                                paddingTop: '4px'
                                                , paddingBottom: '4px'
                                            }}
                                            closable
                                            //@ts-ignore
                                            onClose={() =>   {
                                                store.condition[item] = {}
                                                store.handleSearch();
                                            }}

                                        >{
                                                // @ts-ignore
                                                typeof store.condition[item] === 'string' ? store.condition[item] : store.condition[item].label
                                            }
                                        </Tag>
                                    ) : null)
                                }
                            </div>

                            <Button className='filter-btn' onClick={() => setShowFilter((val: boolean) => !val)} size='small' type='ghost'>
                                Filter
                              {
                                    showFilter ? <UpOutlined /> : <DownOutlined />
                                }

                            </Button>
                        </div>

                        {
                            showFilter && (
                                <div className='condition-box'>
                                    <div className="item">
                                        <span className='label'>{f({ id: 'offer.search.projecttype' })}:</span>
                                        <div className="content">
                                            {
                                                VESSEL_TYPE.map(item => (
                                                    <span
                                                        // @ts-ignore
                                                        className={`radio-item ${store.condition.projectType?.value === item.value ? 'active' : ''}`}
                                                        onClick={() => store.handleProjectTypeChange(item)}
                                                    >{item.label}</span>
                                                ))
                                            }
                                            {/* <div> */}
                                                <Button
                                                    onClick={store.handleSearch}
                                                    style={{marginLeft: 'auto', marginTop: '-10px', width: '74px', height: '27px'}}
                                                    size='small'>Search</Button>
                                                    <Button
                                                    onClick={store.clearSearch}
                                                    style={{marginLeft: '20px',   background: 'rgb(255, 255, 255)',border: '1px solid rgb(207, 207, 207)', marginTop: '-10px', width: '74px', height: '27px'}}
                                                    size='small'>Clear</Button>
                                            {/* </div> */}
                                        </div>
                                    </div>

                                    <div className="item">
                                        <span style={{ paddingTop: '20px' }} className='label'>{f({ id: 'offer.search.vesseltype' })}:</span>
                                        <div className="content">
                                            <TreeSelect
                                                // showSearch
                                                style={{ width: '100%' }}
                                                // value={this.state.value}
                                                dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                placeholder="Please select"
                                                allowClear
                                                treeDefaultExpandAll
                                                bordered={false}
                                                value={store.condition.vesselType.value}
                                                onChange={(value, label) => {
                                                    store.handleVesselTypeChange({
                                                        label: label[0],
                                                        value,
                                                    })
                                                }}
                                                treeData={store.vesselType}
                                            >

                                            </TreeSelect>
                                        </div>
                                    </div>

                                    {/* <div className="item">
                                        <span style={{ paddingTop: '18px' }} className='label'>{f({ id: 'offer.search.date' })}:</span>
                                        <div className="content" style={{ alignItems: 'center' }}>
                                            {
                                                TIME.map(item => (
                                                    <span
                                                        onClick={() => store.hanldeTimeChange(item)}
                                                        style={{ paddingRight: '5px' }}
                                                        // @ts-ignore
                                                        className={`radio-item ${store.condition.publishTime?.label === item.label ? 'active' : ''}`}>{item.label}</span>
                                                ))
                                            }

                                        </div>
                                    </div> */}
                                    {/* <div className="item">
                                        <div className='row' >
                                            <span className='label'>{f({ id: 'customize' })}</span>
                                            <RangePicker onChange={(value: any) => {
                                                store.searchModal.publishTimeStart = dayjs(value[0]).format('YYYY-MM-DD')
                                                store.searchModal.publishTimeEnd = dayjs(value[1]).format('YYYY-MM-DD');
                                                store.handleSearch();
                                            }} style={{ width: '380px' }} />
                                            <Button onClick={() => store.handleSearch()} type='ghost' className='confirm-btn'>{f({ id: 'confirm' })}</Button>
                                        </div>
                                    </div> */}

                                    <div className="item">
                                        <span style={{ paddingTop: '28px' }} className='label'>{f({ id: 'offer.search.imo' })}</span>
                                        <div className="content border-none" style={{ alignItems: 'center' }}>
                                            <Input value={store.searchModal.projectNumber} onChange={(e) => store.searchModal.projectNumber = e.target.value} style={{ width: '90px' }} className='filter-input'></Input>
                                            {/* </div> */}
                                            <div className='row' style={{ marginLeft: 'auto' }}>
                                                <div className="item center">
                                                    <span className='label'>{f({ id: 'offer.search.spvname' })}</span>
                                                    <Input value={store.condition.vesselName}  onChange={(e) => store.handleInputChange('vesselName', e)} style={{ width: '90px' }} className='filter-input'></Input>
                                                </div>
                                                <div className="item center">

                                                    <span className='label'>{f({ id: 'offer.search.fundraising' })}</span>
                                                    <div className="number-range">
                                                        <InputNumber value={store.searchModal.fundraisingMin}
                                                            onChange={(e) => store.handleInputChange('fundraisingMin', e)}
                                                            min={0} />
                                                        <span className='line'>-</span>
                                                        <InputNumber min={0} value={store.searchModal.fundraisingMax}
                                                            onChange={(e) => store.handleInputChange('fundraisingMax', e)}
                                                        />
                                                    </div>
                                                    <Button
                                                        onClick={store.handleSearch}
                                                        type='ghost'
                                                        className='confirm-btn'
                                                    >{f({ id: 'confirm' })}</Button>
                                                </div>


                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )
                        }


                    </div>

                    <div className="list-wrap">
                        <div className="sort-wrap">
                            {/* <Sort value={sortArray.publish_time} onChange={(val: any) => store.handleOrderChange('publish_time', val)} label='Earliest Offer'></Sort>

                            <Sort value={sortArray.fundraising} onChange={(val: any) => store.handleOrderChange('fundraising', val)} label='Biggest Offer'></Sort>

                            <Sort value={sortArray.expire_time} onChange={(val: any) => store.handleOrderChange('expire_time', val)} label='Last-minute Offer'></Sort> */}
                            <Sort value={sortArray.publish_time} keyValue = 'publish_time' onChange={updateSortValue} label='Date posted'></Sort>

                            <Sort value={sortArray.fundraising} keyValue ='fundraising' onChange={updateSortValue} label='Total available funds'></Sort>

                            <Sort value={sortArray.expire_time} keyValue = 'expire_time' onChange={updateSortValue} label='Expiration date'></Sort>

                            <div className="quick-page">
                                <ABtn disabled={store.pageIndex === 1} icon={<LeftOutlined />} />
                                <p className='page'>
                                    <span className='current'>{store.pageIndex}</span>
                                    <span className='total'>/{store.totalPage}</span>
                                </p>
                                <ABtn disabled={store.pageIndex === store.totalPage} icon={<RightOutlined />} />
                            </div>
                        </div>
                        {
                            topOffer ? (
                                <div className="recommend-wrap">
                                    <div className="header">
                                        <Title>Featured Offer</Title>
                                    </div>
                                    <div className="row recommend-offer">
                                        <img alt='' src={topOffer.imgUrl}></img>
                                        <div className="info">
                                            <div className='row'>
                                                <div>
                                                    <p className="desc">
                                                        {topOffer.vesselName}
                                                    </p>
                                                    <p className='summary'>{topOffer.synopsis}</p>
                                                </div>

                                                <div className='row tag-wrap'>
                                                    <p className="item left"><span className='bolder'>{topOffer.percentage}</span>%</p>
                                                    <div className="item right">
                                                        <p>Remaining</p>
                                                        <p><span className='yellow'>{topOffer.expireDay}</span>
                                                    days left
                                                 </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row digist">
                                                <div>
                                                    <p className='label'>Raising Fund</p>
                                                    <p className='value'>USD {formatMoney(topOffer.fundraising)}</p>
                                                </div>
                                                <div className="divider"></div>
                                                <div>
                                                    <p className='label'>Current fundraising progress</p>
                                                    <p className='value'>{formatMoney(topOffer.currentFundraising)}</p>
                                                </div>
                                                <Button onClick={() => handleToDetail(topOffer)}>View Details</Button>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            ) : null
                        }
                        <div className="list">
                            {
                                store.offerList.map((item: any, index: number) => (
                                    <div key={index} className="offer-item">
                                        {/* 上方增加名字和type显示 */}
                                        <div className = "offer-title">
                                            <div
                                            className = "offer-title-value"
                                            style = {{textAlign: "left"}}
                                            title = {item.tokenName?item.tokenName:''}>
                                                <strong>Token: </strong>{item.tokenName?item.tokenName:''}
                                            </div>
                                            <div
                                            className = "offer-title-value"
                                            style ={{textAlign: "right"}}
                                            title = {item.projectObjective?item.projectObjective:''}>
                                                <strong>Type:</strong>
                                                {
                                                    item.projectObjective === 'Equity'?
                                                    <span>&nbsp;&nbsp;</span>:
                                                    null
                                                }
                                                {item.projectObjective?item.projectObjective:''}
                                            </div>
                                        </div>
                                        <div className="row">
                                            <img alt='' src={item.imgUrl} ></img>
                                            <div className='desc'>
                                                <p className='title'
                                                title = {item.vesselName}
                                                style = {{
                                                    width: "100%",
                                                    textOverflow: "ellipsis",
                                                    whiteSpace: "nowrap",
                                                    overflow:"hidden"
                                                }}
                                                >{item.vesselName}</p>
                                                <Tooltip
                                                mouseLeaveDelay = {1}
                                                title={item.synopsis} color= "gold" key={item.synopsis} placement = "topLeft">
                                                    <p className='summary'
                                                    // title = {item.synopsis}
                                                    style = {{
                                                        width: "100%",
                                                        textOverflow: "ellipsis",
                                                        whiteSpace: "nowrap",
                                                        overflow:"hidden",
                                                        display:"inline-block",
                                                        cursor: "pointer"
                                                    }}
                                                > {item.synopsis}</p>
                                                </Tooltip>
                                                    {//筹款超时，或者结束
                                                        item.percentage <= 0 || Number(item.status)  === 3 ? (
                                                        <div
                                                        style ={{
                                                            marginTop: "16px",
                                                            padding: "20px 16px",
                                                            textAlign: "center",
                                                            fontSize: "14px",
                                                            fontWeight: 400,
                                                            background: "#f6f6f6",
                                                            lineHeight: "25px"
                                                        }}
                                                        >
                                                            Completed
                                                        </div>
                                                        ):(
                                                            <div className="digist row">
                                                            <div>
                                                                <p className='blue'> {item.percentage}% </p>
                                                                <p>Remaining</p>
                                                            </div>
                                                            <div>
                                                               {
                                                                   item.expireDay !== 0?
                                                                   (
                                                                       <React.Fragment>
                                                                           <p className='yellow'>{item.expireDay} </p>
                                                                            <p>days left</p>
                                                                       </React.Fragment>

                                                                   ):(
                                                                    <React.Fragment>
                                                                        <p className='yellow'>{'Last'} </p>
                                                                        <p className= 'yellow'>Chance</p>
                                                                    </React.Fragment>
                                                                   )
                                                               }
                                                                {/* {Number(item.expireDay) === 0 ? '等于0': '不等于'} */}
                                                            </div>
                                                            </div>
                                                        )
                                                    }
                                            </div>

                                        </div>

                                        <div className="row info">
                                            {/* 修改为三行显示 */}
                                            <div className='item'>
                                                <p className='label'>
                                                    Total available funds
                                                    {/* Buyable */}
                                                    </p>
                                                <p className='value'>
                                                    USD {formatMoney(item.fundraising)}
                                                </p>

                                                <div className="line"></div>

                                                <p className='label'>
                                                Fundraising progress
                                                    </p>
                                                <p className='value'>
                                                    USD {formatMoney(item.currentFundraising || 0)}
                                                </p>
                                                <div className="line"></div>
                                                <p className='label'>
                                                    {/* Available funds */}
                                                    Buyable
                                                    </p>
                                                <p className='value'>
                                                    USD {formatMoney((item.fundraising - item.currentFundraising))}
                                                </p>
                                            </div>
                                            <div>
                                            <div style = {{marginBottom: "20px",textAlign: "left"}}>
                                                <p style = {{color: "#5e5e5e",lineHeight: 1.2,margin: "5px 0"}}>Valid from</p>
                                                <p style = {{marginTop: "8px",fontWeight:"bold",color:"#164a78" }}>{dayjs(item.publishTime).format('YYYY/MM/DD')}</p>
                                                <p style = {{color: "#5e5e5e",lineHeight: 1.2,margin: "5px 0"}}>To</p>
                                                <p style = {{marginTop: "8px",fontWeight:"bold",color:"#164a78" }}>{dayjs(item.expireTime).format('YYYY/MM/DD')}</p>
                                            </div>
                                            <Button
                                                type='ghost'
                                                className='detail-btn'
                                                onClick={() => handleToDetail(item)}
                                            >
                                                View Details
                                            </Button>
                                            </div>
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                    </div>
                    <Pagination className='mdr-pagination'
                        showTotal={(total) => `${total} items in total`}
                        showSizeChanger={false} showQuickJumper total={store.total} />
                </div>
            </div>


        </section >
    )
})

export default OfferIndex;
