import React, { useState, useEffect } from 'react';

import './index.scss';
import { Title, Button } from '../../../../components';
import { getUserInfo,transLocal,getAllOptionsValue } from '../../../../utils';
import { Button as Abt, Table, Pagination,Modal } from 'antd'
import { useHistory } from 'react-router-dom';
import api from '../../../../api';
import store from '../store';
import { observer } from 'mobx-react';
import OfflineMessage from '../Topup/components/offlineMessage';

let  columns:any;
const Cash = observer(() => {
    const [total, setTotal] = useState(0);
    const [list, setList] = useState([]);
    const [pageIndex, setPageIndex] = useState(1);
    const [offlineMessage,setOfflineMessage] = useState(false);//线下充值弹出的modal层
    const history  = useHistory();
    const [auditDay,setAuditDay] = useState(14);
    const [serialNumber,setSerialNumber] = useState('');
    useEffect(() => {
        fetchList();
    },[pageIndex]);
    useEffect(() => {
        getAuditDay();
    },[])

    const getAuditDay = async () => {
        const optionsValue = await getAllOptionsValue()
        setAuditDay(optionsValue['investorCertEffective'] || 14)
    }
    const fetchList = async () => {
        const userInfo = getUserInfo();
        const { list = [], total }  = await api.moneyPageList({
           pageIndex,
           pageSize: 5,
           param: {
            type: userInfo?.type === 4 ? 1: userInfo?.type
           }
       }) || {};

       

       store.totaMoney = list[0] ? list[0].allIncomeMoney : 0;

       setList(list);
       setTotal(total);
    }
    const getViewDetail  = (row:any) => {//根据不同的身份跳转到不同的页面
        // 如果是线下充值就展示modal信息
        if(row.sourcePurposeType  === 1) {//线下充值
            setSerialNumber(row.serialNumber || '');
            setOfflineMessage(true);
        }else {
            history.push(`/usercenter/invest/detail/${row.relationId}`);
        }
        // if(rootStore.getAuthType === 1){//如果是投资者
            
        // }else if(rootStore.getAuthType === 2 || rootStore.getAuthType === 4) {//船东，船东和投资者
        //    history.push(`/usercenter/sto/detail/${row.relationId}`);
        // }
    }
    columns = [
        {
            title: 'Time',
            dataIndex: 'createTime',
            key: 'createTime',
            width: 170
        },
        {
            title: 'Type',
            dataIndex: 'addType',
            key: 'addType',
            width: 105
        },
        {
            title: "Serial number",
            dataIndex: "serialNumber",
            key: "serialNumber",
            width: 170
        },
        {
            title: 'Amount change',
            dataIndex: 'accountChange',
            key: 'accountChange',
            width: 100,
            render:(item: string) => transLocal(Number(item)),
            
        },
    
        {
            title: 'Channel',
            dataIndex: 'channel',
            key: 'channel',
            width: 100,
            render:(item: string) => item || '--'
            
        },
        {
            title: 'User operation',
            dataIndex: 'sourcePurpose',
            key: 'sourcePurpose',
            width: 120,
            // render: (item: string) => dayjs(item).format('YYYY-MM-DD')
        },
        {
            title: ' Status',
            dataIndex: 'status',
            key: 'status',
            width: 120,
            //@ts-ignore
            render: (item: string) => {
                const map = {
                    1: 'Succeeded',
                    2: 'Under Review',
                    3: 'Cancelled',
                }
                //@ts-ignore
                return map[item];
            }
        },
        {
            title: ' Remark',
            dataIndex: 'remark',
            key: 'remark',
            width: 120,
            //@ts-ignore
            // render: (item: string) => STO_STATUS_MAP[item]
        },
        {
            title: 'Operation',
            dataIndex: 'operation',
            key: 'operation',
            render: (item: any, row: any) => {
                // const list : Array<string> = ['Refund investment' ] ;
                // if(!row.relationId) return null;
                // 如果是线上充值就不返回信息
                if(row.sourcePurposeType  === 2 || row.sourcePurpose === 'Withdraw') {
                    return null;
                }
                return (
                    <Abt type='link' onClick = {() => {getViewDetail(row)}} style={{ fontSize: '12px' }}>View Detail</Abt>
                )
            }
        },
    
    ];

    return (
        <div className="cash-wrap">
            <div className="summary-wrap row">
                <Title>Available Cash</Title>

                <p className='money'><span className='count'>{
                transLocal(Number(store.totaMoney))
                }</span><span>MDRX</span></p>

                <div className="btn-wrap">
                    <Button size='middle' onClick={() => {
                        history.push('/usercenter/wallet/topup')
                    }} type='primary'>Deposit</Button>

                    <Button onClick={() => {
                         history.push('/usercenter/wallet/withdraw')
                    }} size='middle' style={{marginLeft: '15px'}} type='ghost'>Withdraw</Button>
                </div>

            </div>

            <Table 
            pagination={false} 
            columns={columns} 
            scroll= {{x: 1150}}
            dataSource={list}>
            {/* scroll = {{x :1500, y: 300}} */}
            </Table>
            <Pagination style={{ marginTop: '50px' }} className='mdr-pagination'
                showTotal={(total) => `${total} items  in total`}
                onChange={(page) => setPageIndex(page)}
                showSizeChanger={false}
                showQuickJumper
                pageSize={5}
                defaultCurrent={pageIndex}
                total={total}
            />
            {/* 线下充值弹窗弹窗 */}
            <Modal
                footer={null}
                visible={offlineMessage}
                closable={false}
                width = {740}
            >
                <OfflineMessage serialNumber = {serialNumber} auditDay = {auditDay}></OfflineMessage>
                <div style = {{
                    textAlign: "center"
                }}>
                    <Button onClick={() => {
                        // setPayHelp(false);
                        // history.push(`/about?keyword=Need Help&id=9`)
                        setOfflineMessage(false);
                    }
                    } type='ghost' size='small' >Ok</Button>
                </div>
            </Modal>
        </div>
    )
})

export default Cash;