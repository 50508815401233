import React, { useEffect, useState } from 'react';
import './index.scss';
import Form, { useForm } from 'antd/lib/form/Form';
import vesselStore from '../../store';
import StepRootStore from '../../../../store';
import DynamicFormItem, { IDtem } from '../../../../../../../../../components/DynamicFormItem';
import { Button } from '../../../../../../../../../components';
import { formDataParser } from '../../../../../../../../../utils';
import api from '../../../../../../../../../api';

interface IProps {
    data: IDataCode[];
    getDisabledItem: Function;
    disabledItem: IDisabledItem[];
}
interface IDataCode {
    code?:string;
}
interface IDisabledItem {
    code: string;
    disabled: boolean;
}


const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

const Basic: React.FC<IProps> = (props) => {
    const { data,getDisabledItem,disabledItem } = props;
    const [fileMap, setFileMap] = useState({});
    const [prevData,setPrevData] = useState([]) as any;
    const [form] = useForm();
    const getVessleType = async () => {
        const getData = await api.getStoInfoMainById(StepRootStore.stoId);
        StepRootStore.setVessleType(getData.vesselType);
        StepRootStore.setVessleTypeName(getData.vesselTypeName);
        initFirstData();

    }
    const initFirstData = () => {
        let firstCode = '';
        // 只要船型包含101 都需要进行更新
        data.forEach((item => {
            if(item.code && item.code.endsWith('101')) {
                firstCode = item.code;
            }
        }))
        if(firstCode) {
            form.setFieldsValue(formDataParser(data, true, StepRootStore));
            form.setFieldsValue({[firstCode]: StepRootStore.vessleTypeName})
        } else {
            form.setFieldsValue(formDataParser(data, true, StepRootStore));
        }
    }
    // 初始设置值判断是否存在船型，不存在就创建
    useEffect(() => {
            if(!StepRootStore.vessleType) {
                getVessleType();//请求以后再设置值;
            }else {
                initFirstData()
            }
    }, [data]);
    if(data !== prevData) {
        setPrevData(data);
    }
    useEffect(() => {
        prevData.forEach((item:IDtem) => {
            disabledItem.forEach((itemChild:IDisabledItem) =>{
                if(item.code === itemChild.code) {
                    const itemChecks:any = JSON.parse(item.checks);
                    itemChecks.disabled = itemChild.disabled;
                    item.checks = JSON.stringify(itemChecks);
                }
            })
        })
        setPrevData(prevData.slice());
    },[data])
    const handleSave = () => {
        form.validateFields().then(() => {
            // 保存的时候需要把原来的船型列表给替换回来
            const saveValue = Object.assign({...form.getFieldsValue(),...fileMap},{'T101':StepRootStore.vessleType});
            vesselStore.updateCurForm(saveValue);
        })
    }
    // 保存信息
    const saveVessialBasicMessage = () => {
        // 保存的时候需要把原来的船型列表给替换回来
        const saveValue = Object.assign({...form.getFieldsValue(),...fileMap},{'T101': StepRootStore.vessleType});
        vesselStore.updateFormMessage(saveValue);
    }
    
    return (
        <div className='basic-vessel-document'>
            <p className='section-title'>
              
            </p>
           
            <Form
                form={form}
                {...layout}
                labelAlign='right'
                layout='horizontal'
                colon={false}
                scrollToFirstError={true}
            >
                {
                    prevData.map((item: any) => 
                        <DynamicFormItem
                            readonly={!StepRootStore.canEdit}
                            form={form} 
                            key={item.id}
                            data={item as IDtem}
                            onFileChange={(code, value) => {
                                //@ts-ignore
                                fileMap[code] = value;
                                setFileMap(fileMap)
                            }}
                            onMSelectChange = {result => {//select联动配置
                            //根据传递的code值改变相应值
                            prevData.forEach((itemChild:IDtem) => {
                                if(itemChild.code === result.code) {
                                    const setData = {[result.code]: result.checkValue};
                                    form.setFieldsValue(setData);
                                    // 维护数组设置当前已经禁用的数据每次渲染就重新赋值数据
                                    //设置是否禁用
                                    const itemChecks:any = JSON.parse(itemChild.checks);
                                    itemChecks.disabled = result.disabled;
                                    itemChild.checks = JSON.stringify(itemChecks);
                                    // 返回需要设置disabled的code
                                    getDisabledItem(result.code,result.disabled);
                                }
                                })
                                setPrevData(prevData.slice())
                            }}
                        />

                    )}

            </Form>

            <div className="button-wrap">
                <Button
                    arrowLeft
                    type='ghost'
                    size='middle'
                    style={{ marginRight: '23px' }}
                    onClick={() => StepRootStore.setCurStep('companyInfo')}
                >
                    Go Back
                </Button>
                {
                data.length > 0 && StepRootStore.canEdit &&  
                    <Button
                    type='ghost'
                    size='middle'
                    style={{ marginRight: '23px' }}
                    onClick={saveVessialBasicMessage}
                    >
                        Save
                    </Button>
                }
                {
                    data.length > 0 && (

                        <Button
                            arrowRight
                            size='middle'
                            onClick={handleSave}
                        >
                            {'Next'}
                        </Button>
                    )
                }
            </div>

        </div>
    );
}

export default Basic;