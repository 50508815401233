export default {
    "introudce.investors.benefit.title": "How MDR benefits individual investors",

    "introudce.investors.benefit.list": JSON.stringify([
        "Investments are backed by physical marine assets",
        "Benefits and capital costs are fully guaranteed. Investors act as shareholders of SPV (special purpose vehicle) entities",
        "Customizable scale options allow customers to invest as much or as little as they please",
    ]),

    "introudce.invsetors.option.title": "An Alternative Investment Option",

    "introudce.invsetors.option.detail": "All Investments made through MDR are backed by real-world shipping assets.",

    "introudce.invsetors.option.morelink": "Learn more about marine market",

    "introudce.invsetors.option.label": "Select a Type",

    "introudce.invsetors.option.invest": " Invest Now",

    "introudce.invsetors.about.title": "About MDR",
    "introudce.invsetors.about.detail": "MDR is an informational platform that increases liquidity and transparency while lessening traditional ship owning entry barriers.",
    "introudce.invsetors.about.more": "Learn More >",

    "introudce.invsetors.apply.title": "Existing Shipowners",

    "introudce.invsetors.apply.desc": "Apply online to be a part of the tokenized shipping revolution",

    "introudce.invsetors.apply.btn": "Apply STO Now",

    "introudce.shipowner.title": "Marine Digital Republic",
    // "introudce.shipowner.title.desc": "Is a blockchain-based platform that cuts out ship owning barrier-of-entry costs and allows everyone to become a ship owner.",
    "introudce.shipowner.title.desc": "MDR is an informational platform that increases liquidity and transparency while lessening traditional ship owning entry barriers.",
    "introudce.shipowner.benefit.title": "How MDR benefits the Shipping Industry",

    // "introudce.shipowner.benefit.list": JSON.stringify([
    //     "Builds the world's first ship asset STO exchange, reduces financing costs by more than 50%, combines national incentive policies, and attracts new global investor demographics.",
    //     "Provides experience and ideas for the STO of the heavy asset of assets and digital accuracy, the authenticity of operational of assets and digital accuracy, the authenticity of operational and regulatory information.",
    //     "Reducing the investment threshold of ships, bringing trillions of capital investment to the shipping industry,and promoting the survival of the fittest , helping the 100 billion shipping market to improve efficiency",
    //     "Ensures legal compliance in operations and transactions in accordance with regulatory standards.",
    //     "Guides new shipping entities to Hong Kong, making Hong. Kong the largest SPV registration place and strengthening Hong Kong's 'ship king' status.",
    // ]),
    "introudce.shipowner.benefit.list": JSON.stringify([
        "Provides a platform to assist shipowners and investors with deals or project-making in ship asset trading and management. Blockchain technology and the MDR STO process enable better ship asset operational transparency and liquidity.",
        "Provides experience and ideas for the STO of the heavy assets and digital accuracy, the authenticity of operational of assets and digital accuracy, the authenticity of operational and regulatory information.",
        "Reduces the investment threshold of ships. Improves efficiency of ship-asset projections and management."
    ]),

    "introudce.shipowner.apply.title": "STO Process is open to",

    "introudce.shipowner.apply.detail": JSON.stringify([
        "4 Types of vessels ",
        "Any portion as you need",
        " Liquidate it NOW",
    ]),
    "introudce.shipowner.apply.btn": "Apply STO Now",

    "introudce.shipowner.faq.title": "Frequently Asked Questions about STO",

    "introudce.shipowner.offer.title": "Investment Offering",

    "introudce.shipowner.offer.desc": "Apply online to be a part of the tokenized shipping revolution",
};