import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';

import { observer } from 'mobx-react';

import { Button, Title } from '../../../../../components';

import store from '../../../store';

import './index.scss'
import { gunzip } from 'zlib';
const Done: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();
    const [time, setTime] = useState(10);

    const [username, setusername] = useState('')

    useEffect(() => {
        if (time === 0) {
            store.setActive(1);
        }

        const givenName = sessionStorage.getItem('givenName');

        const selfName = sessionStorage.getItem('surname');

        setusername(`${selfName} ${givenName}`)
        const timer = setInterval(() => {
            setTime(time => time = time - 1)
        }, 1000)
        return () => clearInterval(timer);
    }, [time]);

    return (
        <div className='done-wrap'>

            <Title>{f({ id: 'register.done.tips' })}</Title>

            <Title style={{ marginTop: '26px' }}>
                {username}, {f({ id: 'register.done.subtips' })}
            </Title>

            <span className='subtips'>
                {f({ id: 'register.done.autologin' }, { time: time })}
            </span>
            <Button
                size='middle'
                onClick={() => store.setActive(1)}
            >
                {f({ id: 'login' })}
                <img alt='icon' src={require('../../../../../assets/image/arrow.png')}></img>
            </Button>
        </div>
    )
});

export default Done;