import React, { useEffect, useState } from 'react';
import { Form, TreeSelect, Input, message,Modal } from 'antd';
import store from './store';
import StepStore from '../../store';
import { MSelect, Button } from '../../../../../../../components';
import { VESSEL_TYPE, OBJECTIVE, GOAL } from '../../../../../../../constants';
import './index.scss';
import { observer } from 'mobx-react';
import { useForm } from 'antd/lib/form/util';
import { useStores, useEventListener, useInterval, } from '../../../../../../../hooks';

const layout = {
    labelCol: { span: 10 },
    wrapperCol: { span: 14 },
};

const ProjectType: React.FC = observer(() => {

    const [form] = useForm();

    const { rootStore } = useStores();

    const [projectName,setProjectName] = useState('')

    useEffect(() => {
        store.startPinCheck();
    }, [rootStore.hasPin]);

    // useEventListener('mousemove', () => {
    //     if (!StepStore.stoId) return;
    //     sessionStorage.setItem('lastTime', Date.now().toString());
    // })
    useEffect(() => {
        //@ts-ignore
        store.init(form);
        // store.fetchVesselTypeList();//获取到船型
    }, [form]);

    const handleNewSto = () => {
        form.validateFields().then(() => {
            const data = form.getFieldsValue();
            store.updateStoInfoMain({
                ...data
            });
        });
    }
    const treeNodeSelect = (value: any, node: any) => {
        if (node.children) {
            // message.warning('请选择具体船型');
            form.setFieldsValue({ "vesselType": '' });
            return false;
        }
        StepStore.vessleType = value;
        // 增加字段控制第三个内容的回显
        StepStore.vessleTypeName = node.label
    }

    return (
        <div className='projectType-wrap'>
            <Form
                {...layout}
                form={form}
                labelAlign='left'
                hideRequiredMark
                className='init-form'
            >

                {
                    StepStore.stoId && (
                        <Form.Item className='projectno' label='Project No'>
                            <span>{store.projectNo}</span>
                        </Form.Item>
                    )
                }

                <Form.Item rules={[{ required: true }]} label='Project Type' name='projectType'>
                    <MSelect
                        placeholder="Please select"
                        dataSource={VESSEL_TYPE}
                        onChange={(data)=>{
                            // setProjectName(data)
                            store.fetchVesselTypeList(data);//获取到船型
                        }}
                        disabled={!!StepStore.stoId}
                    />
                </Form.Item>

                <Form.Item rules={[{ required: true }]} required label='Vessel Type' name='vesselType'>
                    <TreeSelect
                        className='mdr-select'
                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                        placeholder="Please select"
                        allowClear
                        onChange={(data, a) => {
                            console.log(data);
                            // StepStore.vessleType = a[0] as string;
                        }}
                        onSelect={treeNodeSelect}
                        treeDefaultExpandAll
                        disabled={!!StepStore.stoId}
                        treeData={store.vesselTypeList}
                    />
                </Form.Item>

                <Form.Item rules={[{ required: true }]} label='Project Objective' name='projectObjective'>
                    <MSelect
                        placeholder="Please select"
                        disabled= {!StepStore.canEdit}
                        dataSource={OBJECTIVE}
                    />
                </Form.Item>

                <Form.Item rules={[{ required: true }]} label='Financial Goal' name='financialGoal'>
                    <MSelect
                        placeholder="Please select"
                        disabled= {!StepStore.canEdit}
                        dataSource={GOAL}
                    />
                </Form.Item>

                <Form.Item rules={[{ required: true }]} label='Give a Project Name' name='projectName'>
                    <Input
                     style={{ height: '49px' }} 
                     disabled= {!StepStore.canEdit}
                    className='m-input'></Input>
                </Form.Item>
            </Form>

            <div style={{ marginTop: '30px' }}>
                <Button
                    style={{ marginLeft: '20px' }}
                    arrowRight
                    size='middle'
                    onClick={handleNewSto}
                >{
                        StepStore.canEdit ? (store.projectNo ? 'Save & Next' : 'Create STO Now') : 'Next'
                    }
                </Button>
            </div>

        </div>
    )
})

export default ProjectType;