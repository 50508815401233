import moment from "moment";
import api from "../api";
import request from "./request";
import momentTz from 'moment-timezone'

export const formDataParser = (data: any, isBasic: boolean, StepRootStore?: any) => {
    const valueList = data.map((item: any) => {
        let obj = {} as any;
        if (isBasic && item.code.endsWith('101') && !item.propertyValue) {
            obj[item.code] = StepRootStore.vessleType;
        } else {
            obj[item.code] = item.propertyValue
        }
        if (item.type === 'date' || item.type === 'year' || item.type === 'month') {
            if(!item.propertyValue) {
                obj[item.code] = item.propertyValue
            }else {
                obj[item.code] = moment(item.propertyValue)
            }

        }

        // if(item.type === 'dateRange') {
        //     obj
        // }
        return obj;
    });

    const formData = valueList.reduce((prev: any, next: any) => {
        Object.keys(next).map(item => {
            prev[item] = next[item];
        })
        return prev;
    }, {})
    return formData;
}

export const getImageUrl = async (id: string) => {
    if (!id) return;
    return await api.getFileUrl(id)
}

//获取到用户相关信息
export const getUserInfo = () => {
    try {
        const userInfo = sessionStorage.getItem('userInfo');
        if (!userInfo) return null;
        return JSON.parse(userInfo);
    } catch (error) {
        console.log(error)
    }
}


export const formatMoney = (num: any) => {
    /*let t: any = parseInt(n), i, r;
    for (t = t.toString().replace(/^(\d*)$/, "$1."), t = (t + "00").replace(/(\d*\.\d\d)\d*!/, "$1"), t = t.replace(".", ","), i = /(\d)(\d{3},)/; i.test(t);)
        t = t.replace(i, "$1,$2");
    return t = t.replace(/,(\d\d)$/, ".$1"), r = t.split("."), r[1] == "00" && (t = r[0]), t
*/
    var res = num.toString().replace(/\d+/, function (n:any) { // 先提取整数部分

        return n.replace(/(\d)(?=(\d{3})+$)/g, function ($1:any) {

            return $1 + ",";

        });

    });

    return res;

}

// 转换千分位 2位小数
const toNumber = (num: any) => {
    let data = num
    .toFixed(2)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return data;
}

// 千分位保留2位小数
export const formatTwoMoney = (num: any) => {
 // 保留小数  不取整
 const newData = Number(num.toString().match(/^-?\d+(?:\.\d{0,2})?/))

 return toNumber(Number(newData));
}

// 转换千分位 2位小数
const toLingNumber = (num: any) => {
    let data = num
    .toFixed(0)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return data;
}

// 千分位保留2位小数
export const formatLingMoney = (num: any) => {
 // 保留小数  不取整
 const newData = Number(num.toString().match(/^-?\d+(?:\.\d{0,2})?/))

 return toLingNumber(Number(newData));
}

// 转换千分位 3位小数
const threeMoney = (num: any) => {
    let data = num
    .toFixed(3)
    .toString()
    .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return data;
}

export const formatThreeMoney = (num: any) => {
//     let data = num
//     .toFixed(3)
//     .toString()
//     .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
//   return data;
const newData = Number(num.toString().match(/^-?\d+(?:\.\d{0,3})?/))

return threeMoney(Number(newData));
}

// 千分位保留2位小数
export const formatMoneyDecimal = (num: any,n:any) => {
    // debugger
 
    // 保留小数  不取整
    const newData = Number(num.toString().match(/^-?\d+(?:\.\d{0,3})?/))

  return threeMoney(Number(newData));
}

// UTC 时区名称集合
const ChannelType = {
    London: 'London',
    Hamburg: 'Hamburg',
    HongKong:'Hong Kong',
    Beijing:'Beijing',

    description(rawValue:any) {
      switch (rawValue) {
        case this.London: return 'Europe/London'; // 伦敦时间
        case this.Hamburg: return 'Europe/Berlin'; // 巴黎时间
        case this.HongKong: return 'Hongkong'; // 香港时间
        case this.Beijing: return 'Asia/Shanghai'; // 上海时间
        default: return 'Europe/London';
      }
    },
  };

  export default ChannelType

// 根据不同的时间类型显示 日期、时间、    type: time(12:03:04) / date(2021-04-05) / datetime(2020-12-23 12:34:34)
  export function formatTime(time: any,type:any){
      // 默认时间 伦敦
    if (!localStorage.getItem('changeUTC') || localStorage.getItem('changeUTC') == ChannelType.London) {
        if(time && type == 'hour') {
            return momentTz(new Date(time)).format('MM/DD HH:mm')
        }
        if(time && type == 'time') {
            return momentTz(new Date(time)).format('HH:mm:ss')
        }
        if(time && type == 'date') {
            return momentTz(new Date(time)).format('YYYY-MM-DD')
        }
        if(time && type == 'datetime') {
            return momentTz(new Date(time)).format('YYYY-MM-DD HH:mm:ss')
        }
        return '--'
    } else {
        let utc = moment.tz(time,'UTC'); 
        // let newUTC = utc.format(); 
        if(time && type == 'hour') {
            return momentTz.tz(utc.format(),ChannelType.description(localStorage.getItem('changeUTC'))).format('MM/DD HH:mm')
        }
        if(time && type == 'time') { 
            return momentTz.tz(utc.format(),ChannelType.description(localStorage.getItem('changeUTC'))).format('HH:mm:ss')
        }
        if(time && type == 'date') {
            return momentTz.tz(utc.format(),ChannelType.description(localStorage.getItem('changeUTC'))).format('YYYY-MM-DD')
        }
        if(time && type == 'datetime') {
            return momentTz.tz(utc.format(),ChannelType.description(localStorage.getItem('changeUTC'))).format('YYYY-MM-DD HH:mm:ss')
        }
        return '--'
    }
    
    
  }

// 传递percent true，就不保留两位小数
export const transLocal = (num:number,percent?:boolean) =>{
    if(isNaN(num)) {
        return '';
    }
    if(!num) {
        return num;
    }
    const toNumber = Number(num);
    if(isNaN(toNumber)) {
        return num;
    }
    const number = toNumber.toLocaleString();
        let arr = number.split('.')
        if(!arr[1]) {
            return number + '.00'
        }else if(arr[1].length < 2) {
            return arr[0] + '.' +arr[1] + '0';
        }else {
            return number;
        }
}
export const sendSms = (phoneNumber?: string) => {
    const userInfo = getUserInfo();
    if (!phoneNumber && !userInfo) return;
    const phone = phoneNumber || `${userInfo.phoneCountry}${userInfo.phoneNumber}`;
    return api.sendSms({ phoneNumber: phone });
}

export const sendSmsVerificationWithUser = (phoneNumber?: string) => {
    const userInfo = getUserInfo();
    if (!phoneNumber && !userInfo) return;
    const phone = phoneNumber || `${userInfo.phoneCountry}${userInfo.phoneNumber}`;
    return api.sendSmsVerificationWithUser({ phoneNumber: phone });
}

export const sendEmail = (eamil: string) => {
    return api.sendEmail(eamil);
}

export const checkSms = (code: string) => {
    const userInfo = getUserInfo();
    if (!userInfo) return;
    const phone = `${userInfo.phoneCountry}${userInfo.phoneNumber}`;
    return api.checkSms({ phoneNumber: phone, code, });
}

export const checkSmsUser = (code: string) => {
    const userInfo = getUserInfo();
    if (!userInfo) return;
    const phone = `${userInfo.phoneCountry}${userInfo.phoneNumber}`;
    return api.checkSmsUser({ phoneNumber: phone, code, });
}


export const getAllOptions = async (key: string) => {
    const data = await api.options() || {}
    return data[key];
}
export const getAllOptionsValue = async() => {
    const data = await api.options() || {};
    return data;
}
export const updateUserInfo = async () => {
    const {id} = getUserInfo() || {};
    if(!id) return
    const data = await api.findUserById({id}) || {};
    sessionStorage.setItem('userInfo', JSON.stringify(data));
}
export const getCurrentUserInfo = async() => {
    const {id} = getUserInfo() || {};
    if(!id) return {};
    const data = await api.findUserById({id}) || {};
    return data;
}

export const download = (url: string, fileName: string) => {
    const a = document.createElement('a');
    a.href = url;
    a.target="__blank"
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
}
// 防抖处理
export const throttle:any = function(fn:Function, wait:number, scope?:any) {
    clearTimeout(throttle.timer);
    throttle.timer = setTimeout(function() {
        fn.apply(scope);
    }, wait);
};
