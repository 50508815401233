import { observable, action } from "mobx";
import api from "../../../api";
import { ILoginRes } from "../../../api/type";
import cookie from 'js-cookie';
import { message } from "antd";

import rootStore from '../../../store/index';

class Store {
  @observable curStep = "email";
  loginStep: Array<string> = [];

  @observable smsVerify: boolean = false;
  @observable phoneNumber: string = "";

  @observable email: string = "";
  @observable password: string = "";
  // code 验证码
  @observable code: string = "";
  @observable nextLoading:boolean = false;

  onLogin: Function | undefined;

  userInfo: ILoginRes | null = null;

  setLoginCb(onLogin: Function) {
    this.onLogin = onLogin;
  }

  @action.bound
  setCurStep(curStep: string) {
    this.curStep = curStep;
  }
  @action.bound
  setNextLoading(value:boolean) {
    this.nextLoading = value
  }

  async login() {
    try {
      await api.login({
        email: this.email,
        password: this.password,
      });
      // 设置next的按钮为可以点击的状态
      this.setNextLoading(false);
      this.toNext();
    } catch (error) {
      this.setNextLoading(false);
    }
  }

  async checkSms(value: string) {
    const userInfo = await api.checkLoginSms({
      email: this.email,
      code: value,
      password:this.password
    })

    message.success('Verification code checked');

    // 保存验证码
    this.code = value;
    this.userInfo = userInfo;
  }


  async handleLogin() {
    if (!this.onLogin) return;
    console.log(this.smsVerify);

    if (!this.smsVerify) {
      message.warning("Please input a correct verification code")
      return
    }
    // 登录传递 email code 从上面拿
    // await api.checkLoginSms({
    //   email: this.email,
    //   code: this.code,
    // });

    await api.deleteLoginSms({
      email: this.email,
      code: this.code,
      password: this.password
    })

    rootStore.login(this.userInfo);
    this.onLogin();
  }

  toNext = () => {
    const index = this.loginStep.findIndex((item) => item === this.curStep);
    const next = index + 1;
    if (next < this.loginStep.length) {
      this.setCurStep(this.loginStep[next]);
    }
  };

  handleNextStep = async () => {
    if(this.nextLoading){//如果发送请求就不再处理
      return;
    }
    if(this.curStep === 'email') {
      this.setNextLoading(true);//点击设置loading状态
    }
    try {
      if (this.curStep === "email") {
        this.login();
      }

      if (this.curStep === "phone" && this.smsVerify) {//验证码界面，并且验证码正确
        this.handleLogin();
        return;
      }
    } catch (error) {
      alert(error);
    }
  };
}

export default new Store();
