import React from 'react';
import { List } from 'antd';
import './index.scss';
import { Title, Button, Folder } from '../../../../../../components';
import { useHistory } from 'react-router-dom';
import { formatMoney,transLocal,formatLingMoney } from '../../../../../../utils';
import dayjs from 'dayjs';


const cols = (data: any) => [
    {
        key: 'Raising fund',
        value: transLocal(data.raisingFund),
        extra: 'MDRX'
    },
    {
        key: 'Token number',
        value: formatLingMoney(data.tokenNumber),
        extra: 'Tokens'
    },
    {
        key: 'Token price',
        value: transLocal(data.tokenPrice),
        extra: 'MDRX'
    },
    {
        key: 'Number of tokens user hold',
        value: formatLingMoney(data.numberOfTokenUserHold),
        extra: 'Tokens'
    },
    {
        key: 'Total fundraising',
        value: transLocal(data.totalFundraising),
        extra: 'MDRX'
    },
    {
        key: 'Fundraising start date',
        value: dayjs(data.publishTime).format('YYYY-MM-DD'),
        extra: 'MDRX'
    },
    {
        key: 'Current fundraising status',
        value: data.fundraisingStatus !== 'Active' ? dayjs(data.fundraisingStatus).format('YYYY-MM-DD') : data.fundraisingStatus,
        extra: data.fundraisingStatus === 'Active' ? `${data.fundraisingDays} Days Left` : ''
    },

]

interface IProps {
    dataSource: any
}


const Investment: React.FC<IProps> = ({ dataSource }) => {
    const handleToStoDetail = () => {
        const url = `${window.location.origin}/#/usercenter/sto/apply?stoId=${100}`
        window.open(url)
    }

    const { purchase } = dataSource;

    const data = cols(dataSource);

    return (
        <div className="detail-investment">
            <Folder fold={true} title={`${dayjs(purchase).format('YYYY-MM-DD')} Fundraising`}>
                <div className="list-wrap">
                    <span className='list-title'>Fundraising</span>
                    <List
                        bordered
                        dataSource={data.map(item => JSON.stringify(item))}
                        renderItem={(item: string) => {
                            const items: any = JSON.parse(item);
                            return (
                                <List.Item className='row property-item'>
                                    {/* <div className='r0w'> */}
                                    <p className='label'>{items.key}</p>
                                    <p className='value'>{items.value}</p>
                                    <p className='extra'>{items.extra}</p>
                                    {/* </div>    */}
                                </List.Item>
                            )
                        }}
                    />
                </div>
            </Folder>
        </div>
    )
}

export default Investment