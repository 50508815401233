import React from 'react';

import './index.scss';

interface ITitle {
    children: React.ReactNode;
    color?: string;
    style?: React.CSSProperties;
    sub?: boolean;
}

const Title: React.FC<ITitle> = (props) => {
    const { sub, style, color } = props;
    return (
        <p
            className={['mdr-title', sub ? 'sub' : ''].join(' ')}
            style={{ color: color, ...style }}
        >
            {props.children}
        </p>
    )
}

export default Title;