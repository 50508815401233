/* eslint-disable react-hooks/rules-of-hooks */
import React, {useRef, useEffect} from "react";

import qs from "querystring";
import {MobXProviderContext} from "mobx-react";
import {Store} from "../store/index";
import {useLocation} from "react-router-dom";

interface IStore {
    rootStore: Store;
}

export function useStores(): IStore {
    return React.useContext(MobXProviderContext) as IStore;
}

export function useUrlQuery(key: string, querystring?: string): string {
    const {search} = useLocation();
    const values = qs.parse(querystring || search.replace("?", ''));
    return values[key] as string;
}

// Hook
export function useEventListener(eventName: string, handler: Function, element = window) {
    // Create a ref that stores handler
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const savedHandler = useRef();

    // Update ref.current value if handler changes.
    // This allows our effect below to always get latest handler ...
    // ... without us needing to pass it in effect deps array ...
    // ... and potentially cause effect to re-run every render.
    useEffect(() => {
        //@ts-ignore
        savedHandler.current = handler;
    }, [handler]);

    useEffect(
        () => {
            // Make sure element supports addEventListener
            // On
            const isSupported = element && element.addEventListener;
            if (!isSupported) return;

            // Create event listener that calls handler function stored in ref
            //@ts-ignore
            const eventListener = event => savedHandler.current(event);

            // Add event listener
            element.addEventListener(eventName, eventListener);

            // Remove event listener on cleanup
            return () => {
                element.removeEventListener(eventName, eventListener);
            };
        },
        [eventName, element] // Re-run if eventName or element changes
    );
};

//hook
export function useInterval(callback: Function, delay: number) {
    const savedCallback = useRef();

    // 保存新回调
    useEffect(() => {
        //@ts-ignore
        savedCallback.current = callback;
    });

    // 建立 interval
    useEffect(() => {
        function tick() {
            //@ts-ignore
            savedCallback.current();
        }

        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}
