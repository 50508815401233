import React, { useState, useEffect } from 'react';

import './index.scss';
import { useIntl } from 'react-intl';
import { Button, FloatCard } from '../../components';
import { Link, useHistory } from 'react-router-dom';
//杨晓扬的引入
import { getUserInfo } from '../../../src/utils';
import { message, Button as Abt,Modal } from 'antd';
import api from '../../api';
import { VESSEL_TYPE } from '../../constants';
//
interface  IHomePage{
    "BannerTitle"?: string,
    "BannerButton"?:string,
    "BannerLink"?: string,
    "P1Title"?: string,
    "P1Text"?: string,
    "P1Button"?: string,
    "P1Link"?:string,
    "P2Title"?:string
}
const Home: React.FC = () => {
    const { formatMessage: f } = useIntl();
    const [msg, setMsg] = useState(null) as any;
    const [homePageText,setHomePageText] = useState<IHomePage>({}) ;
    const history = useHistory();
    const shipTypeList: string[] = JSON.parse(f({ id: 'home.ship.type' }));

    useEffect(() => {
        fetchMsg();
        // 获取首页文字信息
        getHomeData();
        Modal.info({
            title: "Notification",
            centered: true,
            content: <span>Internal Test Only. However, you are free to register your interest although you are not allowed to invest online for this period. Alternatively you can  contact us by email: <a href="mailto:service@shipdigitalbank.com">service@shipdigitalbank.com</a> if you have any enquiries.</span>,
            icon: null,
            okText: "Accept",
            onOk: () => {

            }
        })
    }, []);
    const getHomeData = async()=> {
        const data = await api.findHomePage()
        setHomePageText(data);

    }
    const fetchMsg = async () => {
        const { list = [] } = await api.fetchNewsList({
            pageIndex: 1,
            pageSize: 1,
            param: {
                // keyword: '',
                timeInterval: 'month',
                statusParam:[1]
                // startTime: '',
                // endTime: '',
            }
        }) || {};
        setMsg(list[0]);
    }

    return (
        <div className='home-wrap'>
            <div className="banner">
                <div className="left">
                    <p className="title"
                    style = {{
                      lineHeight: "36px"
                    }}>
                        {homePageText.BannerTitle || f({ id: 'home.banner.title' })}
                    </p>
                    <p className="title sub-title"
                    style = {{
                        lineHeight: "36px"
                    }}>
                        {/* {f({ id: 'home.banner.subTitle' })} */}
                    </p>

                    <Button onClick={() => history.push('/offer')} size='large'>{homePageText.BannerButton || 'Browse'}</Button>
                </div>

                {
                    msg && <div className="news-wrap">
                        <Link to={`/press/${msg.id}`} className="news">
                            {msg.title}
                            {/* <span style = {{fontWeight: "normal"}}>{msg.remark}</span> */}
                        </Link>
                        <div
                            onClick={() => history.push('/press')}
                            className="operator">
                            {f({ id: 'home.newslist.more' })}

                            <img alt='arrow' src={require('../../assets/image/arrow.png')}></img>
                        </div>
                    </div>
                }

            </div>

            <div className="introduce">
                {/* <p className="title">{f({ id: 'home.introduce.title' })}</p> */}
                <p className="title">{homePageText.P1Title || f({ id: 'home.introduce.title' })}</p>
                {/* <p className="desc">{f({ id: 'home.introduce.desc' })} </p> */}
                <p className="desc">{homePageText.P1Text || f({ id: 'home.introduce.desc' })} </p>

                <Link className='more' to="/about?keyword=About MDR&id=7"> {homePageText.P1Button ||  f({ id: 'home.introduce.more' })}</Link>

                {/* <img alt='img' src={require('../../assets/image/liquidity_transparentcy.png')}></img> */}
                <img alt='img' src={'http://mdr-public.oss-cn-hangzhou.aliyuncs.com/website/homeCenter.png'}></img>
                <p className="invest-type-title">{f({ id: 'home.introduce.invest.type.title' })}</p>
            </div>

            <div className="ship-wrap">
                <div className="card-wrap">
                    {
                        VESSEL_TYPE.map(item => (
                            <div className='ship-card'>
                                <p onClick={() => {
                                    // history.push(`/offer?projectType=${JSON.stringify(item)}`)
                                    history.push(`/offer`)
                                    sessionStorage.setItem('items', JSON.stringify(item));
                                }}>{item.label}</p>
                            </div>
                        ))
                    }
                </div>

                {/* <div className="ship-list">
                    {
                        new Array(7).fill('').map(item => (
                            <div className="ship">
                                <img alt='ship' src={require('../../assets/imnpm age/ship_model.png')}></img>
                                <span>Container Ship</span>
                            </div>
                        ))
                    }
                </div>

                <Button size='small'>{f({id: 'home.ship.invest.btn'})}</Button> */}
            </div>

            <div className="apply-wrap displaynone">
                <img className='img' alt='img' src={require('../../assets/image/home-work.png')}></img>
                <div className="info">
                    <p className="invest-type-title">{f({ id: 'introudce.invsetors.apply.title' })}</p>
                    <p className="desc">{f({ id: 'home.introduce.invest.desc' })}</p>
                    <Button type='ghost'>{f({ id: 'home.introduce.invest.apply.btn' })}</Button>
                </div>
            </div>

            <div className="partner-wrap">
                <div className="title">{homePageText.P2Title ||  f({ id: 'home.introduce.partner.title' })}</div>
                <div className="partner">
                    <a href="https://www.hammonia-reederei.de/">
                        {/* <img alt='logo' className='solve' src={require('../../assets/image/hammonia.png')}></img> */}
                        <img alt='logo' className='solve' src={'http://mdr-public.oss-cn-hangzhou.aliyuncs.com/website/footer.png'}></img>
                    </a>
                    {/* <img alt='logo' className='inventory' src={require('../../assets/image/inventory.png')}></img>
                    <img alt='logo' className="planbox" src={require('../../assets/image/planbox.png')}></img>
                    <img alt='logo' className="studioc" src={require('../../assets/image/studioc.png')}></img>
                    <img alt='logo' className="aurik" src={require('../../assets/image/aurik.png')}></img>
                    <img alt='logo' className="cloudfish" src={require('../../assets/image/cloudfish.png')}></img> */}
                </div>
            </div>
            <FloatCard
                title={f({ id: 'introudce.invsetors.apply.title' })}
                desc={f({ id: 'introudce.invsetors.apply.desc' })}
                btn={{
                    text: f({ id: 'introudce.invsetors.apply.btn' }),
                    onClick: () => {
                        const userInfo = getUserInfo();
                        if (!userInfo) {
                            message.error('Please login first to proceed with more services >>');
                            history.push('/register/login');
                            return;
                        }
                        history.push('/usercenter/sto/apply');   // if(){
                    }
                }}
            />
        </div>
    )
}


export default Home;
