import React, { useEffect }  from 'react';
import Cash from './Cash'
import CashTwo from './caseTwo'
import { PlusCircleFilled, MinusCircleFilled} from '@ant-design/icons'
import { Divider } from "antd";
import PeopleStore from '../../peopleStore'
import {useObserver} from "mobx-react";
import walletStore from "./walletStore";
import { formatTwoMoney } from '../../../../utils';
const MyWallet = () => {
    useEffect(() => {
        walletStore.fetchList().then()
        walletStore.fetchTwoList().then()
    }, [])
    return useObserver(() => (
        <div className = "wallet-content">
            <p className = "title-p">My wallet is for you to manage usable cash.</p>
          
            <DividerSpan
                currColl={PeopleStore.walletCollFlag.sto} 
                title = "STO"
                subTitle= {formatTwoMoney(Number(walletStore.stoTotalMoney || 0))}
                titleLabel= 'MDRX'
                clickHandler={() => PeopleStore.setCollFlag(Object.assign({}, PeopleStore.walletCollFlag, {sto: !PeopleStore.walletCollFlag.sto}))}/>
            {!PeopleStore.walletCollFlag.sto ? <Cash inType = {1}/> : null}
             <p className="title-msg1">Click STO Wallet to view your cash management for STO market.</p>

               
            <DividerSpan
                currColl={PeopleStore.walletCollFlag.ex}
                title = "EX"
                subTitle= {formatTwoMoney(Number(walletStore.exTotalMoney || 0))}
                // subTitle= {(isNaN(Number(walletStore.exTotalMoney)) ? walletStore.exTotalMoney : Number(walletStore.exTotalMoney).toFixed(2)) + ''}
                titleLabel= 'MDRX'
                clickHandler={() => PeopleStore.setCollFlag(Object.assign({}, PeopleStore.walletCollFlag, {ex: !PeopleStore.walletCollFlag.ex}))}/>
            {!PeopleStore.walletCollFlag.ex ? <CashTwo inType = {2}/> : null }
            <p className="title-msg2">Click EX Wallet to view your cash management for Exchange market.</p>
        </div>
    ))
}
export default MyWallet
type IDividerSpanProps = {
    currColl: boolean,
    title?: string,
    subTitle?: string
    titleLabel?: String
    clickHandler?: () => void
}
export function DividerSpan(props: IDividerSpanProps) {
    const {currColl, title, subTitle, titleLabel} = props
    return(
        <div className = "title-flex">
            {currColl ?
                <PlusCircleFilled onClick={() => props.clickHandler && props.clickHandler()}/>:
                <MinusCircleFilled onClick={() => props.clickHandler && props.clickHandler()}/>}
            <span className = "left">{title}&nbsp;</span>
            <Divider className = "divider-style" dashed/>
            <span>&nbsp;{subTitle}</span>
            {titleLabel && <span>{titleLabel}</span>}
        </div>
    )
}
