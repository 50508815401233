import Introduce from "./introduce";
import Home from "./home";
import Register from "./register";

import ResetPwd from "./resetpwd";

import Offer from "./offer";

export default {
  "navbar.home": "Home",
  "navbar.investmentOffering": "Investment Offering",
  "navbar.existingShipowners": "Existing Shipowners",
  "navbar.aboutMdr": "About MDR",
  "navbar.press": "Press",

  "overlay.ShipownerQualification": "Shipowner Qualification",
  "overlay.GetQualifiedNow": "Get Qualified Now",
  "overlay.Eligibility": "Eligibility",
  "overlay.Howitworks": "How it works",

  "overlay.MySTOManagement": "My STO Management",
  "overlay.NeedHelp": "Need Help",

  signup: "Sign Up",
  login: "Log in",
  remeberme: "Remember me",
  forgetpwd: "Forgot Password",

  customize: "Duration",

  confirm: "confirm",

  details: "Detalis",

  search: "Search",

  next: "Next",

  back: "Back",

  done: "Done",

  "footer.router": JSON.stringify([{
      name: "User Terms, Disclaimer, and Privacy Policy",
      router: "/about?keyword=User Terms, Disclaimer, and Privacy Policy&id=12",
    },
    {
      name: "KYC Guidelines",
      router: "/about?keyword=KYC Guidelines&id=11",
    },
    // {
    //   name: "Legal Statement",
    //   router: "/about?keyword=Legal Statement&id=4",
    // },
    // {
    //   name: "Regulations of AML&ATF",
    //   router: "/about?keyword=Regulations of AML&ATF&id=5",
    // },
    {
      name: "FAQ",
      router: "/about?keyword=How to register&id=1",
    },
    {
      // name: "Need Help +86-10-64930505 service@shipdigitalbank.com",
      name: "Need Help +49 408221-76111 service@shipdigitalbank.com",
      router: "",
    },
  ]),

  "footer.cookie": "We use cookies to help provide and enhance our service and tailor content and ads. By continuing you agree to the use of cookies.",

  // "footer.auth": "©船舶资产交易数字化平台有限公司",
  "footer.auth": "©Marine Digital Republic Ltd.",

  // "introudce.title0": "Set sail on an alternative investment opportunity \ sign up in the lucrative ship owning industry",
  "introudce.title0": "Set sail on an alternative investment opportunity in the lucrative ship owning industry",
  "introudce.titleOne": "My STO Management \ safe and secured STO submission system.",
  // "introduce.titleOne1": "My STO Management",
  // "introduce.titleTwo2": "safe and secured STO submission system.",
  "introduce.titleOne1": "STO Management",
  "introduce.titleTwo2": "Secured STO submission portal",




  "introudce.title.btn": "Browse Offers",
  "introudce.main.tab.0": "For Shipowners",
  "introudce.main.tab.1": "For Investors",

  ...Home,
  ...Introduce,
  ...Register,
  ...ResetPwd,
  ...Offer,
};
