import React, { useState, useRef, useEffect } from 'react';

import { Title, Button, FloatCard, MSelect } from '../../../../components'
import { Select, message } from 'antd';
import { Link, useHistory } from 'react-router-dom';
import { useIntl } from 'react-intl';
import './index.scss';
import { VESSEL_TYPE } from '../../../../constants';
import { getUserInfo } from '../../../../utils';



const Invertors: React.FC = () => {
    const { formatMessage: f } = useIntl();
    const [isPlay, setIsPlay] = useState(false)

    const [projectType, setProjectType] = useState({
        label: 'New Building Vessel',
        value: 'newBuildingVessel',
    });

    const video: React.RefObject<HTMLVideoElement> = useRef(null);
    const history = useHistory();

    useEffect(() => {
        video.current ?.addEventListener('pause', pausePlay);
        return () => {
            video.current ?.removeEventListener('pause', pausePlay);
        };
    }, [])

    const pausePlay = () => {
        setIsPlay(false)
    }

    const handlePlay = () => {
        video.current ?.play();
        setIsPlay(true)
    }


    const benefitList = JSON.parse(f({ id: 'introudce.investors.benefit.list' })) as string[];;



    return (
        <section className='investors'>
            <div className='video-wrap'>
                <video
                    loop
                    autoPlay
                    poster={require('../../../../assets/image/cover.png')}
                    ref={video} className="video"
                    // controls
                    src='http://mdr-public.oss-cn-hangzhou.aliyuncs.com/video/investors.mp4'
                />

                {/* {
                    !isPlay ? <img alt='video' onClick={handlePlay} className='video-cover' src={require('../../../../assets/image/cover.png')} /> : null
                } */}
            </div>

            {/* <div className='benefit-wrap'>
                <Title>{f({ id: 'introudce.investors.benefit.title' })}</Title>
                <div className="benefit-list-wrap">
                    {
                        benefitList.map((item, index) => (
                            <div key={index} className='item'>
                                <span className='index'>{index + 1}</span>

                                <p className='desc'>{item}</p>
                            </div>
                        ))
                    }
                </div>
            </div> */}

            <div className="invest-option-wrap">
                <div className='desc'>
                    <Title color="#F19D3E">{f({ id: 'introudce.invsetors.option.title' })}</Title>

                    <span className='detail'>{f({ id: 'introudce.invsetors.option.detail' })}</span>

                    <Link className='more-link' to="/about?keyword=About shipping market&id=8">{f({ id: 'introudce.invsetors.option.morelink' })}</Link>
                </div>

                <div className="float-ship">
                    <div>
                        <span className='label'>{f({ id: 'introudce.invsetors.option.label' })}</span>

                        <MSelect
                            value={projectType}
                            labelInValue
                            onChange={(item) => {
                                setProjectType(item);
                                // console.log(projectType)

                            }}
                            dataSource={VESSEL_TYPE} size='large' className='mdr-select'>
                        </MSelect>
                    </div>

                    <div className="invest-btn-wrap">
                        <Button onClick={() => {
                            history.push(`/Offer/?projectType=${JSON.stringify(projectType)}`);
                        }}
                        >
                            {f({ id: 'introudce.invsetors.option.invest' })}
                            <img src={require('../../../../assets/image/arrow.png')} />
                        </Button>
                    </div>
                </div>
            </div>

            <div className="about-wrap">
                <Title>{f({ id: 'introudce.invsetors.about.title' })}</Title>

                <p className='detail'>
                    {f({ id: 'introudce.invsetors.about.detail' })}
                </p>

                <Link className="more-link" to='/about?keyword=About MDR&id=7'>{f({ id: 'introudce.invsetors.about.more' })}</Link>

                <img alt='' src={require('../../../../assets/image/liquidity_transparentcy.png')}></img>
            </div>

            <FloatCard
                title={f({ id: 'introudce.invsetors.apply.title' })}
                desc={f({ id: 'introudce.invsetors.apply.desc' })}
                btn={{
                    text: f({ id: 'introudce.invsetors.apply.btn' }),
                    onClick: () => {
                        const userInfo = getUserInfo();
                        if (!userInfo) {
                            message.error('Please login first to proceed with more services >>');
                            history.push('/register/login');
                            return;
                        }
                        history.push('/usercenter/sto/apply');
                    }

                }}
            />
        </section >
    )

}

export default Invertors;