import React, { useEffect, useState } from 'react';
import './index.scss';
import { Form, Slider, DatePicker, InputNumber, Upload, Input, message,Modal } from 'antd';
import StepRootStore from '../../store';
import DynamicFormItem, { IDtem } from '../../../../../../../components/DynamicFormItem';
import { Button, MSelect } from '../../../../../../../components';
import { useForm } from 'antd/lib/form/Form';
import api from '../../../../../../../api';
import { getImageUrl, formDataParser } from '../../../../../../../utils';
import moment from 'moment';
import { uploadAction } from '../../../../../../../constants';
import { useHistory } from 'react-router-dom';

const dayslist = [60, 90, 120].map(item => ({
    label: `${item} days`,
    value: item + '',
}))


// interface IProps {
//     data: []
// }

const layout = {
    labelCol: { span: 12 },
    wrapperCol: { span: 12 },
};

const FundraisingInfo: React.FC = (props) => {
    const [form] = useForm();

    const [info, setInfo] = useState({} as any);

    const [formItemList, setFormItemList] = useState([]) as any;

    const history = useHistory();

    const [uploadData, setUploadData] = useState({}) as any;

    useEffect(() => {
        fetchInfo();
        fetchForm();
    }, []);


    useEffect(() => {
        form.setFieldsValue(formDataParser(formItemList, true, StepRootStore));
    }, [formItemList]);

    // const handleUploadChange = (code: string, response: any) => {
    //     const resp = response.file.response;
    //     if (resp !== undefined) {
    //         if (resp.code === '200') {
    //             const { objectName } = resp.data;
    //             // setVal(objectName);
    //             uploadData[code] = objectName;
    //             const data = {
    //                 ...uploadData,
    //                 [code]: objectName
    //             }
    //             console.log(uploadData)
    //             setUploadData(data);
    //         } else {
    //             message.error(resp.msg)
    //         }
    //     }
    // }

    const fetchForm = async () => {
        const data = await api.findStoInfoAllById(
            StepRootStore.stoId,
            8,
        ) || [];
        console.log(data)
        setFormItemList(data)
    }

    const fetchInfo = async () => {
        const data = await api.findStoFundraisingById(StepRootStore.stoId) || {};
        //@ts-ignore
        const newUploadData = {
            ...uploadData,
            prospectus: data.prospectus
        };

        setUploadData(newUploadData)

        data.prospectus = await getImageUrl(data.prospectus);
        data.fundsStart = moment(data.fundsStart)
        setInfo(data);
        form.setFieldsValue(data);
    }

    // const handleSave = () => {
    //     form.validateFields().then(async () => {
    //         const data = form.getFieldsValue();
    //         await api[info.id ? 'updateStoFundraising' : 'addStoFundraising']({
    //             id: StepRootStore.stoId,
    //             ...data,
    //             ...uploadData
    //         });

    //         message.success('提交成功');
    //         if (StepRootStore.stoStatus < 40) {
    //             await api.agreementSigning(StepRootStore.stoId);
    //         }
    //         history.push('/usercenter/sto')
    //     })

    // }

    const handleSave = () => {
        if(StepRootStore.stoStatus === 30) {
            form.validateFields().then(async () => {
                const data = form.getFieldsValue();
    
                const formData = { ...data, ...uploadData };
    
                const newForm = formItemList.map((item: any) => ({
                    ...item,
                    propertyValue: formData[item.code] || ''
                }));
    
                console.log(newForm);
    
            //    await api.updateStoInfoAll(newForm);
            // 新增请求,防止因为船型原因，造成无法判断
            await api.updateStoInfoStatus(newForm);
                message.success('Submitted successfully');
                // if (StepRootStore.stoStatus < 40) {
                //     await api.agreementSigning(StepRootStore.stoId);
                // }
                history.push('/usercenter/sto');
            });
        }else {
            // 如果是填写过就不让填写
            if(StepRootStore.stoStatus == 35) {//不能再次填写
                Modal.warning({
                    content: "Please wait for the administrator to review",
                    onOk: ()=> history.push('/usercenter/sto')
                })
                return;
            }
            if(StepRootStore.stoStatus >= 40) {
                StepRootStore.setCurStep('agreement')//跳转到同意界面
                return;
            }
        }
    }


    // const download = async (name: string) => {
    //     if (uploadData[name]) {
    //         const obj = uploadData[name];
    //         const url = await getImageUrl(obj);
    //         window.open(url, 'new_mdr', 'width=600,height=800')
    //     } else {
    //         window.open(info[name], 'new_mdr', 'width=600,height=800')
    //     }

    // }


    const isDisabled = StepRootStore.stoStatus >= 60;



    return (
        <div className='fundraising-info'>
            {/* <p className='section-title'>
                Fundraising Information
            </p> */}
            <Form
                form={form}
                {...layout}
                labelAlign='right'
                layout='horizontal'
                colon={false}
                // hideRequiredMark
                scrollToFirstError={true}

            >
                {
                    formItemList.map((item: any) =>
                        <DynamicFormItem
                            readonly={StepRootStore.stoStatus > 30 || isDisabled}//填写过就禁用
                            // readonly={isDisabled}
                            form={form} key={item.id}
                            data={item as IDtem}
                            onFileChange={(code, value) => {
                                //@ts-ignore
                                uploadData[code] = value;
                                setUploadData(uploadData)
                            }}
                        />
                    )

                }
                {/* <Form.Item name="currency" label="Currency of Raising Funds">
                    <Input disabled={isDisabled} className='m-input'></Input>
                </Form.Item>

                <Form.Item rules={[{ required: true }]} name="contractPrice" label="New Building Contract Price of Vessel">
                    <Input className='m-input' disabled={isDisabled} suffix='USD'></Input>
                </Form.Item>

                <Form.Item rules={[{ required: true }]} name="startCosts" label="Necessary Start-up Costs">
                    <Input className='m-input' disabled={isDisabled} suffix='USD'></Input>
                </Form.Item>

                <Form.Item rules={[{ required: true }]} name="minimum" label="Minimum Amount to be Raised">
                    <Input className='m-input' disabled={isDisabled} suffix='%'></Input>
                </Form.Item>

                <Form.Item rules={[{ required: true }]} name="maximum" label="Maximum Amount to be Raised">
                    <Input className='m-input' disabled={isDisabled} suffix='%'></Input>
                </Form.Item>


                <Form.Item rules={[{ required: true }]} name="fundsStart" label="Start Time of Raising Funds">
                    <DatePicker disabled={isDisabled}></DatePicker>
                </Form.Item>

                <Form.Item rules={[{ required: true }]} name="fundsDuration" label="Duration of Raising Funds">
                    <MSelect dataSource={dayslist} disabled={isDisabled} />
                </Form.Item>

                <Form.Item rules={[{ required: true }]} name="controlling" label="Share Proportion Retained by Controlling Shareholder">
                    <Input className='m-input' disabled={isDisabled} suffix='%'></Input>
                </Form.Item>

                <Form.Item name="prospectus" label="STO Prospectus"> */}
                {/* 
                    <div className="row company-upload">
                        <Input className='m-input' readOnly value={info.prospectus || uploadData.prospectus}></Input>
                        <Upload
                            data={{ type: 'other' }}
                            action={uploadAction}
                            showUploadList={false}
                            disabled={isDisabled}
                            onChange={(resp) => handleUploadChange('prospectus', resp)}
                        >
                            <Button size='small' type='ghost'>Upload</Button>
                        </Upload>
                        {
                            info.prospectus || uploadData.prospectus ? <a style={{ marginLeft: '5px' }} onClick={() => download('prospectus')} href={info.prospectus}>view</a> : null
                        }
                    </div>

                </Form.Item> */}

            </Form>

            <div className="button-wrap">
                <Button
                    arrowLeft
                    type='ghost'
                    size='middle'
                    style={{ marginRight: '23px' }}
                    onClick={() => StepRootStore.setCurStep('review')}
                >
                    Go Back
                </Button>
                <Button
                    arrowRight
                    size='middle'
                    onClick={handleSave}
                >
                    Next
            </Button>
            </div>

        </div>
    );
}

export default FundraisingInfo;
