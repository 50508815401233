import React, {useEffect,useState} from 'react';
import { List } from 'antd';
import './index.scss';
import { Title, Button } from '../../../../../../components';
import { observer } from 'mobx-react';
import store from '../../store';
import { formatMoney,transLocal } from '../../../../../../utils';
import { RZ_STATUS } from '../../../../../../constants';
import dayjs from 'dayjs';

// actualFundraising: 0
// endDate: "2020-09-06 00:00:00"
// leftDays: 88
// raisingFunds: 0
// startDate: "2020-06-08 00:00:00"
// status: 2
// stoId: 12
// subtotalValue: 100000
// tokenNumber: 1000
// tokenPrice: 100
// totalFundraising: 420000
// totalReceived: 430762.5
// transactionFee: 10762.5
// userHoldNumber: 4200


const Fundraising = observer(() => {

    const { fundraising } = store;
    const { raisingFunds, isTransfer,fundraisingComplateDate,endDate,statusDesc, status, tokenNumber, tokenPrice, subtotalValue, userHoldNumber, startDate, leftDays, totalFundraising, actualFundraising } = fundraising || {};
    const isDone = +status === 5;

    const data = [
        {
            key: 'Currency of Raising Funds',
            value: transLocal(raisingFunds),
            extra: 'USD'
        },
        {
            key: 'Current Fundraising Progress',
            value: actualFundraising,
            extra: 'USD'
        },
        {
            key: 'Token number',
            value: tokenNumber,
            extra: 'Tokens'
        },
        {
            key: 'Token price',
            value: transLocal(tokenPrice),
            extra: 'MDRX'
        },

        {
            key: 'Subtotal Value',
            value: transLocal(subtotalValue),
            extra: 'MDRX'
        },

        {
            key: 'Number of Tokens user Hold',
            value: userHoldNumber,
            extra: `Tokens ${ Math.floor((userHoldNumber / tokenNumber) * 10000) * 100 }% completed`
        },

{
    key: 'Total Fundraising',
        value: transLocal(totalFundraising),
            extra: 'MDRX'
},

{
        key: 'Fundraising start date',
        value: dayjs(startDate).format('YYYY-MM-DD'),
        extra: ''
},

{

    // key: 'Current Fundraising Status',
    key: statusDesc,
        //@ts-ignore
        value: dayjs(endDate).format('YYYY-MM-DD'),
        extra: ''
}
    ]
    
        
        if (isDone) {
            data[1] = {
                key: 'Actual fundraising',
                value: formatMoney(actualFundraising),
                extra: 'USD'
            };
            data[8] = {
                key: statusDesc,
                value: dayjs(endDate).format('YYYY-MM-DD'),
                extra: ''
            }
        }
            switch(status) {
                case 1://筹款未开始
                    data[8] = {
                        key: statusDesc,
                        value: 'Upcoming',
                        extra: ""
                    }
                    break;
                case 2: 
                    data[8] = {
                        key: statusDesc,
                        value: "Active",
                        extra: `${leftDays} Days Left`
                    }
                    break;
                case 3://超时
                data[8] = {
                    key: statusDesc,
                    value: dayjs(endDate).format('YYYY-MM-DD'),
                    extra: ''
                }
                break;
                case 5:
                    data[8] = {
                        key: statusDesc,
                        value: dayjs(fundraisingComplateDate).format('YYYY-MM-DD'),
                        extra: ""
                    }
                break;
                case 4:
                data[8] = {
                    key: statusDesc,
                    value: dayjs(endDate).format('YYYY-MM-DD'),
                    extra: ''
                }
                break;
                case 6:
                    data[8] = {
                        key: statusDesc,
                        value: dayjs(endDate).format('YYYY-MM-DD'),
                        extra: ""
                    }
                break;
            }

        const handleNext = () => {
            if(isTransfer) {//转账后
                store.handleNext()
            }else {
                store.cur = 1;
            }
        }
        
return (
    <div className="detail-fundraising">
        <Title>{status === 5 || status === 6 || status === 4 ? 'Fundraising Information' : 'Offering is published and fundraising'}</Title>
        <span className='list-title'>Fundraising Progress</span>
        <List
            bordered
            dataSource={data.map((item:any) => JSON.stringify(item))}
            renderItem={(item: string) => {
                const items: any = JSON.parse(item);
                return (
                    <List.Item className='row property-item'>
                        {/* <div className='r0w'> */}
                        <p className='label'>{items.key}</p>
                        <p className='value'>{items.value}</p>
                        <p className='extra'>{items.extra}</p>
                        {/* </div>    */}
                    </List.Item>
                )
            }}
        />

        {//转账后和finish 都可以
            isTransfer || store.finishData.find((item: any) => item.type === 'Finished') 
              ? (
                <div className="btn-wrap">
                    <Button type='ghost' arrowRight size='small' onClick={handleNext} >Next</Button>
                </div>
            ) : null
        }
    </div>

)
})

export default Fundraising