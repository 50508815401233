import React, { useState, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Tab, TabPro } from '../../../components';

import Cash from './Cash';

import './index.scss';
import Token from './Tokens';

const Wallet: React.FC = () => {
    const { formatMessage: f } = useIntl();

    const [active, setActive] = useState(0);


    const renderItem = () => {
        switch (active) {
            case 0:
                return <Cash></Cash>
            case 1:
                return <Token></Token>
            default:
                return null;
        }
    }

    return (
        <section className="common-wrap">
            <p className='page-title'>{f({ id: 'home.banner.title' })}</p>
            <p className='page-title'>{f({ id: 'home.banner.subTitle' })}</p>

            <div className='wallet-wrap'>

                <div className="tab-wrap" >
                    <TabPro
                        active={0}
                      
                        data={['My Wallet']}
                    />
                </div>

                <div className="content">
                    <div className="header">
                        <Tab
                            active={0}
                            onChange={(val:any) => setActive(+val)}
                            simple
                            data={['Available Cash', 'Held Tokens']}
                        />
                    </div>

                    {renderItem()}
                </div>
            </div>

        </section>
    )
}

export default Wallet;