import React from 'react';
import { List, Button } from 'antd';
import './index.scss';
import { Title, Folder } from '../../../../../../components';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { formatMoney, getImageUrl } from '../../../../../../utils';

// {
//     "projectObjective":"Equity",
//     "purchase":"2020-06-27 17:06:21",
//     "type":"divident",
//     "fundDividend":{
//         "dividendPerToken":6.0,
//         "dividendsStatement":"other/other_1593248776903.pdf",
//         "stoId":437,
//         "createTime":1593248781000,
//         "userHoldNumber":6.0,
//         "paymentDividendsDate":1591632000000,
//         "statementDate":1591632000000,
//         "id":6,
//         "category":1,
//         "financialReport":"",
//         "totalDividendsDistributed":3000.0,
//         "userId":58
//     }
// },

const handlePreview = async (objectName: string) => {
    if (!objectName) return;
    const fileUrl = await getImageUrl(objectName);
    window.open(fileUrl, '', 'width=600,height=800')

}

const cols = (data: any) => {
    const fundDividend = data.fundDividend || {};
    // fundDividend.totalDividendsDistributed
    let totalDividendsDistributedValue;
    const projectValueType = data.projectObjective;
    if(projectValueType === 'Liability') {
        // 如果是-号就拼接
        if(data.addType === '-') {
            totalDividendsDistributedValue = `-${fundDividend.totalDividendsDistributed}`
        }else {
            totalDividendsDistributedValue = fundDividend.totalDividendsDistributed;
        }
    }else {
        totalDividendsDistributedValue = fundDividend.totalDividendsDistributed;
    }
    return [
        {
            key: 'Dividends statement',
            value: fundDividend.dividendsStatement ? fundDividend.dividendsStatement : '',
            extra: ''
        },
        {
            key: 'Number of tokens I hold',
            value: fundDividend.userHoldNumber,
            extra: 'Tokens'
        },
        {
            key: 'Dividend per token',
            value: fundDividend.dividendPerToken,
            extra: data.projectObjective === 'Equity' ? 'Unit per token' : 'MDRX per Token'
        },
        {
            key: 'Total dividends distributed',
            value: totalDividendsDistributedValue,
            extra: data.projectObjective === 'Equity' ? 'Tokens' : 'MDRX'
        },
        {
            key: 'Date of statement',
            value: dayjs(fundDividend.statementDate).format('YYYY-MM-DD'),
            extra: ''
        },
        {
            key: 'Date of payment of dividends',
            value: dayjs(fundDividend.paymentDividendsDate).format('YYYY-MM-DD'),
            extra: ''
        },
        {
            key: 'purchase Time',
            value: data.purchase,
            extra: ''
        },
    ]

}

interface IProps {
    dataSource: any
}


const Purchase: React.FC<IProps> = ({ dataSource }) => {


    const handleToStoDetail = () => {
        const url = `${window.location.origin}/#/usercenter/sto/apply?stoId=${100}`
        window.open(url)
    }

    const { fundDividend = {}, projectObjective } = dataSource;

    const data = cols(dataSource);
    const dataCopy = data.map(item => JSON.stringify(item))
    return (
        <div className="detail-investment">
            <Folder fold={true} title={`${dayjs(fundDividend.createTime).format('YYYY-MM-DD')} ${projectObjective}  dividend`}>
                <div className="list-wrap" style={{ marginTop: '10px' }}>
                    <List
                        bordered
                        dataSource={dataCopy}
                        renderItem={(item: string) => {
                            const items: any = JSON.parse(item);
                            return (
                                <List.Item className='row property-item'>
                                    {
                                        items.key === "Dividends statement"?(
                                            <React.Fragment>
                                                <p className='label' >{items.key}</p>
                                        <p className='value'>{items.value && <Button onClick={() => {handlePreview(items.value)}} type='link'>Click to View</Button>}</p>
                                                <p className='extra'>{items.extra}</p>
                                            </React.Fragment>
                                        ) :(
                                            <React.Fragment>
                                                <p className='label' >{items.key}</p>
                                                <p className='value'>{items.value}</p>
                                                 <p className='extra'>{items.extra}</p>
                                            </React.Fragment>
                                        )
                                    }
                                </List.Item>
                            )
                        }}
                    />
                </div>
            </Folder>



        </div>

    )
}

export default Purchase