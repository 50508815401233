import React, { useState, useEffect } from 'react';
import { Title, Button } from '../../../../../../../components';
import {Checkbox,Modal,message} from 'antd';
import './index.scss';
import store from '../../store';
import api from '../../../../../../../api';
import { useHistory } from 'react-router-dom';
import { FileOutlined } from '@ant-design/icons';

const Agreement: React.FC = () => {
    const [signData, setSignData] = useState({} as any);
    const [agreeFlag,setAgreeFlag] = useState(true);
    const [saveLoading,setSaveLoading] = useState(false);
    const [nextLoading,setNextLoading] = useState(false);
    const {stoId} = store;
    const history = useHistory();
    useEffect(() => {
        // 如果状态大于41设置默认值为true
        setAgreeFlag(store.stoStatus  > 40);
        // fetchSignDetail();
    }, []);

    const fetchSignDetail = async () => {
        const data = await api.agreementSigningMdrTodo(store.stoId);
        setSignData(data);
    }
    // 勾选事件
    const agreeChange = (e:any) => {
        setAgreeFlag(e.target.checked);
    }
    // save事件
    const saveAgreement = async() => {
        if(store.stoStatus !== 40) {
            return;
        }
        if(!agreeFlag) {
            Modal.warning({
                content: 'Please make sure you have read the terms',
                onOk: () => {
                    return;
                }
            });
            return;
        }
        if(saveLoading) {
            return;
        }
        setSaveLoading(true);
        const data = await api.updateAgreementSignStatus({
            stoId,
            status: agreeFlag? 1:0
        })
        setSaveLoading(false);
        console.log(JSON.parse(JSON.stringify(data)));
        Modal.success({
            content: "Save success",
            onOk: () => {
                
            }
        })
    }
    // 下一步事件
    const agreeNext = async() => {
        // 只有40才能填写
        if(store.stoStatus === 40) {
            if(!agreeFlag) {
                message.warning('Please make sure you have read the terms');
                return;
            }
            if(nextLoading) {
                return;
            }
            setNextLoading(true);
            const data = await api.updateAgreementSignStatus({
                stoId,
                status: agreeFlag? 1:0
            })
            setNextLoading(false);
            console.log(JSON.parse(JSON.stringify(data)));
            Modal.success({
                content: "Submitted successfully",
                onOk: () => {
                    history.push('/usercenter/sto');
                }
            })
        }else {
             // 如果是审核中，提示正在审核，跳转首页
             if(store.stoStatus === 41) {
                // message.info('Please wait for the administrator to review');
                // return history.push('/usercenter/sto');
                store.setCurStep('approval');
            }else if(store.stoStatus > 50) {
                store.setCurStep('approval');
                return;
            }else if(store.stoStatus === 50) {
                store.setCurStep('approval');
                return;
            }
        }
    }
    return (
        <div className='agreement-wrap'>
            <div className="row">
                {/* <Title>{signData.status === 1 ? 'Sign STO Agreement' : 'STO under contract'}</Title> */}
                <Title>Sign STO Agreement</Title>
            </div>

            <div className="agree-content">
                {
                     (
                        <div>
                            <Title>STO Agreement</Title>
                            {/* <p className='item'>
                                <span className='agree-status'>Agreement Signed</span> {signData.approvalTime}
                            </p>


                            <p className='item'> Submitted for signing at {signData.applicationTime}</p>


                            <div className="agree-link">
                                <FileOutlined />
                                <a href='' target='__blank'>Click and Read the STO Agreement Online.</a>
                            </div> */}
                            
                                
                                
                            <p className = "item">
                                <span className = "agree-status">Clause 1.</span>
                            </p>
                            <p className = "item">
                                <span className = "agree-status">Clause 2.</span>
                            </p>
                            <p className = "item">
                                <span className = "agree-status">Clause 3.</span>
                            </p>
                            <p className = "item">
                                <span className = "agree-status">...</span>
                            </p>
                            
                        </div>

                    )
                }
            </div>
            <div className = "agreeSto">
                <Checkbox
                disabled = {store.stoStatus > 40} 
                onChange={agreeChange}
                checked = {agreeFlag}
                >By clicking NEXT, you agree to our agreement that you have read.</Checkbox>
            </div>

            <div className="modal-footer">
                {/* {
                    store.stoStatus === 40 && <Button
                     // arrowLeft
                     type='ghost'
                     size='middle'
                     // onClick={() => store.setCurStep('fundraisingInfo')}
                     onClick = {saveAgreement}
                 >Save</Button>
                } */}
               {
                (
                    <Button
                    size='middle'
                    style={{ marginLeft: '20px' }}
                    // onClick={() => store.setCurStep('approval')}
                    onClick = {agreeNext}
                    arrowRight>Next</Button>
                   )
               }
            </div>
        </div>
    )
}

export default Agreement;