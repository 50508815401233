import React, { useState, useEffect } from 'react';
import { Title, Button, SMSInput } from '../../../../components';

import './index.scss';
import { formatMoney,formatLingMoney,formatTwoMoney,checkSmsUser, getAllOptions, sendSms,sendSmsVerificationWithUser, checkSms, getUserInfo,transLocal } from '../../../../utils';
import {
    Radio, Input, Modal, InputNumber, message
} from 'antd';
import {useHistory, useLocation} from 'react-router-dom';
import store from '../store';
import api from '../../../../api';
import { observer } from 'mobx-react';
import publicStore from '../../../../store' 

// consr handleNext1

const WithDraw = observer(() => {
    const [payhelp, setPayHelp] = useState(false);
    const [payConfirm, setPayConfirm] = useState(false);
    const [cur, setCur] = useState(0);
    const [rate, setRate] = useState(1);
    const [value, setValue] = useState(1);
    const [total, setTotal] = useState(0);
    const [verifySms, setVerifySms] = useState(false);
    const history = useHistory();
    const { state } = useLocation()
    const [inType, setInType] = useState<1 | 2>(2)
    useEffect(() => {
        init();
        store.fetchList(sessionStorage.getItem('inType') || 1);
    }, []);
    useEffect(() => {
        handleChange(1);
    },[rate])
    const init = async () => {
        const rate = await getAllOptions('rechargeRate');
        setRate(rate as number);
    }

    const handleChange = (val: any) => { 
        // if(!/^\d+$/.test(val.toString())) {
        //     return;
        // }
        if (val >= 2000001) {
            const MaxMoney = Number(store.totaMoney)
            const total = accMul(MaxMoney, rate)
            setValue(MaxMoney);
            setTotal(Math.floor(total * 100) / 100);
            return
        }
        const total = accMul(val || 0, rate);
        setValue(val);
        setTotal(Math.floor(total * 100) / 100);
    }
    const accMul = (arg1:number, arg2:number) => {
        var m = 0, s1 = arg1.toString(), s2 = arg2.toString();
        try {
            m += s1.split(".")[1].length;
        }
        catch (e) {
        }
        try {
            m += s2.split(".")[1].length;
        }
        catch (e) {
        }
        return Number(s1.replace(".", "")) * Number(s2.replace(".", "")) / Math.pow(10, m);
    }


    const handleNext = async () => {
        if (!total) {
            message.info('Cash withdraw amount should be larger than 0')
            return;
        };
        // await api.checkMoneyEnough({
        //     mdrx: value,
        //     relationId: '11'
        // });
        // await sendSms();
        await sendSmsVerificationWithUser();
        setCur(1);
    }

    const checkSMS = async (code: string) => {
        // await checkSms(code);
        await checkSmsUser(code);
        setVerifySms(true);
    }

    const handleConfirm = async () => {
        if (!verifySms) return;

        await api.withdraw({
            "mdrx": value,
            "total": total,
            'email': getUserInfo() ?.email,
            personType: getUserInfo().type,
            inType: sessionStorage.getItem('inType') || 1
        });

        Modal.success({
            title: 'Withdraw is confirmed.',
            content: 'MDR administrator will complete the approval within 14 days',
            onOk: () => {
                history.push('/investment/manage?active=0')
            }
        })
    }
    const linkBack = () => {
        history.goBack();
    }

    return (
        <section className="common-wrap wallet-withdraw">
            <p className='page-title'>Set sail on an alternative investment opportunity</p>
            <p className='page-title'>in the lucrative ship owning industry</p>

            <div className="withdraw-pannel">
                <div className="header">
                    <Title>My Wallet</Title>
                </div>
                {
                    cur === 0 ?
                        (<div className="content">
                            <div className='row'>
                                <Title>Available Cash</Title>
                                <p className='total'><span className='value'>{formatTwoMoney(Number(store.totaMoney))}</span>MDRX</p>
                                <p className='rate'>Exchange rate 1 MDRX = {rate} USD</p>
                            </div>

                            <div className="row">
                                <div className="topup-form">
                                    <div className="row">
                                        <p className="label">Withdraw  amount</p>
                                        {/* max={store.allIncomeMoney} */}
                                        <InputNumber min={1}  value={formatLingMoney(value)} onChange={handleChange} style={{ width: '212px', marginRight: '10px' }} className='m-input' />
                                        <p>MDRX</p>
                                    </div>

                                    <div className="row">
                                        <p className="label">You will receive</p>
                                        <Input value={formatLingMoney(total)} readOnly style={{ width: '262px' }} className='m-input' suffix='USD'></Input>
                                    </div>

                                    <div className="row btn-wrap">

                                        <Button onClick={linkBack} size='small' arrowLeft type='ghost'> Back</Button>
                                        <Button onClick={handleNext} size='small' arrowRight>Next</Button>
                                    </div>

                                </div>
                                <div className="info-wrap">
                                    <div className='info-item'>
                                        {/* <Title>How Deposit Works?</Title> */}
                                        <Title>&nbsp;</Title>
                                        <div className="row">
                                            <span className="tips-circle"></span>
                                            <p className='desc'>MDRX is limited to its usage for internal settlement on the MDR platform; moreover, they can only be used to acquire SPV tokens (or “vessel tokens”, for that matter) within the “closed ecosystem” that is the MDR platform.</p>
                                        </div>
                                    </div>

                                    {/* <div>
                                        <Title>Trustee for MDR:</Title>
                                        <div className="row">
                                            <span className="tips-circle"></span>
                                            <p className='desc'>MDRX is the only legal currency to be used purchasing Vessel Token from MarineDigitalRepublic.</p>
                                        </div>
                                    </div> */}

                                </div>
                            </div>
                        </div>) : (
                            <div className="content">
                                <div className="row">
                                    <div className='row result'>
                                        <Title>Withdraw amount</Title>
                                        <p className='total'><span className='value'>{formatLingMoney(store.totaMoney)}</span>MDRX</p>
                                    </div>

                                    <div className='row result' style={{ marginLeft: 'auto' }}>
                                        <Title>You will receive</Title>
                                        <p className='total'><span className='value'>{formatLingMoney(total)}</span>USD</p>
                                    </div>
                                </div>


                                <div className="sms-wrap">
                                    <SMSInput success={verifySms} onDone={checkSMS} hint='A 6-digit code has been sent to your phone' showRetry />
                                </div>

                                <div className="btn-wrap">
                                    <Button onClick={() => setCur(0)} size='small' arrowLeft type='ghost'> Back</Button>
                                    <Button onClick={handleConfirm} size='small' arrowRight >Submit </Button>
                                </div>
                            </div>
                        )
                }
            </div>
            <Modal
                footer={null}
                visible={payConfirm}
                closable={false}
                className='pay-confirm'
            >
                <p className='desc'>
                    Whether the payment is successful?
                    </p>

                <div className="btn-wrap">
                    <Button onClick={() => {
                        setPayConfirm(false);
                        setPayHelp(true);
                    }} size='small' type='ghost' >No</Button>
                    <Button onClick={() => {
                        setPayConfirm(false);
                        history.push('/investment/manage?active=1');
                    }} size='small' >Yes</Button>
                </div>


            </Modal>

        </section >
    )
})

export default WithDraw;
