import React,{useRef} from 'react';
import { useIntl } from 'react-intl';

import { MSelect, Title } from '../../../../../components';



import './index.scss'
import { PHONE_COUNTRY,PHONE_COUNTRYARRAY } from '../../../../../constants';
import { Input, message } from 'antd';
import { observer } from 'mobx-react';
import store from '../../../store';
import FormItem from 'antd/lib/form/FormItem';
import api from '../../../../../api';

const Phone: React.FC = observer(() => {
    const { formatMessage: f } = useIntl();
    // const cityRef = useRef<HTMLSelectElement>();

    // const phoneMap = Object.keys(PHONE_COUNTRY).map(item => ({
    //     label: `+${item} ${PHONE_COUNTRY[item]}`,
    //     value: `+${item}`
    // }));
    const phoneMap = PHONE_COUNTRYARRAY.map(item => ({
        label: `+${item.label} ${item.value}`,
        value: `+${item.label}`
    }))

    const handlePhoneCountryChange = (value: string) => {

        store.updateModel({ 'phoneCountry': value })
    }

    const handleNumChange = (e: any) => {
        store.updateModel({ 'phoneNumber': +e.target.value })
    }
    const selectMouseLeave = () => {
        // cityRef.current && cityRef.current.blur();
    }

    return (
        <div className="phone-wrap">
            {/* <Title>{f({ id: 'register.phone.title01' })}</Title> */}
            <Title sub>{f({ id: 'register.phone.title' })}</Title>
            <div className='number-wrap'>
                <div className="col">
                    <span className='label'>{f({ id: 'register.phone.country' })}</span>
                    <MSelect
                        showSearch
                        // forwardRef = {cityRef}
                        value={store.model.phoneCountry}
                        className='code-select'
                        dataSource={phoneMap}
                        // onMouseLeave = {throttle(selectMouseLeave,500)}
                        onChange={handlePhoneCountryChange}
                        optionLabelProp="value"
                        filterOption={(input, option: any) => {
                            return option.value.toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }}
                        renderOpionItem={(label: string) => {
                            const labelList = label.split(' ');
                            return <div className="phone-item">
                                {labelList.map((item: any) => <span className='code'>
                                    {item}
                                </span>)}
                            </div>

                        }}

                    >
                    </MSelect>
                </div>
                <div className="col">
                    <span className='label'>{f({ id: 'register.phone.number' })}</span>
                    <FormItem name="number" rules={[{ required: true },]}>
                        <Input
                            type='tel'
                            value={store.model.phoneNumber}
                            onChange={handleNumChange}
                            // onBlur={checkExist}
                            placeholder={f({ id: 'register.phone.placeholder' })}
                            className='m-input number-input'
                        />
                    </FormItem>

                </div>

            </div>
        </div>
    )
})

export default Phone;