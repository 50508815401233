import React, { useState, useEffect } from 'react';
import { Form, Input, Upload, message,InputNumber } from 'antd';

import './index.scss';
import { uploadAction } from '../../constants';
import { Button } from '..';
import { getImageUrl } from '../../utils';
import { useForm } from 'antd/lib/form/util';
import api from '../../api';
import { FormProps } from 'antd/lib/form';

interface ICompanyForm extends FormProps {
    disabled?: boolean;
    id: string;
    onClose?: () => void;
    onSave?: () => void;
    closeText?: string;
    saveText?: string;
    arrow?: boolean;
    children?: any;
    // onChange?: (code: string, value: string) => void
    // "accountName": "string",
    // "bankAddress": "string",
    // "bankName": "string",
    // "companyEmail": "string",
    // "companyName": "string",
    // "companyWebsite": "string",
    // "createTime": "2020-05-30T09:13:15.646Z",
    // "createUser": 0,
    // "id": 0,
    // "legalPerson": "string",
    // "legalPersonId": "string",
    // "license": "string",
    // "modifyTime": "2020-05-30T09:13:15.646Z",
    // "modifyUser": 0,
    // "placeRegistration": "string",
    // "registeredCapital": 0,
    // "reputation": "string",
    // "swiftCode": "string",
    // "taxNumber": "string"
}

const CompanyForm: React.FC<ICompanyForm> = (props) => {
    const { disabled, id, onClose, onSave, closeText, saveText, arrow, ...formProps } = props;
    const [form] = useForm();

    const [uploadData, setUploadData] = useState({} as any);

    const [info, setInfo] = useState({} as any);

    useEffect(() => {
        fetchCompanyInfo();
        console.log(id)
    }, [id])

    const fetchCompanyInfo = async () => {
        if (!props.id) return;
        const data = await api.findCompanyById(id) || {};
        setInfo(data);
        form.setFieldsValue(data)
    }



    const handleUploadChange = (code: string, response: any) => {
        const resp = response.file.response;
        if (resp !== undefined) {
            if (resp.code === '200') {
                const { objectName } = resp.data;
                // setVal(objectName);
                uploadData[code] = objectName;
                const data = {
                    ...uploadData,
                    [code]: objectName
                }
                console.log(uploadData)
                setUploadData(data);
            } else {
                message.error(resp.msg || 'File upload unsuccessful. Please try again')
            }
        }
    }

    const handleClose = () => {
        onClose && onClose();
        reset();
        
    }
    
    const reset = () => {
        form.resetFields();
        setUploadData({})
    }

    const handleSave = () => {

        form.validateFields().then(async () => {
            const company = form.getFieldsValue();
            console.log(info.id)
            console.log(company)
            try {

                await api[!info.id ? 'insertCompany' : 'updateCompany']({
                    ...company,
                    ...uploadData,
                    id: info.id
                });
                message.success('Saved successfully');
                onSave && onSave();
                reset();
            } catch (error) {
                console.log(error)
            }

        });
    }

    const download = async (name: string) => {
        const obj = uploadData[name] || info[name];
        const url = await getImageUrl(obj);
        window.open(url, 'new_mdr', 'width=600,height=800')
    }

    return (

        <div className="company-fields">
            <Form
                {...formProps}
                form={form}
            >
                <section>
                    {/* <p className="section-title">
                        Company Documents 
                </p> */}
                    <div className='form-item-wrap'>

                        <Form.Item name='companyName' label='CompanyName' rules = {[
                            {required: true, message: 'Please Enter CompanyName'}
                        ]}>
                            <Input className='m-input' disabled={disabled}></Input>
                        </Form.Item>

                        <Form.Item name='license' 
                        label='Business Registration Certificate / Cerfificate of Incorporation'
                        rules = {[
                            {required: true, message: 'Please Upload'}
                        ]}
                        >
                            <div className="row company-upload">
                                <Input className='m-input' value={uploadData.license || info.license} readOnly />
                                <Upload
                                    data={{ type: 'other' }}
                                    action={uploadAction}
                                    showUploadList={false}
                                    disabled={disabled}
                                    onChange={(resp) => handleUploadChange('license', resp)}
                                >
                                    <Button size='small' type='ghost'>Upload</Button>
                                </Upload>

                                {(uploadData.license || info.license) && <a onClick={() => download('license')}>Click to view</a>}
                            </div>

                        </Form.Item>

                        <Form.Item name='reputation' label='Certificate of Good Standing'>
                            <div className="row company-upload">
                                <Input className='m-input' value={uploadData.reputation || info.reputation} readOnly />
                                <Upload
                                    data={{ type: 'other' }}
                                    action={uploadAction}
                                    showUploadList={false}
                                    disabled={disabled}
                                    onChange={(resp) => handleUploadChange('reputation', resp)}
                                >
                                    <Button size='small' type='ghost'>Upload</Button>
                                </Upload>
                                {(uploadData.reputation || info.reputation) && <a onClick={() => download('reputation')}>Click to view</a>}
                            </div>
                        </Form.Item>


                        <Form.Item name='taxNumber' label='Tax Number'>
                            <Input className='m-input' disabled={disabled}></Input>
                        </Form.Item>

                        <Form.Item name='placeRegistration' 
                        label='Place of Registration'
                        rules = {[
                            {required: true, message: 'Please Enter the Place of Registration '}
                        ]}>
                            <Input className='m-input' disabled={disabled}></Input>
                        </Form.Item>

                        <Form.Item name='registeredCapital' 
                        label='Registered Capital(USD)'
                        rules = {[
                            {required: true, message: 'Please Enter the Registered Capital'}
                        ]}
                        >
                            <InputNumber
                            className="ant-input-number"
                            style = {{width: "317px",height: "45px",lineHeight: "45px"}}
                            disabled={disabled}
                            precision={2}
                            min={0}
                            formatter={value => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                            parser={(value:any) => value.replace(/[^\d^\.]+/g, '')}//增加千分位设置
                            ></InputNumber>
                            
                        </Form.Item>

                        <Form.Item name='legalPerson' 
                        label='CEO/Managing Director'
                        rules = {[
                            {required: true, message: 'Please Enter Value'}
                        ]}
                        >
                            <Input className='m-input' disabled={disabled}></Input>
                        </Form.Item>


                        <Form.Item name='legalPersonId' label='CEO/Managing Director ID'
                        rules = {[
                            {required: true, message: 'Please Upload'}
                        ]}>
                            <div className="row company-upload">
                                <Input className='m-input' value={uploadData.legalPersonId || info.legalPersonId} readOnly />
                                <Upload
                                    data={{ type: 'other' }}
                                    action={uploadAction}
                                    showUploadList={false}
                                    disabled={disabled}
                                    onChange={(resp) => handleUploadChange('legalPersonId', resp)}
                                >
                                    <Button size='small' type='ghost'>Upload</Button>
                                </Upload>
                                {(uploadData.legalPersonId || info.legalPersonId) && <a onClick={() => download('legalPersonId')}>Click to view</a>}
                            </div>
                        </Form.Item>


                        <Form.Item name='companyEmail' label='Company Email'
                        rules = {[
                            {required: true, message: 'Please Enter Company Email'}
                        ]}>
                            <Input className='m-input' disabled={disabled}></Input>
                        </Form.Item>

                        <Form.Item name='companyWebsite' label='Company Website(if avallable)'>
                            <Input className='m-input' disabled={disabled}></Input>
                        </Form.Item>
                    </div>
                </section>

                <section>
                    <p className="section-title">
                        Banking Information
                </p>

                    <div className='form-item-wrap'>
                        <Form.Item name='bankName' label='Company’s Bank Name'
                        rules = {[
                            {required: true, message: 'Please Enter the Company’s Bank Name'}
                        ]}
                        >
                            <Input className='m-input' disabled={disabled}></Input>
                        </Form.Item>

                        <Form.Item name='bankAddress' label='Company’s Bank Address'
                        rules = {[
                            {required: true, message: 'Please Enter the Company’s Bank Address'}
                        ]}>
                            <Input className='m-input' disabled={disabled}></Input>
                        </Form.Item>

                        <Form.Item name='swiftCode' label='SWIFT Code'
                        rules = {[
                            {required: true, message: 'Please Enter Value'}
                        ]}
                        >
                            <Input className='m-input' disabled={disabled}></Input>
                        </Form.Item>

                        <Form.Item name='accountName' label='ACCOUNT Name'
                        rules = {[
                            {required: true, message: 'Please Enter Value'}
                        ]}>
                        
                            <Input className='m-input' disabled={disabled}></Input>
                        </Form.Item>
                    </div>
                </section>

                <div className="form-button">
                    <Button type='ghost' size='middle' arrowLeft={arrow} onClick={handleClose}>{closeText || 'Close'}</Button>
                    <Button size='middle' arrowRight={arrow} onClick={handleSave}>{saveText || 'Save'}</Button>
                </div>
            </Form>
        </div >
    )
}

export default CompanyForm