import React from 'react';
import { useIntl } from 'react-intl';

import { Button } from '../../../../../components';
import FormItem from 'antd/lib/form/FormItem';
import { PWD_REG } from '../../../../../constants';
import store from '../../store';
import './index.scss';
import { Input, message } from 'antd';
import Form, { useForm } from 'antd/lib/form/Form';
import api from '../../../../../api';
import { useStores } from '../../../../../hooks';

const Password: React.FC = () => {
    const { formatMessage: f } = useIntl();

    const  {rootStore} = useStores();

    const [form] = useForm();

    const handleSave = () => {
        form.validateFields().then(async () => {
            const data = form.getFieldsValue();
            await api.changePwd({
                ...data,
            });
            message.success('New password is updated. Please log in again');
            rootStore.logout();
            
        })
    }

    return (
        <div className="detail-password">
            <Form form={form}>
                <div className="input-item">
                    <span className='label'>Old password</span>
                    <FormItem
                        name='oldPassword'
                        rules={[{ required: true }]}
                    >
                        <Input.Password  className='m-input' placeholder='Old password' style={{ width: '100%' }} />
                    </FormItem>
                </div>

                <div className="input-item">
                    <span className='label'>New password</span>
                    <FormItem
                        name='newPassword'
                        required
                        rules={
                            [
                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (value && PWD_REG.test(getFieldValue('newPassword'))) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(f({ id: 'register.password.one.placeholder' }));
                                    },
                                })
                            ]
                        }
                    >
                        <Input.Password className='m-input' placeholder='New password' style={{ width: '100%' }} />
                    </FormItem>

                </div>

                <div className="input-item form-group">
                    <span className='label'>Confirm new password</span>
                    <FormItem
                        name='confirm'
                        dependencies={['newPassword']}
                        rules={
                            [
                                { required: true, message: 'confirm password is required' },

                                ({ getFieldValue }) => ({
                                    validator(rule, value) {
                                        if (!value || getFieldValue('newPassword') === value) {
                                            store.updateModel({ password: value })
                                            return Promise.resolve();
                                        }
                                        return Promise.reject('The two passwords that you entered do not match!');
                                    },
                                })
                            ]
                        }
                    >
                        <Input.Password  className='m-input' placeholder='Confirm new password' style={{ width: '100%' }} />
                    </FormItem>

                </div>

            </Form>
            <div className="btn-wrap">
                <Button onClick={handleSave} size='small'>Submit</Button>
            </div>
        </div >
    )
}

export default Password;