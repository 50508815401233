import React from 'react';
import { List } from 'antd';
import './index.scss';
import { Title, Button, Folder } from '../../../../../../components';
import { useHistory } from 'react-router-dom';
import dayjs from 'dayjs';
import { formatMoney,transLocal } from '../../../../../../utils';




const cols = (data: any) => [
    {
        key: 'Number of tokens refunded',
        value: data.numberOfToken,
        extra: 'Tokens'
    },
    {
        key: 'Token price',
        value: transLocal(data.tokenPrice),
        extra: 'MDRX per Token'
    },
    {
        key: 'Subtotal refund',
        value: transLocal(data.subTotal),
        extra: 'MDRX'
    },
    {
        key: 'Transaction fee refunded',
        value: transLocal(data.transactionFee),
        extra: `MDRX(${isNaN(Number(data.transfee))?  '': Number(data.transfee) * 100}${isNaN(Number(data.transfee))? '': '%'})`
    },
    {
        key: 'Total refunded',
        value: transLocal(data.totalPay),
        extra: 'MDRX'
    },
    {
        key: 'Refunded Time',
        value: data.purchase,
        extra: ''
    },
]

interface IProps {
    dataSource: any
}


const Purchase: React.FC<IProps> = ({ dataSource }) => {
    
    const handleToStoDetail = () => {
        const url = `${window.location.origin}/#/usercenter/sto/apply?stoId=${100}`
        window.open(url)
    }

    const { createTime } = dataSource;

    const data = cols(dataSource);

    return (
        <div className="detail-investment">
            <Folder fold={true} title={`${dayjs(createTime).format('YYYY-MM-DD')} Refund`}>
                <div className="list-wrap" style={{ marginTop: '10px' }}>
                    <List
                        bordered
                        dataSource={data.map(item => JSON.stringify(item))}
                        renderItem={(item: string) => {
                            const items: any = JSON.parse(item);
                            return (
                                <List.Item className='row property-item'>
                                    {/* <div className='r0w'> */}
                                    <p className='label' >{items.key}</p>
                                    <p className='value'>{items.value}</p>
                                    <p className='extra'>{items.extra}</p>
                                    {/* </div>    */}
                                </List.Item>
                            )
                        }}
                    />
                </div>
            </Folder>



        </div>

    )
}

export default Purchase